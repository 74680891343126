import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";
import {message, Modal, Space, Button, Form, Row, Col, Input, Table} from "antd";
import {FileDoneOutlined, CloseCircleOutlined} from "@ant-design/icons";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./Modal.module.css";

const EnterpriseModal = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const [enterprisePageIndex, setEnterprisePageIndex] = useState(1);
    const [enterprisePageSize, setEnterprisePageSize] = useState(store.main.pageSize);
    const [enterpriseIdList, setEnterpriseIdList] = useState([]);
    const [enterpriseTotal, setEnterpriseTotal] = useState(0);
    const [enterpriseList, setEnterpriseList] = useState([]);
    const [selectList, setSelectList] = useState([]);

    useEffect(() => {
        if (props.visible) {
            handleSearchEnterprise();
        }

        //eslint-disable-next-line
    }, [props.visible]);

    const handleSearchEnterprise = () => {
        handleLoadEnterprise(1, enterprisePageSize);
    };

    const handleLoadEnterprise = (enterprisePageIndex, enterprisePageSize) => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        let data = form.getFieldsValue();
        data.pageIndex = enterprisePageIndex;
        data.pageSize = enterprisePageSize;

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/enterprise/business/website/v1/modal/list",
            data: data,
            success: (response) => {
                setEnterprisePageIndex(enterprisePageIndex);
                setEnterprisePageSize(enterprisePageSize);
                setEnterpriseIdList([]);
                setEnterpriseTotal(response.data.total);
                setEnterpriseList(response.data.list);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
                setIsError(
                    typeof response.data == "undefined" ||
                        response.data.total == "undefined" ||
                        response.data.total == null ||
                        response.data.list == "undefined" ||
                        response.data.list == null
                );
            },
        });
    };

    const handleChangeEnterprisePagination = (enterprisePageIndex, enterprisePageSize) => {
        handleLoadEnterprise(enterprisePageIndex, enterprisePageSize);
    };

    const handleSelectEnterprise = (enterpriseIdList) => {
        let selectList = [];
        for (let i = 0; i < enterpriseList.length; i++) {
            let isExit = false;

            for (let j = 0; j < enterpriseIdList.length; j++) {
                if (enterpriseList[i].enterpriseId == enterpriseIdList[j]) {
                    isExit = true;

                    break;
                }
            }

            if (isExit) {
                selectList.push(enterpriseList[i]);
            }
        }

        setEnterpriseIdList(enterpriseIdList);
        setSelectList(selectList);
    };

    const handleClickEnterprise = (enterprise) => {
        let index = -1;

        for (let i = 0; i < enterpriseIdList.length; i++) {
            if (enterprise.enterpriseId == enterpriseIdList[i]) {
                index = i;
                break;
            }
        }

        if (index == -1) {
            if (props.multiple) {
                enterpriseIdList.push(enterprise.enterpriseId);
            } else {
                enterpriseIdList.splice(0, enterpriseIdList.length);
                enterpriseIdList.push(enterprise.enterpriseId);
            }
        } else {
            enterpriseIdList.splice(index, 1);
        }

        let selectList = [];
        for (let i = 0; i < enterpriseList.length; i++) {
            let isExit = false;

            for (let j = 0; j < enterpriseIdList.length; j++) {
                if (enterpriseList[i].enterpriseId == enterpriseIdList[j]) {
                    isExit = true;

                    break;
                }
            }

            if (isExit) {
                selectList.push(enterpriseList[i]);
            }
        }

        setEnterpriseIdList([...enterpriseIdList]);
        setSelectList(selectList);
    };

    const handleCancelEnterprise = () => {
        setEnterpriseIdList([]);
        setSelectList([]);

        props.onClose();
    };

    const handleSubmitEnterprise = () => {
        if (selectList.length > 0) {
            setEnterpriseIdList([]);
            setSelectList([]);

            if (props.multiple) {
                props.onSubmit(selectList);
            } else {
                props.onSubmit(selectList[0]);
            }
        }

        props.onClose();
    };

    return (
        <Modal
            title="企业"
            visible={props.visible}
            centered
            width={ChuangShiOS.Util.getModalWidth()}
            bodyStyle={{
                backgroundColor: "#eff3f8",
                padding: "20px",
            }}
            cancelButtonProps={{
                icon: <CloseCircleOutlined />,
                size: "large",
            }}
            okButtonProps={{
                icon: <FileDoneOutlined />,
                size: "large",
            }}
            onCancel={handleCancelEnterprise}
            onOk={handleSubmitEnterprise}
            footer={
                <div className={Style.footer}>
                    <div className={Style.footer_space}></div>
                    <div className={Style.footer_count}>已选{selectList.length}条</div>
                    <Space>
                        <Button icon={<CloseCircleOutlined />} size="large" onClick={handleCancelEnterprise}>
                            取消
                        </Button>
                        <Button loading={isLoad} icon={<FileDoneOutlined />} type="primary" size="large" onClick={handleSubmitEnterprise}>
                            提交
                        </Button>
                    </Space>
                </div>
            }
        >
            <div style={{height: ChuangShiOS.Util.getModalHeight() + "px"}} className={Style.page}>
                <ChuangShiOS.Content
                    className={Style.content}
                    isLoad={isLoad}
                    isEmpty={false}
                    isError={isError}
                    marginLeft={0}
                    marginRight={0}
                    paddingTop={0}
                    paddingRight={0}
                    paddingBottom={0}
                    paddingLeft={0}
                >
                    <div className={Style.main}>
                        <Form form={form}>
                            <Row>
                                <Col span={6}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "none"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="企业名称"
                                        labelAlign={"left"}
                                        name="enterpriseName"
                                        initialValue={""}
                                        rules={[
                                            {
                                                required: false,
                                                message: "请输入企业名称",
                                            },
                                        ]}
                                    >
                                        <Input className={Style.card_field} placeholder="请输入企业名称" onPressEnter={handleSearchEnterprise} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "none"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="企业代码"
                                        labelAlign={"left"}
                                        name="enterpriseCode"
                                        initialValue={""}
                                        rules={[
                                            {
                                                required: false,
                                                message: "请输入企业代码",
                                            },
                                        ]}
                                    >
                                        <Input className={Style.card_field} placeholder="请输入企业代码" onPressEnter={handleSearchEnterprise} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                        <div className={Style.card_table}>
                            <Table
                                size="small"
                                rowKey={(record) => record.enterpriseId}
                                columns={[
                                    {
                                        title: "企业名称",
                                        dataIndex: "enterpriseName",
                                    },
                                    {
                                        title: "企业代码",
                                        dataIndex: "enterpriseCode",
                                    },
                                ]}
                                dataSource={enterpriseList}
                                rowSelection={{
                                    fixed: true,
                                    type: props.multiple ? "checkbox" : "radio",
                                    selectedRowKeys: enterpriseIdList,
                                    onChange: handleSelectEnterprise,
                                }}
                                onRow={(record) => {
                                    return {
                                        onClick: () => {
                                            handleClickEnterprise(record);
                                        },
                                    };
                                }}
                                scroll={{y: ChuangShiOS.Util.getModalHeight() - 200}}
                                pagination={{
                                    size: "default",
                                    showQuickJumper: true,
                                    showSizeChanger: true,
                                    current: enterprisePageIndex,
                                    pageSize: enterprisePageSize,
                                    total: enterpriseTotal,
                                    showTotal: (total, range) => "显示" + range[0] + "到" + range[1] + "条,共有" + total + "条",
                                    onChange: handleChangeEnterprisePagination,
                                    onShowSizeChange: handleChangeEnterprisePagination,
                                }}
                            />
                        </div>
                    </div>
                </ChuangShiOS.Content>
            </div>
        </Modal>
    );
};

EnterpriseModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    multiple: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

EnterpriseModal.defaultProps = {};

export default EnterpriseModal;
