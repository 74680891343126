import React from "react";
import {Route} from "react-router-dom";
import KeepAlive from "react-activation";
import ChuangShiOS from "chuangshi-os-website-component";

import GoodsSkuCategoryIndex from "./Index";
import GoodsSkuCategoryDetail from "./Detail";

const data = [
    <Route
        exact
        key="GoodsSkuCategoryIndex"
        path="/goods/sku/category/index"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return (
                <KeepAlive name="/goods/sku/category/index">
                    <GoodsSkuCategoryIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="GoodsSkuCategoryAdd"
        path="/goods/sku/category/add"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <GoodsSkuCategoryDetail {...props} />;
        }}
    />,
    <Route
        exact
        key="GoodsSkuCategoryEdit"
        path="/goods/sku/category/edit/:goodsSkuCategoryId"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <GoodsSkuCategoryDetail {...props} />;
        }}
    />,
];

export default data;
