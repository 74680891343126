import React from "react";
import {Route} from "react-router-dom";
import KeepAlive from "react-activation";
import ChuangShiOS from "chuangshi-os-website-component";

import BenefitIndex from "./Index";
import BenefitDetail from "./Detail";

const data = [
    <Route
        exact
        key="BenefitIndex"
        path="/benefit/index"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return (
                <KeepAlive name="/benefit/index">
                    <BenefitIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="BenefitAdd"
        path="/benefit/add"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <BenefitDetail {...props} />;
        }}
    />,
    <Route
        exact
        key="BenefitEdit"
        path="/benefit/edit/:benefitId"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <BenefitDetail {...props} />;
        }}
    />,
];

export default data;
