import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {message, Breadcrumb, Form, Row, Col, Input, Select, Space, Button, Divider, Table, Checkbox} from "antd";
import {LeftCircleOutlined, FileDoneOutlined} from "@ant-design/icons";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./Detail.module.css";

const RoleDetail = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [systemVersion, setSystemVersion] = useState(0);
    const [menuExpandedRowKeyList, setMenuExpandedRowKeyList] = useState([]);
    const [menuList, setMenuList] = useState([]);

    useEffect(() => {
        if (store.main.userName) {
            ChuangShiOS.Notification.emit("main_menu_active", {url: "/role/index"});

            switch (props.match.path) {
                case "/role/add":
                    setIsSave(true);

                    handleLoadMenu();

                    break;
                case "/role/edit/:roleId":
                    setIsEdit(true);

                    handleLoadRole();

                    break;
                default:
                    break;
            }
        }

        //eslint-disable-next-line
    }, [store.main.userName]);

    const handleLoadMenu = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/menu/business/website/v1/permission/list",
            data: {
                roleId: props.match.params.roleId,
            },
            success: (response) => {
                let menuExpandedRowKeyList = handleExpandedMenu(response.data);
                setMenuExpandedRowKeyList(menuExpandedRowKeyList);

                setMenuList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleExpandedMenu = (menuList) => {
        let menuExpandedRowKeyList = [];
        for (let i = 0; i < menuList.length; i++) {
            menuExpandedRowKeyList.push(menuList[i].menuId);

            if (menuList[i].children) {
                menuExpandedRowKeyList = menuExpandedRowKeyList.concat(handleExpandedMenu(menuList[i].children));
            }
        }
        return menuExpandedRowKeyList;
    };

    const handleLoadRole = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/role/business/website/v1/find",
            data: {
                roleId: props.match.params.roleId,
            },
            success: (response) => {
                form.setFieldsValue(response.data);

                let menuExpandedRowKeyList = handleExpandedMenu(response.data.menuList);
                setMenuExpandedRowKeyList(menuExpandedRowKeyList);

                setMenuList(response.data.menuList);
                setSystemVersion(response.data.systemVersion);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
                setIsError(typeof response.data == "undefined" || typeof response.data.roleId == "undefined" || response.data.roleId == null);
            },
        });
    };

    const handleSubmitRole = () => {
        if (request.current) {
            return;
        }

        form.validateFields()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                data.roleId = props.match.params.roleId;
                data.permissionIdList = handleExpandedPermissionId(menuList);
                data.systemVersion = systemVersion;

                let url = "";
                if (isSave) {
                    url = "/windeal/role/business/website/v1/save";
                }
                if (isEdit) {
                    url = "/windeal/role/business/website/v1/update";
                }

                ChuangShiOS.Util.request(props, {
                    url: Application.host + url,
                    data: data,
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);

                        ChuangShiOS.Util.navigateBack(props, {});
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            })
            .catch((response) => {
                ChuangShiOS.Util.parseResponseError(response);
            });
    };

    const handleExpandedPermissionId = (menuList) => {
        let permissionId = [];
        for (let i = 0; i < menuList.length; i++) {
            for (let j = 0; j < menuList[i].permissionList.length; j++) {
                if (menuList[i].permissionList[j].active) {
                    permissionId.push(menuList[i].permissionList[j].permissionId);
                }
            }

            if (menuList[i].children) {
                permissionId = permissionId.concat(handleExpandedPermissionId(menuList[i].children));
            }
        }
        return permissionId;
    };

    const handleBackRole = () => {
        ChuangShiOS.Util.navigateBack(props, {});
    };

    const handleChangePermission = (menuId, permissionIndex) => {
        handleExpandedChange(menuList, menuId, permissionIndex);

        setMenuList([...menuList]);
    };

    const handleExpandedChange = (menuList, menuId, permissionIndex) => {
        for (let i = 0; i < menuList.length; i++) {
            if (menuList[i].menuId == menuId) {
                menuList[i].permissionList[permissionIndex].active = !menuList[i].permissionList[permissionIndex].active;

                break;
            } else {
                if (menuList[i].children) {
                    handleExpandedChange(menuList[i].children, menuId, permissionIndex);
                }
            }
        }
    };

    return (
        <div className={Style.page}>
            <div className={Style.header}>
                <div className={Style.header_title}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <span>首页</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span>{store.main.menuName}</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className={Style.header_title_text}>{store.main.sidebarName}</div>
                </div>
                <div className={Style.header_button}>
                    <Space>
                        <Button.Group>
                            <Button icon={<LeftCircleOutlined />} size="large" onClick={handleBackRole}>
                                返回
                            </Button>
                        </Button.Group>
                        <Button
                            loading={isLoad}
                            disabled={isError}
                            icon={<FileDoneOutlined />}
                            type="primary"
                            size="large"
                            onClick={handleSubmitRole}
                        >
                            提交
                        </Button>
                    </Space>
                </div>
            </div>
            <ChuangShiOS.Content
                className={Style.content}
                isLoad={isLoad}
                isEmpty={false}
                isError={isError}
                paddingTop={28}
                isScroll={store.main.isFixedContent}
            >
                <Row>
                    <Col flex="auto">
                        <div className={Style.card_title}>基本信息</div>
                    </Col>
                    <Col></Col>
                </Row>
                <Form form={form}>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="角色名称"
                                name="roleName"
                                initialValue={""}
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入角色名称",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入角色名称" onPressEnter={handleSubmitRole} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="角色类型"
                                name="roleType"
                                initialValue={null}
                                rules={[
                                    {
                                        required: false,
                                        message: "请选择角色类型",
                                    },
                                ]}
                            >
                                <Select className={Style.card_field} placeholder="请选择角色类型" allowClear onChange={(value) => {}}>
                                    <Select.Option value={"NORMAL"}>普通用户</Select.Option>
                                    <Select.Option value={"ADMIN"}>管理员</Select.Option>
                                    <Select.Option value={"MERCHANT"}>商户</Select.Option>
                                    <Select.Option value={"MERCHANT_FINANCE"}>商户财务</Select.Option>
                                    <Select.Option value={"ENTERPRISE"}>企业</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Divider />
                <Row>
                    <Col flex="auto">
                        <div className={Style.card_title}>菜单权限</div>
                    </Col>
                    <Col></Col>
                </Row>
                <Row>
                    <Col flex={"12%"}></Col>
                    <Col flex="auto" className={Style.card_value}>
                        <Table
                            className={Style.card_table}
                            size="large"
                            rowKey={(record) => record.menuId}
                            columns={[
                                {
                                    title: "菜单名称",
                                    dataIndex: "menuName",
                                },
                                {
                                    title: "菜备注",
                                    dataIndex: "menuRemark",
                                },
                                {
                                    title: "权限名称",
                                    dataIndex: "permissionName",
                                    render: (text, record, recordIndex) => (
                                        <div className={Style.card_table_row}>
                                            {record.permissionList.map((permission, permissionIndex) => {
                                                return (
                                                    <div key={permissionIndex} className={Style.card_table_checkbox}>
                                                        <Checkbox
                                                            checked={permission.active}
                                                            onChange={(event) => {
                                                                handleChangePermission(record.menuId, permissionIndex);
                                                            }}
                                                        >
                                                            {permission.permissionName}
                                                        </Checkbox>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ),
                                },
                            ]}
                            dataSource={menuList}
                            expandable={{
                                expandIcon: ({expanded, onExpand, record}) => {
                                    return "";
                                },
                                expandedRowKeys: menuExpandedRowKeyList,
                            }}
                            pagination={false}
                        />
                        <br />
                    </Col>
                </Row>
            </ChuangShiOS.Content>
            <div className={Style.footer}></div>
        </div>
    );
};

RoleDetail.propTypes = {};

RoleDetail.defaultProps = {};

export default RoleDetail;
