import React from "react";
import {Route} from "react-router-dom";
import KeepAlive from "react-activation";
import ChuangShiOS from "chuangshi-os-website-component";

import MenuIndex from "./Index";
import MenuDetail from "./Detail";

const data = [
    <Route
        exact
        key="MenuIndex"
        path="/menu/index"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return (
                <KeepAlive name="/menu/index">
                    <MenuIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="MenuAdd"
        path="/menu/add"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <MenuDetail {...props} />;
        }}
    />,
    <Route
        exact
        key="MenuEdit"
        path="/menu/edit/:menuId"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <MenuDetail {...props} />;
        }}
    />,
];

export default data;
