const initialState = {
    menuName: "",
    sidebarName: "",
    userName: "",
    userAccount: "",
    permissionKeyList: [],
    isShowHeader: true,
    isShowSider: true,
    isFixedContent: true,
    pageSize: 10,
    scrollTop: 0,
};

function reducer(state = initialState, action) {
    switch (action.type) {
        case "main":
            return Object.assign({}, state, action.data);

        default:
            return state;
    }
}

const data = {
    name: "main",
    reducer: reducer,
};

export default data;
