import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {useActivate, useUnactivate} from "react-activation";
import {message, Modal, Breadcrumb, Space, Button, Form, Row, Col, Input, Table, Tag, Select} from "antd";
import {FileAddOutlined, DeleteOutlined, CloseCircleOutlined, FileSearchOutlined, FileTextOutlined, InsuranceOutlined} from "@ant-design/icons";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import PasswordModal from "./PasswordModal";

import Style from "./Index.module.css";

const UserIndex = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const [tableHeight, setTableHeight] = useState(0);
    const [userPageIndex, setUserPageIndex] = useState(1);
    const [userPageSize, setUserPageSize] = useState(store.main.pageSize);
    const [userIdList, setUserIdList] = useState([]);
    const [userTotal, setUserTotal] = useState(0);
    const [userList, setUserList] = useState([]);
    const [userId, setUserId] = useState("");
    const [isPassword, setIsPassword] = useState(false);

    useEffect(() => {
        if (store.main.userName) {
            ChuangShiOS.Notification.emit("main_menu_active", {url: "/user/index"});

            handleSearchUser();
        }

        //eslint-disable-next-line
    }, [store.main.userName]);

    useEffect(() => {
        handleLoadUser(userPageIndex, store.main.pageSize);

        //eslint-disable-next-line
    }, [store.main.pageSize]);

    useActivate(() => {
        ChuangShiOS.Notification.emit("main_set_scroll_top", {});

        handleLoadUser(userPageIndex, userPageSize);
    });

    useUnactivate(() => {
        ChuangShiOS.Notification.emit("main_get_scroll_top", {});
    });

    const handleSearchUser = () => {
        handleLoadUser(1, userPageSize);
    };

    const handleLoadUser = (userPageIndex, userPageSize) => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        let data = form.getFieldsValue();
        data.pageIndex = userPageIndex;
        data.pageSize = userPageSize;

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/user/business/website/v1/list",
            data: data,
            success: (response) => {
                setUserPageIndex(userPageIndex);
                setUserPageSize(userPageSize);
                setUserIdList([]);
                setUserTotal(response.data.total);
                setUserList(response.data.list);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
                setIsError(
                    typeof response.data == "undefined" ||
                        response.data.total == "undefined" ||
                        response.data.total == null ||
                        response.data.list == "undefined" ||
                        response.data.list == null
                );
            },
        });
    };

    const handleAddUser = () => {
        ChuangShiOS.Util.navigateTo(props, {
            url: "/user/add",
        });
    };

    const handleEditUser = (userId) => {
        ChuangShiOS.Util.navigateTo(props, {
            url: "/user/edit/" + userId,
        });
    };

    const handleDelUser = () => {
        if (userIdList.length == 0) {
            return;
        }

        if (request.current) {
            return;
        }

        Modal.confirm({
            title: "是否确认操作？",
            content: "本次数据操作后将无法恢复",
            okText: "确认",
            okType: "danger",
            okButtonProps: {
                icon: <DeleteOutlined />,
            },
            cancelText: "取消",
            cancelButtonProps: {
                icon: <CloseCircleOutlined />,
            },
            onOk: () => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                ChuangShiOS.Util.request(props, {
                    url: Application.host + "/windeal/user/business/website/v1/delete",
                    data: {
                        userIdList: userIdList,
                    },
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                        setUserIdList([]);

                        handleLoadUser(userPageIndex, userPageSize);
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            },
        });
    };

    const handleChangeUserIdList = (userIdList) => {
        setUserIdList(userIdList);
    };

    const handleChangeUserPagination = (userPageIndex, userPageSize) => {
        handleLoadUser(userPageIndex, userPageSize);
    };

    const handleOpenPassword = (userId) => {
        setUserId(userId);
        setIsPassword(true);
    };

    const handleClosePassword = () => {
        setIsPassword(false);
    };

    const handleSubmitPassword = (data) => {
        setIsPassword(false);

        handleLoadUser(userPageIndex, userPageSize);
    };

    return (
        <div className={Style.page}>
            <div className={Style.header}>
                <div className={Style.header_title}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <span>首页</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span>{store.main.menuName}</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className={Style.header_title_text}>{store.main.sidebarName}</div>
                </div>
                <div className={Style.header_button}>
                    <Space>
                        <Button.Group>
                            <Button icon={<FileAddOutlined />} size="large" onClick={handleAddUser}>
                                新增
                            </Button>
                            <Button loading={isLoad} disabled={userIdList.length == 0} icon={<DeleteOutlined />} size="large" onClick={handleDelUser}>
                                删除
                            </Button>
                        </Button.Group>
                        <Button loading={isLoad} icon={<FileSearchOutlined />} type="primary" size="large" onClick={handleSearchUser}>
                            查询
                        </Button>
                    </Space>
                </div>
            </div>
            <ChuangShiOS.Content className={Style.content} isLoad={isLoad} isEmpty={false} isError={isError} isScroll={store.main.isFixedContent}>
                <Form form={form}>
                    <Row>
                        <Col span={6}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "none"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="用户姓名"
                                labelAlign={"left"}
                                name="userName"
                                initialValue={""}
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入用户姓名",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入用户姓名" onPressEnter={handleSearchUser} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "none"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="用户账号"
                                labelAlign={"left"}
                                name="userAccount"
                                initialValue={""}
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入用户账号",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入用户账号" onPressEnter={handleSearchUser} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "none"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="用户类型"
                                labelAlign={"left"}
                                name="userType"
                                initialValue={null}
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入用户账号",
                                    },
                                ]}
                            >
                                <Select className={Style.card_field} placeholder="请选择用户类型" allowClear onChange={handleSearchUser}>
                                    <Select.Option value={"NORMAL"}>普通用户</Select.Option>
                                    <Select.Option value={"ADMIN"}>管理员</Select.Option>
                                    <Select.Option value={"MERCHANT"}>商户</Select.Option>
                                    <Select.Option value={"MERCHANT_FINANCE"}>商户财务</Select.Option>
                                    <Select.Option value={"ENTERPRISE"}>企业</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div
                    ref={(ref) => {
                        if (ref != null && tableHeight == 0 && tableHeight != ref.clientHeight) {
                            setTableHeight(ref.clientHeight);
                        }
                    }}
                    className={store.main.isFixedContent ? Style.card_table : null}
                >
                    <Table
                        size="small"
                        rowKey={(record) => record.userId}
                        columns={[
                            {
                                title: "用户类型",
                                dataIndex: "userTypeValue",
                            },
                            {
                                title: "用户姓名",
                                dataIndex: "userName",
                            },
                            {
                                title: "用户账号",
                                dataIndex: "userAccount",
                            },
                            {
                                title: "所属商户",
                                dataIndex: "merchantName",
                            },
                            {
                                title: "所属银行",
                                dataIndex: "bankName",
                            },
                            {
                                title: "用户状态",
                                dataIndex: "userStatus",
                                render: (text, record, recordIndex) =>
                                    record.userStatus ? <Tag color="blue">正常</Tag> : <Tag color="red">冻结</Tag>,
                            },
                            {
                                title: "操作",
                                width: 300,
                                render: (text, record, recordIndex) => (
                                    <Space>
                                        <Button icon={<FileTextOutlined />} size="default" onClick={() => handleEditUser(record.userId)}>
                                            修改
                                        </Button>
                                        <Button loading={isLoad} icon={<InsuranceOutlined />} onClick={() => handleOpenPassword(record.userId)}>
                                            修改密码
                                        </Button>
                                    </Space>
                                ),
                            },
                        ]}
                        dataSource={userList}
                        rowSelection={{
                            fixed: true,
                            selectedRowKeys: userIdList,
                            onChange: handleChangeUserIdList,
                        }}
                        scroll={store.main.isFixedContent ? {y: tableHeight - 130} : {}}
                        pagination={{
                            size: "default",
                            showQuickJumper: true,
                            showSizeChanger: true,
                            current: userPageIndex,
                            pageSize: userPageSize,
                            total: userTotal,
                            showTotal: (total, range) => "显示" + range[0] + "到" + range[1] + "条,共有" + total + "条",
                            onChange: handleChangeUserPagination,
                            onShowSizeChange: handleChangeUserPagination,
                        }}
                    />
                </div>
            </ChuangShiOS.Content>
            <div className={Style.footer}></div>
            <PasswordModal visible={isPassword} userId={userId} onClose={handleClosePassword} onSubmit={handleSubmitPassword}></PasswordModal>
        </div>
    );
};

UserIndex.propTypes = {};

UserIndex.defaultProps = {};

export default UserIndex;
