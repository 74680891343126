import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {message, Breadcrumb, Form, Row, Col, Input, InputNumber, DatePicker, TimePicker, Space, Button, Select, Cascader} from "antd";
import {LeftCircleOutlined, FileDoneOutlined} from "@ant-design/icons";
import moment from "moment";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./Detail.module.css";

const TradeDetail = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [activityId, setActivityId] = useState("");
    const [benefitId, setBenefitId] = useState("");
    const [systemVersion, setSystemVersion] = useState(0);
    const [activityList, setActivityList] = useState([]);
    const [benefitList, setBenefitList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [shopList, setShopList] = useState([]);
    const [goodsSkuList, setGoodsSkuList] = useState([]);
    const [minDate, setMinDate] = useState(null);
    const [maxDate, setMaxDate] = useState(null);
    const [calendarList, setCalendarList] = useState([]);
    const [startHour, setStartHour] = useState(0);
    const [startMinute, setStartMinute] = useState(0);
    const [endHour, setEndHour] = useState(0);
    const [endMinute, setEndMinute] = useState(0);
    const [exclude, setExclude] = useState(false);
    const [excludeStartHour, setExcludeStartHour] = useState(0);
    const [excludeStartMinute, setExcludeStartMinute] = useState(0);
    const [excludeEndHour, setExcludeEndHour] = useState(0);
    const [excludeEndMinute, setExcludeEndMinute] = useState(0);

    useEffect(() => {
        if (store.main.userName) {
            ChuangShiOS.Notification.emit("main_menu_active", {url: "/trade/enterprise/index"});

            handleLoadCity();

            switch (props.match.path) {
                case "/trade/enterprise/add":
                    setIsSave(true);

                    handleLoadActivity();

                    break;
                case "/trade/enterprise/edit/:tradeId":
                    setIsEdit(true);

                    handleLoadTrade();

                    break;
                default:
                    break;
            }
        }

        //eslint-disable-next-line
    }, [store.main.userName]);

    const handleLoadCity = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/city/business/website/v1/cascader/list",
            data: {},
            success: (response) => {
                setCityList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadActivity = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/activity/business/website/v1/enterprise/select/list",
            data: {},
            success: (response) => {
                setActivityList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadActivityShop = (activityId, cityId) => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/activity/business/website/v1/shop/select/list",
            data: {
                activityId: activityId,
                cityId: cityId,
            },
            success: (response) => {
                setShopList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadActivityGoodsSku = (activityId, shopId) => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/activity/business/website/v1/goods/sku/select/list",
            data: {
                activityId: activityId,
                shopId: shopId,
            },
            success: (response) => {
                setGoodsSkuList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadActivityGoodsDate = (activityId, goodsSkuId) => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/activity/business/website/v1/date/find",
            data: {
                activityId: activityId,
                goodsSkuId: goodsSkuId,
                startDay: isSave ? 0 : 1,
                endDay: 22,
            },
            success: (response) => {
                setMinDate(response.data.minDate);
                setMaxDate(response.data.maxDate);
                setCalendarList(response.data.calendarList);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadBenefit = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/benefit/business/website/v1/select/list",
            data: {},
            success: (response) => {
                setBenefitList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadBenefitShop = (benefitId, cityId) => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/benefit/business/website/v1/shop/select/list",
            data: {
                benefitId: benefitId,
                cityId: cityId,
            },
            success: (response) => {
                setShopList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadBenefitGoodsSku = (benefitId, shopId) => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/benefit/business/website/v1/goods/sku/select/list",
            data: {
                benefitId: benefitId,
                shopId: shopId,
            },
            success: (response) => {
                setGoodsSkuList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadBenefitGoodsDate = (memberBenefitId, benefitCategoryId, goodsSkuId) => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/benefit/business/website/v1/date/find",
            data: {
                memberBenefitId: memberBenefitId,
                benefitCategoryId: benefitCategoryId,
                goodsSkuId: goodsSkuId,
                startDay: 1,
                endDay: 30,
            },
            success: (response) => {
                setMinDate(response.data.minDate);
                setMaxDate(response.data.maxDate);
                setCalendarList(response.data.calendarList);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadGoodsTime = (goodsSkuId, reserveDate) => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/goods/business/website/v1/time/find",
            data: {
                goodsSkuId: goodsSkuId,
                reserveDate: reserveDate,
            },
            success: (response) => {
                setStartHour(response.data.startHour);
                setStartMinute(response.data.startMinute);
                setEndHour(response.data.endHour);
                setEndMinute(response.data.endMinute);
                setExclude(response.data.exclude);
                setExcludeStartHour(response.data.excludeStartHour);
                setExcludeStartMinute(response.data.excludeStartMinute);
                setExcludeEndHour(response.data.excludeEndHour);
                setExcludeEndMinute(response.data.excludeEndMinute);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadTrade = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/trade/business/website/v1/find",
            data: {
                tradeId: props.match.params.tradeId,
            },
            success: (response) => {
                response.data.provinceCity = [response.data.provinceId, response.data.cityId];
                response.data.reserveDate = moment(response.data.reserveTime, "YYYY-MM-DD HH:mm");
                response.data.reserveTime = moment(response.data.reserveTime, "YYYY-MM-DD HH:mm");

                form.setFieldsValue(response.data);

                setActivityId(response.data.activityId);
                setBenefitId(response.data.benefitId);
                setSystemVersion(response.data.systemVersion);

                if (response.data.activityId) {
                    handleLoadActivity();

                    handleLoadActivityShop(response.data.activityId, response.data.cityId);

                    handleLoadActivityGoodsSku(response.data.activityId, response.data.shopId);

                    handleLoadActivityGoodsDate(response.data.activityId, response.data.goodsSkuId);
                }

                if (response.data.benefitId) {
                    handleLoadBenefit();

                    handleLoadBenefitShop(response.data.benefitId, response.data.cityId);

                    handleLoadBenefitGoodsSku(response.data.benefitId, response.data.shopId);

                    handleLoadBenefitGoodsDate(response.data.memberBenefitId, response.data.benefitCategoryId, response.data.goodsSkuId);
                }

                handleLoadGoodsTime(response.data.goodsSkuId, response.data.reserveDate.format("YYYY-MM-DD"));
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
                setIsError(typeof response.data == "undefined" || typeof response.data.tradeId == "undefined" || response.data.tradeId == null);
            },
        });
    };

    const handleSubmitTrade = () => {
        if (request.current) {
            return;
        }

        form.validateFields()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                data.tradeId = props.match.params.tradeId;
                data.reserveTime = data.reserveDate.format("YYYY-MM-DD") + " " + data.reserveTime.format("HH:mm");
                data.systemVersion = systemVersion;

                let url = "";
                if (isSave) {
                    url = "/windeal/trade/business/website/v1/activity/save";
                }
                if (isEdit) {
                    url = "/windeal/trade/business/website/v1/update";
                }

                ChuangShiOS.Util.request(props, {
                    url: Application.host + url,
                    data: data,
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);

                        ChuangShiOS.Util.navigateBack(props, {});
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            })
            .catch((response) => {
                ChuangShiOS.Util.parseResponseError(response);
            });
    };

    const handleBackTrade = () => {
        ChuangShiOS.Util.navigateBack(props, {});
    };

    return (
        <div className={Style.page}>
            <div className={Style.header}>
                <div className={Style.header_title}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <span>首页</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span>{store.main.menuName}</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className={Style.header_title_text}>{store.main.sidebarName}</div>
                </div>
                <div className={Style.header_button}>
                    <Space>
                        <Button.Group>
                            <Button icon={<LeftCircleOutlined />} size="large" onClick={handleBackTrade}>
                                返回
                            </Button>
                        </Button.Group>
                        <Button
                            loading={isLoad}
                            disabled={isError}
                            icon={<FileDoneOutlined />}
                            type="primary"
                            size="large"
                            onClick={handleSubmitTrade}
                        >
                            提交
                        </Button>
                    </Space>
                </div>
            </div>
            <ChuangShiOS.Content
                className={Style.content}
                isLoad={isLoad}
                isEmpty={false}
                isError={isError}
                paddingTop={28}
                isScroll={store.main.isFixedContent}
            >
                <Row>
                    <Col flex="auto">
                        <div className={Style.card_title}>基本信息</div>
                    </Col>
                    <Col></Col>
                </Row>
                <Form form={form}>
                    {isSave || activityId ? (
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "24%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="活动名称"
                                    name="activityId"
                                    initialValue={null}
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择活动名称",
                                        },
                                    ]}
                                >
                                    <Select
                                        className={Style.card_field}
                                        placeholder="请选择活动名称"
                                        allowClear
                                        disabled={isEdit}
                                        onChange={(value) => {
                                            if (value) {
                                                let data = form.getFieldsValue();
                                                if (data.provinceCity && data.provinceCity.length > 0) {
                                                    handleLoadActivityShop(data.activityId, data.provinceCity[1]);
                                                }
                                            }

                                            setShopList([]);
                                            setMinDate(null);

                                            form.setFieldsValue({
                                                shopId: null,
                                                goodsSkuId: null,
                                            });
                                        }}
                                    >
                                        {activityList.map((activity, activityIndex) => {
                                            return (
                                                <Select.Option key={activityIndex} value={activity.activityId}>
                                                    {activity.activityName}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    ) : null}
                    {benefitId ? (
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "24%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="权益名称"
                                    name="benefitId"
                                    initialValue={null}
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择权益名称",
                                        },
                                    ]}
                                >
                                    <Select
                                        className={Style.card_field}
                                        placeholder="请选择权益名称"
                                        allowClear
                                        disabled={isEdit}
                                        onChange={(value) => {
                                            if (value) {
                                                let data = form.getFieldsValue();
                                                if (data.provinceCity && data.provinceCity.length > 0) {
                                                    handleLoadBenefitShop(data.actibenefitIdvityId, data.provinceCity[1]);
                                                }
                                            }

                                            setShopList([]);
                                            setMinDate(null);

                                            form.setFieldsValue({
                                                shopId: null,
                                                goodsSkuId: null,
                                            });
                                        }}
                                    >
                                        {benefitList.map((benefit, benefitIndex) => {
                                            return (
                                                <Select.Option key={benefitIndex} value={benefit.benefitId}>
                                                    {benefit.benefitName}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    ) : null}
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="城市名称"
                                name="provinceCity"
                                initialValue={null}
                                rules={[
                                    {
                                        required: true,
                                        message: "请选择城市名称",
                                    },
                                ]}
                            >
                                <Cascader
                                    className={Style.card_field}
                                    allowClear
                                    disabled={isEdit}
                                    options={cityList}
                                    placeholder="请选择城市名称"
                                    onChange={(value) => {
                                        if (value && value.length > 0) {
                                            let data = form.getFieldsValue();
                                            if (data.activityId) {
                                                handleLoadActivityShop(data.activityId, data.provinceCity[1]);
                                            }
                                        } else {
                                            form.setFieldsValue({
                                                provinceCity: null,
                                            });
                                        }

                                        setShopList([]);
                                        setMinDate(null);

                                        form.setFieldsValue({
                                            shopId: null,
                                            goodsSkuId: null,
                                        });
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="门店名称"
                                name="shopId"
                                initialValue={null}
                                rules={[
                                    {
                                        required: true,
                                        message: "请选择门店名称",
                                    },
                                ]}
                            >
                                <Select
                                    className={Style.card_field}
                                    placeholder="请选择门店名称"
                                    allowClear
                                    disabled={isEdit}
                                    onChange={(value) => {
                                        if (value) {
                                            let data = form.getFieldsValue();

                                            handleLoadActivityGoodsSku(data.activityId, data.shopId);
                                        }

                                        setMinDate(null);

                                        form.setFieldsValue({
                                            goodsSkuId: null,
                                        });
                                    }}
                                >
                                    {shopList.map((shop, shopIndex) => {
                                        return (
                                            <Select.Option key={shopIndex} value={shop.shopId}>
                                                {shop.merchantName} - {shop.shopName}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="商品名称"
                                name="goodsSkuId"
                                initialValue={null}
                                rules={[
                                    {
                                        required: true,
                                        message: "请选择商品名称",
                                    },
                                ]}
                            >
                                <Select
                                    className={Style.card_field}
                                    placeholder="请选择商品名称"
                                    allowClear
                                    disabled={isEdit}
                                    onChange={(value) => {
                                        if (value) {
                                            let data = form.getFieldsValue();

                                            handleLoadActivityGoodsDate(data.activityId, data.goodsSkuId);
                                        } else {
                                            setMinDate(null);
                                        }
                                    }}
                                >
                                    {goodsSkuList.map((goodsSku, goodsSkuIndex) => {
                                        return (
                                            <Select.Option key={goodsSkuIndex} value={goodsSku.goodsSkuId}>
                                                {goodsSku.goodsSkuName} - ¥{goodsSku.goodsSkuSalePrice}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="预定姓名"
                                name="reserveName"
                                initialValue={""}
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入预定姓名",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入预定姓名" onPressEnter={handleSubmitTrade} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="预定电话"
                                name="reservePhone"
                                initialValue={""}
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入预定电话",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入预定电话" onPressEnter={handleSubmitTrade} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="出席人数"
                                name="reserveQuantity"
                                initialValue={1}
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入出席人数",
                                    },
                                ]}
                            >
                                <InputNumber
                                    className={Style.card_field}
                                    min={1}
                                    max={999999}
                                    placeholder="请输入出席人数"
                                    onPressEnter={handleSubmitTrade}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="预定日期"
                                name="reserveDate"
                                rules={[
                                    {
                                        required: true,
                                        message: "请选择预定日期",
                                    },
                                ]}
                            >
                                <DatePicker
                                    className={Style.card_field}
                                    showNow={false}
                                    format="YYYY-MM-DD"
                                    placeholder="请输入预定日期"
                                    disabledDate={(date) => {
                                        if (!minDate) {
                                            return true;
                                        }

                                        let day = moment(date).format("YYYY-MM-DD");
                                        if (calendarList.indexOf(day) > -1) {
                                            return true;
                                        }

                                        return date && (date < moment(minDate) || date > moment(maxDate).add(1, "days"));
                                    }}
                                    onChange={(value) => {
                                        let data = form.getFieldsValue();

                                        handleLoadGoodsTime(data.goodsSkuId, value.format("YYYY-MM-DD"));

                                        form.setFieldsValue({
                                            reserveTime: "",
                                        });

                                        setStartHour(0);
                                        setStartMinute(0);
                                        setEndHour(0);
                                        setEndMinute(0);
                                    }}
                                    onPressEnter={handleSubmitTrade}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="预定时间"
                                name="reserveTime"
                                rules={[
                                    {
                                        required: true,
                                        message: "请选择预定时间",
                                    },
                                ]}
                            >
                                <TimePicker
                                    className={Style.card_field}
                                    showNow={false}
                                    format="HH:mm"
                                    placeholder="请输入预定时间"
                                    disabledHours={() => {
                                        let hour = [];

                                        if (startHour == 0 && startMinute == 0 && endHour == 0 && endMinute == 0) {
                                            for (let i = 0; i < 24; i++) {
                                                hour.push(i);
                                            }
                                            return hour;
                                        }

                                        if (exclude) {
                                            let start = moment(moment().startOf("day").add(startHour, "hours").add(startMinute, "minutes"));
                                            let end = moment(moment().startOf("day").add(endHour, "hours").add(endMinute, "minutes"));
                                            let excludeStart = moment(
                                                moment().startOf("day").add(excludeStartHour, "hours").add(excludeStartMinute, "minutes")
                                            );
                                            let excludeEnd = moment(
                                                moment().startOf("day").add(excludeEndHour, "hours").add(excludeEndMinute, "minutes")
                                            );
                                            for (let i = 0; i < 24; i++) {
                                                for (let j = 0; j < 60; j++) {
                                                    let datetime =
                                                        moment().format("YYYY-MM-DD ") + (i < 10 ? "0" + i : i) + ":" + (j < 10 ? "0" + j : j);
                                                    if (moment(datetime).isSameOrAfter(start) && moment(datetime).isSameOrBefore(end)) {
                                                        if (moment(datetime).isBefore(excludeStart) || moment(datetime).isAfter(excludeEnd)) {
                                                        } else {
                                                            if (hour.indexOf(i) == -1) {
                                                                hour.push(i);
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            for (let i = 0; i < 24; i++) {
                                                for (let j = 0; j < 60; j++) {
                                                    let datetime =
                                                        moment().format("YYYY-MM-DD ") + (i < 10 ? "0" + i : i) + ":" + (j < 10 ? "0" + j : j);
                                                    if (moment(datetime).isSameOrAfter(start) && moment(datetime).isSameOrBefore(end)) {
                                                        if (moment(datetime).isBefore(excludeStart) || moment(datetime).isAfter(excludeEnd)) {
                                                            if (hour.indexOf(i) > -1) {
                                                                hour.splice(hour.indexOf(i), 1);
                                                            }
                                                        } else {
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        for (let i = 0; i < 24; i++) {
                                            if (i < startHour || i > endHour) {
                                                hour.push(i);
                                            }
                                        }
                                        return hour;
                                    }}
                                    disabledMinutes={(selectedHour) => {
                                        let minute = [];

                                        if (selectedHour == -1 || (startHour == 0 && startMinute == 0 && endHour == 0 && endMinute == 0)) {
                                            for (let i = 0; i < 60; i++) {
                                                minute.push(i);
                                            }
                                            return minute;
                                        }

                                        let start = moment(moment().startOf("day").add(startHour, "hours").add(startMinute, "minutes"));
                                        let end = moment(moment().startOf("day").add(endHour, "hours").add(endMinute, "minutes"));
                                        let excludeStart = moment(
                                            moment().startOf("day").add(excludeStartHour, "hours").add(excludeStartMinute, "minutes")
                                        );
                                        let excludeEnd = moment(
                                            moment().startOf("day").add(excludeEndHour, "hours").add(excludeEndMinute, "minutes")
                                        );
                                        let hour = moment().format("YYYY-MM-DD ") + selectedHour + ":";

                                        if (exclude) {
                                            for (let i = 0; i < 60; i++) {
                                                let datetime = hour + (i < 10 ? "0" + i : i);
                                                if (moment(datetime).isBefore(excludeStart) || moment(datetime).isAfter(excludeEnd)) {
                                                } else {
                                                    minute.push(i);
                                                }
                                            }
                                        }

                                        for (let i = 0; i < 60; i++) {
                                            if (
                                                moment(hour + (i < 10 ? "0" + i : i)).isBefore(start) ||
                                                moment(hour + (i < 10 ? "0" + i : i)).isAfter(end)
                                            ) {
                                                if (minute.indexOf(i) == -1) {
                                                    minute.push(i);
                                                }
                                            }
                                        }

                                        return minute;
                                    }}
                                    onPressEnter={handleSubmitTrade}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="预定备注"
                                name="reserveRemark"
                                initialValue={""}
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入预定备注",
                                    },
                                ]}
                            >
                                <Input.TextArea className={Style.card_field} placeholder="请输入预定备注" rows={5} onPressEnter={handleSubmitTrade} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </ChuangShiOS.Content>
            <div className={Style.footer}></div>
        </div>
    );
};

TradeDetail.propTypes = {};

TradeDetail.defaultProps = {};

export default TradeDetail;
