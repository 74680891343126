import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {useActivate, useUnactivate} from "react-activation";
import {message, Modal, Breadcrumb, Space, Button, Form, Row, Col, Input, Table} from "antd";
import {FileAddOutlined, DeleteOutlined, CloseCircleOutlined, FileSearchOutlined, FileTextOutlined} from "@ant-design/icons";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./Index.module.css";

const ActivityBannerIndex = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const [tableHeight, setTableHeight] = useState(0);
    const [activityBannerPageIndex, setActivityBannerPageIndex] = useState(1);
    const [activityBannerPageSize, setActivityBannerPageSize] = useState(store.main.pageSize);
    const [activityBannerIdList, setActivityBannerIdList] = useState([]);
    const [activityBannerTotal, setActivityBannerTotal] = useState(0);
    const [activityBannerList, setActivityBannerList] = useState([]);

    useEffect(() => {
        if (store.main.userName) {
            ChuangShiOS.Notification.emit("main_menu_active", {url: "/activity/banner/index"});

            handleSearchActivityBanner();
        }

        //eslint-disable-next-line
    }, [store.main.userName]);

    useEffect(() => {
        handleLoadActivityBanner(activityBannerPageIndex, store.main.pageSize);

        //eslint-disable-next-line
    }, [store.main.pageSize]);

    useActivate(() => {
        ChuangShiOS.Notification.emit("main_set_scroll_top", {});

        handleLoadActivityBanner(activityBannerPageIndex, activityBannerPageSize);
    });

    useUnactivate(() => {
        ChuangShiOS.Notification.emit("main_get_scroll_top", {});
    });

    const handleSearchActivityBanner = () => {
        handleLoadActivityBanner(1, activityBannerPageSize);
    };

    const handleLoadActivityBanner = (activityBannerPageIndex, activityBannerPageSize) => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        let data = form.getFieldsValue();
        data.pageIndex = activityBannerPageIndex;
        data.pageSize = activityBannerPageSize;

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/activity/banner/business/website/v1/list",
            data: data,
            success: (response) => {
                setActivityBannerPageIndex(activityBannerPageIndex);
                setActivityBannerPageSize(activityBannerPageSize);
                setActivityBannerIdList([]);
                setActivityBannerTotal(response.data.total);
                setActivityBannerList(response.data.list);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
                setIsError(
                    typeof response.data == "undefined" ||
                        response.data.total == "undefined" ||
                        response.data.total == null ||
                        response.data.list == "undefined" ||
                        response.data.list == null
                );
            },
        });
    };

    const handleAddActivityBanner = () => {
        ChuangShiOS.Util.navigateTo(props, {
            url: "/activity/banner/add",
        });
    };

    const handleEditActivityBanner = (activityBannerId) => {
        ChuangShiOS.Util.navigateTo(props, {
            url: "/activity/banner/edit/" + activityBannerId,
        });
    };

    const handleDelActivityBanner = () => {
        if (activityBannerIdList.length == 0) {
            return;
        }

        if (request.current) {
            return;
        }

        Modal.confirm({
            title: "是否确认操作？",
            content: "本次数据操作后将无法恢复",
            okText: "确认",
            okType: "danger",
            okButtonProps: {
                icon: <DeleteOutlined />,
            },
            cancelText: "取消",
            cancelButtonProps: {
                icon: <CloseCircleOutlined />,
            },
            onOk: () => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                ChuangShiOS.Util.request(props, {
                    url: Application.host + "/windeal/activity/banner/business/website/v1/delete",
                    data: {
                        activityBannerIdList: activityBannerIdList,
                    },
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                        setActivityBannerIdList([]);

                        handleLoadActivityBanner(activityBannerPageIndex, activityBannerPageSize);
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            },
        });
    };

    const handleChangeActivityBannerIdList = (activityBannerIdList) => {
        setActivityBannerIdList(activityBannerIdList);
    };

    const handleChangeActivityBannerPagination = (activityBannerPageIndex, activityBannerPageSize) => {
        handleLoadActivityBanner(activityBannerPageIndex, activityBannerPageSize);
    };

    return (
        <div className={Style.page}>
            <div className={Style.header}>
                <div className={Style.header_title}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <span>首页</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span>{store.main.menuName}</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className={Style.header_title_text}>{store.main.sidebarName}</div>
                </div>
                <div className={Style.header_button}>
                    <Space>
                        <Button.Group>
                            <Button icon={<FileAddOutlined />} size="large" onClick={handleAddActivityBanner}>
                                新增
                            </Button>
                            <Button
                                loading={isLoad}
                                disabled={activityBannerIdList.length == 0}
                                icon={<DeleteOutlined />}
                                size="large"
                                onClick={handleDelActivityBanner}
                            >
                                删除
                            </Button>
                        </Button.Group>
                        <Button loading={isLoad} icon={<FileSearchOutlined />} type="primary" size="large" onClick={handleSearchActivityBanner}>
                            查询
                        </Button>
                    </Space>
                </div>
            </div>
            <ChuangShiOS.Content className={Style.content} isLoad={isLoad} isEmpty={false} isError={isError} isScroll={store.main.isFixedContent}>
                <Form form={form}>
                    <Row>
                        <Col span={6}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "none"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="活动编号"
                                labelAlign={"left"}
                                name="activityId"
                                initialValue={""}
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入活动编号",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入活动编号" onPressEnter={handleSearchActivityBanner} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div
                    ref={(ref) => {
                        if (ref != null && tableHeight == 0 && tableHeight != ref.clientHeight) {
                            setTableHeight(ref.clientHeight);
                        }
                    }}
                    className={store.main.isFixedContent ? Style.card_table : null}
                >
                    <Table
                        size="small"
                        rowKey={(record) => record.activityBannerId}
                        columns={[
                            {
                                title: "广告图片",
                                dataIndex: "activityBannerImage",
                            },
                            {
                                title: "广告地址",
                                dataIndex: "activityBannerUrl",
                            },
                            {
                                title: "操作",
                                width: 150,
                                render: (text, record, recordIndex) => (
                                    <Space>
                                        <Button
                                            icon={<FileTextOutlined />}
                                            size="default"
                                            onClick={() => handleEditActivityBanner(record.activityBannerId)}
                                        >
                                            修改
                                        </Button>
                                    </Space>
                                ),
                            },
                        ]}
                        dataSource={activityBannerList}
                        rowSelection={{
                            fixed: true,
                            selectedRowKeys: activityBannerIdList,
                            onChange: handleChangeActivityBannerIdList,
                        }}
                        scroll={store.main.isFixedContent ? {y: tableHeight - 130} : {}}
                        pagination={{
                            size: "default",
                            showQuickJumper: true,
                            showSizeChanger: true,
                            current: activityBannerPageIndex,
                            pageSize: activityBannerPageSize,
                            total: activityBannerTotal,
                            showTotal: (total, range) => "显示" + range[0] + "到" + range[1] + "条,共有" + total + "条",
                            onChange: handleChangeActivityBannerPagination,
                            onShowSizeChange: handleChangeActivityBannerPagination,
                        }}
                    />
                </div>
            </ChuangShiOS.Content>
            <div className={Style.footer}></div>
        </div>
    );
};

ActivityBannerIndex.propTypes = {};

ActivityBannerIndex.defaultProps = {};

export default ActivityBannerIndex;
