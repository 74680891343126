import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {message, Breadcrumb, Form, Row, Col, InputNumber, Select, Space, Button, Switch} from "antd";
import {LeftCircleOutlined, FileDoneOutlined} from "@ant-design/icons";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./Detail.module.css";

const BenefitCodeDetail = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [systemVersion, setSystemVersion] = useState(0);
    const [benefitList, setBenefitList] = useState([]);
    const [benefitCodeIsAvailable, setBenefitCodeIsAvailable] = useState(false);

    useEffect(() => {
        if (store.main.userName) {
            ChuangShiOS.Notification.emit("main_menu_active", {url: "/benefit/code/index"});

            handleLoadBenefit();

            switch (props.match.path) {
                case "/benefit/code/add":
                    setIsSave(true);

                    break;
                case "/benefit/code/edit/:benefitCodeId":
                    setIsEdit(true);

                    handleLoadBenefitCode();

                    break;
                default:
                    break;
            }
        }

        //eslint-disable-next-line
    }, [store.main.userName]);

    const handleLoadBenefit = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/benefit/business/website/v1/select/list",
            data: {},
            success: (response) => {
                setBenefitList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadBenefitCode = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/benefit/code/business/website/v1/find",
            data: {
                benefitCodeId: props.match.params.benefitCodeId,
            },
            success: (response) => {
                form.setFieldsValue(response.data);

                setSystemVersion(response.data.systemVersion);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
                setIsError(
                    typeof response.data == "undefined" || typeof response.data.benefitCodeId == "undefined" || response.data.benefitCodeId == null
                );
            },
        });
    };

    const handleSubmitBenefitCode = () => {
        if (request.current) {
            return;
        }

        form.validateFields()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                data.benefitCodeId = props.match.params.benefitCodeId;
                if (data.benefitCodeAvailable) {
                    data.benefitCodeAvailable = data.benefitCodeAvailable.format("YYYY-MM-DD");
                }
                data.systemVersion = systemVersion;

                let url = "";
                if (isSave) {
                    url = "/windeal/benefit/code/business/website/v1/save";
                }
                if (isEdit) {
                    url = "/windeal/benefit/code/business/website/v1/update";
                }

                ChuangShiOS.Util.request(props, {
                    url: Application.host + url,
                    data: data,
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);

                        ChuangShiOS.Util.navigateBack(props, {});
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            })
            .catch((response) => {
                ChuangShiOS.Util.parseResponseError(response);
            });
    };

    const handleBackBenefitCode = () => {
        ChuangShiOS.Util.navigateBack(props, {});
    };

    return (
        <div className={Style.page}>
            <div className={Style.header}>
                <div className={Style.header_title}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <span>首页</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span>{store.main.menuName}</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className={Style.header_title_text}>{store.main.sidebarName}</div>
                </div>
                <div className={Style.header_button}>
                    <Space>
                        <Button.Group>
                            <Button icon={<LeftCircleOutlined />} size="large" onClick={handleBackBenefitCode}>
                                返回
                            </Button>
                        </Button.Group>
                        <Button
                            loading={isLoad}
                            disabled={isError}
                            icon={<FileDoneOutlined />}
                            type="primary"
                            size="large"
                            onClick={handleSubmitBenefitCode}
                        >
                            提交
                        </Button>
                    </Space>
                </div>
            </div>
            <ChuangShiOS.Content
                className={Style.content}
                isLoad={isLoad}
                isEmpty={false}
                isError={isError}
                paddingTop={28}
                isScroll={store.main.isFixedContent}
            >
                <Row>
                    <Col flex="auto">
                        <div className={Style.card_title}>基本信息</div>
                    </Col>
                    <Col></Col>
                </Row>
                <Form form={form}>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="权益活动"
                                name="benefitId"
                                initialValue={null}
                                rules={[
                                    {
                                        required: true,
                                        message: "请选择权益活动",
                                    },
                                ]}
                            >
                                <Select className={Style.card_field} placeholder="请选择权益活动" allowClear onChange={() => {}}>
                                    {benefitList.map((benefit, benefitIndex) => {
                                        return (
                                            <Select.Option key={benefitIndex} value={benefit.benefitId}>
                                                {benefit.benefitName}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    {isSave ? (
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "24%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="权益数量"
                                    name="benefitCodeQuantity"
                                    initialValue={""}
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入权益数量",
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        className={Style.card_field}
                                        min={0}
                                        max={999999}
                                        placeholder="请输入权益数量"
                                        onPressEnter={handleSubmitBenefitCode}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    ) : null}
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="日期类型"
                                name="benefitCodeType"
                                initialValue={null}
                                rules={[
                                    {
                                        required: true,
                                        message: "请选择日期类型",
                                    },
                                ]}
                            >
                                <Select className={Style.card_field} placeholder="请选择日期类型" allowClear onChange={(value) => {}}>
                                    <Select.Option value="MONTH">月数</Select.Option>
                                    <Select.Option value="DAY">天数</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="日期数量"
                                name="benefitCodeTypeQuantity"
                                initialValue={""}
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入日期数量",
                                    },
                                ]}
                            >
                                <InputNumber
                                    className={Style.card_field}
                                    min={0}
                                    max={999999}
                                    placeholder="请输入日期数量"
                                    onPressEnter={handleSubmitBenefitCode}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="是否宽限"
                                name="benefitCodeIsAvailable"
                                initialValue={false}
                                valuePropName="checked"
                                rules={[
                                    {
                                        required: false,
                                        message: "请选择是否宽限",
                                    },
                                ]}
                            >
                                <Switch
                                    checkedChildren="开启"
                                    unCheckedChildren="关闭"
                                    onChange={(value) => {
                                        setBenefitCodeIsAvailable(value);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    {benefitCodeIsAvailable ? (
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "24%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="宽限数量"
                                    name="benefitCodeAvailableQuantity"
                                    initialValue={""}
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择宽限数量",
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        className={Style.card_field}
                                        min={0}
                                        max={999999}
                                        placeholder="请选择宽限数量"
                                        onPressEnter={handleSubmitBenefitCode}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    ) : null}
                </Form>
            </ChuangShiOS.Content>
            <div className={Style.footer}></div>
        </div>
    );
};

BenefitCodeDetail.propTypes = {};

BenefitCodeDetail.defaultProps = {};

export default BenefitCodeDetail;
