import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {useActivate, useUnactivate} from "react-activation";
import {message, Modal, Breadcrumb, Space, Button, Form, Row, Col, Input, Table, Tag} from "antd";
import {
    FileAddOutlined,
    DeleteOutlined,
    CloseCircleOutlined,
    FileSearchOutlined,
    FileTextOutlined,
    PlayCircleOutlined,
    PauseCircleOutlined,
} from "@ant-design/icons";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./Index.module.css";

const JobIndex = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const [tableHeight, setTableHeight] = useState(0);
    const [jobPageIndex, setJobPageIndex] = useState(1);
    const [jobPageSize, setJobPageSize] = useState(store.main.pageSize);
    const [jobIdList, setJobIdList] = useState([]);
    const [jobTotal, setJobTotal] = useState(0);
    const [jobList, setJobList] = useState([]);

    useEffect(() => {
        if (store.main.userName) {
            ChuangShiOS.Notification.emit("main_menu_active", {url: "/job/index"});

            handleSearchJob();
        }

        //eslint-disable-next-line
    }, [store.main.userName]);

    useEffect(() => {
        handleLoadJob(jobPageIndex, store.main.pageSize);

        //eslint-disable-next-line
    }, [store.main.pageSize]);

    useActivate(() => {
        ChuangShiOS.Notification.emit("main_set_scroll_top", {});

        handleLoadJob(jobPageIndex, jobPageSize);
    });

    useUnactivate(() => {
        ChuangShiOS.Notification.emit("main_get_scroll_top", {});
    });

    const handleSearchJob = () => {
        handleLoadJob(1, jobPageSize);
    };

    const handleLoadJob = (jobPageIndex, jobPageSize) => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        let data = form.getFieldsValue();
        data.pageIndex = jobPageIndex;
        data.pageSize = jobPageSize;

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/job/business/website/v1/list",
            data: data,
            success: (response) => {
                setJobPageIndex(jobPageIndex);
                setJobPageSize(jobPageSize);
                setJobIdList([]);
                setJobTotal(response.data.total);
                setJobList(response.data.list);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
                setIsError(
                    typeof response.data == "undefined" ||
                        response.data.total == "undefined" ||
                        response.data.total == null ||
                        response.data.list == "undefined" ||
                        response.data.list == null
                );
            },
        });
    };

    const handleAddJob = () => {
        ChuangShiOS.Util.navigateTo(props, {
            url: "/job/add",
        });
    };

    const handleEditJob = (jobId) => {
        ChuangShiOS.Util.navigateTo(props, {
            url: "/job/edit/" + jobId,
        });
    };

    const handleDelJob = () => {
        if (jobIdList.length == 0) {
            return;
        }

        if (request.current) {
            return;
        }

        Modal.confirm({
            title: "是否确认操作？",
            content: "本次数据操作后将无法恢复",
            okText: "确认",
            okType: "danger",
            okButtonProps: {
                icon: <DeleteOutlined />,
            },
            cancelText: "取消",
            cancelButtonProps: {
                icon: <CloseCircleOutlined />,
            },
            onOk: () => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                ChuangShiOS.Util.request(props, {
                    url: Application.host + "/windeal/job/business/website/v1/delete",
                    data: {
                        jobIdList: jobIdList,
                    },
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                        setJobIdList([]);

                        handleLoadJob(jobPageIndex, jobPageSize);
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            },
        });
    };

    const handleChangeJobIdList = (jobIdList) => {
        setJobIdList(jobIdList);
    };

    const handleChangeJobPagination = (jobPageIndex, jobPageSize) => {
        handleLoadJob(jobPageIndex, jobPageSize);
    };

    const handleChangeStatus = (jobId, jobStatus, systemVersion) => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        message.loading({
            content: "正在加载",
            key: "isLoad",
            duration: 0,
        });

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/job/business/website/v1/status/update",
            data: {
                jobId: jobId,
                jobStatus: jobStatus,
                systemVersion: systemVersion,
            },
            success: (response) => {
                message.success({
                    content: response.message,
                    key: "isLoad",
                    duration: 2,
                });

                request.current = false;

                setIsLoad(false);

                handleLoadJob(jobPageIndex, jobPageSize);
            },
            error: (response) => {
                message.error({
                    content: response.message,
                    key: "isLoad",
                    duration: 2,
                });

                request.current = false;

                setIsLoad(false);
            },
            complete: (response) => {},
        });
    };

    const handleRun = (jobId) => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        message.loading({
            content: "正在加载",
            key: "isLoad",
            duration: 0,
        });

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/job/business/website/v1/run",
            data: {
                jobId: jobId,
            },
            success: (response) => {
                message.success({
                    content: response.message,
                    key: "isLoad",
                    duration: 2,
                });

                request.current = false;

                setIsLoad(false);
            },
            error: (response) => {
                message.error({
                    content: response.message,
                    key: "isLoad",
                    duration: 2,
                });

                request.current = false;

                setIsLoad(false);
            },
            complete: (response) => {},
        });
    };

    return (
        <div className={Style.page}>
            <div className={Style.header}>
                <div className={Style.header_title}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <span>首页</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span>{store.main.menuName}</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className={Style.header_title_text}>{store.main.sidebarName}</div>
                </div>
                <div className={Style.header_button}>
                    <Space>
                        <Button.Group>
                            <Button icon={<FileAddOutlined />} size="large" onClick={handleAddJob}>
                                新增
                            </Button>
                            <Button loading={isLoad} disabled={jobIdList.length == 0} icon={<DeleteOutlined />} size="large" onClick={handleDelJob}>
                                删除
                            </Button>
                        </Button.Group>
                        <Button loading={isLoad} icon={<FileSearchOutlined />} type="primary" size="large" onClick={handleSearchJob}>
                            查询
                        </Button>
                    </Space>
                </div>
            </div>
            <ChuangShiOS.Content className={Style.content} isLoad={isLoad} isEmpty={false} isError={isError} isScroll={store.main.isFixedContent}>
                <Form form={form}>
                    <Row>
                        <Col span={6}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "none"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="任务名称"
                                labelAlign={"left"}
                                name="jobName"
                                initialValue={""}
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入任务名称",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入任务名称" onPressEnter={handleSearchJob} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "none"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="Handler"
                                labelAlign={"left"}
                                name="jobHandler"
                                initialValue={""}
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入Handler",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入Handler" onPressEnter={handleSearchJob} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div
                    ref={(ref) => {
                        if (ref != null && tableHeight == 0 && tableHeight != ref.clientHeight) {
                            setTableHeight(ref.clientHeight);
                        }
                    }}
                    className={store.main.isFixedContent ? Style.card_table : null}
                >
                    <Table
                        size="small"
                        rowKey={(record) => record.jobId}
                        columns={[
                            {
                                title: "任务名称",
                                dataIndex: "jobName",
                            },
                            {
                                title: "Handler",
                                dataIndex: "jobHandler",
                            },
                            {
                                title: "Cron",
                                dataIndex: "jobCron",
                            },
                            {
                                title: "任务备注",
                                dataIndex: "jobRemark",
                            },
                            {
                                title: "任务状态",
                                dataIndex: "jobStatus",
                                render: (text, record, recordIndex) =>
                                    record.jobStatus ? <Tag color="blue">运行</Tag> : <Tag color="red">暂停</Tag>,
                            },
                            {
                                title: "操作",
                                width: 300,
                                render: (text, record, recordIndex) => (
                                    <Space>
                                        <Button icon={<FileTextOutlined />} size="default" onClick={() => handleEditJob(record.jobId)}>
                                            修改
                                        </Button>
                                        {record.jobStatus ? (
                                            <Button
                                                loading={isLoad}
                                                icon={<PauseCircleOutlined />}
                                                onClick={() => handleChangeStatus(record.jobId, false, record.systemVersion)}
                                            >
                                                暂停
                                            </Button>
                                        ) : (
                                            <Button
                                                loading={isLoad}
                                                icon={<PlayCircleOutlined />}
                                                onClick={() => handleChangeStatus(record.jobId, true, record.systemVersion)}
                                            >
                                                运行
                                            </Button>
                                        )}
                                        <Button loading={isLoad} icon={<PlayCircleOutlined />} onClick={() => handleRun(record.jobId)}>
                                            执行
                                        </Button>
                                    </Space>
                                ),
                            },
                        ]}
                        dataSource={jobList}
                        rowSelection={{
                            fixed: true,
                            selectedRowKeys: jobIdList,
                            onChange: handleChangeJobIdList,
                        }}
                        scroll={store.main.isFixedContent ? {y: tableHeight - 130} : {}}
                        pagination={{
                            size: "default",
                            showQuickJumper: true,
                            showSizeChanger: true,
                            current: jobPageIndex,
                            pageSize: jobPageSize,
                            total: jobTotal,
                            showTotal: (total, range) => "显示" + range[0] + "到" + range[1] + "条,共有" + total + "条",
                            onChange: handleChangeJobPagination,
                            onShowSizeChange: handleChangeJobPagination,
                        }}
                    />
                </div>
            </ChuangShiOS.Content>
            <div className={Style.footer}></div>
        </div>
    );
};

JobIndex.propTypes = {};

JobIndex.defaultProps = {};

export default JobIndex;
