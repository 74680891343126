import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {useActivate, useUnactivate} from "react-activation";
import {message, Modal, Breadcrumb, Space, Button, Form, Row, Col, Input, Table, Select, Tag, Upload, DatePicker} from "antd";
import {
    FileAddOutlined,
    DeleteOutlined,
    CloseCircleOutlined,
    FileSearchOutlined,
    FileTextOutlined,
    CloudDownloadOutlined,
    CloudUploadOutlined,
} from "@ant-design/icons";
import moment from "moment";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./Index.module.css";

const SpecialIndex = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const [tableHeight, setTableHeight] = useState(0);
    const [specialPageIndex, setSpecialPageIndex] = useState(1);
    const [specialPageSize, setSpecialPageSize] = useState(store.main.pageSize);
    const [specialIdList, setSpecialIdList] = useState([]);
    const [specialTotal, setSpecialTotal] = useState(0);
    const [specialList, setSpecialList] = useState([]);
    const [activityList, setActivityList] = useState([]);

    useEffect(() => {
        if (store.main.userName) {
            ChuangShiOS.Notification.emit("main_menu_active", {url: "/special/index"});

            handleLoadActivity();

            handleSearchSpecial();
        }

        //eslint-disable-next-line
    }, [store.main.userName]);

    useEffect(() => {
        handleLoadSpecial(specialPageIndex, store.main.pageSize);

        //eslint-disable-next-line
    }, [store.main.pageSize]);

    useActivate(() => {
        ChuangShiOS.Notification.emit("main_set_scroll_top", {});

        handleLoadSpecial(specialPageIndex, specialPageSize);
    });

    useUnactivate(() => {
        ChuangShiOS.Notification.emit("main_get_scroll_top", {});
    });

    const handleLoadActivity = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/activity/business/website/v1/select/list",
            data: {},
            success: (response) => {
                setActivityList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleSearchSpecial = () => {
        handleLoadSpecial(1, specialPageSize);
    };

    const handleLoadSpecial = (specialPageIndex, specialPageSize) => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        let data = form.getFieldsValue();
        if (data.startDate) {
            data.startDate = moment(data.startDate).format("YYYY-MM-DD");
        }
        if (data.endDate) {
            data.endDate = moment(data.endDate).format("YYYY-MM-DD");
        }
        data.pageIndex = specialPageIndex;
        data.pageSize = specialPageSize;

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/special/business/website/v1/list",
            data: data,
            success: (response) => {
                setSpecialPageIndex(specialPageIndex);
                setSpecialPageSize(specialPageSize);
                setSpecialIdList([]);
                setSpecialTotal(response.data.total);
                setSpecialList(response.data.list);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
                setIsError(
                    typeof response.data == "undefined" ||
                        response.data.total == "undefined" ||
                        response.data.total == null ||
                        response.data.list == "undefined" ||
                        response.data.list == null
                );
            },
        });
    };

    const handleAddSpecial = () => {
        ChuangShiOS.Util.navigateTo(props, {
            url: "/special/add",
        });
    };

    const handleEditSpecial = (specialId) => {
        ChuangShiOS.Util.navigateTo(props, {
            url: "/special/edit/" + specialId,
        });
    };

    const handleDelSpecial = () => {
        if (specialIdList.length == 0) {
            return;
        }

        if (request.current) {
            return;
        }

        Modal.confirm({
            title: "是否确认操作？",
            content: "本次数据操作后将无法恢复",
            okText: "确认",
            okType: "danger",
            okButtonProps: {
                icon: <DeleteOutlined />,
            },
            cancelText: "取消",
            cancelButtonProps: {
                icon: <CloseCircleOutlined />,
            },
            onOk: () => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                ChuangShiOS.Util.request(props, {
                    url: Application.host + "/windeal/special/business/website/v1/delete",
                    data: {
                        specialIdList: specialIdList,
                    },
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                        setSpecialIdList([]);

                        handleLoadSpecial(specialPageIndex, specialPageSize);
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            },
        });
    };

    const handleChangeSpecialIdList = (specialIdList) => {
        setSpecialIdList(specialIdList);
    };

    const handleChangeSpecialPagination = (specialPageIndex, specialPageSize) => {
        handleLoadSpecial(specialPageIndex, specialPageSize);
    };

    const handleImport = (info) => {
        if (info.file.status == "uploading") {
            if (!isLoad) {
                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });
            }
        } else {
            setIsLoad(false);

            if (info.file.status === "done") {
                if (info.file.response.code === 200) {
                    message.success({
                        content: "上传成功",
                        key: "isLoad",
                        duration: 2,
                    });

                    handleLoadSpecial(specialPageIndex, specialPageSize);
                } else {
                    message.error({
                        content: info.file.response.message,
                        key: "isLoad",
                        duration: 2,
                    });
                }
            } else if (info.file.status === "error") {
                message.error({
                    content: "上传失败",
                    key: "isLoad",
                    duration: 2,
                });
            }
        }
    };

    const handleExport = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        message.loading({content: "正在加载", key: "isLoad", duration: 0});

        let data = form.getFieldsValue();
        data.pageIndex = 0;
        data.pageSize = 0;

        ChuangShiOS.Util.download(this, {
            url: Application.host + "/windeal/special/business/website/v1/export",
            data: data,
            complete: (response) => {
                message.success({
                    content: "导出成功",
                    key: "isLoad",
                    duration: 2,
                });

                request.current = false;

                setIsLoad(false);
            },
        });
    };

    return (
        <div className={Style.page}>
            <div className={Style.header}>
                <div className={Style.header_title}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <span>首页</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span>{store.main.menuName}</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className={Style.header_title_text}>{store.main.sidebarName}</div>
                </div>
                <div className={Style.header_button}>
                    <Space>
                        <Button.Group>
                            <Button icon={<FileAddOutlined />} size="large" onClick={handleAddSpecial}>
                                新增
                            </Button>
                            <Upload
                                {...{
                                    name: "file",
                                    action: Application.host + "/windeal/special/business/website/v1/import",
                                    accept: ".xls,.xlsx",
                                    multiple: true,
                                    showUploadList: false,
                                    data: {
                                        appId: Application.appId,
                                        token: ChuangShiOS.Util.getToken(),
                                    },
                                    onChange: handleImport,
                                }}
                            >
                                <Button size="large" loading={isLoad}>
                                    <CloudUploadOutlined /> 上传
                                </Button>
                            </Upload>
                            <Button loading={isLoad} icon={<CloudDownloadOutlined />} size="large" onClick={handleExport}>
                                下载
                            </Button>
                            <Button
                                loading={isLoad}
                                disabled={specialIdList.length == 0}
                                icon={<DeleteOutlined />}
                                size="large"
                                onClick={handleDelSpecial}
                            >
                                删除
                            </Button>
                        </Button.Group>
                        <Button loading={isLoad} icon={<FileSearchOutlined />} type="primary" size="large" onClick={handleSearchSpecial}>
                            查询
                        </Button>
                    </Space>
                </div>
            </div>
            <ChuangShiOS.Content className={Style.content} isLoad={isLoad} isEmpty={false} isError={isError} isScroll={store.main.isFixedContent}>
                <Form form={form}>
                    <Row>
                        <Col span={6}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "none"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="活动名称"
                                labelAlign={"left"}
                                name="activityId"
                                initialValue={null}
                                rules={[
                                    {
                                        required: false,
                                        message: "请选择活动名称",
                                    },
                                ]}
                            >
                                <Select
                                    className={Style.card_field}
                                    placeholder="请选择活动名称"
                                    allowClear
                                    onChange={() => {
                                        handleSearchSpecial();
                                    }}
                                >
                                    {activityList.map((activity, activityIndex) => {
                                        return (
                                            <Select.Option key={activityIndex} value={activity.activityId}>
                                                {activity.activityName}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "none"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="名单电话"
                                labelAlign={"left"}
                                name="specialPhone"
                                initialValue={""}
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入名单电话",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入名单电话" onPressEnter={handleSearchSpecial} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "none"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="开始日期"
                                labelAlign={"left"}
                                name="startDate"
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入开始日期",
                                    },
                                ]}
                            >
                                <DatePicker className={Style.card_field} placeholder="请输入开始日期" onPressEnter={handleSearchSpecial} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "none"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="结束日期"
                                labelAlign={"left"}
                                name="endDate"
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入结束日期",
                                    },
                                ]}
                            >
                                <DatePicker className={Style.card_field} placeholder="请输入结束日期" onPressEnter={handleSearchSpecial} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div
                    ref={(ref) => {
                        if (ref != null && tableHeight == 0 && tableHeight != ref.clientHeight) {
                            setTableHeight(ref.clientHeight);
                        }
                    }}
                    className={store.main.isFixedContent ? Style.card_table : null}
                >
                    <Table
                        size="small"
                        rowKey={(record) => record.specialId}
                        columns={[
                            {
                                title: "活动名称",
                                dataIndex: "activityName",
                            },
                            {
                                title: "名单电话",
                                dataIndex: "specialPhone",
                            },
                            {
                                title: "开始日期",
                                dataIndex: "specialStartDay",
                            },
                            {
                                title: "结束日期",
                                dataIndex: "specialEndDay",
                            },
                            {
                                title: "名单状态",
                                width: 100,
                                render: (text, record, recordIndex) =>
                                    record.specialStatus ? <Tag color="blue">启动</Tag> : <Tag color="red">停止</Tag>,
                            },
                            {
                                title: "操作",
                                width: 100,
                                render: (text, record, recordIndex) => (
                                    <Space>
                                        <Button icon={<FileTextOutlined />} size="default" onClick={() => handleEditSpecial(record.specialId)}>
                                            修改
                                        </Button>
                                    </Space>
                                ),
                            },
                        ]}
                        dataSource={specialList}
                        rowSelection={{
                            fixed: true,
                            selectedRowKeys: specialIdList,
                            onChange: handleChangeSpecialIdList,
                        }}
                        scroll={store.main.isFixedContent ? {y: tableHeight - 130} : {}}
                        pagination={{
                            size: "default",
                            showQuickJumper: true,
                            showSizeChanger: true,
                            current: specialPageIndex,
                            pageSize: specialPageSize,
                            total: specialTotal,
                            showTotal: (total, range) => "显示" + range[0] + "到" + range[1] + "条,共有" + total + "条",
                            onChange: handleChangeSpecialPagination,
                            onShowSizeChange: handleChangeSpecialPagination,
                        }}
                    />
                </div>
            </ChuangShiOS.Content>
            <div className={Style.footer}></div>
        </div>
    );
};

SpecialIndex.propTypes = {};

SpecialIndex.defaultProps = {};

export default SpecialIndex;
