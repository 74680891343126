import React from "react";
import {Route} from "react-router-dom";
import KeepAlive from "react-activation";
import ChuangShiOS from "chuangshi-os-website-component";

import ProvinceIndex from "./Index";
import ProvinceDetail from "./Detail";

const data = [
    <Route
        exact
        key="ProvinceIndex"
        path="/province/index"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return (
                <KeepAlive name="/province/index">
                    <ProvinceIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="ProvinceAdd"
        path="/province/add"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <ProvinceDetail {...props} />;
        }}
    />,
    <Route
        exact
        key="ProvinceEdit"
        path="/province/edit/:provinceId"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <ProvinceDetail {...props} />;
        }}
    />,
];

export default data;
