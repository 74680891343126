import React from "react";
import {Route} from "react-router-dom";
import KeepAlive from "react-activation";
import ChuangShiOS from "chuangshi-os-website-component";

import CalendarIndex from "./Index";
import CalendarDetail from "./Detail";

const data = [
    <Route
        exact
        key="CalendarIndex"
        path="/calendar/index"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return (
                <KeepAlive name="/calendar/index">
                    <CalendarIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="CalendarAdd"
        path="/calendar/add"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <CalendarDetail {...props} />;
        }}
    />,
    <Route
        exact
        key="CalendarEdit"
        path="/calendar/edit/:calendarId"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <CalendarDetail {...props} />;
        }}
    />,
];

export default data;
