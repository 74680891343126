import React from "react";
import {Route} from "react-router-dom";
import KeepAlive from "react-activation";
import ChuangShiOS from "chuangshi-os-website-component";

import MemberBenefitIndex from "./Index";
import MemberBenefitDetail from "./Detail";

const data = [
    <Route
        exact
        key="MemberBenefitIndex"
        path="/member/benefit/index"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return (
                <KeepAlive name="/member/benefit/index">
                    <MemberBenefitIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="MemberBenefitAdd"
        path="/member/benefit/add"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <MemberBenefitDetail {...props} />;
        }}
    />,
    <Route
        exact
        key="MemberBenefitEdit"
        path="/member/benefit/edit/:memberBenefitId"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <MemberBenefitDetail {...props} />;
        }}
    />,
];

export default data;
