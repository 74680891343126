import React from "react";
import {Route} from "react-router-dom";
import KeepAlive from "react-activation";
import ChuangShiOS from "chuangshi-os-website-component";

import ActivityNoticeIndex from "./Index";
import ActivityNoticeDetail from "./Detail";

const data = [
    <Route
        exact
        key="ActivityNoticeIndex"
        path="/activity/notice/index"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return (
                <KeepAlive name="/activity/notice/index">
                    <ActivityNoticeIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="ActivityNoticeAdd"
        path="/activity/notice/add"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <ActivityNoticeDetail {...props} />;
        }}
    />,
    <Route
        exact
        key="ActivityNoticeEdit"
        path="/activity/notice/edit/:activityNoticeId"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <ActivityNoticeDetail {...props} />;
        }}
    />,
];

export default data;