import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {message, Breadcrumb, Form, Row, Col, Input, InputNumber, Space, Button, TimePicker} from "antd";
import {LeftCircleOutlined, FileDoneOutlined} from "@ant-design/icons";
import moment from "moment";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./Detail.module.css";

const GoodsCategoryDetail = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [systemVersion, setSystemVersion] = useState(0);

    useEffect(() => {
        if (store.main.userName) {
            ChuangShiOS.Notification.emit("main_menu_active", {url: "/goods/category/index"});

            switch (props.match.path) {
                case "/goods/category/add":
                    setIsSave(true);

                    break;
                case "/goods/category/edit/:goodsCategoryId":
                    setIsEdit(true);

                    handleLoadGoodsCategory();

                    break;
                default:
                    break;
            }
        }

        //eslint-disable-next-line
    }, [store.main.userName]);

    const handleLoadGoodsCategory = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/goods/category/business/website/v1/find",
            data: {
                goodsCategoryId: props.match.params.goodsCategoryId,
            },
            success: (response) => {
                response.data.goodsCategoryStart = moment(response.data.goodsCategoryStart, "HH:mm");
                response.data.goodsCategoryEnd = moment(response.data.goodsCategoryEnd, "HH:mm");

                form.setFieldsValue(response.data);

                setSystemVersion(response.data.systemVersion);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
                setIsError(
                    typeof response.data == "undefined" ||
                        typeof response.data.goodsCategoryId == "undefined" ||
                        response.data.goodsCategoryId == null
                );
            },
        });
    };

    const handleSubmitGoodsCategory = () => {
        if (request.current) {
            return;
        }

        form.validateFields()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                data.goodsCategoryId = props.match.params.goodsCategoryId;
                data.goodsCategoryStart = data.goodsCategoryStart.format("HH:mm");
                data.goodsCategoryEnd = data.goodsCategoryEnd.format("HH:mm");
                data.systemVersion = systemVersion;

                let url = "";
                if (isSave) {
                    url = "/windeal/goods/category/business/website/v1/save";
                }
                if (isEdit) {
                    url = "/windeal/goods/category/business/website/v1/update";
                }

                ChuangShiOS.Util.request(props, {
                    url: Application.host + url,
                    data: data,
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);

                        ChuangShiOS.Util.navigateBack(props, {});
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            })
            .catch((response) => {
                ChuangShiOS.Util.parseResponseError(response);
            });
    };

    const handleBackGoodsCategory = () => {
        ChuangShiOS.Util.navigateBack(props, {});
    };

    return (
        <div className={Style.page}>
            <div className={Style.header}>
                <div className={Style.header_title}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <span>首页</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span>{store.main.menuName}</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className={Style.header_title_text}>{store.main.sidebarName}</div>
                </div>
                <div className={Style.header_button}>
                    <Space>
                        <Button.Group>
                            <Button icon={<LeftCircleOutlined />} size="large" onClick={handleBackGoodsCategory}>
                                返回
                            </Button>
                        </Button.Group>
                        <Button
                            loading={isLoad}
                            disabled={isError}
                            icon={<FileDoneOutlined />}
                            type="primary"
                            size="large"
                            onClick={handleSubmitGoodsCategory}
                        >
                            提交
                        </Button>
                    </Space>
                </div>
            </div>
            <ChuangShiOS.Content
                className={Style.content}
                isLoad={isLoad}
                isEmpty={false}
                isError={isError}
                paddingTop={28}
                isScroll={store.main.isFixedContent}
            >
                <Row>
                    <Col flex="auto">
                        <div className={Style.card_title}>基本信息</div>
                    </Col>
                    <Col></Col>
                </Row>
                <Form form={form}>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="分类名称"
                                name="goodsCategoryName"
                                initialValue={""}
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入分类名称",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入分类名称" onPressEnter={handleSubmitGoodsCategory} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="开始时间"
                                name="goodsCategoryStart"
                                initialValue={""}
                                rules={[
                                    {
                                        required: true,
                                        message: "请选择开始时间",
                                    },
                                ]}
                            >
                                <TimePicker
                                    className={Style.card_field}
                                    showNow={false}
                                    format="HH:mm"
                                    placeholder="请选择开始时间"
                                    onPressEnter={handleSubmitGoodsCategory}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="结束时间"
                                name="goodsCategoryEnd"
                                initialValue={""}
                                rules={[
                                    {
                                        required: true,
                                        message: "请选择结束时间",
                                    },
                                ]}
                            >
                                <TimePicker
                                    className={Style.card_field}
                                    showNow={false}
                                    format="HH:mm"
                                    placeholder="请选择结束时间"
                                    onPressEnter={handleSubmitGoodsCategory}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="分类排序"
                                name="goodsCategorySort"
                                initialValue={0}
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入分类排序",
                                    },
                                ]}
                            >
                                <InputNumber
                                    className={Style.card_field}
                                    min={0}
                                    max={999999}
                                    placeholder="请输入分类排序"
                                    onPressEnter={handleSubmitGoodsCategory}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </ChuangShiOS.Content>
            <div className={Style.footer}></div>
        </div>
    );
};

GoodsCategoryDetail.propTypes = {};

GoodsCategoryDetail.defaultProps = {};

export default GoodsCategoryDetail;
