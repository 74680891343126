import React from "react";
import {Route} from "react-router-dom";
import KeepAlive from "react-activation";
import ChuangShiOS from "chuangshi-os-website-component";

import ActivityIndex from "./Index";
import ActivityDetail from "./Detail";

const data = [
    <Route
        exact
        key="ActivityIndex"
        path="/activity/index"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return (
                <KeepAlive name="/activity/index">
                    <ActivityIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="ActivityAdd"
        path="/activity/add"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <ActivityDetail {...props} />;
        }}
    />,
    <Route
        exact
        key="ActivityEdit"
        path="/activity/edit/:activityId"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <ActivityDetail {...props} />;
        }}
    />,
];

export default data;
