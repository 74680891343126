import React from "react";
import {Route} from "react-router-dom";
import KeepAlive from "react-activation";
import ChuangShiOS from "chuangshi-os-website-component";

import GoodsIndex from "./Index";
import GoodsDetail from "./Detail";

const data = [
    <Route
        exact
        key="GoodsIndex"
        path="/goods/index"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return (
                <KeepAlive name="/goods/index">
                    <GoodsIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="GoodsAdd"
        path="/goods/add"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <GoodsDetail {...props} />;
        }}
    />,
    <Route
        exact
        key="GoodsEdit"
        path="/goods/edit/:goodsId"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <GoodsDetail {...props} />;
        }}
    />,
];

export default data;
