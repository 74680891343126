import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {useActivate, useUnactivate} from "react-activation";
import {message, Modal, Breadcrumb, Space, Button, Form, Row, Input, Select, Table, Tag, DatePicker} from "antd";
import {FileAddOutlined, DeleteOutlined, CloseCircleOutlined, FileSearchOutlined, FileTextOutlined, CloudDownloadOutlined} from "@ant-design/icons";
import moment from "moment";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./Index.module.css";

const BenefitCodeIndex = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const [tableHeight, setTableHeight] = useState(0);
    const [benefitCodePageIndex, setBenefitCodePageIndex] = useState(1);
    const [benefitCodePageSize, setBenefitCodePageSize] = useState(store.main.pageSize);
    const [benefitCodeIdList, setBenefitCodeIdList] = useState([]);
    const [benefitCodeTotal, setBenefitCodeTotal] = useState(0);
    const [benefitCodeList, setBenefitCodeList] = useState([]);
    const [benefitList, setBenefitList] = useState([]);

    useEffect(() => {
        if (store.main.userName) {
            ChuangShiOS.Notification.emit("main_menu_active", {url: "/benefit/code/index"});

            handleLoadBenefit();

            handleSearchBenefitCode();
        }

        //eslint-disable-next-line
    }, [store.main.userName]);

    useEffect(() => {
        handleLoadBenefitCode(benefitCodePageIndex, store.main.pageSize);

        //eslint-disable-next-line
    }, [store.main.pageSize]);

    useActivate(() => {
        ChuangShiOS.Notification.emit("main_set_scroll_top", {});

        handleLoadBenefitCode(benefitCodePageIndex, benefitCodePageSize);
    });

    useUnactivate(() => {
        ChuangShiOS.Notification.emit("main_get_scroll_top", {});
    });

    const handleLoadBenefit = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/benefit/business/website/v1/select/list",
            data: {},
            success: (response) => {
                setBenefitList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleSearchBenefitCode = () => {
        handleLoadBenefitCode(1, benefitCodePageSize);
    };

    const handleLoadBenefitCode = (benefitCodePageIndex, benefitCodePageSize) => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        let data = form.getFieldsValue();
        if (data.memberBenefitStart) {
            data.memberBenefitStart = moment(data.memberBenefitStart).format("YYYY-MM-DD");
        }
        if (data.memberBenefitEnd) {
            data.memberBenefitEnd = moment(data.memberBenefitEnd).format("YYYY-MM-DD");
        }
        data.pageIndex = benefitCodePageIndex;
        data.pageSize = benefitCodePageSize;

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/benefit/code/business/website/v1/list",
            data: data,
            success: (response) => {
                setBenefitCodePageIndex(benefitCodePageIndex);
                setBenefitCodePageSize(benefitCodePageSize);
                setBenefitCodeIdList([]);
                setBenefitCodeTotal(response.data.total);
                setBenefitCodeList(response.data.list);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
                setIsError(
                    typeof response.data == "undefined" ||
                        response.data.total == "undefined" ||
                        response.data.total == null ||
                        response.data.list == "undefined" ||
                        response.data.list == null
                );
            },
        });
    };

    const handleAddBenefitCode = () => {
        ChuangShiOS.Util.navigateTo(props, {
            url: "/benefit/code/add",
        });
    };

    const handleEditBenefitCode = (benefitCodeId) => {
        ChuangShiOS.Util.navigateTo(props, {
            url: "/benefit/code/edit/" + benefitCodeId,
        });
    };

    const handleDelBenefitCode = () => {
        if (benefitCodeIdList.length == 0) {
            return;
        }

        if (request.current) {
            return;
        }

        Modal.confirm({
            title: "是否确认操作？",
            content: "本次数据操作后将无法恢复",
            okText: "确认",
            okType: "danger",
            okButtonProps: {
                icon: <DeleteOutlined />,
            },
            cancelText: "取消",
            cancelButtonProps: {
                icon: <CloseCircleOutlined />,
            },
            onOk: () => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                ChuangShiOS.Util.request(props, {
                    url: Application.host + "/windeal/benefit/code/business/website/v1/delete",
                    data: {
                        benefitCodeIdList: benefitCodeIdList,
                    },
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                        setBenefitCodeIdList([]);

                        handleLoadBenefitCode(benefitCodePageIndex, benefitCodePageSize);
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            },
        });
    };

    const handleChangeBenefitCodeIdList = (benefitCodeIdList) => {
        setBenefitCodeIdList(benefitCodeIdList);
    };

    const handleChangeBenefitCodePagination = (benefitCodePageIndex, benefitCodePageSize) => {
        handleLoadBenefitCode(benefitCodePageIndex, benefitCodePageSize);
    };

    const handleExport = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        message.loading({content: "正在加载", key: "isLoad", duration: 0});

        let data = form.getFieldsValue();
        data.pageIndex = 0;
        data.pageSize = 0;

        ChuangShiOS.Util.download(this, {
            url: Application.host + "/windeal/benefit/code/business/website/v1/export",
            data: data,
            complete: (response) => {
                message.success({
                    content: "导出成功",
                    key: "isLoad",
                    duration: 2,
                });

                request.current = false;

                setIsLoad(false);
            },
        });
    };

    return (
        <div className={Style.page}>
            <div className={Style.header}>
                <div className={Style.header_title}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <span>首页</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span>{store.main.menuName}</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className={Style.header_title_text}>{store.main.sidebarName}</div>
                </div>
                <div className={Style.header_button}>
                    <Space>
                        <Button.Group>
                            <Button icon={<FileAddOutlined />} size="large" onClick={handleAddBenefitCode}>
                                新增
                            </Button>
                            <Button loading={isLoad} icon={<CloudDownloadOutlined />} size="large" onClick={handleExport}>
                                下载
                            </Button>
                            <Button
                                loading={isLoad}
                                disabled={benefitCodeIdList.length == 0}
                                icon={<DeleteOutlined />}
                                size="large"
                                onClick={handleDelBenefitCode}
                            >
                                删除
                            </Button>
                        </Button.Group>
                        <Button loading={isLoad} icon={<FileSearchOutlined />} type="primary" size="large" onClick={handleSearchBenefitCode}>
                            查询
                        </Button>
                    </Space>
                </div>
            </div>
            <ChuangShiOS.Content className={Style.content} isLoad={isLoad} isEmpty={false} isError={isError} isScroll={store.main.isFixedContent}>
                <Form form={form}>
                    <Row>
                        <Form.Item
                            {...{
                                labelCol: {flex: "none"},
                                wrapperCol: {flex: "auto"},
                            }}
                            style={{flex: 1}}
                            label="权益活动"
                            labelAlign={"left"}
                            name="benefitId"
                            initialValue={null}
                            rules={[
                                {
                                    required: false,
                                    message: "请选择权益活动",
                                },
                            ]}
                        >
                            <Select className={Style.card_field} placeholder="请选择权益活动" allowClear onChange={handleSearchBenefitCode}>
                                {benefitList.map((benefit, benefitIndex) => {
                                    return (
                                        <Select.Option key={benefitIndex} value={benefit.benefitId}>
                                            {benefit.benefitName}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            {...{
                                labelCol: {flex: "none"},
                                wrapperCol: {flex: "auto"},
                            }}
                            style={{flex: 1}}
                            label="权益代码"
                            labelAlign={"left"}
                            name="benefitCode"
                            initialValue={""}
                            rules={[
                                {
                                    required: false,
                                    message: "请输入权益代码",
                                },
                            ]}
                        >
                            <Input className={Style.card_field} placeholder="请输入权益代码" onPressEnter={handleSearchBenefitCode} />
                        </Form.Item>
                        <Form.Item
                            {...{
                                labelCol: {flex: "none"},
                                wrapperCol: {flex: "auto"},
                            }}
                            style={{flex: 1}}
                            label="权益兑换"
                            labelAlign={"left"}
                            name="benefitCodeIsExchange"
                            initialValue={null}
                            rules={[
                                {
                                    required: false,
                                    message: "请选择权益兑换",
                                },
                            ]}
                        >
                            <Select
                                className={Style.card_field}
                                placeholder="请选择权益兑换"
                                allowClear
                                onChange={() => {
                                    handleSearchBenefitCode();
                                }}
                            >
                                <Select.Option value={true}>已兑换</Select.Option>
                                <Select.Option value={false}>未兑换</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            {...{
                                labelCol: {flex: "none"},
                                wrapperCol: {flex: "auto"},
                            }}
                            style={{flex: 1}}
                            label="绑定日期"
                            labelAlign={"left"}
                            name="memberBenefitStart"
                            rules={[
                                {
                                    required: false,
                                    message: "请输入绑定日期",
                                },
                            ]}
                        >
                            <DatePicker
                                className={Style.card_field}
                                placeholder="请输入绑定日期"
                                onChange={() => {
                                    handleSearchBenefitCode();
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            {...{
                                labelCol: {flex: "none"},
                                wrapperCol: {flex: "auto"},
                            }}
                            style={{flex: 1}}
                            label="结束日期"
                            labelAlign={"left"}
                            name="memberBenefitEnd"
                            rules={[
                                {
                                    required: false,
                                    message: "请输入结束日期",
                                },
                            ]}
                        >
                            <DatePicker
                                className={Style.card_field}
                                placeholder="请输入结束日期"
                                onChange={() => {
                                    handleSearchBenefitCode();
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            {...{
                                labelCol: {flex: "none"},
                                wrapperCol: {flex: "auto"},
                            }}
                            style={{flex: 1}}
                            label="会员手机"
                            labelAlign={"left"}
                            name="memberPhone"
                            initialValue={""}
                            rules={[
                                {
                                    required: false,
                                    message: "请输入会员手机",
                                },
                            ]}
                        >
                            <Input className={Style.card_field} placeholder="请输入会员手机" onPressEnter={handleSearchBenefitCode} />
                        </Form.Item>
                        <Form.Item
                            {...{
                                labelCol: {flex: "none"},
                                wrapperCol: {flex: "auto"},
                            }}
                            style={{flex: 1}}
                            label="会员名称"
                            labelAlign={"left"}
                            name="memberName"
                            initialValue={""}
                            rules={[
                                {
                                    required: false,
                                    message: "请输入会员名称",
                                },
                            ]}
                        >
                            <Input className={Style.card_field} placeholder="请输入会员名称" onPressEnter={handleSearchBenefitCode} />
                        </Form.Item>
                    </Row>
                </Form>
                <div
                    ref={(ref) => {
                        if (ref != null && tableHeight == 0 && tableHeight != ref.clientHeight) {
                            setTableHeight(ref.clientHeight);
                        }
                    }}
                    className={store.main.isFixedContent ? Style.card_table : null}
                >
                    <Table
                        size="small"
                        rowKey={(record) => record.benefitCodeId}
                        columns={[
                            {
                                title: "权益活动",
                                dataIndex: "benefitName",
                            },
                            {
                                title: "权益代码",
                                dataIndex: "benefitCode",
                            },
                            {
                                title: "权益日期",
                                width: 100,
                                dataIndex: "benefitCodeTypeQuantity",
                                render: (text, record, recordIndex) =>
                                    record.benefitCodeType == "MONTH"
                                        ? record.benefitCodeTypeQuantity + "个月"
                                        : record.benefitCodeTypeQuantity + "天",
                            },
                            {
                                title: "宽限日期",
                                width: 130,
                                dataIndex: "benefitCodeAvailable",
                            },
                            {
                                title: "权益兑换",
                                width: 130,
                                render: (text, record, recordIndex) =>
                                    record.benefitCodeIsExchange ? <Tag color="blue">已兑换</Tag> : <Tag>未兑换</Tag>,
                            },
                            {
                                title: "绑定日期",
                                dataIndex: "memberBenefitStart",
                            },
                            {
                                title: "结束日期",
                                dataIndex: "memberBenefitEnd",
                            },
                            {
                                title: "会员手机",
                                dataIndex: "memberPhone",
                            },
                            {
                                title: "会员名称",
                                dataIndex: "memberName",
                            },
                            {
                                title: "操作",
                                width: 120,
                                fixed: "right",
                                render: (text, record, recordIndex) => (
                                    <Space>
                                        {record.benefitCodeIsExchange ? null : (
                                            <Button
                                                icon={<FileTextOutlined />}
                                                size="default"
                                                onClick={() => handleEditBenefitCode(record.benefitCodeId)}
                                            >
                                                修改
                                            </Button>
                                        )}
                                    </Space>
                                ),
                            },
                        ]}
                        dataSource={benefitCodeList}
                        rowSelection={{
                            fixed: true,
                            selectedRowKeys: benefitCodeIdList,
                            onChange: handleChangeBenefitCodeIdList,
                            getCheckboxProps: (record) => ({
                                disabled: record.benefitCodeIsExchange,
                            }),
                        }}
                        scroll={store.main.isFixedContent ? {x: 1500, y: tableHeight - 130} : {x: 1500}}
                        pagination={{
                            size: "default",
                            showQuickJumper: true,
                            showSizeChanger: true,
                            current: benefitCodePageIndex,
                            pageSize: benefitCodePageSize,
                            total: benefitCodeTotal,
                            showTotal: (total, range) => "显示" + range[0] + "到" + range[1] + "条,共有" + total + "条",
                            onChange: handleChangeBenefitCodePagination,
                            onShowSizeChange: handleChangeBenefitCodePagination,
                        }}
                    />
                </div>
            </ChuangShiOS.Content>
            <div className={Style.footer}></div>
        </div>
    );
};

BenefitCodeIndex.propTypes = {};

BenefitCodeIndex.defaultProps = {};

export default BenefitCodeIndex;
