import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {message, Breadcrumb, Form, Row, Col, Input, Space, Button, Cascader, Select} from "antd";
import {LeftCircleOutlined, FileDoneOutlined} from "@ant-design/icons";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./Detail.module.css";

const MerchantDetail = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [systemVersion, setSystemVersion] = useState(0);
    const [cityList, setCityList] = useState([]);
    const [brandList, setBrandList] = useState([]);

    useEffect(() => {
        if (store.main.userName) {
            ChuangShiOS.Notification.emit("main_menu_active", {url: "/merchant/index"});

            switch (props.match.path) {
                case "/merchant/add":
                    setIsSave(true);

                    break;
                case "/merchant/edit/:merchantId":
                    setIsEdit(true);

                    handleLoadMerchant();

                    break;
                default:
                    break;
            }

            handleLoadCity();

            handleLoadBrand();
        }

        //eslint-disable-next-line
    }, [store.main.userName]);

    const handleLoadCity = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/city/business/website/v1/cascader/list",
            data: {},
            success: (response) => {
                setCityList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadBrand = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/brand/business/website/v1/select/list",
            data: {},
            success: (response) => {
                setBrandList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadMerchant = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/merchant/business/website/v1/find",
            data: {
                merchantId: props.match.params.merchantId,
            },
            success: (response) => {
                response.data.provinceCity = [response.data.provinceId, response.data.cityId];

                form.setFieldsValue(response.data);

                setSystemVersion(response.data.systemVersion);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
                setIsError(typeof response.data == "undefined" || typeof response.data.merchantId == "undefined" || response.data.merchantId == null);
            },
        });
    };

    const handleSubmitMerchant = () => {
        if (request.current) {
            return;
        }

        form.validateFields()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                data.merchantId = props.match.params.merchantId;
                data.cityId = data.provinceCity[1];
                data.systemVersion = systemVersion;

                delete data.provinceCity;

                let url = "";
                if (isSave) {
                    url = "/windeal/merchant/business/website/v1/save";
                }
                if (isEdit) {
                    url = "/windeal/merchant/business/website/v1/update";
                }

                ChuangShiOS.Util.request(props, {
                    url: Application.host + url,
                    data: data,
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);

                        ChuangShiOS.Util.navigateBack(props, {});
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            })
            .catch((response) => {
                ChuangShiOS.Util.parseResponseError(response);
            });
    };

    const handleBackMerchant = () => {
        ChuangShiOS.Util.navigateBack(props, {});
    };

    return (
        <div className={Style.page}>
            <div className={Style.header}>
                <div className={Style.header_title}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <span>首页</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span>{store.main.menuName}</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className={Style.header_title_text}>{store.main.sidebarName}</div>
                </div>
                <div className={Style.header_button}>
                    <Space>
                        <Button.Group>
                            <Button icon={<LeftCircleOutlined />} size="large" onClick={handleBackMerchant}>
                                返回
                            </Button>
                        </Button.Group>
                        <Button
                            loading={isLoad}
                            disabled={isError}
                            icon={<FileDoneOutlined />}
                            type="primary"
                            size="large"
                            onClick={handleSubmitMerchant}
                        >
                            提交
                        </Button>
                    </Space>
                </div>
            </div>
            <ChuangShiOS.Content
                className={Style.content}
                isLoad={isLoad}
                isEmpty={false}
                isError={isError}
                paddingTop={28}
                isScroll={store.main.isFixedContent}
            >
                <Row>
                    <Col flex="auto">
                        <div className={Style.card_title}>基本信息</div>
                    </Col>
                    <Col></Col>
                </Row>
                <Form form={form}>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="商户名称"
                                name="merchantName"
                                initialValue={""}
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入商户名称",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入商户名称" onPressEnter={handleSubmitMerchant} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="商户代码"
                                name="merchantCode"
                                initialValue={""}
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入商户代码",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入商户代码" onPressEnter={handleSubmitMerchant} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="所属城市"
                                name="provinceCity"
                                initialValue={""}
                                rules={[
                                    {
                                        required: true,
                                        message: "请选择所属城市",
                                    },
                                ]}
                            >
                                <Cascader className={Style.card_field} allowClear options={cityList} placeholder="请选择所属城市" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="所属品牌"
                                name="brandId"
                                initialValue={null}
                                rules={[
                                    {
                                        required: true,
                                        message: "请选择所属品牌",
                                    },
                                ]}
                            >
                                <Select className={Style.card_field} placeholder="请选择所属品牌" allowClear onChange={() => {}}>
                                    {brandList.map((brand, brandIndex) => {
                                        return (
                                            <Select.Option key={brandIndex} value={brand.brandId}>
                                                {brand.brandName}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </ChuangShiOS.Content>
            <div className={Style.footer}></div>
        </div>
    );
};

MerchantDetail.propTypes = {};

MerchantDetail.defaultProps = {};

export default MerchantDetail;
