import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {useActivate, useUnactivate} from "react-activation";
import {message, Modal, Breadcrumb, Space, Button, Form, Row, Col, Input, Table, Select} from "antd";
import {FileAddOutlined, DeleteOutlined, CloseCircleOutlined, FileSearchOutlined} from "@ant-design/icons";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./Index.module.css";

const MemberBenefitIndex = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const [tableHeight, setTableHeight] = useState(0);
    const [memberBenefitPageIndex, setMemberBenefitPageIndex] = useState(1);
    const [memberBenefitPageSize, setMemberBenefitPageSize] = useState(store.main.pageSize);
    const [memberBenefitIdList, setMemberBenefitIdList] = useState([]);
    const [memberBenefitTotal, setMemberBenefitTotal] = useState(0);
    const [memberBenefitList, setMemberBenefitList] = useState([]);
    const [benefitList, setBenefitList] = useState([]);

    useEffect(() => {
        if (store.main.userName) {
            ChuangShiOS.Notification.emit("main_menu_active", {url: "/member/benefit/index"});

            handleLoadBenefit();

            handleSearchMemberBenefit();
        }

        //eslint-disable-next-line
    }, [store.main.userName]);

    useEffect(() => {
        handleLoadMemberBenefit(memberBenefitPageIndex, store.main.pageSize);

        //eslint-disable-next-line
    }, [store.main.pageSize]);

    useActivate(() => {
        ChuangShiOS.Notification.emit("main_set_scroll_top", {});

        handleLoadMemberBenefit(memberBenefitPageIndex, memberBenefitPageSize);
    });

    useUnactivate(() => {
        ChuangShiOS.Notification.emit("main_get_scroll_top", {});
    });

    const handleLoadBenefit = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/benefit/business/website/v1/select/list",
            data: {},
            success: (response) => {
                setBenefitList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleSearchMemberBenefit = () => {
        handleLoadMemberBenefit(1, memberBenefitPageSize);
    };

    const handleLoadMemberBenefit = (memberBenefitPageIndex, memberBenefitPageSize) => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        let data = form.getFieldsValue();
        data.pageIndex = memberBenefitPageIndex;
        data.pageSize = memberBenefitPageSize;

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/member/benefit/business/website/v1/list",
            data: data,
            success: (response) => {
                setMemberBenefitPageIndex(memberBenefitPageIndex);
                setMemberBenefitPageSize(memberBenefitPageSize);
                setMemberBenefitIdList([]);
                setMemberBenefitTotal(response.data.total);
                setMemberBenefitList(response.data.list);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
                setIsError(
                    typeof response.data == "undefined" ||
                        response.data.total == "undefined" ||
                        response.data.total == null ||
                        response.data.list == "undefined" ||
                        response.data.list == null
                );
            },
        });
    };

    const handleAddMemberBenefit = () => {
        ChuangShiOS.Util.navigateTo(props, {
            url: "/member/benefit/add",
        });
    };

    const handleDelMemberBenefit = () => {
        if (memberBenefitIdList.length == 0) {
            return;
        }

        if (request.current) {
            return;
        }

        Modal.confirm({
            title: "是否确认操作？",
            content: "本次数据操作后将无法恢复",
            okText: "确认",
            okType: "danger",
            okButtonProps: {
                icon: <DeleteOutlined />,
            },
            cancelText: "取消",
            cancelButtonProps: {
                icon: <CloseCircleOutlined />,
            },
            onOk: () => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                ChuangShiOS.Util.request(props, {
                    url: Application.host + "/windeal/member/benefit/business/website/v1/delete",
                    data: {
                        memberBenefitIdList: memberBenefitIdList,
                    },
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                        setMemberBenefitIdList([]);

                        handleLoadMemberBenefit(memberBenefitPageIndex, memberBenefitPageSize);
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            },
        });
    };

    const handleChangeMemberBenefitIdList = (memberBenefitIdList) => {
        setMemberBenefitIdList(memberBenefitIdList);
    };

    const handleChangeMemberBenefitPagination = (memberBenefitPageIndex, memberBenefitPageSize) => {
        handleLoadMemberBenefit(memberBenefitPageIndex, memberBenefitPageSize);
    };

    return (
        <div className={Style.page}>
            <div className={Style.header}>
                <div className={Style.header_title}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <span>首页</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span>{store.main.menuName}</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className={Style.header_title_text}>{store.main.sidebarName}</div>
                </div>
                <div className={Style.header_button}>
                    <Space>
                        <Button.Group>
                            <Button icon={<FileAddOutlined />} size="large" onClick={handleAddMemberBenefit}>
                                新增
                            </Button>
                            <Button
                                loading={isLoad}
                                disabled={memberBenefitIdList.length == 0}
                                icon={<DeleteOutlined />}
                                size="large"
                                onClick={handleDelMemberBenefit}
                            >
                                删除
                            </Button>
                        </Button.Group>
                        <Button loading={isLoad} icon={<FileSearchOutlined />} type="primary" size="large" onClick={handleSearchMemberBenefit}>
                            查询
                        </Button>
                    </Space>
                </div>
            </div>
            <ChuangShiOS.Content className={Style.content} isLoad={isLoad} isEmpty={false} isError={isError} isScroll={store.main.isFixedContent}>
                <Form form={form}>
                    <Row>
                        <Col span={6}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "none"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="权益活动"
                                labelAlign={"left"}
                                name="benefitId"
                                initialValue={null}
                                rules={[
                                    {
                                        required: false,
                                        message: "请选择权益活动",
                                    },
                                ]}
                            >
                                <Select className={Style.card_field} placeholder="请选择权益活动" allowClear onChange={handleSearchMemberBenefit}>
                                    {benefitList.map((benefit, benefitIndex) => {
                                        return (
                                            <Select.Option key={benefitIndex} value={benefit.benefitId}>
                                                {benefit.benefitName}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "none"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="权益代码"
                                labelAlign={"left"}
                                name="benefitCode"
                                initialValue={""}
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入权益代码",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入权益代码" onPressEnter={handleSearchMemberBenefit} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "none"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="会员手机"
                                labelAlign={"left"}
                                name="memberPhone"
                                initialValue={""}
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入会员手机",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入会员手机" onPressEnter={handleSearchMemberBenefit} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div
                    ref={(ref) => {
                        if (ref != null && tableHeight == 0 && tableHeight != ref.clientHeight) {
                            setTableHeight(ref.clientHeight);
                        }
                    }}
                    className={store.main.isFixedContent ? Style.card_table : null}
                >
                    <Table
                        size="small"
                        rowKey={(record) => record.memberBenefitId}
                        columns={[
                            {
                                title: "权益活动",
                                dataIndex: "benefitName",
                            },
                            {
                                title: "权益代码",
                                dataIndex: "benefitCode",
                            },
                            {
                                title: "会员手机",
                                dataIndex: "memberPhone",
                            },
                            {
                                title: "开始时间",
                                dataIndex: "memberBenefitStart",
                            },
                            {
                                title: "结束时间",
                                dataIndex: "memberBenefitEnd",
                            },
                        ]}
                        dataSource={memberBenefitList}
                        rowSelection={{
                            fixed: true,
                            selectedRowKeys: memberBenefitIdList,
                            onChange: handleChangeMemberBenefitIdList,
                        }}
                        scroll={store.main.isFixedContent ? {y: tableHeight - 130} : {}}
                        pagination={{
                            size: "default",
                            showQuickJumper: true,
                            showSizeChanger: true,
                            current: memberBenefitPageIndex,
                            pageSize: memberBenefitPageSize,
                            total: memberBenefitTotal,
                            showTotal: (total, range) => "显示" + range[0] + "到" + range[1] + "条,共有" + total + "条",
                            onChange: handleChangeMemberBenefitPagination,
                            onShowSizeChange: handleChangeMemberBenefitPagination,
                        }}
                    />
                </div>
            </ChuangShiOS.Content>
            <div className={Style.footer}></div>
        </div>
    );
};

MemberBenefitIndex.propTypes = {};

MemberBenefitIndex.defaultProps = {};

export default MemberBenefitIndex;
