import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {withRouter} from "react-router-dom";
import {useDispatch} from "react-redux";
import {message, Modal, Drawer, Row, Col, Switch, Radio} from "antd";
import {UserOutlined, SkinOutlined, LockOutlined, PoweroffOutlined, RightOutlined} from "@ant-design/icons";
import * as Icon from "@ant-design/icons";
import {Scrollbars} from "react-custom-scrollbars-2";
import {useAliveController} from "react-activation";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./Index.module.css";

let scrollTop = 0;
let scrollLastTop = 0;

let routers = [];
let routerContexts = require.context("../", true, /^.*\/Router\.js$/);
routerContexts.keys().forEach((fileName) => {
    routers = routers.concat(routerContexts(fileName).default);
});

let storageCount = 0;

const MainIndex = (props) => {
    const store = useSelector((store) => store);
    const dispatch = useDispatch();
    const {drop, clear} = useAliveController();
    const request = useRef(false);
    const scrollbar = useRef(null);
    const [isLaunch, setIsLaunch] = useState(false);
    const [isDrawer, setIsDrawer] = useState(false);
    const [menuList, setMenuList] = useState([]);
    const [sidebarList, setSidebarList] = useState([]);

    ChuangShiOS.Notification.remove("main_menu_active", this);
    ChuangShiOS.Notification.on("main_menu_active", this, (data) => {
        let menuName = "";
        let sidebarName = "";
        let sidebarList = [];

        let isExit = false;
        for (let i = 0; i < menuList.length; i++) {
            menuList[i].active = false;

            if (menuList[i].children && menuList[i].children.length > 0) {
                for (let j = 0; j < menuList[i].children.length; j++) {
                    if (data.url == menuList[i].children[j].menuUrl) {
                        menuList[i].active = true;
                        menuList[i].children[j].active = true;

                        menuName = menuList[i].menuName;
                        sidebarName = menuList[i].children[j].menuName;

                        sidebarList = menuList[i].children;

                        isExit = true;
                    } else {
                        menuList[i].children[j].active = false;
                    }
                }
            } else {
                if (data.url == menuList[i].menuUrl) {
                    menuList[i].active = true;

                    menuName = menuList[i].menuName;
                    sidebarName = menuList[i].menuName;

                    if (menuList[i].children) {
                        sidebarList = menuList[i].children;
                    }

                    isExit = true;
                }
            }
        }

        if (isExit) {
            setMenuList([...menuList]);
            setSidebarList(sidebarList);

            let url = localStorage.getItem("keepAlive" + Application.appId);
            if (url != data.url) {
                drop(url);

                clear();

                localStorage.setItem("keepAlive" + Application.appId, data.url);
            }

            dispatch({
                type: "main",
                data: {
                    menuName: menuName,
                    sidebarName: sidebarName,
                },
            });
        } else {
            ChuangShiOS.Util.redirectTo(props, {
                url: Application.loginPath,
            });
        }
    });

    ChuangShiOS.Notification.remove("main_redirect", this);
    ChuangShiOS.Notification.on("main_redirect", this, (data) => {
        ChuangShiOS.Util.navigateTo(props, {
            url: data.url,
        });
    });

    useEffect(() => {
        let isShowHeader = localStorage.getItem("isShowHeader");
        if (isShowHeader) {
            dispatch({
                type: "main",
                data: {
                    isShowHeader: isShowHeader == "true",
                },
            });
        } else {
            localStorage.setItem("isShowHeader", store.main.isShowHeader);
        }

        let isShowSider = localStorage.getItem("isShowSider");
        if (isShowSider) {
            dispatch({
                type: "main",
                data: {
                    isShowSider: isShowSider == "true",
                },
            });
        } else {
            localStorage.setItem("isShowSider", store.main.isShowSider);
        }

        let isFixedContent = localStorage.getItem("isFixedContent");
        if (isFixedContent) {
            dispatch({
                type: "main",
                data: {
                    isFixedContent: isFixedContent == "true",
                },
            });
        } else {
            localStorage.setItem("isFixedContent", store.main.isFixedContent);
        }

        let pageSize = localStorage.getItem("pageSize");
        if (pageSize) {
            dispatch({
                type: "main",
                data: {
                    pageSize: parseInt(pageSize),
                },
            });
        } else {
            localStorage.setItem("pageSize", store.main.pageSize);
        }

        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (store.main.userName) {
            if (props.location.pathname == "/") {
                if (menuList.length > 0) {
                    ChuangShiOS.Util.redirectTo(props, {
                        url: menuList[0].menuUrl,
                    });
                }
            }
        }

        //eslint-disable-next-line
    }, [store.main.userName]);

    useEffect(() => {
        ChuangShiOS.Util.validateToken(props);

        let token = ChuangShiOS.Util.getToken();
        if (token) {
            handleLoadUser();
        }

        window.addEventListener("storage", (event) => {
            if (event.key == Application.tokenKey + Application.appId) {
                if (storageCount == 0) {
                    storageCount++;

                    Modal.info({
                        title: "温馨提示",
                        content: "您的登录信息有变化，需要刷新后才能继续操作",
                        onOk: () => {
                            storageCount = 0;

                            if (ChuangShiOS.Util.getToken()) {
                                handleLoadUser();

                                ChuangShiOS.Util.navigateTo(props, {
                                    url: Application.homePath,
                                });
                            } else {
                                ChuangShiOS.Util.navigateTo(props, {
                                    url: Application.loginPath,
                                });
                            }
                        },
                    });
                }
            }
        });

        ChuangShiOS.Notification.on("main_scroll_top", this, (data) => {
            scrollbar.current.scrollTop(data.top);
        });

        ChuangShiOS.Notification.on("main_get_scroll_top", this, (data) => {
            scrollLastTop = scrollTop;
        });

        ChuangShiOS.Notification.on("main_set_scroll_top", this, (data) => {
            scrollbar.current.scrollTop(scrollLastTop);
        });

        return () => {
            ChuangShiOS.Notification.remove("main_menu_active", this);

            ChuangShiOS.Notification.remove("main_redirect", this);

            ChuangShiOS.Notification.remove("main_scroll_top", this);

            ChuangShiOS.Notification.remove("main_get_scroll_top", this);

            ChuangShiOS.Notification.remove("main_set_scroll_top", this);
        };

        //eslint-disable-next-line
    }, []);

    const handleLoadUser = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/user/business/website/v1/profile/find",
            data: {},
            success: (response) => {
                setMenuList(response.data.menuList);

                dispatch({
                    type: "main",
                    data: {
                        userName: response.data.userName,
                        userAccount: response.data.userAccount,
                        userType: response.data.userType,
                        permissionKeyList: response.data.permissionKeyList,
                    },
                });
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                setIsLaunch(true);
            },
        });
    };

    function handleClickMenu(index) {
        let sidebarList = [];
        let url = "";

        for (let i = 0; i < menuList.length; i++) {
            if (i == index) {
                if (menuList[i].children) {
                    sidebarList = menuList[i].children;
                }
                url = menuList[i].menuUrl;

                break;
            }
        }

        if (url != "") {
            setSidebarList(sidebarList);
            ChuangShiOS.Util.navigateTo(props, {
                url: url,
            });
        }
    }

    const handleClickSidebar = (sidebarIndex) => {
        let url = "";

        for (let i = 0; i < sidebarList.length; i++) {
            if (i == sidebarIndex) {
                url = sidebarList[i].menuUrl;

                break;
            }
        }

        if (url != "") {
            ChuangShiOS.Util.navigateTo(props, {
                url: url,
            });
        }
    };

    const handleClickLogout = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/user/business/website/v1/logout",
            data: {},
            success: (response) => {
                ChuangShiOS.Util.removeToken();

                dispatch({
                    type: "main",
                    data: {
                        userName: "",
                        userType: "",
                        permissionKeyList: [],
                    },
                });

                setMenuList([]);
                setSidebarList([]);

                ChuangShiOS.Util.navigateTo(props, {
                    url: Application.loginPath,
                });
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;
            },
        });
    };

    const handleClean = () => {
        for (let i = 0; i < menuList.length; i++) {
            menuList[i].active = false;
        }
        setMenuList([...menuList]);
        setSidebarList([]);

        let url = localStorage.getItem("keepAlive" + Application.appId);
        if (url) {
            drop(url);

            clear();

            localStorage.setItem("keepAlive" + Application.appId, "");
        }
    };

    return (
        <React.Fragment>
            {isLaunch ? (
                <Scrollbars
                    ref={scrollbar}
                    onScrollFrame={(event) => {
                        scrollTop = event.scrollTop;
                    }}
                >
                    <div className={Style.page} style={{height: store.main.isFixedContent ? "100%" : "auto"}}>
                        <div className={Style.header} style={{display: store.main.isShowHeader ? "flex" : "none"}}>
                            <div className={Style.header_title}>我德优惠券平台</div>
                            <div className={Style.header_menu}></div>
                            <div className={Style.header_profile}>
                                <UserOutlined />
                                <span className={Style.header_button_text}>
                                    {store.main.userName}
                                    {store.main.userAccount ? <React.Fragment> ({store.main.userAccount})</React.Fragment> : null}
                                </span>
                            </div>
                            <div className={Style.header_line}></div>
                            <div
                                className={Style.header_button}
                                onClick={() => {
                                    handleClean();

                                    ChuangShiOS.Util.navigateTo(props, {
                                        url: "/password/index",
                                    });
                                }}
                            >
                                <LockOutlined />
                                <span className={Style.header_button_text}>修改密码</span>
                            </div>
                            <div className={Style.header_line}></div>
                            <div
                                className={Style.header_button}
                                onClick={() => {
                                    setIsDrawer(true);
                                }}
                            >
                                <SkinOutlined />
                                <span className={Style.header_button_text}>风格设置</span>
                            </div>
                            <div className={Style.header_line}></div>
                            <div
                                className={Style.header_button_logout}
                                onClick={() => {
                                    Modal.confirm({
                                        title: "温馨提示",
                                        content: "您是否需要退出系统？",
                                        okText: "确定",
                                        onOk: () => {
                                            handleClickLogout();
                                        },
                                    });
                                }}
                            >
                                <PoweroffOutlined />
                                <span className={Style.header_button_text}>退出系统</span>
                            </div>
                        </div>
                        <Scrollbars className={Style.menu} style={{display: store.main.isShowSider ? "flex" : "none"}}>
                            {menuList.map((menu, menuIndex) => {
                                return (
                                    <div
                                        key={menuIndex}
                                        className={Style.menu_item + " " + (menu.active ? Style.menu_item_active : "")}
                                        onClick={() => handleClickMenu(menuIndex)}
                                    >
                                        {menu.menuIcon ? React.createElement(Icon[menu.menuIcon], {className: Style.menu_item_icon}) : null}
                                        <div className={Style.menu_item_text}>{menu.menuName}</div>
                                    </div>
                                );
                            })}
                        </Scrollbars>
                        {sidebarList.length > 0 ? (
                            <div className={Style.sidebar} style={{display: store.main.isShowSider ? "block" : "none"}}>
                                {sidebarList.map((sidebar, sidebarIndex) => {
                                    return (
                                        <div
                                            key={sidebarIndex}
                                            className={Style.sidebar_item + " " + (sidebar.active ? Style.sidebar_item_active : "")}
                                            onClick={() => handleClickSidebar(sidebarIndex)}
                                        >
                                            <div className={Style.sidebar_item_text}>{sidebar.menuName}</div>
                                            {sidebar.active ? <RightOutlined className={Style.sidebar_item_icon} /> : null}
                                        </div>
                                    );
                                })}
                            </div>
                        ) : null}
                        <div className={Style.content}>
                            {routers.map((router) => {
                                return router;
                            })}
                        </div>
                    </div>
                </Scrollbars>
            ) : (
                <div className={Style.page} style={{height: store.main.isFixedContent ? "100%" : "auto"}}>
                    <ChuangShiOS.Content isLoad={true}></ChuangShiOS.Content>
                </div>
            )}
            <Drawer
                title="整体风格设置"
                placement="right"
                closable={false}
                onClose={() => {
                    setIsDrawer(false);
                }}
                visible={isDrawer}
            >
                {/* <Row>
                    <Col flex="auto">显示顶部导航</Col>
                    <Col>
                        <Switch
                            checkedChildren="开启"
                            unCheckedChildren="关闭"
                            checked={store.main.isShowHeader}
                            onChange={(value) => {
                                dispatch({
                                    type: "main",
                                    data: {
                                        isShowHeader: value,
                                    },
                                });

                                localStorage.setItem("isShowHeader", value);
                            }}
                        />
                    </Col>
                </Row>
                <br /> */}
                <Row>
                    <Col flex="auto">显示侧边菜单</Col>
                    <Col>
                        <Switch
                            checkedChildren="开启"
                            unCheckedChildren="关闭"
                            checked={store.main.isShowSider}
                            onChange={(value) => {
                                dispatch({
                                    type: "main",
                                    data: {
                                        isShowSider: value,
                                    },
                                });

                                localStorage.setItem("isShowSider", value);
                            }}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col flex="auto">固定内容高度</Col>
                    <Col>
                        <Switch
                            checkedChildren="开启"
                            unCheckedChildren="关闭"
                            checked={store.main.isFixedContent}
                            onChange={(value) => {
                                dispatch({
                                    type: "main",
                                    data: {
                                        isFixedContent: value,
                                    },
                                });

                                localStorage.setItem("isFixedContent", value);
                            }}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col flex="auto">每页显示数据</Col>
                    <Col>
                        <Radio.Group
                            onChange={(event) => {
                                dispatch({
                                    type: "main",
                                    data: {
                                        pageSize: event.target.value,
                                    },
                                });

                                localStorage.setItem("pageSize", event.target.value);
                            }}
                            defaultValue={store.main.pageSize}
                        >
                            <Radio.Button value={10}>10</Radio.Button>
                            <Radio.Button value={20}>20</Radio.Button>
                            <Radio.Button value={50}>50</Radio.Button>
                            <Radio.Button value={100}>100</Radio.Button>
                        </Radio.Group>
                    </Col>
                </Row>
            </Drawer>
        </React.Fragment>
    );
};

MainIndex.propTypes = {};

MainIndex.defaultProps = {};

export default withRouter(MainIndex);
