import React, {useState, useRef, useEffect} from "react";
import {message} from "antd";
import {PlusOutlined, CloseCircleFilled} from "@ant-design/icons";
import ChuangShiOS from "chuangshi-os-website-component";

import MerchantModal from "../merchant/Modal";

import Application from "../../common/Application";

import Style from "./Input.module.css";

const MerchantInput = (props) => {
    const request = useRef(false);
    const [isModal, setIsModal] = useState(false);
    const [merchantId, setMerchantId] = useState("");
    const [merchantName, setMerchantName] = useState("");

    useEffect(() => {
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (props.value) {
            handleLoadMerchant(props.value);
        }

        //eslint-disable-next-line
    }, [props.value]);

    const handleLoadMerchant = (merchantId) => {
        if (request.current) {
            return;
        }
        request.current = true;

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/merchant/business/website/v1/find",
            data: {
                merchantId: merchantId,
            },
            success: (response) => {
                setMerchantId(response.data.merchantId);
                setMerchantName(response.data.merchantName);

                if (props.onQuery) {
                    props.onQuery(response.data);
                }
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;
            },
        });
    };

    const handleOpenModal = () => {
        setIsModal(true);
    };

    const handleCloseModal = () => {
        setIsModal(false);
    };

    const handleSubmitModal = (merchant) => {
        setIsModal(false);
        setMerchantId(merchant.merchantId);
        setMerchantName(merchant.merchantName);

        props.onChange(merchant.merchantId);
    };

    const handleClearMerchant = () => {
        setMerchantId("");
        setMerchantName("");

        props.onChange("");
    };

    return (
        <React.Fragment>
            <div className={Style.input + " ant-input-affix-wrmerchanter " + props.className}>
                <div className={Style.input_text} onClick={handleOpenModal}>
                    {merchantId == "" && merchantName == "" ? <span className={Style.input_placeholder}>{props.placeholder}</span> : null}
                    {merchantName ? merchantName : merchantId}
                </div>
                {merchantId == "" ? (
                    <div className={Style.input_clear + " ant-input-clear-icon"} onClick={handleClearMerchant}>
                        <PlusOutlined />
                    </div>
                ) : (
                    <div className={Style.input_clear + " ant-input-clear-icon"} onClick={handleClearMerchant}>
                        <CloseCircleFilled />
                    </div>
                )}
            </div>
            <MerchantModal visible={isModal} multiple={false} onClose={handleCloseModal} onSubmit={handleSubmitModal}></MerchantModal>
        </React.Fragment>
    );
};

MerchantInput.propTypes = {};

MerchantInput.defaultProps = {};

export default MerchantInput;
