import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {message, Breadcrumb, Form, Row, Col, Input, DatePicker, Select, Space, Button, Card} from "antd";
import {LeftCircleOutlined, FileDoneOutlined} from "@ant-design/icons";
import moment from "moment";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./Detail.module.css";

const ActivityNoticeDetail = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [systemVersion, setSystemVersion] = useState(0);
    const [activityList, setActivityList] = useState([]);

    useEffect(() => {
        if (store.main.userName) {
            ChuangShiOS.Notification.emit("main_menu_active", {url: "/activity/notice/index"});

            handleLoadActivity();

            switch (props.match.path) {
                case "/activity/notice/add":
                    setIsSave(true);

                    break;
                case "/activity/notice/edit/:activityNoticeId":
                    setIsEdit(true);

                    handleLoadActivityNotice();

                    break;
                default:
                    break;
            }
        }

        //eslint-disable-next-line
    }, [store.main.userName]);

    const handleLoadActivity = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/activity/business/website/v1/select/list",
            data: {},
            success: (response) => {
                setActivityList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadActivityNotice = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/activity/notice/business/website/v1/find",
            data: {
                activityNoticeId: props.match.params.activityNoticeId,
            },
            success: (response) => {
                response.data.activityNoticeTime = moment(response.data.activityNoticeTime, "YYYY-MM-DD HH:mm");

                form.setFieldsValue(response.data);

                setSystemVersion(response.data.systemVersion);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
                setIsError(
                    typeof response.data == "undefined" ||
                        typeof response.data.activityNoticeId == "undefined" ||
                        response.data.activityNoticeId == null
                );
            },
        });
    };

    const handleSubmitActivityNotice = () => {
        if (request.current) {
            return;
        }

        form.validateFields()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                data.activityNoticeId = props.match.params.activityNoticeId;
                data.activityNoticeTime = data.activityNoticeTime.format("YYYY-MM-DD HH:mm");
                data.systemVersion = systemVersion;

                let url = "";
                if (isSave) {
                    url = "/windeal/activity/notice/business/website/v1/save";
                }
                if (isEdit) {
                    url = "/windeal/activity/notice/business/website/v1/update";
                }

                ChuangShiOS.Util.request(props, {
                    url: Application.host + url,
                    data: data,
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);

                        ChuangShiOS.Util.navigateBack(props, {});
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            })
            .catch((response) => {
                ChuangShiOS.Util.parseResponseError(response);
            });
    };

    const handleBackActivityNotice = () => {
        ChuangShiOS.Util.navigateBack(props, {});
    };

    return (
        <div className={Style.page}>
            <div className={Style.header}>
                <div className={Style.header_title}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <span>首页</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span>{store.main.menuName}</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className={Style.header_title_text}>{store.main.sidebarName}</div>
                </div>
                <div className={Style.header_button}>
                    <Space>
                        <Button.Group>
                            <Button icon={<LeftCircleOutlined />} size="large" onClick={handleBackActivityNotice}>
                                返回
                            </Button>
                        </Button.Group>
                        <Button
                            loading={isLoad}
                            disabled={isError}
                            icon={<FileDoneOutlined />}
                            type="primary"
                            size="large"
                            onClick={handleSubmitActivityNotice}
                        >
                            提交
                        </Button>
                    </Space>
                </div>
            </div>
            <ChuangShiOS.Content
                className={Style.content}
                isLoad={isLoad}
                isEmpty={false}
                isError={isError}
                paddingTop={0}
                paddingRight={0}
                paddingBottom={0}
                paddingLeft={0}
                isScroll={store.main.isFixedContent}
            >
                <Card>
                    <Row>
                        <Col flex="auto">
                            <div className={Style.card_title}>基本信息</div>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Form form={form}>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "24%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="活动名称"
                                    name="activityIdList"
                                    initialValue={[]}
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择活动名称",
                                        },
                                    ]}
                                >
                                    <Select
                                        className={Style.card_field}
                                        mode="multiple"
                                        placeholder="请选择活动名称"
                                        allowClear
                                        onChange={(value) => {}}
                                    >
                                        {activityList.map((activity, activityIndex) => {
                                            return (
                                                <Select.Option key={activityIndex} value={activity.activityId}>
                                                    {activity.activityName}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "24%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="通告类型"
                                    name="activityNoticeType"
                                    initialValue={null}
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择通告类型",
                                        },
                                    ]}
                                >
                                    <Select className={Style.card_field} placeholder="请选择通告类型" allowClear onChange={(value) => {}}>
                                        <Select.Option value={"公告"}>公告</Select.Option>
                                        <Select.Option value={"折扣"}>折扣</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "24%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="通告标题"
                                    name="activityNoticeTitle"
                                    initialValue={""}
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入通告标题",
                                        },
                                    ]}
                                >
                                    <Input className={Style.card_field} placeholder="请输入通告标题" onPressEnter={handleSubmitActivityNotice} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "12%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="通告内容"
                                    name="activityNoticeContent"
                                    initialValue={""}
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入通告内容",
                                        },
                                    ]}
                                >
                                    <ChuangShiOS.Editor id="activityNoticeContent" placeholder="请输入通告内容" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "24%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="通告时间"
                                    name="activityNoticeTime"
                                    rules={[
                                        {
                                            required: true,
                                            type: "object",
                                            message: "请选择通告时间",
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        className={Style.card_field}
                                        showTime={{format: "HH:mm"}}
                                        format="YYYY-MM-DD HH:mm"
                                        placeholder="请输入通告时间"
                                        onPressEnter={handleSubmitActivityNotice}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </ChuangShiOS.Content>
            <div className={Style.footer}></div>
        </div>
    );
};

ActivityNoticeDetail.propTypes = {};

ActivityNoticeDetail.defaultProps = {};

export default ActivityNoticeDetail;
