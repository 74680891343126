import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {useActivate, useUnactivate} from "react-activation";
import {message, Breadcrumb, Space, Button, Form, Row, Col, Input, Table, Select, Tag} from "antd";
import {FileAddOutlined, FileSearchOutlined, FileTextOutlined, UpCircleOutlined, DownCircleOutlined} from "@ant-design/icons";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./Index.module.css";

const GoodsIndex = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const [tableHeight, setTableHeight] = useState(0);
    const [goodsPageIndex, setGoodsPageIndex] = useState(1);
    const [goodsPageSize, setGoodsPageSize] = useState(store.main.pageSize);
    const [goodsIdList, setGoodsIdList] = useState([]);
    const [goodsTotal, setGoodsTotal] = useState(0);
    const [goodsList, setGoodsList] = useState([]);
    const [goodsCategoryList, setGoodsCategoryList] = useState([]);
    const [provinceList, setProvinceList] = useState([]);
    const [cityList, setCityList] = useState([]);

    useEffect(() => {
        if (store.main.userName) {
            ChuangShiOS.Notification.emit("main_menu_active", {url: "/goods/index"});

            handleSearchGoods();

            handleLoadProvince();

            handleLoadGoodsCategory();
        }

        //eslint-disable-next-line
    }, [store.main.userName]);

    useEffect(() => {
        handleLoadGoods(goodsPageIndex, store.main.pageSize);

        //eslint-disable-next-line
    }, [store.main.pageSize]);

    useEffect(() => {
        handleLoadGoods(goodsPageIndex, store.main.pageSize);

        //eslint-disable-next-line
    }, [store.main.pageSize]);

    useActivate(() => {
        ChuangShiOS.Notification.emit("main_set_scroll_top", {});

        handleLoadGoods(goodsPageIndex, goodsPageSize);
    });

    useUnactivate(() => {
        ChuangShiOS.Notification.emit("main_get_scroll_top", {});
    });

    const handleLoadProvince = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/province/business/website/v1/select/list",
            data: {},
            success: (response) => {
                setProvinceList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadCity = (provinceId) => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/city/business/website/v1/select/list",
            data: {
                provinceId: provinceId,
            },
            success: (response) => {
                setCityList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadGoodsCategory = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/goods/category/business/website/v1/select/list",
            data: {},
            success: (response) => {
                setGoodsCategoryList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleSearchGoods = () => {
        handleLoadGoods(1, goodsPageSize);
    };

    const handleLoadGoods = (goodsPageIndex, goodsPageSize) => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        let data = form.getFieldsValue();
        data.pageIndex = goodsPageIndex;
        data.pageSize = goodsPageSize;

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/goods/business/website/v1/list",
            data: data,
            success: (response) => {
                setGoodsPageIndex(goodsPageIndex);
                setGoodsPageSize(goodsPageSize);
                setGoodsIdList([]);
                setGoodsTotal(response.data.total);
                setGoodsList(response.data.list);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
                setIsError(
                    typeof response.data == "undefined" ||
                        response.data.total == "undefined" ||
                        response.data.total == null ||
                        response.data.list == "undefined" ||
                        response.data.list == null
                );
            },
        });
    };

    const handleAddGoods = () => {
        ChuangShiOS.Util.navigateTo(props, {
            url: "/goods/add",
        });
    };

    const handleEditGoods = (goodsId) => {
        ChuangShiOS.Util.navigateTo(props, {
            url: "/goods/edit/" + goodsId,
        });
    };

    const handleChangeStatus = (goodsStatus) => {
        if (goodsIdList.length == 0) {
            return;
        }

        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        message.loading({
            content: "正在加载",
            key: "isLoad",
            duration: 0,
        });

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/goods/business/website/v1/change/status",
            data: {
                goodsIdList: goodsIdList,
                goodsStatus: goodsStatus,
            },
            success: (response) => {
                message.success({
                    content: response.message,
                    key: "isLoad",
                    duration: 2,
                });

                request.current = false;

                setIsLoad(false);
                setGoodsIdList([]);

                handleLoadGoods(goodsPageIndex, goodsPageSize);
            },
            error: (response) => {
                message.error({
                    content: response.message,
                    key: "isLoad",
                    duration: 2,
                });

                request.current = false;

                setIsLoad(false);
            },
            complete: (response) => {},
        });
    };

    const handleChangeGoodsIdList = (goodsIdList) => {
        setGoodsIdList(goodsIdList);
    };

    const handleChangeGoodsPagination = (goodsPageIndex, goodsPageSize) => {
        handleLoadGoods(goodsPageIndex, goodsPageSize);
    };

    return (
        <div className={Style.page}>
            <div className={Style.header}>
                <div className={Style.header_title}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <span>首页</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span>{store.main.menuName}</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className={Style.header_title_text}>{store.main.sidebarName}</div>
                </div>
                <div className={Style.header_button}>
                    <Space>
                        <Button.Group>
                            <Button icon={<FileAddOutlined />} size="large" onClick={handleAddGoods}>
                                新增
                            </Button>
                            <Button
                                loading={isLoad}
                                disabled={goodsIdList.length == 0}
                                icon={<UpCircleOutlined />}
                                size="large"
                                onClick={() => {
                                    handleChangeStatus(true);
                                }}
                            >
                                上架
                            </Button>
                            <Button
                                loading={isLoad}
                                disabled={goodsIdList.length == 0}
                                icon={<DownCircleOutlined />}
                                size="large"
                                onClick={() => {
                                    handleChangeStatus(false);
                                }}
                            >
                                下架
                            </Button>
                        </Button.Group>
                        <Button loading={isLoad} icon={<FileSearchOutlined />} type="primary" size="large" onClick={handleSearchGoods}>
                            查询
                        </Button>
                    </Space>
                </div>
            </div>
            <ChuangShiOS.Content className={Style.content} isLoad={isLoad} isEmpty={false} isError={isError} isScroll={store.main.isFixedContent}>
                <Form form={form}>
                    <Row>
                        <Col span={4}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "none"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="省份名称"
                                labelAlign={"left"}
                                name="provinceId"
                                initialValue={null}
                                rules={[
                                    {
                                        required: false,
                                        message: "请选择省份名称",
                                    },
                                ]}
                            >
                                <Select
                                    className={Style.card_field}
                                    placeholder="请选择省份名称"
                                    allowClear
                                    onChange={(event) => {
                                        form.setFieldsValue({
                                            cityId: null,
                                        });

                                        setCityList([]);

                                        if (event) {
                                            handleLoadCity(event);
                                        }

                                        handleSearchGoods();
                                    }}
                                >
                                    {provinceList.map((province, provinceIndex) => {
                                        return (
                                            <Select.Option key={provinceIndex} value={province.provinceId}>
                                                {province.provinceName}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "none"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="城市名称"
                                labelAlign={"left"}
                                name="cityId"
                                initialValue={null}
                                rules={[
                                    {
                                        required: false,
                                        message: "请选择城市名称",
                                    },
                                ]}
                            >
                                <Select
                                    className={Style.card_field}
                                    placeholder="请选择城市名称"
                                    allowClear
                                    onChange={(event) => {
                                        handleSearchGoods();
                                    }}
                                >
                                    {cityList.map((city, cityIndex) => {
                                        return (
                                            <Select.Option key={cityIndex} value={city.cityId}>
                                                {city.cityName}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "none"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="商户名称"
                                labelAlign={"left"}
                                name="merchantName"
                                initialValue={""}
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入商户名称",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入商户名称" onPressEnter={handleSearchGoods} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "none"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="门店名称"
                                labelAlign={"left"}
                                name="shopName"
                                initialValue={""}
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入门店名称",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入门店名称" onPressEnter={handleSearchGoods} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "none"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="商品分类:"
                                labelAlign={"left"}
                                name="goodsCategoryId"
                                initialValue={null}
                                rules={[
                                    {
                                        required: false,
                                        message: "请选择商品分类:",
                                    },
                                ]}
                            >
                                <Select
                                    className={Style.card_field}
                                    placeholder="请选择商品分类:"
                                    allowClear
                                    onChange={() => {
                                        handleSearchGoods();
                                    }}
                                >
                                    {goodsCategoryList.map((goodsCategory, goodsCategoryIndex) => {
                                        return (
                                            <Select.Option key={goodsCategoryIndex} value={goodsCategory.goodsCategoryId}>
                                                {goodsCategory.goodsCategoryName}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "none"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="商品名称"
                                labelAlign={"left"}
                                name="goodsName"
                                initialValue={""}
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入商品名称",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入商品名称" onPressEnter={handleSearchGoods} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div
                    ref={(ref) => {
                        if (ref != null && tableHeight == 0 && tableHeight != ref.clientHeight) {
                            setTableHeight(ref.clientHeight);
                        }
                    }}
                    className={store.main.isFixedContent ? Style.card_table : null}
                >
                    <Table
                        size="small"
                        rowKey={(record) => record.goodsId}
                        columns={[
                            {
                                title: "商品名称",
                                width: 180,
                                dataIndex: "goodsName",
                            },
                            {
                                title: "商品分类",
                                width: 110,
                                dataIndex: "goodsCategoryName",
                            },
                            {
                                title: "商户名称",
                                width: 210,
                                dataIndex: "merchantName",
                            },
                            {
                                title: "门店名称",
                                width: 200,
                                dataIndex: "shopName",
                            },
                            {
                                title: "城市名称",
                                width: 110,
                                dataIndex: "cityName",
                            },
                            {
                                title: "可定日期",
                                width: 130,
                                render: (text, record, recordIndex) => (
                                    <React.Fragment>
                                        {record.goodsReserveWeekList.sort().map((goodsReserveWeek, goodsReserveWeekIndex) => {
                                            return (
                                                <span key={goodsReserveWeekIndex}>
                                                    {goodsReserveWeek == 0 ? null : goodsReserveWeek}
                                                    {goodsReserveWeekIndex + 1 < record.goodsReserveWeekList.length && goodsReserveWeek > 0
                                                        ? ","
                                                        : null}
                                                </span>
                                            );
                                        })}
                                        {record.goodsReserveWeekList.indexOf(0) > -1
                                            ? (record.goodsReserveWeekList.length > 1 ? "," : "") + "7"
                                            : null}
                                    </React.Fragment>
                                ),
                            },
                            {
                                title: "可定时间",
                                width: 130,
                                render: (text, record, recordIndex) => (
                                    <React.Fragment>
                                        {record.goodsReserveStartTime}~{record.goodsReserveEndTime}
                                    </React.Fragment>
                                ),
                            },
                            {
                                title: "商品状态",
                                width: 110,
                                dataIndex: "goodsStatus",
                                render: (text, record, recordIndex) =>
                                    record.goodsStatus ? <Tag color="blue">上架</Tag> : <Tag color="red">下架</Tag>,
                            },
                            {
                                title: "操作",
                                fixed: "right",
                                width: 100,
                                render: (text, record, recordIndex) => (
                                    <Space>
                                        <Button icon={<FileTextOutlined />} size="default" onClick={() => handleEditGoods(record.goodsId)}>
                                            修改
                                        </Button>
                                    </Space>
                                ),
                            },
                        ]}
                        dataSource={goodsList}
                        rowSelection={{
                            fixed: true,
                            selectedRowKeys: goodsIdList,
                            onChange: handleChangeGoodsIdList,
                        }}
                        scroll={store.main.isFixedContent ? {y: tableHeight - 130} : {}}
                        pagination={{
                            size: "default",
                            showQuickJumper: true,
                            showSizeChanger: true,
                            current: goodsPageIndex,
                            pageSize: goodsPageSize,
                            total: goodsTotal,
                            showTotal: (total, range) => "显示" + range[0] + "到" + range[1] + "条,共有" + total + "条",
                            onChange: handleChangeGoodsPagination,
                            onShowSizeChange: handleChangeGoodsPagination,
                        }}
                    />
                </div>
            </ChuangShiOS.Content>
            <div className={Style.footer}></div>
        </div>
    );
};

GoodsIndex.propTypes = {};

GoodsIndex.defaultProps = {};

export default GoodsIndex;
