import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {message, Breadcrumb, Form, Row, Col, Input, Space, Button, Switch} from "antd";
import {LeftCircleOutlined, FileDoneOutlined} from "@ant-design/icons";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./Detail.module.css";

const EnterpriseDetail = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [systemVersion, setSystemVersion] = useState(0);

    useEffect(() => {
        if (store.main.userName) {
            ChuangShiOS.Notification.emit("main_menu_active", {url: "/enterprise/index"});

            switch (props.match.path) {
                case "/enterprise/add":
                    setIsSave(true);

                    break;
                case "/enterprise/edit/:enterpriseId":
                    setIsEdit(true);

                    handleLoadEnterprise();

                    break;
                default:
                    break;
            }
        }

        //eslint-disable-next-line
    }, [store.main.userName]);

    const handleLoadEnterprise = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/enterprise/business/website/v1/find",
            data: {
                enterpriseId: props.match.params.enterpriseId,
            },
            success: (response) => {
                form.setFieldsValue(response.data);

                setSystemVersion(response.data.systemVersion);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
                setIsError(
                    typeof response.data == "undefined" || typeof response.data.enterpriseId == "undefined" || response.data.enterpriseId == null
                );
            },
        });
    };

    const handleSubmitEnterprise = () => {
        if (request.current) {
            return;
        }

        form.validateFields()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                data.enterpriseId = props.match.params.enterpriseId;
                data.systemVersion = systemVersion;

                let url = "";
                if (isSave) {
                    url = "/windeal/enterprise/business/website/v1/save";
                }
                if (isEdit) {
                    url = "/windeal/enterprise/business/website/v1/update";
                }

                ChuangShiOS.Util.request(props, {
                    url: Application.host + url,
                    data: data,
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);

                        ChuangShiOS.Util.navigateBack(props, {});
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            })
            .catch((response) => {
                ChuangShiOS.Util.parseResponseError(response);
            });
    };

    const handleBackEnterprise = () => {
        ChuangShiOS.Util.navigateBack(props, {});
    };

    return (
        <div className={Style.page}>
            <div className={Style.header}>
                <div className={Style.header_title}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <span>首页</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span>{store.main.menuName}</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className={Style.header_title_text}>{store.main.sidebarName}</div>
                </div>
                <div className={Style.header_button}>
                    <Space>
                        <Button.Group>
                            <Button icon={<LeftCircleOutlined />} size="large" onClick={handleBackEnterprise}>
                                返回
                            </Button>
                        </Button.Group>
                        <Button
                            loading={isLoad}
                            disabled={isError}
                            icon={<FileDoneOutlined />}
                            type="primary"
                            size="large"
                            onClick={handleSubmitEnterprise}
                        >
                            提交
                        </Button>
                    </Space>
                </div>
            </div>
            <ChuangShiOS.Content
                className={Style.content}
                isLoad={isLoad}
                isEmpty={false}
                isError={isError}
                paddingTop={28}
                isScroll={store.main.isFixedContent}
            >
                <Row>
                    <Col flex="auto">
                        <div className={Style.card_title}>基本信息</div>
                    </Col>
                    <Col></Col>
                </Row>
                <Form form={form}>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="企业名称"
                                name="enterpriseName"
                                initialValue={""}
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入企业名称",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入企业名称" onPressEnter={handleSubmitEnterprise} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="企业代码"
                                name="enterpriseCode"
                                initialValue={""}
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入企业代码",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入企业代码" onPressEnter={handleSubmitEnterprise} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="系统对接"
                                name="enterpriseIsSystem"
                                initialValue={false}
                                valuePropName="checked"
                                rules={[
                                    {
                                        required: false,
                                        message: "请选择系统对接",
                                    },
                                ]}
                            >
                                <Switch checkedChildren="已对接" unCheckedChildren="未对接" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="发送短信"
                                name="enterpriseIsMessage"
                                initialValue={false}
                                valuePropName="checked"
                                rules={[
                                    {
                                        required: false,
                                        message: "请选择发送短信",
                                    },
                                ]}
                            >
                                <Switch checkedChildren="已开启" unCheckedChildren="未关闭" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "12%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="成功短信"
                                name="enterpriseSuccessMessage"
                                initialValue={""}
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入成功短信",
                                    },
                                ]}
                            >
                                <Input.TextArea className={Style.card_field} placeholder="请输入成功短信" rows={5} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "12%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="失败短信"
                                name="enterpriseFailMessage"
                                initialValue={""}
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入失败短信",
                                    },
                                ]}
                            >
                                <Input.TextArea className={Style.card_field} placeholder="请输入失败短信" rows={5} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "12%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="取消短信"
                                name="enterpriseCancelMessage"
                                initialValue={""}
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入取消短信",
                                    },
                                ]}
                            >
                                <Input.TextArea className={Style.card_field} placeholder="请输入取消短信" rows={5} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={3}></Col>
                        <Col>
                            <div style={{wordBreak: "break-word"}}>
                                {"{0}"}预订人， {"{1}"}YYYY年MM月DD日， {"{2}"}HH时mm分， {"{3}"}人数， {"{4}"}商户名称
                            </div>{" "}
                            <div style={{wordBreak: "break-word"}}>
                                {"{5}"}门店名称， {"{6}"}
                                优惠产品， {"{7}"}核销单号， {"{8}"}末尾卡号， {"{9}"}地址， {"{10}"}电话
                            </div>
                        </Col>
                    </Row>
                </Form>
            </ChuangShiOS.Content>
            <div className={Style.footer}></div>
        </div>
    );
};

EnterpriseDetail.propTypes = {};

EnterpriseDetail.defaultProps = {};

export default EnterpriseDetail;
