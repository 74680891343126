const data = {
    host: "https://api.nowui.com",
    baseHost: "https://api.nowui.com",
    imageUploadHost: "https://api.nowui.com",
    imagePreviewHost: "https://static.nowui.com",
    appId: "1305198331571605504",
    version: "1.0.0",
    platform: "WinDeal-Admin",
    homePath: "/",
    loginPath: "/login/index",
    registerPath: "/register/index",
    tokenKey: "business_website_token_",
};

export default data;
