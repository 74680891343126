import React from "react";
import {Route} from "react-router-dom";
import KeepAlive from "react-activation";
import ChuangShiOS from "chuangshi-os-website-component";

import BrandIndex from "./Index";
import BrandDetail from "./Detail";

const data = [
    <Route
        exact
        key="BrandIndex"
        path="/brand/index"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return (
                <KeepAlive name="/brand/index">
                    <BrandIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="BrandAdd"
        path="/brand/add"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <BrandDetail {...props} />;
        }}
    />,
    <Route
        exact
        key="BrandEdit"
        path="/brand/edit/:brandId"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <BrandDetail {...props} />;
        }}
    />,
];

export default data;
