import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {useActivate, useUnactivate} from "react-activation";
import {message, Modal, Breadcrumb, Space, Button, Form, Row, Col, Input, DatePicker, Table, Select, Dropdown, Menu} from "antd";
import {
    FileSearchOutlined,
    FileAddOutlined,
    DownOutlined,
    DeleteOutlined,
    CloseCircleOutlined,
    FileTextOutlined,
    MessageOutlined,
    CloudDownloadOutlined,
    FileDoneOutlined,
} from "@ant-design/icons";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./EnterpriseIndex.module.css";

const TradeEnterpriseIndex = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const [modalForm] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const [tableHeight, setTableHeight] = useState(0);
    const [tradePageIndex, setTradePageIndex] = useState(1);
    const [tradePageSize, setTradePageSize] = useState(store.main.pageSize);
    const [tradeIdList, setTradeIdList] = useState([]);
    const [tradeTotal, setTradeTotal] = useState(0);
    const [tradeList, setTradeList] = useState([]);
    const [isModal, setIsModal] = useState(false);
    const [tradeId, setTradeId] = useState("");
    const [systemVersion, setSystemVersion] = useState(0);

    useEffect(() => {
        if (store.main.userName) {
            ChuangShiOS.Notification.emit("main_menu_active", {url: "/trade/enterprise/index"});

            handleSearchTrade();
        }

        //eslint-disable-next-line
    }, [store.main.userName]);

    useEffect(() => {
        handleLoadTrade(tradePageIndex, store.main.pageSize);

        //eslint-disable-next-line
    }, [store.main.pageSize]);

    useActivate(() => {
        ChuangShiOS.Notification.emit("main_set_scroll_top", {});

        handleLoadTrade(tradePageIndex, tradePageSize);
    });

    useUnactivate(() => {
        ChuangShiOS.Notification.emit("main_get_scroll_top", {});
    });

    const handleSearchTrade = () => {
        handleLoadTrade(1, tradePageSize);
    };

    const handleLoadTrade = (tradePageIndex, tradePageSize) => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        let data = form.getFieldsValue();
        data.pageIndex = tradePageIndex;
        data.pageSize = tradePageSize;

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/trade/business/website/v1/enterprise/list",
            data: data,
            success: (response) => {
                setTradePageIndex(tradePageIndex);
                setTradePageSize(tradePageSize);
                setTradeIdList([]);
                setTradeTotal(response.data.total);
                setTradeList(response.data.list);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
                setIsError(
                    typeof response.data == "undefined" ||
                        response.data.total == "undefined" ||
                        response.data.total == null ||
                        response.data.list == "undefined" ||
                        response.data.list == null
                );
            },
        });
    };

    const handleChangeTradeIdList = (tradeIdList) => {
        setTradeIdList(tradeIdList);
    };

    const handleChangeTradePagination = (tradePageIndex, tradePageSize) => {
        handleLoadTrade(tradePageIndex, tradePageSize);
    };

    const handleAddTrade = () => {
        ChuangShiOS.Util.navigateTo(props, {
            url: "/trade/enterprise/add",
        });
    };

    const handleEditTrade = (tradeId) => {
        ChuangShiOS.Util.navigateTo(props, {
            url: "/trade/enterprise/edit/" + tradeId,
        });
    };

    const handleCancelTrade = (tradeId, systemVersion) => {
        setIsModal(true);
        setTradeId(tradeId);
        setSystemVersion(systemVersion);
    };

    const handleSubmitCancelTrade = () => {
        if (request.current) {
            return;
        }

        modalForm
            .validateFields()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                ChuangShiOS.Util.request(props, {
                    url: Application.host + "/windeal/trade/business/website/v1/cancel",
                    data: {
                        tradeId: tradeId,
                        tradeRemark: data.tradeRemark,
                        systemVersion: systemVersion,
                    },
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                        setIsModal(false);

                        modalForm.resetFields();

                        handleLoadTrade(tradePageIndex, tradePageSize);
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            })
            .catch((response) => {
                ChuangShiOS.Util.parseResponseError(response);
            });
    };

    const handleSendMessage = (tradeId) => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        message.loading({
            content: "正在加载",
            key: "isLoad",
            duration: 0,
        });

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/trade/business/website/v1/message/send",
            data: {
                tradeId: tradeId,
            },
            success: (response) => {
                message.success({
                    content: response.message,
                    key: "isLoad",
                    duration: 2,
                });
            },
            error: (response) => {
                message.error({
                    content: response.message,
                    key: "isLoad",
                    duration: 2,
                });
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
            },
        });
    };

    const handleExport = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        message.loading({content: "正在加载", key: "isLoad", duration: 0});

        let data = form.getFieldsValue();
        data.pageIndex = 0;
        data.pageSize = 0;

        ChuangShiOS.Util.download(this, {
            url: Application.host + "/windeal/trade/business/website/v1/enterprise/export",
            data: data,
            complete: (response) => {
                message.success({
                    content: "导出成功",
                    key: "isLoad",
                    duration: 2,
                });

                request.current = false;

                setIsLoad(false);
            },
        });
    };

    return (
        <div className={Style.page}>
            <div className={Style.header}>
                <div className={Style.header_title}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <span>首页</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span>{store.main.menuName}</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className={Style.header_title_text}>{store.main.sidebarName}</div>
                </div>
                <div className={Style.header_button}>
                    <Space>
                        <Button.Group>
                            {store.main.permissionKeyList.indexOf("trade:enterprise:add") > -1 ? (
                                <Button icon={<FileAddOutlined />} size="large" onClick={handleAddTrade}>
                                    新增
                                </Button>
                            ) : null}
                            <Button loading={isLoad} icon={<CloudDownloadOutlined />} size="large" onClick={handleExport}>
                                下载
                            </Button>
                        </Button.Group>
                        <Button loading={isLoad} icon={<FileSearchOutlined />} type="primary" size="large" onClick={handleSearchTrade}>
                            查询
                        </Button>
                    </Space>
                </div>
            </div>
            <ChuangShiOS.Content className={Style.content} isLoad={isLoad} isEmpty={false} isError={isError} isScroll={store.main.isFixedContent}>
                <Form form={form}>
                    <Row>
                        <Col span={4}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "none"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="商户名称"
                                labelAlign={"left"}
                                name="merchantName"
                                initialValue={""}
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入商户名称",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入商户名称" onPressEnter={handleSearchTrade} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "none"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="规格名称"
                                labelAlign={"left"}
                                name="goodsSkuName"
                                initialValue={""}
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入规格名称",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入规格名称" onPressEnter={handleSearchTrade} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "none"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="订单状态"
                                labelAlign={"left"}
                                name="tradeStatus"
                                initialValue={null}
                                rules={[
                                    {
                                        required: false,
                                        message: "请选择订单状态",
                                    },
                                ]}
                            >
                                <Select className={Style.card_field} placeholder="请选择订单状态" allowClear onChange={handleSearchTrade}>
                                    <Select.Option value={"WAITING_PAYMENT"}>待支付</Select.Option>
                                    <Select.Option value={"WAITING_CONFIRM"}>待确认</Select.Option>
                                    <Select.Option value={"WAITING_CHECK"}>待核销</Select.Option>
                                    <Select.Option value={"COMPLETE"}>已完成</Select.Option>
                                    <Select.Option value={"INCOMPLETE"}>未出席待确认</Select.Option>
                                    <Select.Option value={"INCOMPLETE_CONFIRM"}>未出席已确认</Select.Option>
                                    <Select.Option value={"CANCEL"}>已取消</Select.Option>
                                    <Select.Option value={"REJECT"}>已拒绝</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "none"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="核销单号"
                                labelAlign={"left"}
                                name="checkNo"
                                initialValue={""}
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入核销单号",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入核销单号" onPressEnter={handleSearchTrade} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={4}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "none"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="预定姓名"
                                labelAlign={"left"}
                                name="reserveName"
                                initialValue={""}
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入预定姓名",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入预定姓名" onPressEnter={handleSearchTrade} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "none"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="预定电话"
                                labelAlign={"left"}
                                name="reservePhone"
                                initialValue={""}
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入预定电话",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入预定电话" onPressEnter={handleSearchTrade} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "none"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="预定开始"
                                labelAlign={"left"}
                                name="reserveStartDate"
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入预定开始",
                                    },
                                ]}
                            >
                                <DatePicker className={Style.card_field} placeholder="请输入预定开始" onPressEnter={handleSearchTrade} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "none"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="预定结束"
                                labelAlign={"left"}
                                name="reserveEndDate"
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入预定结束",
                                    },
                                ]}
                            >
                                <DatePicker className={Style.card_field} placeholder="请输入预定结束" onPressEnter={handleSearchTrade} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div
                    ref={(ref) => {
                        if (ref != null && tableHeight == 0 && tableHeight != ref.clientHeight) {
                            setTableHeight(ref.clientHeight);
                        }
                    }}
                    className={store.main.isFixedContent ? Style.card_table : null}
                >
                    <Table
                        size="small"
                        rowKey={(record) => record.tradeId}
                        columns={[
                            {
                                title: "序号",
                                width: 70,
                                render: (text, record, recordIndex) => <span>{recordIndex + 1 + (tradePageIndex - 1) * tradePageSize}</span>,
                            },
                            {
                                title: "订单状态",
                                width: 100,
                                dataIndex: "tradeStatusValue",
                            },
                            {
                                title: "预定时间",
                                width: 180,
                                dataIndex: "reserveTime",
                            },
                            {
                                title: "核销单号",
                                width: 300,
                                dataIndex: "checkNo",
                            },
                            {
                                title: "商户名称",
                                width: 210,
                                dataIndex: "merchantName",
                            },
                            {
                                title: "门店名称",
                                width: 200,
                                dataIndex: "shopName",
                            },
                            {
                                title: "产品名称",
                                width: 190,
                                dataIndex: "goodsSkuName",
                            },
                            {
                                title: "预定姓名",
                                width: 130,
                                dataIndex: "reserveName",
                            },
                            {
                                title: "预定电话",
                                width: 140,
                                dataIndex: "reservePhone",
                            },
                            {
                                title: "出席人数",
                                width: 120,
                                dataIndex: "reserveQuantity",
                            },
                            {
                                title: "预定备注",
                                dataIndex: "reserveRemark",
                            },
                            {
                                title: "创建时间",
                                width: 180,
                                dataIndex: "createTime",
                            },
                            {
                                title: "确认时间",
                                width: 180,
                                dataIndex: "confirmTime",
                            },
                            {
                                title: "核销时间",
                                width: 180,
                                dataIndex: "checkTime",
                            },
                            {
                                title: "城市名称",
                                width: 120,
                                dataIndex: "cityName",
                            },

                            {
                                title: "结算金额",
                                width: 120,
                                dataIndex: "tradeSettlementAmount",
                            },
                            {
                                title: "操作",
                                fixed: "right",
                                width: 110,
                                render: (text, record, recordIndex) =>
                                    record.tradeStatus == "WAITING_CONFIRM" || record.tradeStatus == "WAITING_CHECK" ? (
                                        <Dropdown
                                            overlay={
                                                <Menu
                                                    onClick={(value) => {
                                                        switch (value.key) {
                                                            case "cancel":
                                                                handleCancelTrade(record.tradeId, record.systemVersion);

                                                                break;
                                                            case "edit":
                                                                setTimeout(() => {
                                                                    handleEditTrade(record.tradeId);
                                                                }, 100);

                                                                break;
                                                            case "message":
                                                                handleSendMessage(record.tradeId);

                                                                break;
                                                            default:
                                                                break;
                                                        }
                                                    }}
                                                >
                                                    {record.tradeStatus == "WAITING_CONFIRM" || record.tradeStatus == "WAITING_CHECK" ? (
                                                        <Menu.Item key={"cancel"} icon={<CloseCircleOutlined />}>
                                                            取消订单
                                                        </Menu.Item>
                                                    ) : null}
                                                    {store.main.permissionKeyList.indexOf("trade:enterprise:edit") > -1 &&
                                                    record.tradeStatus == "WAITING_CONFIRM" ? (
                                                        <Menu.Item key={"edit"} icon={<FileTextOutlined />}>
                                                            修改订单
                                                        </Menu.Item>
                                                    ) : null}
                                                    {record.tradeStatus == "WAITING_CHECK" ? (
                                                        <Menu.Item key={"message"} icon={<MessageOutlined />}>
                                                            重发短信
                                                        </Menu.Item>
                                                    ) : null}
                                                </Menu>
                                            }
                                            arrow
                                            destroyPopupOnHide
                                        >
                                            <Button>
                                                操作
                                                <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                    ) : null,
                            },
                        ]}
                        dataSource={tradeList}
                        rowSelection={{
                            fixed: true,
                            selectedRowKeys: tradeIdList,
                            onChange: handleChangeTradeIdList,
                        }}
                        scroll={store.main.isFixedContent ? {x: 2800, y: tableHeight - 120} : {x: 2800}}
                        pagination={{
                            size: "default",
                            showQuickJumper: true,
                            showSizeChanger: true,
                            current: tradePageIndex,
                            pageSize: tradePageSize,
                            total: tradeTotal,
                            showTotal: (total, range) => "显示" + range[0] + "到" + range[1] + "条,共有" + total + "条",
                            onChange: handleChangeTradePagination,
                            onShowSizeChange: handleChangeTradePagination,
                        }}
                    />
                </div>
            </ChuangShiOS.Content>
            <div className={Style.footer}></div>
            <Modal
                title="取消订单"
                visible={isModal}
                onCancel={() => {
                    setIsModal(false);

                    modalForm.resetFields();
                }}
                footer={
                    <div className={Style.modal}>
                        <div className={Style.modal_space}></div>
                        <Space>
                            <Button
                                icon={<CloseCircleOutlined />}
                                size="large"
                                onClick={() => {
                                    setIsModal(false);

                                    modalForm.resetFields();
                                }}
                            >
                                取消
                            </Button>
                            <Button
                                loading={isLoad}
                                icon={<FileDoneOutlined />}
                                type="primary"
                                size="large"
                                onClick={() => {
                                    handleSubmitCancelTrade();
                                }}
                            >
                                提交
                            </Button>
                        </Space>
                    </div>
                }
            >
                <Form form={modalForm}>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "20%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="取消原因"
                                labelAlign={"left"}
                                name="tradeRemark"
                                initialValue={null}
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入取消原因",
                                    },
                                ]}
                            >
                                <Input.TextArea className={Style.card_field} rows={5} placeholder="请输入取消原因" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};

TradeEnterpriseIndex.propTypes = {};

TradeEnterpriseIndex.defaultProps = {};

export default TradeEnterpriseIndex;
