import React, {useState, useRef, useEffect} from "react";
import PropTypes from "prop-types";
import {message, Modal, Space, Button, Form, Row, Col, InputNumber} from "antd";
import {FileDoneOutlined, CloseCircleOutlined} from "@ant-design/icons";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./GoodsSkuBatchModal.module.css";

const GoodsSkuBatchModal = (props) => {
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);

    useEffect(() => {
        if (props.visible) {
            setTimeout(() => {}, 100);
        } else {
            form.resetFields();
        }

        //eslint-disable-next-line
    }, [props.visible]);

    const handleCancelActivityGoodsSku = () => {
        props.onClose();
    };

    const handleSubmitActivityGoodsSku = () => {
        form.validateFields()
            .then((data) => {
                data.activityGoodsSkuIdList = props.activityGoodsSkuIdList;

                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                ChuangShiOS.Util.request(props, {
                    url: Application.host + "/windeal/activity/goods/sku/business/website/v1/batch/update",
                    data: data,
                    success: (response) => {
                        message.success({content: response.message, duration: 1});

                        props.onSubmit();
                    },
                    error: (response) => {
                        message.error({content: response.message, duration: 1});
                    },
                    complete: (response) => {
                        request.current = false;

                        setIsLoad(false);
                    },
                });
            })
            .catch((response) => {
                ChuangShiOS.Util.parseResponseError(response);
            });
    };

    return (
        <Modal
            title="活动分类"
            visible={props.visible}
            centered
            width={ChuangShiOS.Util.getModalWidth()}
            bodyStyle={{
                backgroundColor: "#eff3f8",
                padding: "20px",
            }}
            cancelButtonProps={{
                icon: <CloseCircleOutlined />,
                size: "large",
            }}
            okButtonProps={{
                icon: <FileDoneOutlined />,
                size: "large",
            }}
            onCancel={handleCancelActivityGoodsSku}
            onOk={handleSubmitActivityGoodsSku}
            footer={
                <div className={Style.footer}>
                    <div className={Style.footer_space}></div>
                    <Space>
                        <Button icon={<CloseCircleOutlined />} size="large" onClick={handleCancelActivityGoodsSku}>
                            取消
                        </Button>
                        <Button icon={<FileDoneOutlined />} type="primary" size="large" onClick={handleSubmitActivityGoodsSku}>
                            提交
                        </Button>
                    </Space>
                </div>
            }
        >
            <div style={{height: ChuangShiOS.Util.getModalHeight() + "px"}} className={Style.page}>
                <ChuangShiOS.Content
                    className={Style.content}
                    isLoad={isLoad}
                    isEmpty={false}
                    isError={false}
                    marginLeft={0}
                    marginRight={0}
                    paddingTop={0}
                    paddingRight={0}
                    paddingBottom={0}
                    paddingLeft={0}
                >
                    <div className={Style.main}>
                        <Form form={form}>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "24%"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="结算价"
                                        name="goodsSkuSettlementPrice"
                                        initialValue={null}
                                        rules={[
                                            {
                                                required: false,
                                                message: "请输入结算价格",
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            className={Style.card_field}
                                            min={0}
                                            max={999999}
                                            placeholder="请输入结算价格"
                                            onPressEnter={handleSubmitActivityGoodsSku}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "24%"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="结算价格增加%"
                                        name="goodsSkuSettlementPriceRatio"
                                        initialValue={null}
                                        rules={[
                                            {
                                                required: false,
                                                message: "请输入结算价格增加%",
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            className={Style.card_field}
                                            min={0}
                                            max={999999}
                                            placeholder="请输入结算价格增加%"
                                            onPressEnter={handleSubmitActivityGoodsSku}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </ChuangShiOS.Content>
            </div>
        </Modal>
    );
};

GoodsSkuBatchModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    activityGoodsSkuIdList: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

GoodsSkuBatchModal.defaultProps = {};

export default GoodsSkuBatchModal;
