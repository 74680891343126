import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {useActivate, useUnactivate} from "react-activation";
import {message, Breadcrumb, Space, Button, Form, Row, Col, Input, Table, Tag} from "antd";
import {FileAddOutlined, FileSearchOutlined} from "@ant-design/icons";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./Index.module.css";

const MemberIndex = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const [tableHeight, setTableHeight] = useState(0);
    const [memberPageIndex, setMemberPageIndex] = useState(1);
    const [memberPageSize, setMemberPageSize] = useState(store.main.pageSize);
    const [memberIdList, setMemberIdList] = useState([]);
    const [memberTotal, setMemberTotal] = useState(0);
    const [memberList, setMemberList] = useState([]);

    useEffect(() => {
        if (store.main.userName) {
            ChuangShiOS.Notification.emit("main_menu_active", {url: "/member/index"});

            handleSearchMember();
        }

        //eslint-disable-next-line
    }, [store.main.userName]);

    useEffect(() => {
        handleLoadMember(memberPageIndex, store.main.pageSize);

        //eslint-disable-next-line
    }, [store.main.pageSize]);

    useActivate(() => {
        ChuangShiOS.Notification.emit("main_set_scroll_top", {});

        handleLoadMember(memberPageIndex, memberPageSize);
    });

    useUnactivate(() => {
        ChuangShiOS.Notification.emit("main_get_scroll_top", {});
    });

    const handleSearchMember = () => {
        handleLoadMember(1, memberPageSize);
    };

    const handleLoadMember = (memberPageIndex, memberPageSize) => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        let data = form.getFieldsValue();
        data.pageIndex = memberPageIndex;
        data.pageSize = memberPageSize;

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/member/business/website/v1/list",
            data: data,
            success: (response) => {
                setMemberPageIndex(memberPageIndex);
                setMemberPageSize(memberPageSize);
                setMemberIdList([]);
                setMemberTotal(response.data.total);
                setMemberList(response.data.list);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
                setIsError(
                    typeof response.data == "undefined" ||
                        response.data.total == "undefined" ||
                        response.data.total == null ||
                        response.data.list == "undefined" ||
                        response.data.list == null
                );
            },
        });
    };

    const handleAddMember = () => {
        ChuangShiOS.Util.navigateTo(props, {
            url: "/member/add",
        });
    };

    // const handleEditMember = (memberId) => {
    //     ChuangShiOS.Util.navigateTo(props, {
    //         url: "/member/edit/" + memberId,
    //     });
    // };

    const handleChangeMemberIdList = (memberIdList) => {
        setMemberIdList(memberIdList);
    };

    const handleChangeMemberPagination = (memberPageIndex, memberPageSize) => {
        handleLoadMember(memberPageIndex, memberPageSize);
    };

    return (
        <div className={Style.page}>
            <div className={Style.header}>
                <div className={Style.header_title}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <span>首页</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span>{store.main.menuName}</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className={Style.header_title_text}>{store.main.sidebarName}</div>
                </div>
                <div className={Style.header_button}>
                    <Space>
                        <Button.Group>
                            <Button icon={<FileAddOutlined />} size="large" onClick={handleAddMember}>
                                新增
                            </Button>
                        </Button.Group>
                        <Button loading={isLoad} icon={<FileSearchOutlined />} type="primary" size="large" onClick={handleSearchMember}>
                            查询
                        </Button>
                    </Space>
                </div>
            </div>
            <ChuangShiOS.Content className={Style.content} isLoad={isLoad} isEmpty={false} isError={isError} isScroll={store.main.isFixedContent}>
                <Form form={form}>
                    <Row>
                        <Col span={6}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "none"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="会员手机"
                                labelAlign={"left"}
                                name="memberPhone"
                                initialValue={""}
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入会员手机",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入会员手机" onPressEnter={handleSearchMember} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "none"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="会员名称"
                                labelAlign={"left"}
                                name="memberName"
                                initialValue={""}
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入会员名称",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入会员名称" onPressEnter={handleSearchMember} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div
                    ref={(ref) => {
                        if (ref != null && tableHeight == 0 && tableHeight != ref.clientHeight) {
                            setTableHeight(ref.clientHeight);
                        }
                    }}
                    className={store.main.isFixedContent ? Style.card_table : null}
                >
                    <Table
                        size="small"
                        rowKey={(record) => record.memberId}
                        columns={[
                            {
                                title: "会员手机",
                                dataIndex: "memberPhone",
                            },
                            {
                                title: "会员名称",
                                dataIndex: "memberName",
                            },
                            {
                                title: "注册时间",
                                dataIndex: "memberRegisterTime",
                            },
                            {
                                title: "会员头像",
                                dataIndex: "memberImage",
                            },
                            {
                                title: "会员状态",
                                dataIndex: "memberStatus",
                                render: (text, record, recordIndex) =>
                                    record.memberStatus ? <Tag color="blue">正常</Tag> : <Tag color="red">冻结</Tag>,
                            },
                        ]}
                        dataSource={memberList}
                        rowSelection={{
                            fixed: true,
                            selectedRowKeys: memberIdList,
                            onChange: handleChangeMemberIdList,
                        }}
                        scroll={store.main.isFixedContent ? {y: tableHeight - 130} : {}}
                        pagination={{
                            size: "default",
                            showQuickJumper: true,
                            showSizeChanger: true,
                            current: memberPageIndex,
                            pageSize: memberPageSize,
                            total: memberTotal,
                            showTotal: (total, range) => "显示" + range[0] + "到" + range[1] + "条,共有" + total + "条",
                            onChange: handleChangeMemberPagination,
                            onShowSizeChange: handleChangeMemberPagination,
                        }}
                    />
                </div>
            </ChuangShiOS.Content>
            <div className={Style.footer}></div>
        </div>
    );
};

MemberIndex.propTypes = {};

MemberIndex.defaultProps = {};

export default MemberIndex;
