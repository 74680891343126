import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";
import {message, Modal, Space, Button, Form, Row, Col, Input, Select, Table, Tag, Cascader} from "antd";
import {FileDoneOutlined, CloseCircleOutlined, FileSearchOutlined} from "@ant-design/icons";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./GoodsSkuListModal.module.css";

const GoodsSkuListModal = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const [goodsSkuPageIndex, setGoodsSkuPageIndex] = useState(1);
    const [goodsSkuPageSize, setGoodsSkuPageSize] = useState(store.main.pageSize);
    const [goodsSkuIdList, setGoodsSkuIdList] = useState([]);
    const [goodsSkuTotal, setGoodsSkuTotal] = useState(0);
    const [goodsSkuList, setGoodsSkuList] = useState([]);
    const [selectList, setSelectList] = useState([]);
    const [goodsSkuCategoryList, setGoodsSkuCategoryList] = useState([]);
    const [goodsCategoryList, setGoodsCategoryList] = useState([]);
    const [cityList, setCityList] = useState([]);

    useEffect(() => {
        if (props.visible) {
            handleLoadGoodsSkuCategory();

            handleLoadGoodsCategory();

            handleLoadCity();

            handleSearchGoodsSku();
        }

        //eslint-disable-next-line
    }, [props.visible]);

    const handleLoadGoodsSkuCategory = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/goods/sku/category/business/website/v1/select/list",
            data: {},
            success: (response) => {
                setGoodsSkuCategoryList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadGoodsCategory = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/goods/category/business/website/v1/select/list",
            data: {},
            success: (response) => {
                setGoodsCategoryList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadCity = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/city/business/website/v1/cascader/list",
            data: {},
            success: (response) => {
                setCityList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleSearchGoodsSku = () => {
        handleLoadGoodsSku(1, goodsSkuPageSize);
    };

    const handleLoadGoodsSku = (goodsSkuPageIndex, goodsSkuPageSize) => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        let data = form.getFieldsValue();
        data.activityId = props.activityId;
        data.activityCategoryId = props.activityCategoryId;
        data.cityId = data.provinceCity[1];
        data.pageIndex = goodsSkuPageIndex;
        data.pageSize = goodsSkuPageSize;

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/activity/goods/sku/business/website/v1/modal/list",
            data: data,
            success: (response) => {
                setGoodsSkuPageIndex(goodsSkuPageIndex);
                setGoodsSkuPageSize(goodsSkuPageSize);
                setGoodsSkuIdList([]);
                setGoodsSkuTotal(response.data.total);
                setGoodsSkuList(response.data.list);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
                setIsError(
                    typeof response.data == "undefined" ||
                        response.data.total == "undefined" ||
                        response.data.total == null ||
                        response.data.list == "undefined" ||
                        response.data.list == null
                );
            },
        });
    };

    const handleChangeGoodsSkuPagination = (goodsSkuPageIndex, goodsSkuPageSize) => {
        handleLoadGoodsSku(goodsSkuPageIndex, goodsSkuPageSize);
    };

    const handleSelectGoodsSku = (goodsSkuIdList) => {
        let selectList = [];
        for (let i = 0; i < goodsSkuList.length; i++) {
            let isExit = false;

            for (let j = 0; j < goodsSkuIdList.length; j++) {
                if (goodsSkuList[i].goodsSkuId == goodsSkuIdList[j]) {
                    isExit = true;

                    break;
                }
            }

            if (isExit) {
                selectList.push(goodsSkuList[i]);
            }
        }

        setGoodsSkuIdList(goodsSkuIdList);
        setSelectList(selectList);
    };

    const handleClickGoodsSku = (goodsSku) => {
        if (goodsSku.goodsSkuIsDisable) {
            return;
        }

        let index = -1;

        for (let i = 0; i < goodsSkuIdList.length; i++) {
            if (goodsSku.goodsSkuId == goodsSkuIdList[i]) {
                index = i;
                break;
            }
        }

        if (index == -1) {
            goodsSkuIdList.push(goodsSku.goodsSkuId);
        } else {
            goodsSkuIdList.splice(index, 1);
        }

        let selectList = [];
        for (let i = 0; i < goodsSkuList.length; i++) {
            let isExit = false;

            for (let j = 0; j < goodsSkuIdList.length; j++) {
                if (goodsSkuList[i].goodsSkuId == goodsSkuIdList[j]) {
                    isExit = true;

                    break;
                }
            }

            if (isExit) {
                selectList.push(goodsSkuList[i]);
            }
        }

        setGoodsSkuIdList([...goodsSkuIdList]);
        setSelectList(selectList);
    };

    const handleCancelGoodsSku = () => {
        setGoodsSkuIdList([]);
        setSelectList([]);

        props.onClose();
    };

    const handleSubmitGoodsSku = () => {
        if (selectList.length > 0) {
            if (request.current) {
                return;
            }
            request.current = true;

            setIsLoad(true);

            let goodsSkuIdList = [];
            for (let i = 0; i < selectList.length; i++) {
                goodsSkuIdList.push(selectList[i].goodsSkuId);
            }

            ChuangShiOS.Util.request(props, {
                url: Application.host + "/windeal/activity/goods/sku/business/website/v1/save",
                data: {
                    activityId: props.activityId,
                    activityCategoryId: props.activityCategoryId,
                    goodsSkuIdList: goodsSkuIdList,
                },
                success: (response) => {
                    setGoodsSkuIdList([]);
                    setSelectList([]);

                    props.onSubmit();
                },
                error: (response) => {
                    message.error({content: response.message, duration: 1});
                },
                complete: (response) => {
                    request.current = false;

                    setIsLoad(false);
                },
            });
        }
    };

    return (
        <Modal
            title="商品规格"
            visible={props.visible}
            centered
            width={ChuangShiOS.Util.getModalWidth()}
            bodyStyle={{
                backgroundColor: "#eff3f8",
                padding: "20px",
            }}
            cancelButtonProps={{
                icon: <CloseCircleOutlined />,
                size: "large",
            }}
            okButtonProps={{
                icon: <FileDoneOutlined />,
                size: "large",
            }}
            onCancel={handleCancelGoodsSku}
            onOk={handleSubmitGoodsSku}
            footer={
                <div className={Style.footer}>
                    <div className={Style.footer_space}></div>
                    <div className={Style.footer_count}>已选{selectList.length}条</div>
                    <Space>
                        <Button icon={<CloseCircleOutlined />} size="large" onClick={handleCancelGoodsSku}>
                            取消
                        </Button>
                        <Button loading={isLoad} icon={<FileDoneOutlined />} type="primary" size="large" onClick={handleSubmitGoodsSku}>
                            提交
                        </Button>
                    </Space>
                </div>
            }
        >
            <div style={{height: ChuangShiOS.Util.getModalHeight() + "px"}} className={Style.page}>
                <ChuangShiOS.Content
                    className={Style.content}
                    isLoad={isLoad}
                    isEmpty={false}
                    isError={isError}
                    marginLeft={0}
                    marginRight={0}
                    paddingTop={0}
                    paddingRight={0}
                    paddingBottom={0}
                    paddingLeft={0}
                >
                    <div className={Style.main}>
                        <Form form={form}>
                            <Row>
                                <Col span={4}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "none"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="规格分类"
                                        labelAlign={"left"}
                                        name="goodsSkuCategoryId"
                                        initialValue={null}
                                        rules={[
                                            {
                                                required: false,
                                                message: "请输入规格分类",
                                            },
                                        ]}
                                    >
                                        <Select
                                            className={Style.card_field}
                                            placeholder="请选择规格分类"
                                            allowClear
                                            onChange={(event) => {
                                                handleSearchGoodsSku();
                                            }}
                                        >
                                            {goodsSkuCategoryList.map((goodsSkuCategory, goodsSkuCategoryIndex) => {
                                                return (
                                                    <Select.Option key={goodsSkuCategoryIndex} value={goodsSkuCategory.goodsSkuCategoryId}>
                                                        {goodsSkuCategory.goodsSkuCategoryName}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "none"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="商品分类"
                                        labelAlign={"left"}
                                        name="goodsCategoryId"
                                        initialValue={null}
                                        rules={[
                                            {
                                                required: false,
                                                message: "请输入商品分类",
                                            },
                                        ]}
                                    >
                                        <Select
                                            className={Style.card_field}
                                            placeholder="请选择商品分类"
                                            allowClear
                                            onChange={(event) => {
                                                handleSearchGoodsSku();
                                            }}
                                        >
                                            {goodsCategoryList.map((goodsCategory, goodsCategoryIndex) => {
                                                return (
                                                    <Select.Option key={goodsCategoryIndex} value={goodsCategory.goodsCategoryId}>
                                                        {goodsCategory.goodsCategoryName}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "none"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="城市名称"
                                        labelAlign={"left"}
                                        name="provinceCity"
                                        initialValue={""}
                                        rules={[
                                            {
                                                required: false,
                                                message: "请选择城市名称",
                                            },
                                        ]}
                                    >
                                        <Cascader
                                            className={Style.card_field}
                                            allowClear
                                            options={cityList}
                                            placeholder="请选择城市名称"
                                            onChange={(event) => {
                                                handleSearchGoodsSku();
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "none"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="商户名称"
                                        labelAlign={"left"}
                                        name="merchantName"
                                        initialValue={""}
                                        rules={[
                                            {
                                                required: false,
                                                message: "请输入商户名称",
                                            },
                                        ]}
                                    >
                                        <Input className={Style.card_field} placeholder="请输入商户名称" onPressEnter={handleSearchGoodsSku} />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "none"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="门店名称"
                                        labelAlign={"left"}
                                        name="shopName"
                                        initialValue={""}
                                        rules={[
                                            {
                                                required: false,
                                                message: "请输入门店名称",
                                            },
                                        ]}
                                    >
                                        <Input className={Style.card_field} placeholder="请输入门店名称" onPressEnter={handleSearchGoodsSku} />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Button loading={isLoad} icon={<FileSearchOutlined />} type="primary" onClick={handleSearchGoodsSku}>
                                        查询
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                        <div className={Style.card_table}>
                            <Table
                                size="small"
                                rowKey={(record) => record.goodsSkuId}
                                columns={[
                                    {
                                        title: "是否添加",
                                        width: 60,
                                        render: (text, record, recordIndex) => (
                                            <div style={{marginTop: "5px"}}>
                                                {record.goodsSkuIsDisable ? <Tag color="processing">已添加</Tag> : <Tag>未添加</Tag>}
                                            </div>
                                        ),
                                    },
                                    {
                                        title: "商户",
                                        width: 140,
                                        ellipsis: true,
                                        dataIndex: "merchantName",
                                    },
                                    {
                                        title: "门店",
                                        width: 100,
                                        ellipsis: true,
                                        dataIndex: "shopName",
                                    },
                                    {
                                        title: "规格名称",
                                        width: 120,
                                        ellipsis: true,
                                        dataIndex: "goodsSkuName",
                                    },
                                    {
                                        title: "可定日期",
                                        width: 85,
                                        render: (text, record, recordIndex) => (
                                            <React.Fragment>
                                                {record.goodsReserveWeekList.sort().map((goodsReserveWeek, goodsReserveWeekIndex) => {
                                                    return (
                                                        <span key={goodsReserveWeekIndex}>
                                                            {goodsReserveWeek == 0 ? null : goodsReserveWeek}
                                                            {goodsReserveWeekIndex + 1 < record.goodsReserveWeekList.length && goodsReserveWeek > 0
                                                                ? ","
                                                                : null}
                                                        </span>
                                                    );
                                                })}
                                                {record.goodsReserveWeekList.indexOf(0) > -1
                                                    ? (record.goodsReserveWeekList.length > 1 ? "," : "") + "7"
                                                    : null}
                                            </React.Fragment>
                                        ),
                                    },
                                    {
                                        title: "可定时间",
                                        width: 85,
                                        render: (text, record, recordIndex) => (
                                            <React.Fragment>
                                                {record.goodsReserveStartTime}~{record.goodsReserveEndTime}
                                            </React.Fragment>
                                        ),
                                    },
                                    {
                                        title: "市场价",
                                        width: 60,
                                        dataIndex: "goodsSkuMarketPrice",
                                    },
                                    {
                                        title: "餐价",
                                        width: 60,
                                        dataIndex: "goodsSkuSalePrice",
                                    },
                                    {
                                        title: "服务费比例",
                                        width: 80,
                                        dataIndex: "goodsSkuServiceChargeRatio",
                                    },
                                    {
                                        title: "销售价",
                                        width: 60,
                                        render: (text, record, recordIndex) => (
                                            <React.Fragment>
                                                {record.goodsSkuSalePrice + record.goodsSkuSalePrice * record.goodsSkuServiceChargeRatio}
                                            </React.Fragment>
                                        ),
                                    },
                                    {
                                        title: "补贴价",
                                        width: 60,
                                        dataIndex: "goodsSkuAllowancePrice",
                                    },
                                    {
                                        title: "结算价",
                                        width: 60,
                                        dataIndex: "goodsSkuSettlementPrice",
                                    },
                                    {
                                        title: "规格类型",
                                        width: 110,
                                        dataIndex: "goodsSkuCategoryName",
                                    },
                                    {
                                        title: "城市",
                                        width: 100,
                                        dataIndex: "cityName",
                                    },
                                ]}
                                dataSource={goodsSkuList}
                                rowSelection={{
                                    fixed: true,
                                    type: "checkbox",
                                    selectedRowKeys: goodsSkuIdList,
                                    onChange: handleSelectGoodsSku,
                                    getCheckboxProps: (record) => ({
                                        disabled: record.goodsSkuIsDisable,
                                    }),
                                }}
                                onRow={(record) => {
                                    return {
                                        onClick: () => {
                                            handleClickGoodsSku(record);
                                        },
                                    };
                                }}
                                scroll={{x: 1800, y: ChuangShiOS.Util.getModalHeight() - 260}}
                                pagination={{
                                    size: "default",
                                    showQuickJumper: true,
                                    showSizeChanger: true,
                                    current: goodsSkuPageIndex,
                                    pageSize: goodsSkuPageSize,
                                    total: goodsSkuTotal,
                                    showTotal: (total, range) => "显示" + range[0] + "到" + range[1] + "条,共有" + total + "条",
                                    onChange: handleChangeGoodsSkuPagination,
                                    onShowSizeChange: handleChangeGoodsSkuPagination,
                                }}
                            />
                        </div>
                    </div>
                </ChuangShiOS.Content>
            </div>
        </Modal>
    );
};

GoodsSkuListModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    activityId: PropTypes.string.isRequired,
    activityCategoryId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

GoodsSkuListModal.defaultProps = {};

export default GoodsSkuListModal;
