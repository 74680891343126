import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {message, Modal, Breadcrumb, Form, Row, Col, Input, Select, Space, Button, Divider, Table} from "antd";
import {LeftCircleOutlined, FileDoneOutlined, PlusCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import RoleModal from "../role/Modal";
import MerchantInput from "../merchant/Input";
import EnterpriseInput from "../enterprise/Input";

import Style from "./Detail.module.css";

const UserDetail = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [userType, setUserType] = useState("");
    const [systemVersion, setSystemVersion] = useState(0);
    const [shopList, setShopList] = useState([]);
    const [isRole, setIsRole] = useState(false);
    const [roleList, setRoleList] = useState([]);

    useEffect(() => {
        if (store.main.userName) {
            ChuangShiOS.Notification.emit("main_menu_active", {url: "/user/index"});

            switch (props.match.path) {
                case "/user/add":
                    setIsSave(true);

                    break;
                case "/user/edit/:userId":
                    setIsEdit(true);

                    handleLoadUser();

                    break;
                default:
                    break;
            }
        }

        //eslint-disable-next-line
    }, [store.main.userName]);

    const handleLoadShop = (merchantId) => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/shop/business/website/v1/merchant/list",
            data: {
                merchantId: merchantId,
            },
            success: (response) => {
                setShopList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadUser = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/user/business/website/v1/find",
            data: {
                userId: props.match.params.userId,
            },
            success: (response) => {
                form.setFieldsValue(response.data);

                setUserType(response.data.userType);
                setSystemVersion(response.data.systemVersion);
                setRoleList(response.data.roleList);

                if (response.data.merchantId) {
                    handleLoadShop(response.data.merchantId);
                }
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
                setIsError(typeof response.data == "undefined" || typeof response.data.userId == "undefined" || response.data.userId == null);
            },
        });
    };

    const handleSubmitUser = () => {
        if (request.current) {
            return;
        }

        form.validateFields()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                let roleIdList = [];
                for (let i = 0; i < roleList.length; i++) {
                    roleIdList.push(roleList[i].roleId);
                }
                data.roleIdList = roleIdList;

                data.userId = props.match.params.userId;
                data.shopIdList = data.shopIdList ? data.shopIdList : [];
                data.systemVersion = systemVersion;

                let url = "";
                if (isSave) {
                    url = "/windeal/user/business/website/v1/save";
                }
                if (isEdit) {
                    url = "/windeal/user/business/website/v1/update";
                }

                ChuangShiOS.Util.request(props, {
                    url: Application.host + url,
                    data: data,
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);

                        ChuangShiOS.Util.navigateBack(props, {});
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            })
            .catch((response) => {
                ChuangShiOS.Util.parseResponseError(response);
            });
    };

    const handleBackUser = () => {
        ChuangShiOS.Util.navigateBack(props, {});
    };

    const handleOpenRole = () => {
        setIsRole(true);
    };

    const handleCloseRole = () => {
        setIsRole(false);
    };

    const handleDeleteRole = (index) => {
        Modal.confirm({
            title: "是否确认操作？",
            content: "本次数据操作后将无法恢复",
            okText: "确认",
            cancelText: "取消",
            onOk: () => {
                roleList.splice(index, 1);

                setRoleList([...roleList]);
            },
        });
    };

    const handleSubmitRole = (list) => {
        for (let i = 0; i < list.length; i++) {
            let isExit = false;
            for (let j = 0; j < roleList.length; j++) {
                if (list[i].roleId == roleList[j].roleId) {
                    isExit = true;

                    break;
                }
            }

            if (!isExit) {
                roleList.push(list[i]);
            }
        }

        setRoleList([...roleList]);
    };

    return (
        <div className={Style.page}>
            <div className={Style.header}>
                <div className={Style.header_title}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <span>首页</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span>{store.main.menuName}</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className={Style.header_title_text}>{store.main.sidebarName}</div>
                </div>
                <div className={Style.header_button}>
                    <Space>
                        <Button.Group>
                            <Button icon={<LeftCircleOutlined />} size="large" onClick={handleBackUser}>
                                返回
                            </Button>
                        </Button.Group>
                        <Button
                            loading={isLoad}
                            disabled={isError}
                            icon={<FileDoneOutlined />}
                            type="primary"
                            size="large"
                            onClick={handleSubmitUser}
                        >
                            提交
                        </Button>
                    </Space>
                </div>
            </div>
            <ChuangShiOS.Content
                className={Style.content}
                isLoad={isLoad}
                isEmpty={false}
                isError={isError}
                paddingTop={28}
                isScroll={store.main.isFixedContent}
            >
                <Row>
                    <Col flex="auto">
                        <div className={Style.card_title}>基本信息</div>
                    </Col>
                    <Col></Col>
                </Row>
                <Form form={form}>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="用户类型"
                                name="userType"
                                initialValue={null}
                                rules={[
                                    {
                                        required: true,
                                        message: "请选择用户类型",
                                    },
                                ]}
                            >
                                <Select
                                    className={Style.card_field}
                                    placeholder="请选择用户类型"
                                    allowClear
                                    onChange={(value) => {
                                        setUserType(value);

                                        form.setFieldsValue({
                                            merchantId: null,
                                            shopIdList: [],
                                            enterpriseId: null,
                                        });
                                    }}
                                >
                                    <Select.Option value={"NORMAL"}>普通用户</Select.Option>
                                    <Select.Option value={"ADMIN"}>管理员</Select.Option>
                                    <Select.Option value={"MERCHANT"}>商户</Select.Option>
                                    <Select.Option value={"MERCHANT_FINANCE"}>商户财务</Select.Option>
                                    <Select.Option value={"ENTERPRISE"}>企业</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="用户姓名"
                                name="userName"
                                initialValue={""}
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入用户姓名",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入用户姓名" onPressEnter={handleSubmitUser} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="用户账号"
                                name="userAccount"
                                initialValue={""}
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入用户账号",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入用户账号" onPressEnter={handleSubmitUser} />
                            </Form.Item>
                        </Col>
                    </Row>
                    {isSave ? (
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "24%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="用户密码"
                                    name="userPassword"
                                    initialValue={""}
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入用户密码",
                                        },
                                    ]}
                                >
                                    <Input className={Style.card_field} placeholder="请输入用户密码" onPressEnter={handleSubmitUser} />
                                </Form.Item>
                            </Col>
                        </Row>
                    ) : null}
                    {userType == "MERCHANT" || userType == "MERCHANT_FINANCE" ? (
                        <React.Fragment>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "24%"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="所属商户"
                                        name="merchantId"
                                        initialValue={null}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请选择所属商户",
                                            },
                                        ]}
                                    >
                                        <MerchantInput
                                            className={Style.card_field}
                                            placeholder={"请选择所属商户"}
                                            onChange={(value) => {
                                                setShopList([]);

                                                form.setFieldsValue({
                                                    shopIdList: [],
                                                });

                                                if (value) {
                                                    handleLoadShop(value);
                                                }
                                            }}
                                        ></MerchantInput>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "24%"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="所属门店"
                                        name="shopIdList"
                                        initialValue={[]}
                                        rules={[
                                            {
                                                required: false,
                                                message: "请选择所属门店",
                                            },
                                        ]}
                                    >
                                        <Select mode="multiple" className={Style.card_field} placeholder="请选择所属门店" allowClear>
                                            {shopList.map((shop, shopIndex) => {
                                                return (
                                                    <Select.Option key={shopIndex} value={shop.shopId}>
                                                        {shop.shopName}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </React.Fragment>
                    ) : null}
                    {userType == "ENTERPRISE" ? (
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "24%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="企业名称"
                                    name="enterpriseId"
                                    initialValue={null}
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择企业名称",
                                        },
                                    ]}
                                >
                                    <EnterpriseInput
                                        className={Style.card_field}
                                        placeholder={"请选择企业名称"}
                                        onSelect={() => {}}
                                    ></EnterpriseInput>
                                </Form.Item>
                            </Col>
                        </Row>
                    ) : null}
                </Form>
                <Divider />
                <Row>
                    <Col flex="auto">
                        <div className={Style.card_title}>菜单权限</div>
                    </Col>
                    <Col>
                        <Button
                            icon={<PlusCircleOutlined />}
                            onClick={() => {
                                handleOpenRole();
                            }}
                        >
                            新增
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col flex={"12%"}></Col>
                    <Col flex="auto" className={Style.card_value}>
                        <Table
                            className={Style.card_table}
                            size="large"
                            rowKey={(record) => record.roleId}
                            columns={[
                                {
                                    title: "角色名称",
                                    dataIndex: "roleName",
                                },
                                {
                                    title: "操作",
                                    width: 150,
                                    render: (text, record, recordIndex) => (
                                        <div className={Style.card_table_row}>
                                            <Button
                                                icon={<CloseCircleOutlined />}
                                                onClick={() => {
                                                    handleDeleteRole(recordIndex);
                                                }}
                                            >
                                                删除
                                            </Button>
                                        </div>
                                    ),
                                },
                            ]}
                            dataSource={roleList}
                            pagination={false}
                        />
                        <br />
                    </Col>
                </Row>
            </ChuangShiOS.Content>
            <div className={Style.footer}></div>
            <RoleModal visible={isRole} multiple={true} onClose={handleCloseRole} onSubmit={handleSubmitRole}></RoleModal>
        </div>
    );
};

UserDetail.propTypes = {};

UserDetail.defaultProps = {};

export default UserDetail;
