import React from "react";
import {Route} from "react-router-dom";
import KeepAlive from "react-activation";
import ChuangShiOS from "chuangshi-os-website-component";

import RoleIndex from "./Index";
import RoleDetail from "./Detail";

const data = [
    <Route
        exact
        key="RoleIndex"
        path="/role/index"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return (
                <KeepAlive name="/role/index">
                    <RoleIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="RoleAdd"
        path="/role/add"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <RoleDetail {...props} />;
        }}
    />,
    <Route
        exact
        key="RoleEdit"
        path="/role/edit/:roleId"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <RoleDetail {...props} />;
        }}
    />,
];

export default data;
