import React from "react";
import {Route} from "react-router-dom";
import KeepAlive from "react-activation";
import ChuangShiOS from "chuangshi-os-website-component";

import MerchantIndex from "./Index";
import MerchantDetail from "./Detail";

const data = [
    <Route
        exact
        key="MerchantIndex"
        path="/merchant/index"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return (
                <KeepAlive name="/merchant/index">
                    <MerchantIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="MerchantAdd"
        path="/merchant/add"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <MerchantDetail {...props} />;
        }}
    />,
    <Route
        exact
        key="MerchantEdit"
        path="/merchant/edit/:merchantId"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <MerchantDetail {...props} />;
        }}
    />,
];

export default data;
