import React from "react";
import {Route} from "react-router-dom";
import KeepAlive from "react-activation";
import ChuangShiOS from "chuangshi-os-website-component";

import BenefitCodeIndex from "./Index";
import BenefitCodeDetail from "./Detail";

const data = [
    <Route
        exact
        key="BenefitCodeIndex"
        path="/benefit/code/index"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return (
                <KeepAlive name="/benefit/code/index">
                    <BenefitCodeIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="BenefitCodeAdd"
        path="/benefit/code/add"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <BenefitCodeDetail {...props} />;
        }}
    />,
    <Route
        exact
        key="BenefitCodeEdit"
        path="/benefit/code/edit/:benefitCodeId"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <BenefitCodeDetail {...props} />;
        }}
    />,
];

export default data;
