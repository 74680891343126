import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {message, Breadcrumb, Form, Row, Col, Input, DatePicker, Space, Button} from "antd";
import {LeftCircleOutlined, FileDoneOutlined} from "@ant-design/icons";
import moment from "moment";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./Detail.module.css";

const MemberDetail = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [systemVersion, setSystemVersion] = useState(0);

    useEffect(() => {
        if (store.main.userName) {
            ChuangShiOS.Notification.emit("main_menu_active", {url: "/member/index"});

            switch (props.match.path) {
                case "/member/add":
                    setIsSave(true);

                    break;
                case "/member/edit/:memberId":
                    setIsEdit(true);

                    handleLoadMember();

                    break;
                default:
                    break;
            }
        }

        //eslint-disable-next-line
    }, [store.main.userName]);

    const handleLoadMember = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/member/business/website/v1/find",
            data: {
                memberId: props.match.params.memberId,
            },
            success: (response) => {
                response.data.memberRegisterTime = moment(response.data.memberRegisterTime, "YYYY-MM-DD HH:mm");

                form.setFieldsValue(response.data);

                setSystemVersion(response.data.systemVersion);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
                setIsError(typeof response.data == "undefined" || typeof response.data.memberId == "undefined" || response.data.memberId == null);
            },
        });
    };

    const handleSubmitMember = () => {
        if (request.current) {
            return;
        }

        form.validateFields()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                data.memberId = props.match.params.memberId;
                data.memberRegisterTime = data.memberRegisterTime.format("YYYY-MM-DD HH:mm");
                data.systemVersion = systemVersion;

                let url = "";
                if (isSave) {
                    url = "/windeal/member/business/website/v1/save";
                }
                if (isEdit) {
                    url = "/windeal/member/business/website/v1/update";
                }

                ChuangShiOS.Util.request(props, {
                    url: Application.host + url,
                    data: data,
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);

                        ChuangShiOS.Util.navigateBack(props, {});
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            })
            .catch((response) => {
                ChuangShiOS.Util.parseResponseError(response);
            });
    };

    const handleBackMember = () => {
        ChuangShiOS.Util.navigateBack(props, {});
    };

    return (
        <div className={Style.page}>
            <div className={Style.header}>
                <div className={Style.header_title}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <span>首页</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span>{store.main.menuName}</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className={Style.header_title_text}>{store.main.sidebarName}</div>
                </div>
                <div className={Style.header_button}>
                    <Space>
                        <Button.Group>
                            <Button icon={<LeftCircleOutlined />} size="large" onClick={handleBackMember}>
                                返回
                            </Button>
                        </Button.Group>
                        <Button
                            loading={isLoad}
                            disabled={isError}
                            icon={<FileDoneOutlined />}
                            type="primary"
                            size="large"
                            onClick={handleSubmitMember}
                        >
                            提交
                        </Button>
                    </Space>
                </div>
            </div>
            <ChuangShiOS.Content
                className={Style.content}
                isLoad={isLoad}
                isEmpty={false}
                isError={isError}
                paddingTop={28}
                isScroll={store.main.isFixedContent}
            >
                <Row>
                    <Col flex="auto">
                        <div className={Style.card_title}>基本信息</div>
                    </Col>
                    <Col></Col>
                </Row>
                <Form form={form}>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="会员手机"
                                name="memberPhone"
                                initialValue={""}
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入会员手机",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入会员手机" onPressEnter={handleSubmitMember} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="会员密码"
                                name="memberPassword"
                                initialValue={""}
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入会员密码",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入会员密码" onPressEnter={handleSubmitMember} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="会员名称"
                                name="memberName"
                                initialValue={""}
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入会员名称",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入会员名称" onPressEnter={handleSubmitMember} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="会员头像"
                                name="memberImage"
                                initialValue={""}
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入会员头像",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入会员头像" onPressEnter={handleSubmitMember} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="注册时间"
                                name="memberRegisterTime"
                                rules={[
                                    {
                                        required: true,
                                        message: "请选择注册时间",
                                    },
                                ]}
                            >
                                <DatePicker
                                    className={Style.card_field}
                                    showTime={{format: "HH:mm"}}
                                    format="YYYY-MM-DD HH:mm"
                                    placeholder="请输入注册时间"
                                    onPressEnter={handleSubmitMember}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="公众号标识"
                                name="memberWechatMpOpenId"
                                initialValue={""}
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入公众号标识",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入公众号标识" onPressEnter={handleSubmitMember} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="联合标识"
                                name="memberWechatUnionId"
                                initialValue={""}
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入联合标识",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入联合标识" onPressEnter={handleSubmitMember} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="会员状态"
                                name="memberStatus"
                                initialValue={""}
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入会员状态",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入会员状态" onPressEnter={handleSubmitMember} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </ChuangShiOS.Content>
            <div className={Style.footer}></div>
        </div>
    );
};

MemberDetail.propTypes = {};

MemberDetail.defaultProps = {};

export default MemberDetail;
