import React from "react";
import {Route} from "react-router-dom";
import KeepAlive from "react-activation";
import ChuangShiOS from "chuangshi-os-website-component";

import ActivityBannerIndex from "./Index";
import ActivityBannerDetail from "./Detail";

const data = [
    <Route
        exact
        key="ActivityBannerIndex"
        path="/activity/banner/index"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return (
                <KeepAlive name="/activity/banner/index">
                    <ActivityBannerIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="ActivityBannerAdd"
        path="/activity/banner/add"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <ActivityBannerDetail {...props} />;
        }}
    />,
    <Route
        exact
        key="ActivityBannerEdit"
        path="/activity/banner/edit/:activityBannerId"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <ActivityBannerDetail {...props} />;
        }}
    />,
];

export default data;
