import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {message, Breadcrumb, Form, Row, Col, Input, Space, Button} from "antd";
import {LeftCircleOutlined, FileDoneOutlined} from "@ant-design/icons";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./Detail.module.css";

const ActivityBannerDetail = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [systemVersion, setSystemVersion] = useState(0);

    useEffect(() => {
        if (store.main.userName) {
            ChuangShiOS.Notification.emit("main_menu_active", {url: "/activity/banner/index"});

            switch (props.match.path) {
                case "/activity/banner/add":
                    setIsSave(true);

                    break;
                case "/activity/banner/edit/:activityBannerId":
                    setIsEdit(true);

                    handleLoadActivityBanner();

                    break;
                default:
                    break;
            }
        }

        //eslint-disable-next-line
    }, [store.main.userName]);

    const handleLoadActivityBanner = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/activity/banner/business/website/v1/find",
            data: {
                activityBannerId: props.match.params.activityBannerId,
            },
            success: (response) => {
                form.setFieldsValue(response.data);

                setSystemVersion(response.data.systemVersion);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
                setIsError(
                    typeof response.data == "undefined" ||
                        typeof response.data.activityBannerId == "undefined" ||
                        response.data.activityBannerId == null
                );
            },
        });
    };

    const handleSubmitActivityBanner = () => {
        if (request.current) {
            return;
        }

        form.validateFields()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                data.activityBannerId = props.match.params.activityBannerId;
                data.systemVersion = systemVersion;

                let url = "";
                if (isSave) {
                    url = "/windeal/activity/banner/business/website/v1/save";
                }
                if (isEdit) {
                    url = "/windeal/activity/banner/business/website/v1/update";
                }

                ChuangShiOS.Util.request(props, {
                    url: Application.host + url,
                    data: data,
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);

                        ChuangShiOS.Util.navigateBack(props, {});
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            })
            .catch((response) => {
                ChuangShiOS.Util.parseResponseError(response);
            });
    };

    const handleBackActivityBanner = () => {
        ChuangShiOS.Util.navigateBack(props, {});
    };

    return (
        <div className={Style.page}>
            <div className={Style.header}>
                <div className={Style.header_title}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <span>首页</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span>{store.main.menuName}</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className={Style.header_title_text}>{store.main.sidebarName}</div>
                </div>
                <div className={Style.header_button}>
                    <Space>
                        <Button.Group>
                            <Button icon={<LeftCircleOutlined />} size="large" onClick={handleBackActivityBanner}>
                                返回
                            </Button>
                        </Button.Group>
                        <Button
                            loading={isLoad}
                            disabled={isError}
                            icon={<FileDoneOutlined />}
                            type="primary"
                            size="large"
                            onClick={handleSubmitActivityBanner}
                        >
                            提交
                        </Button>
                    </Space>
                </div>
            </div>
            <ChuangShiOS.Content
                className={Style.content}
                isLoad={isLoad}
                isEmpty={false}
                isError={isError}
                paddingTop={28}
                isScroll={store.main.isFixedContent}
            >
                <Row>
                    <Col flex="auto">
                        <div className={Style.card_title}>基本信息</div>
                    </Col>
                    <Col></Col>
                </Row>
                <Form form={form}>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="活动编号"
                                name="activityId"
                                initialValue={""}
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入活动编号",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入活动编号" onPressEnter={handleSubmitActivityBanner} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="广告图片"
                                name="activityBannerImage"
                                initialValue={""}
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入广告图片",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入广告图片" onPressEnter={handleSubmitActivityBanner} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="广告地址"
                                name="activityBannerUrl"
                                initialValue={""}
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入广告地址",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入广告地址" onPressEnter={handleSubmitActivityBanner} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </ChuangShiOS.Content>
            <div className={Style.footer}></div>
        </div>
    );
};

ActivityBannerDetail.propTypes = {};

ActivityBannerDetail.defaultProps = {};

export default ActivityBannerDetail;
