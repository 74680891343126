import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {useActivate, useUnactivate} from "react-activation";
import {message, Modal, Breadcrumb, Space, Button, Form, Row, Col, Input, Table} from "antd";
import {FileAddOutlined, DeleteOutlined, CloseCircleOutlined, FileSearchOutlined, FileTextOutlined} from "@ant-design/icons";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./Index.module.css";

const GoodsCategoryIndex = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const [tableHeight, setTableHeight] = useState(0);
    const [goodsCategoryPageIndex, setGoodsCategoryPageIndex] = useState(1);
    const [goodsCategoryPageSize, setGoodsCategoryPageSize] = useState(store.main.pageSize);
    const [goodsCategoryIdList, setGoodsCategoryIdList] = useState([]);
    const [goodsCategoryTotal, setGoodsCategoryTotal] = useState(0);
    const [goodsCategoryList, setGoodsCategoryList] = useState([]);

    useEffect(() => {
        if (store.main.userName) {
            ChuangShiOS.Notification.emit("main_menu_active", {url: "/goods/category/index"});

            handleSearchGoodsCategory();
        }

        //eslint-disable-next-line
    }, [store.main.userName]);

    useEffect(() => {
        handleLoadGoodsCategory(goodsCategoryPageIndex, store.main.pageSize);

        //eslint-disable-next-line
    }, [store.main.pageSize]);

    useActivate(() => {
        ChuangShiOS.Notification.emit("main_set_scroll_top", {});

        handleLoadGoodsCategory(goodsCategoryPageIndex, goodsCategoryPageSize);
    });

    useUnactivate(() => {
        ChuangShiOS.Notification.emit("main_get_scroll_top", {});
    });

    const handleSearchGoodsCategory = () => {
        handleLoadGoodsCategory(1, goodsCategoryPageSize);
    };

    const handleLoadGoodsCategory = (goodsCategoryPageIndex, goodsCategoryPageSize) => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        let data = form.getFieldsValue();
        data.pageIndex = goodsCategoryPageIndex;
        data.pageSize = goodsCategoryPageSize;

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/goods/category/business/website/v1/list",
            data: data,
            success: (response) => {
                setGoodsCategoryPageIndex(goodsCategoryPageIndex);
                setGoodsCategoryPageSize(goodsCategoryPageSize);
                setGoodsCategoryIdList([]);
                setGoodsCategoryTotal(response.data.total);
                setGoodsCategoryList(response.data.list);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
                setIsError(
                    typeof response.data == "undefined" ||
                        response.data.total == "undefined" ||
                        response.data.total == null ||
                        response.data.list == "undefined" ||
                        response.data.list == null
                );
            },
        });
    };

    const handleAddGoodsCategory = () => {
        ChuangShiOS.Util.navigateTo(props, {
            url: "/goods/category/add",
        });
    };

    const handleEditGoodsCategory = (goodsCategoryId) => {
        ChuangShiOS.Util.navigateTo(props, {
            url: "/goods/category/edit/" + goodsCategoryId,
        });
    };

    const handleDelGoodsCategory = () => {
        if (goodsCategoryIdList.length == 0) {
            return;
        }

        if (request.current) {
            return;
        }

        Modal.confirm({
            title: "是否确认操作？",
            content: "本次数据操作后将无法恢复",
            okText: "确认",
            okType: "danger",
            okButtonProps: {
                icon: <DeleteOutlined />,
            },
            cancelText: "取消",
            cancelButtonProps: {
                icon: <CloseCircleOutlined />,
            },
            onOk: () => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                ChuangShiOS.Util.request(props, {
                    url: Application.host + "/windeal/goods/category/business/website/v1/delete",
                    data: {
                        goodsCategoryIdList: goodsCategoryIdList,
                    },
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                        setGoodsCategoryIdList([]);

                        handleLoadGoodsCategory(goodsCategoryPageIndex, goodsCategoryPageSize);
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            },
        });
    };

    const handleChangeGoodsCategoryIdList = (goodsCategoryIdList) => {
        setGoodsCategoryIdList(goodsCategoryIdList);
    };

    const handleChangeGoodsCategoryPagination = (goodsCategoryPageIndex, goodsCategoryPageSize) => {
        handleLoadGoodsCategory(goodsCategoryPageIndex, goodsCategoryPageSize);
    };

    return (
        <div className={Style.page}>
            <div className={Style.header}>
                <div className={Style.header_title}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <span>首页</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span>{store.main.menuName}</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className={Style.header_title_text}>{store.main.sidebarName}</div>
                </div>
                <div className={Style.header_button}>
                    <Space>
                        <Button.Group>
                            <Button icon={<FileAddOutlined />} size="large" onClick={handleAddGoodsCategory}>
                                新增
                            </Button>
                            <Button
                                loading={isLoad}
                                disabled={goodsCategoryIdList.length == 0}
                                icon={<DeleteOutlined />}
                                size="large"
                                onClick={handleDelGoodsCategory}
                            >
                                删除
                            </Button>
                        </Button.Group>
                        <Button loading={isLoad} icon={<FileSearchOutlined />} type="primary" size="large" onClick={handleSearchGoodsCategory}>
                            查询
                        </Button>
                    </Space>
                </div>
            </div>
            <ChuangShiOS.Content className={Style.content} isLoad={isLoad} isEmpty={false} isError={isError} isScroll={store.main.isFixedContent}>
                <Form form={form}>
                    <Row>
                        <Col span={6}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "none"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="分类名称"
                                labelAlign={"left"}
                                name="goodsCategoryName"
                                initialValue={""}
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入分类名称",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入分类名称" onPressEnter={handleSearchGoodsCategory} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div
                    ref={(ref) => {
                        if (ref != null && tableHeight == 0 && tableHeight != ref.clientHeight) {
                            setTableHeight(ref.clientHeight);
                        }
                    }}
                    className={store.main.isFixedContent ? Style.card_table : null}
                >
                    <Table
                        size="small"
                        rowKey={(record) => record.goodsCategoryId}
                        columns={[
                            {
                                title: "分类名称",
                                dataIndex: "goodsCategoryName",
                            },
                            {
                                title: "开始时间",
                                dataIndex: "goodsCategoryStart",
                            },
                            {
                                title: "结束时间",
                                dataIndex: "goodsCategoryEnd",
                            },
                            {
                                title: "分类排序",
                                dataIndex: "goodsCategorySort",
                            },
                            {
                                title: "操作",
                                width: 150,
                                render: (text, record, recordIndex) => (
                                    <Space>
                                        <Button
                                            icon={<FileTextOutlined />}
                                            size="default"
                                            onClick={() => handleEditGoodsCategory(record.goodsCategoryId)}
                                        >
                                            修改
                                        </Button>
                                    </Space>
                                ),
                            },
                        ]}
                        dataSource={goodsCategoryList}
                        rowSelection={{
                            fixed: true,
                            selectedRowKeys: goodsCategoryIdList,
                            onChange: handleChangeGoodsCategoryIdList,
                        }}
                        scroll={store.main.isFixedContent ? {y: tableHeight - 130} : {}}
                        pagination={{
                            size: "default",
                            showQuickJumper: true,
                            showSizeChanger: true,
                            current: goodsCategoryPageIndex,
                            pageSize: goodsCategoryPageSize,
                            total: goodsCategoryTotal,
                            showTotal: (total, range) => "显示" + range[0] + "到" + range[1] + "条,共有" + total + "条",
                            onChange: handleChangeGoodsCategoryPagination,
                            onShowSizeChange: handleChangeGoodsCategoryPagination,
                        }}
                    />
                </div>
            </ChuangShiOS.Content>
            <div className={Style.footer}></div>
        </div>
    );
};

GoodsCategoryIndex.propTypes = {};

GoodsCategoryIndex.defaultProps = {};

export default GoodsCategoryIndex;
