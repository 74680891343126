const initialState = {};

function reducer(state = initialState, action) {
    switch (action.type) {
        case "activity":
            return Object.assign({}, state, action.data);

        default:
            return state;
    }
}

const data = {
    name: "activity",
    reducer: reducer,
};

export default data;