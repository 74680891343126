import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {message, Breadcrumb, Form, Row, Col, Input, InputNumber, DatePicker, Select, Space, Button, Divider, Card, Table, Modal} from "antd";
import {
    LeftCircleOutlined,
    FileDoneOutlined,
    PlusCircleOutlined,
    CloseCircleOutlined,
    FileSearchOutlined,
    DeleteOutlined,
    FileTextOutlined,
} from "@ant-design/icons";
import moment from "moment";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import BannerModal from "./BannerModal";
import GoodsSkuListModal from "./GoodsSkuListModal";
import GoodsSkuEditModal from "./GoodsSkuEditModal";
import GoodsSkuBatchModal from "./GoodsSkuBatchModal";

import Style from "./Detail.module.css";

const BenefitDetail = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [benefitRuleList, setBenefitRuleList] = useState([]);
    const benefitCategoryRefArray = useRef([]);
    const [benefitCategoryList, setBenefitCategoryList] = useState([]);
    const [systemVersion, setSystemVersion] = useState(0);
    const [isBenefitGoodsSkuEdit, setIsBenefitGoodsSkuEdit] = useState(false);
    const [benefitGoodsSku, setBenefitGoodsSku] = useState({});
    const [isBenefitGoodsSkuList, setIsBenefitGoodsSkuList] = useState(false);
    const [isBenefitGoodsSkuBatch, setIsBenefitGoodsSkuBatch] = useState(false);
    const [benefitCategoryIndex, setBenefitCategoryIndex] = useState(-1);
    const [benefitCategoryId, setBenefitCategoryId] = useState("");
    const [goodsCategoryList, setGoodsCategoryList] = useState([]);
    const [goodsSkuCategoryList, setGoodsSkuCategoryList] = useState([]);
    const [provinceList, setProvinceList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [enterpriseList, setEnterpriseList] = useState([]);
    const [isBenefitBanner, setIsBenefitBanner] = useState(false);
    const [benefitBanner, setBenefitBanner] = useState({});
    const [benefitBannerList, setBenefitBannerList] = useState([]);

    useEffect(() => {
        if (store.main.userName) {
            ChuangShiOS.Notification.emit("main_menu_active", {url: "/benefit/index"});

            handleLoadEnterprise();

            handleLoadGoodsCategory();

            handleLoadGoodsSkuCategory();

            switch (props.match.path) {
                case "/benefit/add":
                    setIsSave(true);

                    break;
                case "/benefit/edit/:benefitId":
                    setIsEdit(true);

                    handleLoadProvince();

                    handleLoadBenefit();

                    break;
                default:
                    break;
            }
        }

        //eslint-disable-next-line
    }, [store.main.userName]);

    const handleLoadEnterprise = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/enterprise/business/website/v1/select/list",
            data: {},
            success: (response) => {
                setEnterpriseList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadGoodsCategory = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/goods/category/business/website/v1/select/list",
            data: {},
            success: (response) => {
                setGoodsCategoryList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadGoodsSkuCategory = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/goods/sku/category/business/website/v1/select/list",
            data: {},
            success: (response) => {
                setGoodsSkuCategoryList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadProvince = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/province/business/website/v1/select/list",
            data: {},
            success: (response) => {
                setProvinceList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadCity = (provinceId) => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/city/business/website/v1/select/list",
            data: {
                provinceId: provinceId,
            },
            success: (response) => {
                setCityList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadBenefit = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/benefit/business/website/v1/find",
            data: {
                benefitId: props.match.params.benefitId,
            },
            success: (response) => {
                request.current = false;

                setIsLoad(false);

                if (response.data.benefitStart) {
                    response.data.benefitStart = moment(response.data.benefitStart, "YYYY-MM-DD HH:mm");
                }
                if (response.data.benefitEnd) {
                    response.data.benefitEnd = moment(response.data.benefitEnd, "YYYY-MM-DD HH:mm");
                }
                for (let i = 0; i < response.data.benefitRuleList.length; i++) {
                    response.data.benefitRuleList[i].key = ChuangShiOS.Util.getId();
                }

                form.setFieldsValue(response.data);

                setSystemVersion(response.data.systemVersion);
                setBenefitRuleList(response.data.benefitRuleList);
                setBenefitBannerList(response.data.benefitBannerList);

                handleLoadBenefitCategory();
            },
            error: (response) => {
                request.current = false;

                setIsLoad(false);

                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                setIsError(typeof response.data == "undefined" || typeof response.data.benefitId == "undefined" || response.data.benefitId == null);
            },
        });
    };

    const handleLoadBenefitCategory = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/benefit/category/business/website/v1/list",
            data: {
                benefitId: props.match.params.benefitId,
                pageIndex: 1,
                pageSize: 10,
            },
            success: (response) => {
                for (let i = 0; i < response.data.length; i++) {
                    for (let j = 0; j < response.data[i].benefitCategoryRuleList.length; j++) {
                        response.data[i].benefitCategoryRuleList[j].key = ChuangShiOS.Util.getId();
                    }

                    response.data[i].benefitGoodsSkuPageIndex = 1;
                    response.data[i].benefitGoodsSkuPageSize = 10;
                    response.data[i].benefitGoodsSkuIdList = [];
                }
                setBenefitCategoryList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
            },
        });
    };

    const handleLoadBenefitGoodsSku = (benefitCategoryIndex, benefitGoodsSkuPageIndex, benefitGoodsSkuPageSize) => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        let data = benefitCategoryRefArray.current[benefitCategoryIndex].getFieldsValue();
        data.benefitCategoryId = benefitCategoryList[benefitCategoryIndex].benefitCategoryId;
        data.pageIndex = benefitGoodsSkuPageIndex;
        data.pageSize = benefitGoodsSkuPageSize;

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/benefit/goods/sku/business/website/v1/list",
            data: data,
            success: (response) => {
                benefitCategoryList[benefitCategoryIndex].benefitGoodsSkuPageIndex = benefitGoodsSkuPageIndex;
                benefitCategoryList[benefitCategoryIndex].benefitGoodsSkuPageSize = benefitGoodsSkuPageSize;
                benefitCategoryList[benefitCategoryIndex].benefitGoodsSkuTotal = response.data.total;
                benefitCategoryList[benefitCategoryIndex].benefitGoodsSkuList = response.data.list;
                setBenefitCategoryList([...benefitCategoryList]);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
            },
        });
    };

    const handleSubmitBenefit = () => {
        if (request.current) {
            return;
        }

        form.validateFields()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                data.benefitId = props.match.params.benefitId;
                if (data.benefitStart) {
                    data.benefitStart = data.benefitStart.format("YYYY-MM-DD HH:mm");
                }
                if (data.benefitEnd) {
                    data.benefitEnd = data.benefitEnd.format("YYYY-MM-DD HH:mm");
                }
                data.benefitRuleList = benefitRuleList;
                data.benefitCategoryList = benefitCategoryList;
                data.systemVersion = systemVersion;

                let url = "";
                if (isSave) {
                    url = "/windeal/benefit/business/website/v1/save";
                }
                if (isEdit) {
                    url = "/windeal/benefit/business/website/v1/update";
                }

                ChuangShiOS.Util.request(props, {
                    url: Application.host + url,
                    data: data,
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);

                        ChuangShiOS.Util.navigateBack(props, {});
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            })
            .catch((response) => {
                ChuangShiOS.Util.parseResponseError(response);
            });
    };

    const handleBackBenefit = () => {
        ChuangShiOS.Util.navigateBack(props, {});
    };

    const handleAddBenefitRule = () => {
        benefitRuleList.push({
            key: ChuangShiOS.Util.getId(),
            benefitRuleType: null,
            benefitRuleQuantity: 0,
        });

        setBenefitRuleList([...benefitRuleList]);
    };

    const handleDelBenefitRule = (index) => {
        Modal.confirm({
            title: "是否确认操作？",
            content: "本次数据操作后将无法恢复",
            okText: "确认",
            cancelText: "取消",
            onOk: () => {
                benefitRuleList.splice(index, 1);

                setBenefitRuleList([...benefitRuleList]);
            },
        });
    };

    const handleChangeBenefitRule = (event, index, name) => {
        if (name == "benefitRuleType" || name == "benefitRuleQuantity") {
            benefitRuleList[index][name] = event;
        } else {
            benefitRuleList[index][name] = event.target.value;
        }

        setBenefitRuleList([...benefitRuleList]);
    };

    const handleAddBenefitCategoryList = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        message.loading({
            content: "正在加载",
            key: "isLoad",
            duration: 0,
        });

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/benefit/category/business/website/v1/save",
            data: {
                benefitId: props.match.params.benefitId,
            },
            success: (response) => {
                message.success({
                    content: response.message,
                    key: "isLoad",
                    duration: 2,
                });

                request.current = false;

                setIsLoad(false);

                handleLoadBenefitCategory();
            },
            error: (response) => {
                message.error({
                    content: response.message,
                    key: "isLoad",
                    duration: 2,
                });

                request.current = false;

                setIsLoad(false);
            },
            complete: (response) => {},
        });
    };

    const handleDelBenefitCategoryList = (benefitCategoryId) => {
        if (request.current) {
            return;
        }

        Modal.confirm({
            title: "是否确认操作？",
            content: "本次数据操作后将无法恢复",
            okText: "确认",
            cancelText: "取消",
            onOk: () => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                ChuangShiOS.Util.request(props, {
                    url: Application.host + "/windeal/benefit/category/business/website/v1/delete",
                    data: {
                        benefitCategoryId: benefitCategoryId,
                    },
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);

                        handleLoadBenefitCategory();
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            },
        });
    };

    const handleAddBenefitCategoryRule = (benefitCategoryIndex) => {
        let benefitCategoryRuleList = JSON.parse(JSON.stringify(benefitCategoryList[benefitCategoryIndex].benefitCategoryRuleList));

        benefitCategoryRuleList.push({
            key: ChuangShiOS.Util.getId(),
            benefitCategoryRuleType: null,
            benefitCategoryRuleQuantity: 0,
        });

        benefitCategoryList[benefitCategoryIndex].benefitCategoryRuleList = benefitCategoryRuleList;

        setBenefitCategoryList([...benefitCategoryList]);
    };

    const handleDelBenefitCategoryRule = (benefitCategoryIndex, index) => {
        Modal.confirm({
            title: "是否确认操作？",
            content: "本次数据操作后将无法恢复",
            okText: "确认",
            cancelText: "取消",
            onOk: () => {
                let benefitCategoryRuleList = JSON.parse(JSON.stringify(benefitCategoryList[benefitCategoryIndex].benefitCategoryRuleList));

                benefitCategoryRuleList.splice(index, 1);

                benefitCategoryList[benefitCategoryIndex].benefitCategoryRuleList = benefitCategoryRuleList;

                setBenefitCategoryList([...benefitCategoryList]);
            },
        });
    };

    const handleChangeBenefitCategory = (event, index, name) => {
        if (name == "benefitCategoryImage" || name == "benefitCategoryQuantity") {
            benefitCategoryList[index][name] = event;
        } else {
            benefitCategoryList[index][name] = event.target.value;
        }

        setBenefitCategoryList([...benefitCategoryList]);
    };

    const handleChangeBenefitCategoryRule = (event, benefitCategoryIndex, index, name) => {
        let benefitCategoryRuleList = JSON.parse(JSON.stringify(benefitCategoryList[benefitCategoryIndex].benefitCategoryRuleList));

        if (name == "benefitCategoryRuleType" || name == "benefitCategoryRuleQuantity") {
            benefitCategoryRuleList[index][name] = event;
        } else {
            benefitCategoryRuleList[index][name] = event.target.value;
        }

        benefitCategoryList[benefitCategoryIndex].benefitCategoryRuleList = benefitCategoryRuleList;

        setBenefitCategoryList([...benefitCategoryList]);
    };

    const handleOpenBenefitGoodsSkuList = (benefitCategoryId) => {
        setIsBenefitGoodsSkuList(true);
        setBenefitCategoryId(benefitCategoryId);
    };

    const handleCloseBenefitGoodsSkuList = () => {
        setIsBenefitGoodsSkuList(false);
    };

    const handleSubmitBenefitGoodsSkuList = () => {
        setIsBenefitGoodsSkuList(false);

        let benefitCategoryIndex = -1;
        for (let i = 0; i < benefitCategoryList.length; i++) {
            if (benefitCategoryList[i].benefitCategoryId == benefitCategoryId) {
                benefitCategoryIndex = i;

                break;
            }
        }

        handleLoadBenefitGoodsSku(
            benefitCategoryIndex,
            benefitCategoryList[benefitCategoryIndex].benefitGoodsSkuPageIndex,
            benefitCategoryList[benefitCategoryIndex].benefitGoodsSkuPageSize
        );
    };

    const handleChangeBenefitGoodsSkuIdList = (benefitGoodsSkuIdList, benefitCategoryIndex) => {
        benefitCategoryList[benefitCategoryIndex].benefitGoodsSkuIdList = benefitGoodsSkuIdList;

        setBenefitCategoryList([...benefitCategoryList]);
    };

    const handleChangeBenefitGoodsSkuPagination = (benefitGoodsSkuPageIndex, benefitGoodsSkuPageSize, benefitCategoryIndex) => {
        handleLoadBenefitGoodsSku(benefitCategoryIndex, benefitGoodsSkuPageIndex, benefitGoodsSkuPageSize);
    };

    const handleOpenBenefitGoodsSkuEdit = (benefitGoodsSku, benefitCategoryId, benefitCategoryIndex) => {
        setIsBenefitGoodsSkuEdit(true);
        setBenefitGoodsSku(benefitGoodsSku);
        setBenefitCategoryId(benefitCategoryId);
        setBenefitCategoryIndex(benefitCategoryIndex);
    };

    const handleCloseBenefitGoodsSkuEdit = () => {
        setIsBenefitGoodsSkuEdit(false);
    };

    const handleSubmitBenefitGoodsSkuEdit = () => {
        setIsBenefitGoodsSkuEdit(false);

        handleLoadBenefitGoodsSku(
            benefitCategoryIndex,
            benefitCategoryList[benefitCategoryIndex].benefitGoodsSkuPageIndex,
            benefitCategoryList[benefitCategoryIndex].benefitGoodsSkuPageSize
        );
    };

    const handleDeleteBenefitGoodsSku = (benefitCategoryIndex) => {
        if (benefitCategoryList[benefitCategoryIndex].benefitGoodsSkuIdList.length == 0) {
            return;
        }

        if (request.current) {
            return;
        }

        Modal.confirm({
            title: "是否确认操作？",
            content: "本次数据操作后将无法恢复",
            okText: "确认",
            okType: "danger",
            okButtonProps: {
                icon: <DeleteOutlined />,
            },
            cancelText: "取消",
            cancelButtonProps: {
                icon: <CloseCircleOutlined />,
            },
            onOk: () => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                ChuangShiOS.Util.request(props, {
                    url: Application.host + "/windeal/benefit/goods/sku/business/website/v1/delete",
                    data: {
                        benefitGoodsSkuIdList: benefitCategoryList[benefitCategoryIndex].benefitGoodsSkuIdList,
                    },
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);

                        benefitCategoryList[benefitCategoryIndex].benefitGoodsSkuIdList = [];
                        setBenefitCategoryList([...benefitCategoryList]);

                        handleLoadBenefitGoodsSku(
                            benefitCategoryIndex,
                            benefitCategoryList[benefitCategoryIndex].benefitGoodsSkuPageIndex,
                            benefitCategoryList[benefitCategoryIndex].benefitGoodsSkuPageSize
                        );
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            },
        });
    };

    const handleOpenBenefitGoodsSkuBatch = (benefitCategoryIndex) => {
        setIsBenefitGoodsSkuBatch(true);
        setBenefitCategoryIndex(benefitCategoryIndex);
    };

    const handleCloseBenefitGoodsSkuBatch = () => {
        setIsBenefitGoodsSkuBatch(false);
    };

    const handleSubmitBenefitGoodsSkuBatch = () => {
        setIsBenefitGoodsSkuBatch(false);

        benefitCategoryList[benefitCategoryIndex].benefitGoodsSkuIdList = [];
        setBenefitCategoryList([...benefitCategoryList]);

        handleLoadBenefitGoodsSku(
            benefitCategoryIndex,
            benefitCategoryList[benefitCategoryIndex].benefitGoodsSkuPageIndex,
            benefitCategoryList[benefitCategoryIndex].benefitGoodsSkuPageSize
        );
    };

    const handleOpenBenefitBanner = (benefitBanner) => {
        benefitBanner.benefitId = props.match.params.benefitId;

        setIsBenefitBanner(true);
        setBenefitBanner(benefitBanner);
    };

    const handleCloseBenefitBanner = () => {
        setIsBenefitBanner(false);
    };

    const handleSubmitBenefitBanner = () => {
        setIsBenefitBanner(false);

        handleLoadBenefitBanner();
    };

    const handleLoadBenefitBanner = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/benefit/banner/business/website/v1/list",
            data: {
                benefitId: props.match.params.benefitId,
            },
            success: (response) => {
                setBenefitBannerList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
            },
        });
    };

    const handleDelBenefitBanner = (benefitBannerId) => {
        if (request.current) {
            return;
        }

        Modal.confirm({
            title: "是否确认操作？",
            content: "本次数据操作后将无法恢复",
            okText: "确认",
            cancelText: "取消",
            onOk: () => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                ChuangShiOS.Util.request(props, {
                    url: Application.host + "/windeal/benefit/banner/business/website/v1/delete",
                    data: {
                        benefitBannerId: benefitBannerId,
                    },
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);

                        handleLoadBenefitBanner();
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            },
        });
    };

    return (
        <div className={Style.page}>
            <div className={Style.header}>
                <div className={Style.header_title}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <span>首页</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span>{store.main.menuName}</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className={Style.header_title_text}>{store.main.sidebarName}</div>
                </div>
                <div className={Style.header_button}>
                    <Space>
                        <Button.Group>
                            <Button icon={<LeftCircleOutlined />} size="large" onClick={handleBackBenefit}>
                                返回
                            </Button>
                        </Button.Group>
                        <Button
                            loading={isLoad}
                            disabled={isError}
                            icon={<FileDoneOutlined />}
                            type="primary"
                            size="large"
                            onClick={handleSubmitBenefit}
                        >
                            提交
                        </Button>
                    </Space>
                </div>
            </div>
            <ChuangShiOS.Content
                className={Style.content}
                isLoad={isLoad}
                isEmpty={false}
                isError={isError}
                paddingTop={0}
                paddingRight={0}
                paddingBottom={0}
                paddingLeft={0}
                isScroll={store.main.isFixedContent}
            >
                <Card>
                    <Row>
                        <Col flex="auto">
                            <div className={Style.card_title}>基本信息</div>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Form form={form}>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "24%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="企业名称"
                                    name="enterpriseId"
                                    initialValue={null}
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入企业名称",
                                        },
                                    ]}
                                >
                                    <Select className={Style.card_field} placeholder="请选择企业名称" allowClear onChange={() => {}}>
                                        {enterpriseList.map((enterprise, enterpriseIndex) => {
                                            return (
                                                <Select.Option key={enterpriseIndex} value={enterprise.enterpriseId}>
                                                    {enterprise.enterpriseName}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "24%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="权益名称"
                                    name="benefitName"
                                    initialValue={""}
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入权益名称",
                                        },
                                    ]}
                                >
                                    <Input className={Style.card_field} placeholder="请输入权益名称" onPressEnter={handleSubmitBenefit} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "24%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="开始日期"
                                    name="benefitStart"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择开始日期",
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        className={Style.card_field}
                                        showTime={{format: "HH:mm"}}
                                        format="YYYY-MM-DD HH:mm"
                                        placeholder="请选择开始日期"
                                        onPressEnter={handleSubmitBenefit}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "24%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="结束日期"
                                    name="benefitEnd"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择结束日期",
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        className={Style.card_field}
                                        showTime={{format: "HH:mm"}}
                                        format="YYYY-MM-DD HH:mm"
                                        placeholder="请选择结束日期"
                                        onPressEnter={handleSubmitBenefit}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "12%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="权益协议"
                                    name="benefitAgreement"
                                    initialValue={""}
                                    rules={[
                                        {
                                            required: false,
                                            message: "请输入权益协议",
                                        },
                                    ]}
                                >
                                    <ChuangShiOS.Editor id="benefitAgreement" height={300} placeholder="请输入权益协议" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Divider />
                    <Row>
                        <Col flex="auto">
                            <div className={Style.card_title}>权益规则</div>
                        </Col>
                        <Col>
                            <Button
                                icon={<PlusCircleOutlined />}
                                onClick={() => {
                                    handleAddBenefitRule();
                                }}
                            >
                                新增
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col flex={"12%"}></Col>
                        <Col flex="auto" className={Style.card_value}>
                            <Table
                                size="small"
                                rowKey={(record) => record.key}
                                columns={[
                                    {
                                        title: "规则类型",
                                        render: (text, record, recordIndex) => (
                                            <Select
                                                value={record.benefitRuleType}
                                                className={Style.table_field}
                                                placeholder="请选择规则类型"
                                                allowClear
                                                onChange={(event) => {
                                                    handleChangeBenefitRule(event, recordIndex, "benefitRuleType");
                                                }}
                                            >
                                                <Select.Option value="DAY">每天</Select.Option>
                                                <Select.Option value="WEEK">每周</Select.Option>
                                                <Select.Option value="MONTH">每月</Select.Option>
                                                <Select.Option value="SEASON">每季</Select.Option>
                                                <Select.Option value="YEAR">每年</Select.Option>
                                                <Select.Option value="ALL">全部</Select.Option>
                                            </Select>
                                        ),
                                    },
                                    {
                                        title: "规则次数",
                                        render: (text, record, recordIndex) => (
                                            <InputNumber
                                                value={record.benefitRuleQuantity}
                                                className={Style.table_field}
                                                min={0}
                                                max={999999}
                                                placeholder="请输入规则次数"
                                                onChange={(event) => {
                                                    handleChangeBenefitRule(event, recordIndex, "benefitRuleQuantity");
                                                }}
                                            />
                                        ),
                                    },
                                    {
                                        title: "操作",
                                        width: 150,
                                        render: (text, record, recordIndex) => (
                                            <Space>
                                                <Button
                                                    icon={<CloseCircleOutlined />}
                                                    onClick={() => {
                                                        handleDelBenefitRule(recordIndex);
                                                    }}
                                                >
                                                    删除
                                                </Button>
                                            </Space>
                                        ),
                                    },
                                ]}
                                dataSource={benefitRuleList}
                                pagination={false}
                            />
                        </Col>
                    </Row>
                </Card>
                <Card className={Style.card_top}>
                    <Row>
                        <Col flex="auto">
                            <div className={Style.card_title}>权益广告</div>
                        </Col>
                        <Col>
                            <Space>
                                <Button.Group>
                                    <Button
                                        icon={<PlusCircleOutlined />}
                                        onClick={() => {
                                            handleOpenBenefitBanner({});
                                        }}
                                    >
                                        新增
                                    </Button>
                                </Button.Group>
                                <Button
                                    icon={<FileSearchOutlined />}
                                    type="primary"
                                    onClick={() => {
                                        handleLoadBenefitBanner();
                                    }}
                                >
                                    查询
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                    <Row>
                        <Col flex={"12%"}></Col>
                        <Col flex="auto">
                            <Table
                                size="small"
                                rowKey={(record) => record.benefitBannerId}
                                columns={[
                                    {
                                        title: "广告图片",
                                        width: 120,
                                        render: (text, record, recordIndex) => (
                                            <ChuangShiOS.Image
                                                width={110}
                                                src={Application.imagePreviewHost + ChuangShiOS.Util.scaleImage(record.benefitBannerImage, 120)}
                                            />
                                        ),
                                    },
                                    {
                                        title: "广告地址",
                                        dataIndex: "benefitBannerUrl",
                                    },
                                    {
                                        title: "广告排序",
                                        dataIndex: "benefitBannerSort",
                                    },
                                    {
                                        title: "操作",
                                        width: 200,
                                        render: (text, record, recordIndex) => (
                                            <Space>
                                                <Button
                                                    icon={<FileTextOutlined />}
                                                    onClick={() => {
                                                        handleOpenBenefitBanner(record);
                                                    }}
                                                >
                                                    修改
                                                </Button>
                                                <Button
                                                    icon={<CloseCircleOutlined />}
                                                    onClick={() => {
                                                        handleDelBenefitBanner(record.benefitBannerId);
                                                    }}
                                                >
                                                    删除
                                                </Button>
                                            </Space>
                                        ),
                                    },
                                ]}
                                dataSource={benefitBannerList}
                                pagination={false}
                            />
                            <br />
                        </Col>
                    </Row>
                </Card>
                {benefitCategoryList.map((benefitCategory, benefitCategoryIndex) => {
                    return (
                        <Card key={benefitCategoryIndex} className={Style.card_table}>
                            <Row>
                                <Col flex="auto">
                                    <div className={Style.card_title}>分类信息</div>
                                </Col>
                                <Col>
                                    <Button
                                        icon={<DeleteOutlined />}
                                        onClick={() => {
                                            handleDelBenefitCategoryList(benefitCategory.benefitCategoryId);
                                        }}
                                    >
                                        删除该分类
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "24%"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="分类图片"
                                    >
                                        <ChuangShiOS.File
                                            value={benefitCategory.benefitCategoryImage}
                                            fileType={"IMAGE"}
                                            limit={1}
                                            onChange={(event) => {
                                                handleChangeBenefitCategory(event, benefitCategoryIndex, "benefitCategoryImage");
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "24%"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="分类名称"
                                    >
                                        <Input
                                            value={benefitCategory.benefitCategoryName}
                                            className={Style.card_field}
                                            placeholder="请输入分类名称"
                                            onChange={(event) => {
                                                handleChangeBenefitCategory(event, benefitCategoryIndex, "benefitCategoryName");
                                            }}
                                            onPressEnter={handleSubmitBenefit}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "24%"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="分类备注"
                                    >
                                        <Input
                                            value={benefitCategory.benefitCategoryRemark}
                                            className={Style.card_field}
                                            placeholder="请输入分类备注"
                                            onChange={(event) => {
                                                handleChangeBenefitCategory(event, benefitCategoryIndex, "benefitCategoryRemark");
                                            }}
                                            onPressEnter={handleSubmitBenefit}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "24%"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="权益次数"
                                    >
                                        <InputNumber
                                            value={benefitCategory.benefitCategoryQuantity}
                                            className={Style.card_field}
                                            min={0}
                                            max={999999}
                                            placeholder="请输入权益次数"
                                            onChange={(event) => {
                                                handleChangeBenefitCategory(event, benefitCategoryIndex, "benefitCategoryQuantity");
                                            }}
                                            onPressEnter={handleSubmitBenefit}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider />
                            <Row>
                                <Col flex="auto">
                                    <div className={Style.card_title}>规则信息</div>
                                </Col>
                                <Col>
                                    <Button
                                        icon={<PlusCircleOutlined />}
                                        onClick={() => {
                                            handleAddBenefitCategoryRule(benefitCategoryIndex);
                                        }}
                                    >
                                        新增
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col flex={"12%"}></Col>
                                <Col flex="auto">
                                    <Table
                                        className={store.main.isFixedContent ? Style.card_table : null}
                                        size="large"
                                        rowKey={(record) => record.key}
                                        columns={[
                                            {
                                                title: "规则类型",
                                                render: (text, record, recordIndex) => (
                                                    <Select
                                                        value={record.benefitCategoryRuleType}
                                                        className={Style.table_field}
                                                        placeholder="请选择规则类型"
                                                        allowClear
                                                        onChange={(event) => {
                                                            handleChangeBenefitCategoryRule(
                                                                event,
                                                                benefitCategoryIndex,
                                                                recordIndex,
                                                                "benefitCategoryRuleType"
                                                            );
                                                        }}
                                                    >
                                                        <Select.Option value="DAY">每天</Select.Option>
                                                        <Select.Option value="WEEK">每周</Select.Option>
                                                        <Select.Option value="MONTH">每月</Select.Option>
                                                        <Select.Option value="SEASON">每季</Select.Option>
                                                        <Select.Option value="YEAR">每年</Select.Option>
                                                        <Select.Option value="ALL">全部</Select.Option>
                                                    </Select>
                                                ),
                                            },
                                            {
                                                title: "规则次数",
                                                render: (text, record, recordIndex) => (
                                                    <InputNumber
                                                        value={record.benefitCategoryRuleQuantity}
                                                        className={Style.table_field}
                                                        min={0}
                                                        max={999999}
                                                        placeholder="请输入规则次数"
                                                        onChange={(event) => {
                                                            handleChangeBenefitCategoryRule(
                                                                event,
                                                                benefitCategoryIndex,
                                                                recordIndex,
                                                                "benefitCategoryRuleQuantity"
                                                            );
                                                        }}
                                                    />
                                                ),
                                            },
                                            {
                                                title: "操作",
                                                width: 150,
                                                render: (text, record, recordIndex) => (
                                                    <Space>
                                                        <Button
                                                            icon={<CloseCircleOutlined />}
                                                            onClick={() => {
                                                                handleDelBenefitCategoryRule(benefitCategoryIndex, recordIndex);
                                                            }}
                                                        >
                                                            删除
                                                        </Button>
                                                    </Space>
                                                ),
                                            },
                                        ]}
                                        dataSource={benefitCategory.benefitCategoryRuleList}
                                        pagination={false}
                                    />
                                </Col>
                            </Row>
                            <Divider />
                            <Row>
                                <Col flex="auto">
                                    <div className={Style.card_title}>商品信息</div>
                                </Col>
                                <Col>
                                    <Space>
                                        <Button.Group>
                                            <Button
                                                icon={<PlusCircleOutlined />}
                                                onClick={() => {
                                                    handleOpenBenefitGoodsSkuList(benefitCategory.benefitCategoryId);
                                                }}
                                            >
                                                新增
                                            </Button>
                                            <Button
                                                loading={isLoad}
                                                disabled={benefitCategory.benefitGoodsSkuIdList.length == 0}
                                                icon={<FileTextOutlined />}
                                                onClick={() => {
                                                    handleOpenBenefitGoodsSkuBatch(benefitCategoryIndex);
                                                }}
                                            >
                                                批量设置
                                            </Button>
                                            <Button
                                                loading={isLoad}
                                                disabled={benefitCategory.benefitGoodsSkuIdList.length == 0}
                                                icon={<DeleteOutlined />}
                                                onClick={() => {
                                                    handleDeleteBenefitGoodsSku(benefitCategoryIndex);
                                                }}
                                            >
                                                删除
                                            </Button>
                                        </Button.Group>
                                        <Button
                                            icon={<FileSearchOutlined />}
                                            type="primary"
                                            onClick={() => {
                                                handleLoadBenefitGoodsSku(
                                                    benefitCategoryIndex,
                                                    benefitCategoryList[benefitCategoryIndex].benefitGoodsSkuPageIndex,
                                                    benefitCategoryList[benefitCategoryIndex].benefitGoodsSkuPageSize
                                                );
                                            }}
                                        >
                                            查询
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                            <Row>
                                {/* <Col flex={"12%"}></Col> */}
                                <Col flex="auto" className={Style.card_value}>
                                    <Form
                                        ref={(ref) => {
                                            benefitCategoryRefArray.current[benefitCategoryIndex] = ref;
                                        }}
                                    >
                                        <Row>
                                            <Col span={6}>
                                                <Form.Item
                                                    {...{
                                                        labelCol: {flex: "none"},
                                                        wrapperCol: {flex: "auto"},
                                                    }}
                                                    label="规格分类"
                                                    labelAlign={"left"}
                                                    name="goodsSkuCategoryId"
                                                    initialValue={null}
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: "请输入规格分类",
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        className={Style.card_field_2}
                                                        placeholder="请选择规格分类"
                                                        allowClear
                                                        onChange={(event) => {
                                                            handleLoadBenefitGoodsSku(
                                                                benefitCategoryIndex,
                                                                benefitCategoryList[benefitCategoryIndex].benefitGoodsSkuPageIndex,
                                                                benefitCategoryList[benefitCategoryIndex].benefitGoodsSkuPageSize
                                                            );
                                                        }}
                                                    >
                                                        {goodsSkuCategoryList.map((goodsSkuCategory, goodsSkuCategoryIndex) => {
                                                            return (
                                                                <Select.Option
                                                                    key={goodsSkuCategoryIndex}
                                                                    value={goodsSkuCategory.goodsSkuCategoryId}
                                                                >
                                                                    {goodsSkuCategory.goodsSkuCategoryName}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item
                                                    {...{
                                                        labelCol: {flex: "none"},
                                                        wrapperCol: {flex: "auto"},
                                                    }}
                                                    label="商品分类"
                                                    labelAlign={"left"}
                                                    name="goodsCategoryId"
                                                    initialValue={null}
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: "请输入商品分类",
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        className={Style.card_field_2}
                                                        placeholder="请选择商品分类"
                                                        allowClear
                                                        onChange={(event) => {
                                                            handleLoadBenefitGoodsSku(
                                                                benefitCategoryIndex,
                                                                benefitCategoryList[benefitCategoryIndex].benefitGoodsSkuPageIndex,
                                                                benefitCategoryList[benefitCategoryIndex].benefitGoodsSkuPageSize
                                                            );
                                                        }}
                                                    >
                                                        {goodsCategoryList.map((goodsCategory, goodsCategoryIndex) => {
                                                            return (
                                                                <Select.Option key={goodsCategoryIndex} value={goodsCategory.goodsCategoryId}>
                                                                    {goodsCategory.goodsCategoryName}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item
                                                    {...{
                                                        labelCol: {flex: "none"},
                                                        wrapperCol: {flex: "auto"},
                                                    }}
                                                    label="省份名称"
                                                    labelAlign={"left"}
                                                    name="provinceId"
                                                    initialValue={null}
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: "请选择省份名称",
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        className={Style.card_field_2}
                                                        placeholder="请选择省份名称"
                                                        allowClear
                                                        onChange={(event) => {
                                                            benefitCategoryRefArray.current[benefitCategoryIndex].setFieldsValue({
                                                                cityId: null,
                                                            });

                                                            setCityList([]);

                                                            if (event) {
                                                                handleLoadCity(event);
                                                            }

                                                            handleLoadBenefitGoodsSku(
                                                                benefitCategoryIndex,
                                                                benefitCategoryList[benefitCategoryIndex].benefitGoodsSkuPageIndex,
                                                                benefitCategoryList[benefitCategoryIndex].benefitGoodsSkuPageSize
                                                            );
                                                        }}
                                                    >
                                                        {provinceList.map((province, provinceIndex) => {
                                                            return (
                                                                <Select.Option key={provinceIndex} value={province.provinceId}>
                                                                    {province.provinceName}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item
                                                    {...{
                                                        labelCol: {flex: "none"},
                                                        wrapperCol: {flex: "auto"},
                                                    }}
                                                    label="城市名称"
                                                    labelAlign={"left"}
                                                    name="cityId"
                                                    initialValue={null}
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: "请选择城市名称",
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        className={Style.card_field_2}
                                                        placeholder="请选择城市名称"
                                                        allowClear
                                                        onChange={(event) => {
                                                            handleLoadBenefitGoodsSku(
                                                                benefitCategoryIndex,
                                                                benefitCategoryList[benefitCategoryIndex].benefitGoodsSkuPageIndex,
                                                                benefitCategoryList[benefitCategoryIndex].benefitGoodsSkuPageSize
                                                            );
                                                        }}
                                                    >
                                                        {cityList.map((city, cityIndex) => {
                                                            return (
                                                                <Select.Option key={cityIndex} value={city.cityId}>
                                                                    {city.cityName}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item
                                                    {...{
                                                        labelCol: {flex: "none"},
                                                        wrapperCol: {flex: "auto"},
                                                    }}
                                                    label="商户名称"
                                                    labelAlign={"left"}
                                                    name="merchantName"
                                                    initialValue={""}
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: "请输入商户名称",
                                                        },
                                                    ]}
                                                >
                                                    <Input className={Style.card_field_2} placeholder="请输入商户名称" onPressEnter={() => {}} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item
                                                    {...{
                                                        labelCol: {flex: "none"},
                                                        wrapperCol: {flex: "auto"},
                                                    }}
                                                    label="门店名称"
                                                    labelAlign={"left"}
                                                    name="shopName"
                                                    initialValue={""}
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: "请输入门店名称",
                                                        },
                                                    ]}
                                                >
                                                    <Input className={Style.card_field_2} placeholder="请输入门店名称" onPressEnter={() => {}} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <Table
                                        size="large"
                                        rowKey={(record) => record.benefitGoodsSkuId}
                                        columns={[
                                            {
                                                title: "商户",
                                                width: 140,
                                                ellipsis: true,
                                                dataIndex: "merchantName",
                                            },
                                            {
                                                title: "门店",
                                                width: 100,
                                                ellipsis: true,
                                                dataIndex: "shopName",
                                            },
                                            {
                                                title: "规格名称",
                                                width: 120,
                                                ellipsis: true,
                                                dataIndex: "goodsSkuName",
                                            },
                                            {
                                                title: "可定日期",
                                                width: 85,
                                                render: (text, record, recordIndex) => (
                                                    <React.Fragment>
                                                        {record.goodsReserveWeekList.sort().map((goodsReserveWeek, goodsReserveWeekIndex) => {
                                                            return (
                                                                <span key={goodsReserveWeekIndex}>
                                                                    {goodsReserveWeek == 0 ? null : goodsReserveWeek}
                                                                    {goodsReserveWeekIndex + 1 < record.goodsReserveWeekList.length &&
                                                                    goodsReserveWeek > 0
                                                                        ? ","
                                                                        : null}
                                                                </span>
                                                            );
                                                        })}
                                                        {record.goodsReserveWeekList.indexOf(0) > -1
                                                            ? (record.goodsReserveWeekList.length > 1 ? "," : "") + "7"
                                                            : null}
                                                    </React.Fragment>
                                                ),
                                            },
                                            {
                                                title: "可定时间",
                                                width: 85,
                                                render: (text, record, recordIndex) => (
                                                    <React.Fragment>
                                                        {record.goodsReserveStartTime}~{record.goodsReserveEndTime}
                                                    </React.Fragment>
                                                ),
                                            },
                                            {
                                                title: "市场价",
                                                width: 60,
                                                dataIndex: "goodsSkuMarketPrice",
                                            },
                                            {
                                                title: "餐价",
                                                width: 60,
                                                dataIndex: "goodsSkuSalePrice",
                                            },
                                            {
                                                title: "服务费比例",
                                                width: 80,
                                                dataIndex: "goodsSkuServiceChargeRatio",
                                            },
                                            {
                                                title: "销售价",
                                                width: 60,
                                                render: (text, record, recordIndex) => (
                                                    <React.Fragment>
                                                        {record.goodsSkuSalePrice + record.goodsSkuSalePrice * record.goodsSkuServiceChargeRatio}
                                                    </React.Fragment>
                                                ),
                                            },
                                            {
                                                title: "补贴价",
                                                width: 60,
                                                dataIndex: "goodsSkuAllowancePrice",
                                            },
                                            {
                                                title: "结算价",
                                                width: 60,
                                                dataIndex: "goodsSkuSettlementPrice",
                                            },
                                            {
                                                title: "规格类型",
                                                width: 110,
                                                dataIndex: "goodsSkuCategoryName",
                                            },
                                            {
                                                title: "城市",
                                                width: 100,
                                                dataIndex: "cityName",
                                            },
                                            {
                                                title: "操作",
                                                fixed: "right",
                                                width: 75,
                                                render: (text, record, recordIndex) => (
                                                    <Space>
                                                        <Button
                                                            icon={<FileTextOutlined />}
                                                            size="default"
                                                            onClick={() => {
                                                                handleOpenBenefitGoodsSkuEdit(
                                                                    record,
                                                                    benefitCategory.benefitCategoryId,
                                                                    benefitCategoryIndex
                                                                );
                                                            }}
                                                        >
                                                            修改
                                                        </Button>
                                                    </Space>
                                                ),
                                            },
                                        ]}
                                        dataSource={benefitCategory.benefitGoodsSkuList}
                                        rowSelection={{
                                            fixed: true,
                                            selectedRowKeys: benefitCategory.benefitGoodsSkuIdList,
                                            onChange: (benefitGoodsSkuIdList) => {
                                                handleChangeBenefitGoodsSkuIdList(benefitGoodsSkuIdList, benefitCategoryIndex);
                                            },
                                        }}
                                        scroll={{x: 1800}}
                                        pagination={{
                                            size: "default",
                                            showQuickJumper: true,
                                            showSizeChanger: true,
                                            current: benefitCategory.benefitGoodsSkuPageIndex,
                                            pageSize: benefitCategory.benefitGoodsSkuPageSize,
                                            total: benefitCategory.benefitGoodsSkuTotal,
                                            showTotal: (total, range) => "显示" + range[0] + "到" + range[1] + "条,共有" + total + "条",
                                            onChange: (benefitGoodsSkuPageIndex, benefitGoodsSkuPageSize) => {
                                                handleChangeBenefitGoodsSkuPagination(
                                                    benefitGoodsSkuPageIndex,
                                                    benefitGoodsSkuPageSize,
                                                    benefitCategoryIndex
                                                );
                                            },
                                            onShowSizeChange: (benefitGoodsSkuPageIndex, benefitGoodsSkuPageSize) => {
                                                handleChangeBenefitGoodsSkuPagination(
                                                    benefitGoodsSkuPageIndex,
                                                    benefitGoodsSkuPageSize,
                                                    benefitCategoryIndex
                                                );
                                            },
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    );
                })}
                {isEdit ? (
                    <Button
                        className={Style.card_top}
                        size="large"
                        icon={<PlusCircleOutlined />}
                        onClick={() => {
                            handleAddBenefitCategoryList();
                        }}
                    >
                        新增权益分类
                    </Button>
                ) : null}
            </ChuangShiOS.Content>
            <div className={Style.footer}></div>
            {isEdit ? (
                <BannerModal
                    visible={isBenefitBanner}
                    benefitBanner={benefitBanner}
                    onClose={handleCloseBenefitBanner}
                    onSubmit={handleSubmitBenefitBanner}
                ></BannerModal>
            ) : null}
            {isEdit ? (
                <GoodsSkuListModal
                    visible={isBenefitGoodsSkuList}
                    benefitId={props.match.params.benefitId}
                    benefitCategoryId={benefitCategoryId}
                    onClose={handleCloseBenefitGoodsSkuList}
                    onSubmit={handleSubmitBenefitGoodsSkuList}
                ></GoodsSkuListModal>
            ) : null}
            {isEdit ? (
                <GoodsSkuEditModal
                    visible={isBenefitGoodsSkuEdit}
                    benefitGoodsSku={benefitGoodsSku}
                    onClose={handleCloseBenefitGoodsSkuEdit}
                    onSubmit={handleSubmitBenefitGoodsSkuEdit}
                ></GoodsSkuEditModal>
            ) : null}
            {isEdit ? (
                <GoodsSkuBatchModal
                    visible={isBenefitGoodsSkuBatch}
                    benefitGoodsSkuIdList={benefitCategoryIndex > -1 ? benefitCategoryList[benefitCategoryIndex].benefitGoodsSkuIdList : []}
                    onClose={handleCloseBenefitGoodsSkuBatch}
                    onSubmit={handleSubmitBenefitGoodsSkuBatch}
                ></GoodsSkuBatchModal>
            ) : null}
        </div>
    );
};

BenefitDetail.propTypes = {};

BenefitDetail.defaultProps = {};

export default BenefitDetail;
