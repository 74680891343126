import React from "react";
import {Route} from "react-router-dom";
import KeepAlive from "react-activation";
import ChuangShiOS from "chuangshi-os-website-component";

import EnterpriseIndex from "./Index";
import EnterpriseDetail from "./Detail";

const data = [
    <Route
        exact
        key="EnterpriseIndex"
        path="/enterprise/index"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return (
                <KeepAlive name="/enterprise/index">
                    <EnterpriseIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="EnterpriseAdd"
        path="/enterprise/add"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <EnterpriseDetail {...props} />;
        }}
    />,
    <Route
        exact
        key="EnterpriseEdit"
        path="/enterprise/edit/:enterpriseId"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <EnterpriseDetail {...props} />;
        }}
    />,
];

export default data;
