import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";
import {message, Modal, Space, Button, Form, Row, Col, Input, Table} from "antd";
import {FileDoneOutlined, CloseCircleOutlined, FileSearchOutlined} from "@ant-design/icons";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./Modal.module.css";

const ShopModal = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const [shopPageIndex, setShopPageIndex] = useState(1);
    const [shopPageSize, setShopPageSize] = useState(store.main.pageSize);
    const [shopIdList, setShopIdList] = useState([]);
    const [shopTotal, setShopTotal] = useState(0);
    const [shopList, setShopList] = useState([]);
    const [selectList, setSelectList] = useState([]);

    useEffect(() => {
        if (props.visible) {
            handleSearchShop();
        }

        //eslint-disable-next-line
    }, [props.visible]);

    const handleSearchShop = () => {
        handleLoadShop(1, shopPageSize);
    };

    const handleLoadShop = (shopPageIndex, shopPageSize) => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        let data = form.getFieldsValue();
        data.pageIndex = shopPageIndex;
        data.pageSize = shopPageSize;

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/shop/business/website/v1/modal/list",
            data: data,
            success: (response) => {
                setShopPageIndex(shopPageIndex);
                setShopPageSize(shopPageSize);
                setShopIdList([]);
                setShopTotal(response.data.total);
                setShopList(response.data.list);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
                setIsError(
                    typeof response.data == "undefined" ||
                        response.data.total == "undefined" ||
                        response.data.total == null ||
                        response.data.list == "undefined" ||
                        response.data.list == null
                );
            },
        });
    };

    const handleChangeShopPagination = (shopPageIndex, shopPageSize) => {
        handleLoadShop(shopPageIndex, shopPageSize);
    };

    const handleSelectShop = (shopIdList) => {
        let selectList = [];
        for (let i = 0; i < shopList.length; i++) {
            let isExit = false;

            for (let j = 0; j < shopIdList.length; j++) {
                if (shopList[i].shopId == shopIdList[j]) {
                    isExit = true;

                    break;
                }
            }

            if (isExit) {
                selectList.push(shopList[i]);
            }
        }

        setShopIdList(shopIdList);
        setSelectList(selectList);
    };

    const handleClickShop = (shop) => {
        let index = -1;

        for (let i = 0; i < shopIdList.length; i++) {
            if (shop.shopId == shopIdList[i]) {
                index = i;
                break;
            }
        }

        if (index == -1) {
            if (props.multiple) {
                shopIdList.push(shop.shopId);
            } else {
                shopIdList.splice(0, shopIdList.length);
                shopIdList.push(shop.shopId);
            }
        } else {
            shopIdList.splice(index, 1);
        }

        let selectList = [];
        for (let i = 0; i < shopList.length; i++) {
            let isExit = false;

            for (let j = 0; j < shopIdList.length; j++) {
                if (shopList[i].shopId == shopIdList[j]) {
                    isExit = true;

                    break;
                }
            }

            if (isExit) {
                selectList.push(shopList[i]);
            }
        }

        setShopIdList([...shopIdList]);
        setSelectList(selectList);
    };

    const handleCancelShop = () => {
        setShopIdList([]);
        setSelectList([]);

        props.onClose();
    };

    const handleSubmitShop = () => {
        if (selectList.length > 0) {
            setShopIdList([]);
            setSelectList([]);

            if (props.multiple) {
                props.onSubmit(selectList);
            } else {
                props.onSubmit(selectList[0]);
            }
        }

        props.onClose();
    };

    return (
        <Modal
            title="权限"
            visible={props.visible}
            centered
            width={ChuangShiOS.Util.getModalWidth()}
            bodyStyle={{
                backgroundColor: "#eff3f8",
                padding: "20px",
            }}
            cancelButtonProps={{
                icon: <CloseCircleOutlined />,
                size: "large",
            }}
            okButtonProps={{
                icon: <FileDoneOutlined />,
                size: "large",
            }}
            onCancel={handleCancelShop}
            onOk={handleSubmitShop}
            footer={
                <div className={Style.footer}>
                    <div className={Style.footer_space}></div>
                    <div className={Style.footer_count}>已选{selectList.length}条</div>
                    <Space>
                        <Button icon={<CloseCircleOutlined />} size="large" onClick={handleCancelShop}>
                            取消
                        </Button>
                        <Button loading={isLoad} icon={<FileDoneOutlined />} type="primary" size="large" onClick={handleSubmitShop}>
                            提交
                        </Button>
                    </Space>
                </div>
            }
        >
            <div style={{height: ChuangShiOS.Util.getModalHeight() + "px"}} className={Style.page}>
                <ChuangShiOS.Content
                    className={Style.content}
                    isLoad={isLoad}
                    isEmpty={false}
                    isError={isError}
                    marginLeft={0}
                    marginRight={0}
                    paddingTop={0}
                    paddingRight={0}
                    paddingBottom={0}
                    paddingLeft={0}
                >
                    <div className={Style.main}>
                        <Form form={form}>
                            <Row>
                                <Col span={6}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "none"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="门店名称"
                                        labelAlign={"left"}
                                        name="shopName"
                                        initialValue={""}
                                        rules={[
                                            {
                                                required: false,
                                                message: "请输入门店名称",
                                            },
                                        ]}
                                    >
                                        <Input className={Style.card_field} placeholder="请输入门店名称" onPressEnter={handleSearchShop} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "none"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="商户名称"
                                        labelAlign={"left"}
                                        name="merchantName"
                                        initialValue={""}
                                        rules={[
                                            {
                                                required: false,
                                                message: "请输入商户名称",
                                            },
                                        ]}
                                    >
                                        <Input className={Style.card_field} placeholder="请输入商户名称" onPressEnter={handleSearchShop} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "none"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="门店代码"
                                        labelAlign={"left"}
                                        name="shopCode"
                                        initialValue={""}
                                        rules={[
                                            {
                                                required: false,
                                                message: "请输入门店代码",
                                            },
                                        ]}
                                    >
                                        <Input className={Style.card_field} placeholder="请输入门店代码" onPressEnter={handleSearchShop} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Button loading={isLoad} icon={<FileSearchOutlined />} type="primary" onClick={handleSearchShop}>
                                        查询
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                        <div className={Style.card_table}>
                            <Table
                                size="small"
                                rowKey={(record) => record.shopId}
                                columns={[
                                    {
                                        title: "门店名称",
                                        dataIndex: "shopName",
                                    },
                                    {
                                        title: "商户名称",
                                        dataIndex: "merchantName",
                                    },
                                    {
                                        title: "门店代码",
                                        width: 100,
                                        dataIndex: "shopCode",
                                    },
                                    {
                                        title: "门店电话",
                                        dataIndex: "shopTel",
                                    },
                                ]}
                                dataSource={shopList}
                                rowSelection={{
                                    fixed: true,
                                    type: props.multiple ? "checkbox" : "radio",
                                    selectedRowKeys: shopIdList,
                                    onChange: handleSelectShop,
                                }}
                                onRow={(record) => {
                                    return {
                                        onClick: () => {
                                            handleClickShop(record);
                                        },
                                    };
                                }}
                                scroll={{y: ChuangShiOS.Util.getModalHeight() - 200}}
                                pagination={{
                                    size: "default",
                                    showQuickJumper: true,
                                    showSizeChanger: true,
                                    current: shopPageIndex,
                                    pageSize: shopPageSize,
                                    total: shopTotal,
                                    showTotal: (total, range) => "显示" + range[0] + "到" + range[1] + "条,共有" + total + "条",
                                    onChange: handleChangeShopPagination,
                                    onShowSizeChange: handleChangeShopPagination,
                                }}
                            />
                        </div>
                    </div>
                </ChuangShiOS.Content>
            </div>
        </Modal>
    );
};

ShopModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    multiple: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

ShopModal.defaultProps = {};

export default ShopModal;
