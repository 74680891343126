import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";
import {message, Modal, Space, Button, Form, Row, Col, Input, Table, Cascader} from "antd";
import {FileDoneOutlined, CloseCircleOutlined, FileSearchOutlined} from "@ant-design/icons";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./Modal.module.css";

const MerchantModal = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const [merchantPageIndex, setMerchantPageIndex] = useState(1);
    const [merchantPageSize, setMerchantPageSize] = useState(store.main.pageSize);
    const [merchantIdList, setMerchantIdList] = useState([]);
    const [merchantTotal, setMerchantTotal] = useState(0);
    const [merchantList, setMerchantList] = useState([]);
    const [selectList, setSelectList] = useState([]);
    const [cityList, setCityList] = useState([]);

    useEffect(() => {
        if (props.visible) {
            handleSearchMerchant();

            handleLoadCity();
        }

        //eslint-disable-next-line
    }, [props.visible]);

    const handleLoadCity = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/city/business/website/v1/cascader/list",
            data: {},
            success: (response) => {
                setCityList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleSearchMerchant = () => {
        handleLoadMerchant(1, merchantPageSize);
    };

    const handleLoadMerchant = (merchantPageIndex, merchantPageSize) => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        let data = form.getFieldsValue();
        if (data.provinceCity) {
            data.provinceId = data.provinceCity[0];
            data.cityId = data.provinceCity[1];
        }
        data.pageIndex = merchantPageIndex;
        data.pageSize = merchantPageSize;

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/merchant/business/website/v1/modal/list",
            data: data,
            success: (response) => {
                setMerchantPageIndex(merchantPageIndex);
                setMerchantPageSize(merchantPageSize);
                setMerchantIdList([]);
                setMerchantTotal(response.data.total);
                setMerchantList(response.data.list);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
                setIsError(
                    typeof response.data == "undefined" ||
                        response.data.total == "undefined" ||
                        response.data.total == null ||
                        response.data.list == "undefined" ||
                        response.data.list == null
                );
            },
        });
    };

    const handleChangeMerchantPagination = (merchantPageIndex, merchantPageSize) => {
        handleLoadMerchant(merchantPageIndex, merchantPageSize);
    };

    const handleSelectMerchant = (merchantIdList) => {
        let selectList = [];
        for (let i = 0; i < merchantList.length; i++) {
            let isExit = false;

            for (let j = 0; j < merchantIdList.length; j++) {
                if (merchantList[i].merchantId == merchantIdList[j]) {
                    isExit = true;

                    break;
                }
            }

            if (isExit) {
                selectList.push(merchantList[i]);
            }
        }

        setMerchantIdList(merchantIdList);
        setSelectList(selectList);
    };

    const handleClickMerchant = (merchant) => {
        let index = -1;

        for (let i = 0; i < merchantIdList.length; i++) {
            if (merchant.merchantId == merchantIdList[i]) {
                index = i;
                break;
            }
        }

        if (index == -1) {
            if (props.multiple) {
                merchantIdList.push(merchant.merchantId);
            } else {
                merchantIdList.splice(0, merchantIdList.length);
                merchantIdList.push(merchant.merchantId);
            }
        } else {
            merchantIdList.splice(index, 1);
        }

        let selectList = [];
        for (let i = 0; i < merchantList.length; i++) {
            let isExit = false;

            for (let j = 0; j < merchantIdList.length; j++) {
                if (merchantList[i].merchantId == merchantIdList[j]) {
                    isExit = true;

                    break;
                }
            }

            if (isExit) {
                selectList.push(merchantList[i]);
            }
        }

        setMerchantIdList([...merchantIdList]);
        setSelectList(selectList);
    };

    const handleCancelMerchant = () => {
        setMerchantIdList([]);
        setSelectList([]);

        props.onClose();
    };

    const handleSubmitMerchant = () => {
        if (selectList.length > 0) {
            setMerchantIdList([]);
            setSelectList([]);

            if (props.multiple) {
                props.onSubmit(selectList);
            } else {
                props.onSubmit(selectList[0]);
            }
        }

        props.onClose();
    };

    return (
        <Modal
            title="商户选择"
            visible={props.visible}
            centered
            width={ChuangShiOS.Util.getModalWidth()}
            bodyStyle={{
                backgroundColor: "#eff3f8",
                padding: "20px",
            }}
            cancelButtonProps={{
                icon: <CloseCircleOutlined />,
                size: "large",
            }}
            okButtonProps={{
                icon: <FileDoneOutlined />,
                size: "large",
            }}
            onCancel={handleCancelMerchant}
            onOk={handleSubmitMerchant}
            footer={
                <div className={Style.footer}>
                    <div className={Style.footer_space}></div>
                    <div className={Style.footer_count}>已选{selectList.length}条</div>
                    <Space>
                        <Button icon={<CloseCircleOutlined />} size="large" onClick={handleCancelMerchant}>
                            取消
                        </Button>
                        <Button loading={isLoad} icon={<FileDoneOutlined />} type="primary" size="large" onClick={handleSubmitMerchant}>
                            提交
                        </Button>
                    </Space>
                </div>
            }
        >
            <div style={{height: ChuangShiOS.Util.getModalHeight() + "px"}} className={Style.page}>
                <ChuangShiOS.Content
                    className={Style.content}
                    isLoad={isLoad}
                    isEmpty={false}
                    isError={isError}
                    marginLeft={0}
                    marginRight={0}
                    paddingTop={0}
                    paddingRight={0}
                    paddingBottom={0}
                    paddingLeft={0}
                >
                    <div className={Style.main}>
                        <Form form={form}>
                            <Row>
                                <Col span={6}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "none"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="商户名称"
                                        labelAlign={"left"}
                                        name="merchantName"
                                        initialValue={""}
                                        rules={[
                                            {
                                                required: false,
                                                message: "请输入商户名称",
                                            },
                                        ]}
                                    >
                                        <Input className={Style.card_field} placeholder="请输入商户名称" onPressEnter={handleSearchMerchant} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "none"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="商户代码"
                                        labelAlign={"left"}
                                        name="merchantCode"
                                        initialValue={""}
                                        rules={[
                                            {
                                                required: false,
                                                message: "请输入商户代码",
                                            },
                                        ]}
                                    >
                                        <Input className={Style.card_field} placeholder="请输入商户代码" onPressEnter={handleSearchMerchant} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "none"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="城市名称"
                                        labelAlign={"left"}
                                        name="provinceCity"
                                        initialValue={null}
                                        rules={[
                                            {
                                                required: false,
                                                message: "请选择城市名称",
                                            },
                                        ]}
                                    >
                                        <Cascader
                                            className={Style.card_field}
                                            allowClear
                                            options={cityList}
                                            placeholder="请选择城市名称"
                                            onChange={(value) => {
                                                if (value && value.length > 0) {
                                                } else {
                                                    form.setFieldsValue({
                                                        provinceCity: null,
                                                    });
                                                }
                                                handleSearchMerchant();
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Button loading={isLoad} icon={<FileSearchOutlined />} type="primary" onClick={handleSearchMerchant}>
                                        查询
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                        <div className={Style.card_table}>
                            <Table
                                size="small"
                                rowKey={(record) => record.merchantId}
                                columns={[
                                    {
                                        title: "商户名称",
                                        dataIndex: "merchantName",
                                    },
                                    {
                                        title: "商户代码",
                                        dataIndex: "merchantCode",
                                    },
                                    {
                                        title: "城市名称",
                                        dataIndex: "cityName",
                                    },
                                    {
                                        title: "品牌名称",
                                        dataIndex: "brandName",
                                    },
                                ]}
                                dataSource={merchantList}
                                rowSelection={{
                                    fixed: true,
                                    type: props.multiple ? "checkbox" : "radio",
                                    selectedRowKeys: merchantIdList,
                                    onChange: handleSelectMerchant,
                                }}
                                onRow={(record) => {
                                    return {
                                        onClick: () => {
                                            handleClickMerchant(record);
                                        },
                                    };
                                }}
                                scroll={{y: ChuangShiOS.Util.getModalHeight() - 200}}
                                pagination={{
                                    size: "default",
                                    showQuickJumper: true,
                                    showSizeChanger: true,
                                    current: merchantPageIndex,
                                    pageSize: merchantPageSize,
                                    total: merchantTotal,
                                    showTotal: (total, range) => "显示" + range[0] + "到" + range[1] + "条,共有" + total + "条",
                                    onChange: handleChangeMerchantPagination,
                                    onShowSizeChange: handleChangeMerchantPagination,
                                }}
                            />
                        </div>
                    </div>
                </ChuangShiOS.Content>
            </div>
        </Modal>
    );
};

MerchantModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    multiple: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

MerchantModal.defaultProps = {};

export default MerchantModal;
