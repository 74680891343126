import React, {useState, useRef, useEffect} from "react";
import {message, Form, Row, Col, Input, Button} from "antd";
import {UserOutlined, LockOutlined, SafetyCertificateOutlined} from "@ant-design/icons";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./Index.module.css";

const LoginIndex = (props) => {
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [captchaKey, setCaptchaKey] = useState("");
    const [captchaImage, setCaptchaImage] = useState("");

    useEffect(() => {
        handleLoadCaptcha();

        //eslint-disable-next-line
    }, []);

    const handleLoadCaptcha = () => {
        form.setFieldsValue({
            captchaCode: "",
        });

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/user/business/website/v1/captcha",
            data: {},
            success: (response) => {
                setCaptchaKey(response.data.captchaKey);
                setCaptchaImage(response.data.captchaImage);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleSubmitLogin = () => {
        if (request.current) {
            return;
        }

        form.validateFields()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                data.captchaKey = captchaKey;

                ChuangShiOS.Util.request(props, {
                    url: Application.host + "/windeal/user/business/website/v1/login",
                    data: data,
                    success: (response) => {
                        ChuangShiOS.Util.setToken(response.data.token);

                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        ChuangShiOS.Util.redirectTo(props, {
                            url: Application.homePath,
                        });
                    },
                    error: (response) => {
                        handleLoadCaptcha();

                        request.current = false;

                        setIsLoad(false);

                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });
                    },
                    complete: (response) => {},
                });
            })
            .catch((response) => {
                ChuangShiOS.Util.parseResponseError(response);
            });
    };

    return (
        <div className={Style.page}>
            <ChuangShiOS.Content isLoad={isLoad} isEmpty={false}>
                <div className={Style.login}>
                    <div className={Style.image}></div>
                    <Form form={form}>
                        <div className={Style.form}>
                            <div className={Style.title}>我德优惠券电子平台管理系统</div>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "12%"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        name="userAccount"
                                        initialValue={""}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入登录账号",
                                            },
                                        ]}
                                    >
                                        <Input
                                            prefix={<UserOutlined className={Style.card_icon} />}
                                            placeholder="请输入登录账号"
                                            size="large"
                                            onPressEnter={handleSubmitLogin}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "12%"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        name="userPassword"
                                        initialValue={""}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入登录密码",
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            prefix={<LockOutlined className={Style.card_icon} />}
                                            placeholder="请输入登录密码"
                                            size="large"
                                            onPressEnter={handleSubmitLogin}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col flex={1}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "12%"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        name="captchaCode"
                                        initialValue={""}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入验证码",
                                            },
                                        ]}
                                    >
                                        <Input
                                            prefix={<SafetyCertificateOutlined className={Style.card_icon} />}
                                            placeholder="请输入验证码"
                                            size="large"
                                            onPressEnter={handleSubmitLogin}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col onClick={handleLoadCaptcha} style={{cursor: "pointer"}}>
                                    {captchaImage ? <img src={"data:image/png;base64," + captchaImage} style={{height: "40px"}} alt="" /> : null}
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Button type="primary" size="large" block onClick={handleSubmitLogin}>
                                        登录
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </div>
                <div className={Style.footer}>
                    Copyright©2020 我德优惠券电子平台管理系统{" "}
                    <a href="https://beian.miit.gov.cn/" target="_blank">
                        沪ICP备12040508号-1
                    </a>
                </div>
            </ChuangShiOS.Content>
        </div>
    );
};

LoginIndex.propTypes = {};

LoginIndex.defaultProps = {};

export default LoginIndex;
