import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {message, Modal, Breadcrumb, Form, Row, Col, DatePicker, TimePicker, Space, Button, Divider, Switch, Table} from "antd";
import {LeftCircleOutlined, FileDoneOutlined, PlusCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";
import moment from "moment";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import ShopModal from "../shop/Modal";

import Style from "./Detail.module.css";

const CalendarDetail = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [calendarIsAll, setCalendarIsAll] = useState(true);
    const [isCalendarShopList, setIsCalendarShopList] = useState(false);
    const [calendarShopList, setCalendarShopList] = useState([]);
    const [systemVersion, setSystemVersion] = useState(0);

    useEffect(() => {
        if (store.main.userName) {
            ChuangShiOS.Notification.emit("main_menu_active", {url: "/calendar/index"});

            switch (props.match.path) {
                case "/calendar/add":
                    setIsSave(true);

                    break;
                case "/calendar/edit/:calendarId":
                    setIsEdit(true);

                    handleLoadCalendar();

                    break;
                default:
                    break;
            }
        }

        //eslint-disable-next-line
    }, [store.main.userName]);

    const handleLoadCalendar = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/calendar/business/website/v1/find",
            data: {
                calendarId: props.match.params.calendarId,
            },
            success: (response) => {
                response.data.calendarDate = moment(response.data.calendarDate, "YYYY-MM-DD");

                for (let i = 0; i < response.data.calendarShopList.length; i++) {
                    response.data.calendarShopList[i].restStart = moment(response.data.calendarShopList[i].restStart, "HH:mm");
                    response.data.calendarShopList[i].restEnd = moment(response.data.calendarShopList[i].restEnd, "HH:mm");
                }

                form.setFieldsValue(response.data);

                setCalendarIsAll(response.data.calendarIsAll);
                setCalendarShopList(response.data.calendarShopList);
                setSystemVersion(response.data.systemVersion);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
                setIsError(typeof response.data == "undefined" || typeof response.data.calendarId == "undefined" || response.data.calendarId == null);
            },
        });
    };

    const handleSubmitCalendar = () => {
        for (let i = 0; i < calendarShopList.length; i++) {
            if (!calendarShopList[i].restStart) {
                message.error({
                    content: calendarShopList[i].shopName + "休息开始不能为空",
                    duration: 2,
                });

                return;
            }
            if (!calendarShopList[i].restEnd) {
                message.error({
                    content: calendarShopList[i].shopName + "休息结束不能为空",
                    duration: 2,
                });

                return;
            }

            if (calendarShopList[i].restStart.isAfter(calendarShopList[i].restEnd)) {
                message.error({
                    content: calendarShopList[i].shopName + "休息开始大于休息结束",
                    duration: 2,
                });

                return;
            }
        }

        if (request.current) {
            return;
        }

        form.validateFields()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                let shopList = JSON.parse(JSON.stringify(calendarShopList));
                for (let i = 0; i < shopList.length; i++) {
                    delete shopList[i].merchantName;
                    delete shopList[i].shopName;
                    shopList[i].restStart = moment(shopList[i].restStart).format("HH:mm");
                    shopList[i].restEnd = moment(shopList[i].restEnd).format("HH:mm");
                }

                data.calendarId = props.match.params.calendarId;
                let calendarRange = [];
                if (data.calendarRange) {
                    for (let i = 0; i < data.calendarRange.length; i++) {
                        calendarRange.push(data.calendarRange[i].format("YYYY-MM-DD"));
                    }
                }
                data.calendarRange = calendarRange;
                data.calendarShopList = shopList;
                data.systemVersion = systemVersion;

                let url = "";
                if (isSave) {
                    url = "/windeal/calendar/business/website/v1/save";
                }
                if (isEdit) {
                    url = "/windeal/calendar/business/website/v1/update";
                }

                ChuangShiOS.Util.request(props, {
                    url: Application.host + url,
                    data: data,
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);

                        ChuangShiOS.Util.navigateBack(props, {});
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            })
            .catch((response) => {
                ChuangShiOS.Util.parseResponseError(response);
            });
    };

    const handleBackCalendar = () => {
        ChuangShiOS.Util.navigateBack(props, {});
    };

    const handleOpenShop = () => {
        setIsCalendarShopList(true);
    };

    const handleCloseShop = () => {
        setIsCalendarShopList(false);
    };

    const handleChangeShop = (event, index, name) => {
        if (name == "restStart" || name == "restEnd") {
            calendarShopList[index][name] = event;
        } else {
            calendarShopList[index][name] = event.target.value;
        }

        setCalendarShopList([...calendarShopList]);
    };

    const handleDeleteShop = (index) => {
        Modal.confirm({
            title: "是否确认操作？",
            content: "本次数据操作后将无法恢复",
            okText: "确认",
            cancelText: "取消",
            onOk: () => {
                calendarShopList.splice(index, 1);

                setCalendarShopList([...calendarShopList]);
            },
        });
    };

    const handleSubmitShop = (list) => {
        for (let i = 0; i < list.length; i++) {
            let isExit = false;
            for (let j = 0; j < calendarShopList.length; j++) {
                if (list[i].shopId == calendarShopList[j].shopId) {
                    isExit = true;

                    break;
                }
            }

            if (!isExit) {
                calendarShopList.push({
                    shopId: list[i].shopId,
                    shopName: list[i].shopName,
                    restStart: "",
                    restEnd: "",
                });
            }
        }

        setCalendarShopList([...calendarShopList]);
    };

    return (
        <div className={Style.page}>
            <div className={Style.header}>
                <div className={Style.header_title}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <span>首页</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span>{store.main.menuName}</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className={Style.header_title_text}>{store.main.sidebarName}</div>
                </div>
                <div className={Style.header_button}>
                    <Space>
                        <Button.Group>
                            <Button icon={<LeftCircleOutlined />} size="large" onClick={handleBackCalendar}>
                                返回
                            </Button>
                        </Button.Group>
                        <Button
                            loading={isLoad}
                            disabled={isError}
                            icon={<FileDoneOutlined />}
                            type="primary"
                            size="large"
                            onClick={handleSubmitCalendar}
                        >
                            提交
                        </Button>
                    </Space>
                </div>
            </div>
            <ChuangShiOS.Content
                className={Style.content}
                isLoad={isLoad}
                isEmpty={false}
                isError={isError}
                paddingTop={28}
                isScroll={store.main.isFixedContent}
            >
                <Row>
                    <Col flex="auto">
                        <div className={Style.card_title}>基本信息</div>
                    </Col>
                    <Col></Col>
                </Row>
                <Form form={form}>
                    {isSave ? (
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "24%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="日历日期"
                                    name="calendarRange"
                                    initialValue={[]}
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择日历日期",
                                        },
                                    ]}
                                >
                                    <DatePicker.RangePicker
                                        className={Style.card_field}
                                        format="YYYY-MM-DD"
                                        disabled={isEdit}
                                        onPressEnter={handleSubmitCalendar}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "24%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="日历日期"
                                    name="calendarDate"
                                    initialValue={""}
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择日历日期",
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        className={Style.card_field}
                                        format="YYYY-MM-DD"
                                        disabled={isEdit}
                                        onPressEnter={handleSubmitCalendar}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="全部门店"
                                name="calendarIsAll"
                                initialValue={true}
                                valuePropName="checked"
                                rules={[
                                    {
                                        required: false,
                                        message: "请选择全部门店",
                                    },
                                ]}
                            >
                                <Switch
                                    checkedChildren="开启"
                                    unCheckedChildren="关闭"
                                    onChange={(value) => {
                                        setCalendarIsAll(value);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {calendarIsAll ? null : (
                    <React.Fragment>
                        <Divider />
                        <Row>
                            <Col flex="auto">
                                <div className={Style.card_title}>门店信息</div>
                            </Col>
                            <Col>
                                <Button
                                    icon={<PlusCircleOutlined />}
                                    onClick={() => {
                                        handleOpenShop();
                                    }}
                                >
                                    新增
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col flex={"12%"} className={Style.card_label}>
                                排除门店
                            </Col>
                            <Col flex="auto" className={Style.card_value}>
                                <Table
                                    className={Style.card_table}
                                    size="large"
                                    rowKey={(record) => record.shopId}
                                    columns={[
                                        {
                                            title: "商户名称",
                                            dataIndex: "merchantName",
                                        },
                                        {
                                            title: "门店名称",
                                            dataIndex: "shopName",
                                        },
                                        {
                                            title: "休息开始",
                                            render: (text, record, recordIndex) => (
                                                <TimePicker
                                                    value={record.restStart}
                                                    className={Style.card_field}
                                                    showNow={false}
                                                    format="HH:mm"
                                                    placeholder="请选择休息开始"
                                                    onChange={(value) => {
                                                        handleChangeShop(value, recordIndex, "restStart");
                                                    }}
                                                />
                                            ),
                                        },
                                        {
                                            title: "休息结束",
                                            render: (text, record, recordIndex) => (
                                                <TimePicker
                                                    value={record.restEnd}
                                                    className={Style.card_field}
                                                    showNow={false}
                                                    format="HH:mm"
                                                    placeholder="请选择休息结束"
                                                    onChange={(value) => {
                                                        handleChangeShop(value, recordIndex, "restEnd");
                                                    }}
                                                />
                                            ),
                                        },
                                        {
                                            title: "操作",
                                            width: 150,
                                            render: (text, record, recordIndex) => (
                                                <div className={Style.card_table_row}>
                                                    <Button
                                                        icon={<CloseCircleOutlined />}
                                                        onClick={() => {
                                                            handleDeleteShop(recordIndex);
                                                        }}
                                                    >
                                                        删除
                                                    </Button>
                                                </div>
                                            ),
                                        },
                                    ]}
                                    dataSource={calendarShopList}
                                    pagination={false}
                                />
                                <br />
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
            </ChuangShiOS.Content>
            <div className={Style.footer}></div>
            <ShopModal visible={isCalendarShopList} multiple={true} onClose={handleCloseShop} onSubmit={handleSubmitShop}></ShopModal>
        </div>
    );
};

CalendarDetail.propTypes = {};

CalendarDetail.defaultProps = {};

export default CalendarDetail;
