import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {useActivate, useUnactivate} from "react-activation";
import {message, Modal, Breadcrumb, Space, Button, Form, Row, Col, Input, Table, Select} from "antd";
import {FileAddOutlined, DeleteOutlined, CloseCircleOutlined, FileSearchOutlined, FileTextOutlined} from "@ant-design/icons";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./Index.module.css";

const ActivityNoticeIndex = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const [tableHeight, setTableHeight] = useState(0);
    const [activityNoticePageIndex, setActivityNoticePageIndex] = useState(1);
    const [activityNoticePageSize, setActivityNoticePageSize] = useState(store.main.pageSize);
    const [activityNoticeIdList, setActivityNoticeIdList] = useState([]);
    const [activityNoticeTotal, setActivityNoticeTotal] = useState(0);
    const [activityNoticeList, setActivityNoticeList] = useState([]);
    const [activityList, setActivityList] = useState([]);

    useEffect(() => {
        if (store.main.userName) {
            ChuangShiOS.Notification.emit("main_menu_active", {url: "/activity/notice/index"});

            handleLoadActivity();

            handleSearchActivityNotice();
        }

        //eslint-disable-next-line
    }, [store.main.userName]);

    useEffect(() => {
        handleLoadActivityNotice(activityNoticePageIndex, store.main.pageSize);

        //eslint-disable-next-line
    }, [store.main.pageSize]);

    useActivate(() => {
        ChuangShiOS.Notification.emit("main_set_scroll_top", {});

        handleLoadActivityNotice(activityNoticePageIndex, activityNoticePageSize);
    });

    useUnactivate(() => {
        ChuangShiOS.Notification.emit("main_get_scroll_top", {});
    });

    const handleLoadActivity = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/activity/business/website/v1/select/list",
            data: {},
            success: (response) => {
                setActivityList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleSearchActivityNotice = () => {
        handleLoadActivityNotice(1, activityNoticePageSize);
    };

    const handleLoadActivityNotice = (activityNoticePageIndex, activityNoticePageSize) => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        let data = form.getFieldsValue();
        data.pageIndex = activityNoticePageIndex;
        data.pageSize = activityNoticePageSize;

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/activity/notice/business/website/v1/list",
            data: data,
            success: (response) => {
                setActivityNoticePageIndex(activityNoticePageIndex);
                setActivityNoticePageSize(activityNoticePageSize);
                setActivityNoticeIdList([]);
                setActivityNoticeTotal(response.data.total);
                setActivityNoticeList(response.data.list);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
                setIsError(
                    typeof response.data == "undefined" ||
                        response.data.total == "undefined" ||
                        response.data.total == null ||
                        response.data.list == "undefined" ||
                        response.data.list == null
                );
            },
        });
    };

    const handleAddActivityNotice = () => {
        ChuangShiOS.Util.navigateTo(props, {
            url: "/activity/notice/add",
        });
    };

    const handleEditActivityNotice = (activityNoticeId) => {
        ChuangShiOS.Util.navigateTo(props, {
            url: "/activity/notice/edit/" + activityNoticeId,
        });
    };

    const handleDelActivityNotice = () => {
        if (activityNoticeIdList.length == 0) {
            return;
        }

        if (request.current) {
            return;
        }

        Modal.confirm({
            title: "是否确认操作？",
            content: "本次数据操作后将无法恢复",
            okText: "确认",
            okType: "danger",
            okButtonProps: {
                icon: <DeleteOutlined />,
            },
            cancelText: "取消",
            cancelButtonProps: {
                icon: <CloseCircleOutlined />,
            },
            onOk: () => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                ChuangShiOS.Util.request(props, {
                    url: Application.host + "/windeal/activity/notice/business/website/v1/delete",
                    data: {
                        activityNoticeIdList: activityNoticeIdList,
                    },
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                        setActivityNoticeIdList([]);

                        handleLoadActivityNotice(activityNoticePageIndex, activityNoticePageSize);
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            },
        });
    };

    const handleChangeActivityNoticeIdList = (activityNoticeIdList) => {
        setActivityNoticeIdList(activityNoticeIdList);
    };

    const handleChangeActivityNoticePagination = (activityNoticePageIndex, activityNoticePageSize) => {
        handleLoadActivityNotice(activityNoticePageIndex, activityNoticePageSize);
    };

    return (
        <div className={Style.page}>
            <div className={Style.header}>
                <div className={Style.header_title}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <span>首页</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span>{store.main.menuName}</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className={Style.header_title_text}>{store.main.sidebarName}</div>
                </div>
                <div className={Style.header_button}>
                    <Space>
                        <Button.Group>
                            <Button icon={<FileAddOutlined />} size="large" onClick={handleAddActivityNotice}>
                                新增
                            </Button>
                            <Button
                                loading={isLoad}
                                disabled={activityNoticeIdList.length == 0}
                                icon={<DeleteOutlined />}
                                size="large"
                                onClick={handleDelActivityNotice}
                            >
                                删除
                            </Button>
                        </Button.Group>
                        <Button loading={isLoad} icon={<FileSearchOutlined />} type="primary" size="large" onClick={handleSearchActivityNotice}>
                            查询
                        </Button>
                    </Space>
                </div>
            </div>
            <ChuangShiOS.Content className={Style.content} isLoad={isLoad} isEmpty={false} isError={isError} isScroll={store.main.isFixedContent}>
                <Form form={form}>
                    <Row>
                        <Col span={6}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "none"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="活动名称"
                                labelAlign={"left"}
                                name="activityId"
                                initialValue={null}
                                rules={[
                                    {
                                        required: false,
                                        message: "请选择活动名称",
                                    },
                                ]}
                            >
                                <Select
                                    className={Style.card_field}
                                    placeholder="请选择活动名称"
                                    allowClear
                                    onChange={(value) => {
                                        handleSearchActivityNotice();
                                    }}
                                >
                                    {activityList.map((activity, activityIndex) => {
                                        return (
                                            <Select.Option key={activityIndex} value={activity.activityId}>
                                                {activity.activityName}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "none"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="通告标题"
                                labelAlign={"left"}
                                name="activityNoticeTitle"
                                initialValue={""}
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入通告标题",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入通告标题" onPressEnter={handleSearchActivityNotice} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div
                    ref={(ref) => {
                        if (ref != null && tableHeight == 0 && tableHeight != ref.clientHeight) {
                            setTableHeight(ref.clientHeight);
                        }
                    }}
                    className={Style.card_table}
                >
                    <Table
                        size="small"
                        rowKey={(record) => record.activityNoticeId}
                        columns={[
                            {
                                title: "活动名称",
                                dataIndex: "activityNameList",
                                render: (text, record, recordIndex) => (
                                    <div>
                                        {record.activityNameList.map((activityName, activityNameIndex) => {
                                            return <div key={activityNameIndex}>{activityName}</div>;
                                        })}
                                    </div>
                                ),
                            },
                            {
                                title: "通告类型",
                                width: 80,
                                dataIndex: "activityNoticeType",
                            },
                            {
                                title: "通告标题",
                                dataIndex: "activityNoticeTitle",
                            },
                            {
                                title: "操作",
                                width: 100,
                                render: (text, record, recordIndex) => (
                                    <Space>
                                        <Button
                                            icon={<FileTextOutlined />}
                                            size="default"
                                            onClick={() => handleEditActivityNotice(record.activityNoticeId)}
                                        >
                                            修改
                                        </Button>
                                    </Space>
                                ),
                            },
                        ]}
                        dataSource={activityNoticeList}
                        rowSelection={{
                            fixed: true,
                            selectedRowKeys: activityNoticeIdList,
                            onChange: handleChangeActivityNoticeIdList,
                        }}
                        scroll={store.main.isFixedContent ? {y: tableHeight - 130} : {}}
                        pagination={{
                            size: "default",
                            showQuickJumper: true,
                            showSizeChanger: true,
                            current: activityNoticePageIndex,
                            pageSize: activityNoticePageSize,
                            total: activityNoticeTotal,
                            showTotal: (total, range) => "显示" + range[0] + "到" + range[1] + "条,共有" + total + "条",
                            onChange: handleChangeActivityNoticePagination,
                            onShowSizeChange: handleChangeActivityNoticePagination,
                        }}
                    />
                </div>
            </ChuangShiOS.Content>
            <div className={Style.footer}></div>
        </div>
    );
};

ActivityNoticeIndex.propTypes = {};

ActivityNoticeIndex.defaultProps = {};

export default ActivityNoticeIndex;
