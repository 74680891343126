import React from "react";
import {Route} from "react-router-dom";
import KeepAlive from "react-activation";
import ChuangShiOS from "chuangshi-os-website-component";

import ShopIndex from "./Index";
import ShopDetail from "./Detail";

const data = [
    <Route
        exact
        key="ShopIndex"
        path="/shop/index"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return (
                <KeepAlive name="/shop/index">
                    <ShopIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="ShopAdd"
        path="/shop/add"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <ShopDetail {...props} />;
        }}
    />,
    <Route
        exact
        key="ShopEdit"
        path="/shop/edit/:shopId"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <ShopDetail {...props} />;
        }}
    />,
];

export default data;
