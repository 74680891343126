import React from "react";
import {Route} from "react-router-dom";
import KeepAlive from "react-activation";
import ChuangShiOS from "chuangshi-os-website-component";

import SpecialIndex from "./Index";
import SpecialDetail from "./Detail";

const data = [
    <Route
        exact
        key="SpecialIndex"
        path="/special/index"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return (
                <KeepAlive name="/special/index">
                    <SpecialIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="SpecialAdd"
        path="/special/add"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <SpecialDetail {...props} />;
        }}
    />,
    <Route
        exact
        key="SpecialEdit"
        path="/special/edit/:specialId"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <SpecialDetail {...props} />;
        }}
    />,
];

export default data;
