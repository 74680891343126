import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {
    message,
    Modal,
    Breadcrumb,
    Form,
    Row,
    Col,
    Input,
    InputNumber,
    TimePicker,
    Space,
    Button,
    Select,
    Radio,
    Cascader,
    Checkbox,
    Table,
    Card,
} from "antd";
import {LeftCircleOutlined, FileDoneOutlined, PlusCircleOutlined, CloseCircleOutlined, FileSearchOutlined, FileTextOutlined} from "@ant-design/icons";
import moment from "moment";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import SkuModal from "./SkuModal";
import TaskModal from "./TaskModal";

import Style from "./Detail.module.css";

const GoodsDetail = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [goodsType, setGoodsType] = useState("HOTEL");
    const [systemVersion, setSystemVersion] = useState(0);
    const [cityList, setCityList] = useState([]);
    const [merchantList, setMerchantList] = useState([]);
    const [shopList, setShopList] = useState([]);
    const [goodsCategoryId, setGoodsCategoryId] = useState("");
    const [goodsCategoryList, setGoodsCategoryList] = useState([]);
    const [goodsCategoryName, setGoodsCategoryName] = useState("");
    const [goodsSku, setGoodsSku] = useState({});
    const [isGoodsSku, setIsGoodsSku] = useState(false);
    const [goodsSkuList, setGoodsSkuList] = useState([]);
    const [goodsHistoryList, setGoodsHistoryList] = useState([]);
    const [isGoodsTask, setIsGoodsTask] = useState(false);
    const [goodsTask, setGoodsTask] = useState({});
    const [goodsTaskList, setGoodsTaskList] = useState([]);

    useEffect(() => {
        if (store.main.userName) {
            ChuangShiOS.Notification.emit("main_menu_active", {url: "/goods/index"});

            ChuangShiOS.Notification.emit("main_scroll_top", {top: 0});

            handleLoadCity();

            handleLoadGoodsCategory();

            switch (props.match.path) {
                case "/goods/add":
                    setIsSave(true);

                    break;
                case "/goods/edit/:goodsId":
                    setIsEdit(true);

                    handleLoadGoods();

                    break;
                default:
                    break;
            }
        }

        //eslint-disable-next-line
    }, [store.main.userName]);

    const handleLoadCity = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/city/business/website/v1/cascader/list",
            data: {},
            success: (response) => {
                setCityList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadMerchant = (cityId) => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/merchant/business/website/v1/city/list",
            data: {
                cityId: cityId,
            },
            success: (response) => {
                setMerchantList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadShop = (merchantId) => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/shop/business/website/v1/merchant/list",
            data: {
                merchantId: merchantId,
            },
            success: (response) => {
                setShopList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadGoodsCategory = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/goods/category/business/website/v1/time/select/list",
            data: {},
            success: (response) => {
                setGoodsCategoryList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadGoods = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/goods/business/website/v1/find",
            data: {
                goodsId: props.match.params.goodsId,
            },
            success: (response) => {
                response.data.provinceCity = [response.data.provinceId, response.data.cityId];
                response.data.goodsReserveStartTime = moment(response.data.goodsReserveStartTime, "HH:mm");
                response.data.goodsReserveEndTime = moment(response.data.goodsReserveEndTime, "HH:mm");

                form.setFieldsValue(response.data);

                setGoodsCategoryId(response.data.goodsCategoryId);
                setSystemVersion(response.data.systemVersion);
                setGoodsSkuList(response.data.goodsSkuList);
                setGoodsTaskList(response.data.goodsTaskList);
                setGoodsHistoryList(response.data.goodsHistoryList);

                handleLoadMerchant(response.data.cityId);

                handleLoadShop(response.data.merchantId);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
                setIsError(typeof response.data == "undefined" || typeof response.data.goodsId == "undefined" || response.data.goodsId == null);
            },
        });
    };

    const handleSubmitGoods = () => {
        if (request.current) {
            return;
        }

        form.validateFields()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                data.goodsId = props.match.params.goodsId;
                data.goodsReserveStartTime = data.goodsReserveStartTime.format("HH:mm");
                data.goodsReserveEndTime = data.goodsReserveEndTime.format("HH:mm");
                data.systemVersion = systemVersion;

                let url = "";
                if (isSave) {
                    url = "/windeal/goods/business/website/v1/save";
                }
                if (isEdit) {
                    url = "/windeal/goods/business/website/v1/update";
                }

                ChuangShiOS.Util.request(props, {
                    url: Application.host + url,
                    data: data,
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);

                        ChuangShiOS.Util.navigateBack(props, {});
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            })
            .catch((response) => {
                ChuangShiOS.Util.parseResponseError(response);
            });
    };

    const handleBackGoods = () => {
        ChuangShiOS.Util.navigateBack(props, {});
    };

    const handleOpenGoodsSku = (goodsSku) => {
        let goodsCategoryId = form.getFieldValue("goodsCategoryId");
        let goodsCategoryName = "";
        for (let i = 0; i < goodsCategoryList.length; i++) {
            if (goodsCategoryId == goodsCategoryList[i].goodsCategoryId) {
                goodsCategoryName = goodsCategoryList[i].goodsCategoryName;

                break;
            }
        }

        goodsSku.goodsId = props.match.params.goodsId;

        setGoodsCategoryName(goodsCategoryName);
        setGoodsSku(goodsSku);
        setIsGoodsSku(true);
    };

    const handleCloseGoodsSku = () => {
        setIsGoodsSku(false);
    };

    const handleSubmitGoodsSku = () => {
        setIsGoodsSku(false);

        handleLoadGoodsSku();
    };

    const handleLoadGoodsSku = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/goods/sku/business/website/v1/list",
            data: {
                goodsId: props.match.params.goodsId,
            },
            success: (response) => {
                setGoodsSkuList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
            },
        });
    };

    const handleDeleteGoodsSku = (goodsSkuId) => {
        if (request.current) {
            return;
        }

        Modal.confirm({
            title: "是否确认操作？",
            content: "本次数据操作后将无法恢复",
            okText: "确认",
            cancelText: "取消",
            onOk: () => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                ChuangShiOS.Util.request(props, {
                    url: Application.host + "/windeal/goods/sku/business/website/v1/delete",
                    data: {
                        goodsSkuId: goodsSkuId,
                    },
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);

                        handleLoadGoodsSku();
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            },
        });
    };

    const handleOpenGoodsTask = (goodsTask) => {
        setIsGoodsTask(true);
        setGoodsTask(goodsTask);
    };

    const handleCloseGoodsTask = () => {
        setIsGoodsTask(false);
    };

    const handleSubmitGoodsTask = () => {
        setIsGoodsTask(false);

        handleLoadGoodsTask();
    };

    const handleLoadGoodsTask = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/goods/task/business/website/v1/list",
            data: {
                goodsId: props.match.params.goodsId,
            },
            success: (response) => {
                setGoodsTaskList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
            },
        });
    };

    const handleDeleteGoodsTask = (goodsTaskId) => {
        if (request.current) {
            return;
        }

        Modal.confirm({
            title: "是否确认操作？",
            content: "本次数据操作后将无法恢复",
            okText: "确认",
            cancelText: "取消",
            onOk: () => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                ChuangShiOS.Util.request(props, {
                    url: Application.host + "/windeal/goods/task/business/website/v1/delete",
                    data: {
                        goodsTaskId: goodsTaskId,
                    },
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);

                        handleLoadGoodsTask();
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            },
        });
    };

    return (
        <div className={Style.page}>
            <div className={Style.header}>
                <div className={Style.header_title}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <span>首页</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span>{store.main.menuName}</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className={Style.header_title_text}>{store.main.sidebarName}</div>
                </div>
                <div className={Style.header_button}>
                    <Space>
                        <Button.Group>
                            <Button icon={<LeftCircleOutlined />} size="large" onClick={handleBackGoods}>
                                返回
                            </Button>
                        </Button.Group>
                        <Button
                            loading={isLoad}
                            disabled={isError}
                            icon={<FileDoneOutlined />}
                            type="primary"
                            size="large"
                            onClick={handleSubmitGoods}
                        >
                            提交
                        </Button>
                    </Space>
                </div>
            </div>
            <ChuangShiOS.Content
                className={Style.content}
                isLoad={isLoad}
                isEmpty={false}
                isError={isError}
                paddingTop={0}
                paddingRight={0}
                paddingBottom={0}
                paddingLeft={0}
                isScroll={store.main.isFixedContent}
            >
                <Card>
                    <Row>
                        <Col flex="auto">
                            <div className={Style.card_title}>基本信息</div>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Form form={form}>
                        <Row style={{display: "none"}}>
                            <Col span={12}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "24%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="商品类型"
                                    name="goodsType"
                                    initialValue={"HOTEL"}
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入商品类型",
                                        },
                                    ]}
                                >
                                    <Radio.Group
                                        disabled={isEdit}
                                        onChange={(event) => {
                                            setGoodsType(event.target.value);
                                        }}
                                    >
                                        <Radio value={"HOTEL"}>酒店商品</Radio>
                                        <Radio disabled value={"ELECTRONIC"}>
                                            电子卡卷
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        {goodsType == "HOTEL" ? (
                            <React.Fragment>
                                <Row>
                                    <Col span={12}>
                                        <Form.Item
                                            {...{
                                                labelCol: {flex: "24%"},
                                                wrapperCol: {flex: "auto"},
                                            }}
                                            label="省份城市"
                                            name="provinceCity"
                                            initialValue={null}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请选择省份城市",
                                                },
                                            ]}
                                        >
                                            <Cascader
                                                className={Style.card_field}
                                                allowClear
                                                options={cityList}
                                                placeholder="请选择省份城市"
                                                onChange={(event) => {
                                                    if (event.length > 0) {
                                                        handleLoadMerchant(event[1]);
                                                    } else {
                                                        setMerchantList([]);
                                                        setShopList([]);
                                                    }

                                                    form.setFieldsValue({
                                                        merchantId: null,
                                                        shopId: null,
                                                    });
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Form.Item
                                            {...{
                                                labelCol: {flex: "24%"},
                                                wrapperCol: {flex: "auto"},
                                            }}
                                            label="商户名称"
                                            name="merchantId"
                                            initialValue={null}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请选择商户名称",
                                                },
                                            ]}
                                        >
                                            <Select
                                                className={Style.card_field}
                                                placeholder="请选择商户名称"
                                                allowClear
                                                onChange={(event) => {
                                                    if (event) {
                                                        handleLoadShop(event);
                                                    } else {
                                                        setShopList([]);

                                                        form.setFieldsValue({
                                                            shopId: null,
                                                        });
                                                    }
                                                }}
                                            >
                                                {merchantList.map((merchant, merchantIndex) => {
                                                    return (
                                                        <Select.Option key={merchantIndex} value={merchant.merchantId}>
                                                            {merchant.merchantName}
                                                        </Select.Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Form.Item
                                            {...{
                                                labelCol: {flex: "24%"},
                                                wrapperCol: {flex: "auto"},
                                            }}
                                            label="门店名称"
                                            name="shopId"
                                            initialValue={null}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请选择门店名称",
                                                },
                                            ]}
                                        >
                                            <Select className={Style.card_field} placeholder="请选择门店名称" allowClear onChange={() => {}}>
                                                {shopList.map((shop, shopIndex) => {
                                                    return (
                                                        <Select.Option key={shopIndex} value={shop.shopId}>
                                                            {shop.shopName}
                                                        </Select.Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </React.Fragment>
                        ) : null}
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="商品分类"
                                name="goodsCategoryId"
                                initialValue={null}
                                rules={[
                                    {
                                        required: true,
                                        message: "请选择商品分类",
                                    },
                                ]}
                            >
                                <Select
                                    className={Style.card_field}
                                    placeholder="请选择商品分类"
                                    allowClear
                                    onChange={(goodsCategoryId) => {
                                        for (let i = 0; i < goodsCategoryList.length; i++) {
                                            if (goodsCategoryList[i].goodsCategoryId == goodsCategoryId) {
                                                form.setFieldsValue({
                                                    goodsReserveStartTime: moment(goodsCategoryList[i].goodsCategoryStart, "HH:mm"),
                                                    goodsReserveEndTime: moment(goodsCategoryList[i].goodsCategoryEnd, "HH:mm"),
                                                });

                                                break;
                                            }
                                        }

                                        setGoodsCategoryId(goodsCategoryId);
                                    }}
                                >
                                    {goodsCategoryList.map((goodsCategory, goodsCategoryIndex) => {
                                        return (
                                            <Select.Option key={goodsCategoryIndex} value={goodsCategory.goodsCategoryId}>
                                                {goodsCategory.goodsCategoryName}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "24%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="商品名称"
                                    name="goodsName"
                                    initialValue={""}
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入商品名称",
                                        },
                                    ]}
                                >
                                    <Input className={Style.card_field} placeholder="请输入商品名称" onPressEnter={handleSubmitGoods} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "12%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="商品封面"
                                    name="goodsImage"
                                    initialValue={""}
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择商品封面",
                                        },
                                    ]}
                                >
                                    <ChuangShiOS.File fileType={"IMAGE"} limit={1} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "12%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="商品图片"
                                    name="goodsImageList"
                                    initialValue={[]}
                                    rules={[
                                        {
                                            required: false,
                                            message: "请选择商品图片",
                                        },
                                    ]}
                                >
                                    <ChuangShiOS.File fileType={"IMAGE"} limit={10} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "12%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="预定日期"
                                    name="goodsReserveWeekList"
                                    initialValue={[1, 2, 3, 4, 5, 6, 0]}
                                    rules={[
                                        {
                                            required: false,
                                            message: "请输入预定日期",
                                        },
                                    ]}
                                >
                                    <Checkbox.Group>
                                        <Checkbox value={1}>周一</Checkbox>
                                        <Checkbox value={2}>周二</Checkbox>
                                        <Checkbox value={3}>周三</Checkbox>
                                        <Checkbox value={4}>周四</Checkbox>
                                        <Checkbox value={5}>周五</Checkbox>
                                        <Checkbox value={6}>周六</Checkbox>
                                        <Checkbox value={0}>周日</Checkbox>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "24%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="预定开始时间"
                                    name="goodsReserveStartTime"
                                    initialValue={""}
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择预定开始时间",
                                        },
                                    ]}
                                >
                                    <TimePicker
                                        className={Style.card_field}
                                        showNow={false}
                                        format="HH:mm"
                                        placeholder="请选择预定开始时间"
                                        onPressEnter={handleSubmitGoods}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "24%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="预定结束时间"
                                    name="goodsReserveEndTime"
                                    initialValue={""}
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择预定结束时间",
                                        },
                                    ]}
                                >
                                    <TimePicker
                                        className={Style.card_field}
                                        showNow={false}
                                        format="HH:mm"
                                        placeholder="请选择预定结束时间"
                                        onPressEnter={handleSubmitGoods}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "24%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="可提前预定小时"
                                    name="goodsReserveHouse"
                                    initialValue={0}
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入可提前预定小时",
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        className={Style.card_field}
                                        min={0}
                                        max={999999}
                                        placeholder="请输入可提前预定小时"
                                        onPressEnter={handleSubmitGoods}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "24%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="可提前取消小时"
                                    name="goodsCancelHouse"
                                    initialValue={0}
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入可提前取消小时",
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        className={Style.card_field}
                                        min={0}
                                        max={999999}
                                        placeholder="请输入可提前取消小时"
                                        onPressEnter={handleSubmitGoods}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "12%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="儿童说明"
                                    name="goodsChildren"
                                    initialValue={""}
                                    rules={[
                                        {
                                            required: false,
                                            message: "请输入儿童说明",
                                        },
                                    ]}
                                >
                                    <ChuangShiOS.Editor id="goodsChildren" height={300} placeholder="请输入儿童说明" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "12%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="使用条件"
                                    name="goodsContent"
                                    initialValue={""}
                                    rules={[
                                        {
                                            required: false,
                                            message: "请输入使用条件",
                                        },
                                    ]}
                                >
                                    <ChuangShiOS.Editor id="goodsContent" height={300} placeholder="请输入使用条件" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
                {isEdit ? (
                    <Card className={Style.card_top}>
                        <Row>
                            <Col flex="auto">
                                <div className={Style.card_title}>商品规格</div>
                            </Col>
                            <Col>
                                <Space>
                                    <Button.Group>
                                        <Button
                                            icon={<PlusCircleOutlined />}
                                            disabled={!goodsCategoryId}
                                            onClick={() => {
                                                handleOpenGoodsSku({
                                                    goodsSkuId: null,
                                                    goodsSkuCategoryId: null,
                                                    goodsSkuName: "",
                                                    goodsSkuMarketPrice: 0,
                                                    goodsSkuSalePrice: 0,
                                                    goodsSkuAllowancePrice: 0,
                                                    goodsSkuSettlementPrice: 0,
                                                    goodsSkuServiceChargeRatio: 0,
                                                });
                                            }}
                                        >
                                            新增
                                        </Button>
                                    </Button.Group>
                                    <Button
                                        icon={<FileSearchOutlined />}
                                        type="primary"
                                        onClick={() => {
                                            handleLoadGoodsSku();
                                        }}
                                    >
                                        查询
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                        <Row>
                            <Col flex={"12%"}></Col>
                            <Col flex="auto" className={Style.card_value}>
                                <Table
                                    className={Style.card_table}
                                    size="large"
                                    rowKey={(record) => record.goodsSkuId}
                                    columns={[
                                        {
                                            title: "规格名称",
                                            dataIndex: "goodsSkuName",
                                        },
                                        {
                                            title: "规格分类",
                                            dataIndex: "goodsSkuCategoryName",
                                        },
                                        {
                                            title: "市场价",
                                            dataIndex: "goodsSkuMarketPrice",
                                        },
                                        {
                                            title: "餐价",
                                            dataIndex: "goodsSkuSalePrice",
                                        },
                                        {
                                            title: "补贴价",
                                            dataIndex: "goodsSkuAllowancePrice",
                                        },
                                        {
                                            title: "结算价",
                                            dataIndex: "goodsSkuSettlementPrice",
                                        },
                                        {
                                            title: "服务费比例",
                                            dataIndex: "goodsSkuServiceChargeRatio",
                                        },
                                        {
                                            title: "操作",
                                            width: 200,
                                            render: (text, record, recordIndex) => (
                                                <Space>
                                                    <Button
                                                        icon={<FileTextOutlined />}
                                                        onClick={() => {
                                                            handleOpenGoodsSku(record);
                                                        }}
                                                    >
                                                        修改
                                                    </Button>
                                                    <Button
                                                        icon={<CloseCircleOutlined />}
                                                        onClick={() => {
                                                            handleDeleteGoodsSku(record.goodsSkuId);
                                                        }}
                                                    >
                                                        删除
                                                    </Button>
                                                </Space>
                                            ),
                                        },
                                    ]}
                                    dataSource={goodsSkuList}
                                    pagination={false}
                                />
                            </Col>
                        </Row>
                    </Card>
                ) : null}
                {isEdit ? (
                    <Card className={Style.card_top}>
                        <Row>
                            <Col flex="auto">
                                <div className={Style.card_title}>定时任务</div>
                            </Col>
                            <Col>
                                <Space>
                                    <Button.Group>
                                        <Button
                                            icon={<PlusCircleOutlined />}
                                            disabled={!goodsCategoryId}
                                            onClick={() => {
                                                handleOpenGoodsTask({
                                                    goodsTaskId: null,
                                                    goodsId: props.match.params.goodsId,
                                                    goodsTaskType: null,
                                                    goodsTaskDate: null,
                                                    goodsSkuIdList: [],
                                                    goodsSkuMarketPrice: null,
                                                    goodsSkuSalePrice: null,
                                                    goodsSkuAllowancePrice: null,
                                                    goodsSkuSettlementPrice: null,
                                                    goodsSkuServiceChargeRatio: null,
                                                });
                                            }}
                                        >
                                            新增
                                        </Button>
                                    </Button.Group>
                                    <Button
                                        icon={<FileSearchOutlined />}
                                        type="primary"
                                        onClick={() => {
                                            handleLoadGoodsTask();
                                        }}
                                    >
                                        查询
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                        <Row>
                            <Col flex={"12%"}></Col>
                            <Col flex="auto" className={Style.card_value}>
                                <Table
                                    className={Style.card_table}
                                    size="large"
                                    rowKey={(record) => record.goodsTaskId}
                                    columns={[
                                        {
                                            title: "任务类型",
                                            dataIndex: "goodsTaskType",
                                            render: (text, record, recordIndex) => (
                                                <div>
                                                    {record.goodsTaskType == "ON" ? <div>上架</div> : null}
                                                    {record.goodsTaskType == "OFF" ? <div>下架</div> : null}
                                                    {record.goodsTaskType == "PRICE" ? <div>修改价格</div> : null}
                                                </div>
                                            ),
                                        },
                                        {
                                            title: "任务日期",
                                            dataIndex: "goodsTaskDate",
                                        },
                                        {
                                            title: "任务说明",
                                            render: (text, record, recordIndex) => (
                                                <div>
                                                    {record.goodsSkuName ? <div>规格: {record.goodsSkuName}</div> : null}
                                                    {record.goodsSkuMarketPrice ? <div>市场价: {record.goodsSkuMarketPrice}</div> : null}
                                                    {record.goodsSkuSalePrice ? <div>餐价: {record.goodsSkuSalePrice}</div> : null}
                                                    {record.goodsSkuAllowancePrice ? <div>补贴价: {record.goodsSkuAllowancePrice}</div> : null}
                                                    {record.goodsSkuSettlementPrice ? <div>结算价: {record.goodsSkuSettlementPrice}</div> : null}
                                                    {record.goodsSkuServiceChargeRatio ? (
                                                        <div>服务费比例: {record.goodsSkuServiceChargeRatio}</div>
                                                    ) : null}
                                                </div>
                                            ),
                                        },
                                        {
                                            title: "修改用户",
                                            dataIndex: "userName",
                                        },
                                        {
                                            title: "操作",
                                            width: 200,
                                            render: (text, record, recordIndex) => (
                                                <Space>
                                                    {/* <Button
                                                        icon={<FileTextOutlined />}
                                                        onClick={() => {
                                                            handleOpenGoodsTask(record);
                                                        }}
                                                    >
                                                        修改
                                                    </Button> */}
                                                    <Button
                                                        icon={<CloseCircleOutlined />}
                                                        onClick={() => {
                                                            handleDeleteGoodsTask(record.goodsTaskId);
                                                        }}
                                                    >
                                                        删除
                                                    </Button>
                                                </Space>
                                            ),
                                        },
                                    ]}
                                    dataSource={goodsTaskList}
                                    pagination={false}
                                />
                            </Col>
                        </Row>
                    </Card>
                ) : null}
                {isEdit ? (
                    <Card className={Style.card_top}>
                        <Row>
                            <Col flex="auto">
                                <div className={Style.card_title}>修改记录</div>
                            </Col>
                            <Col></Col>
                        </Row>
                        <Row>
                            <Col flex={"12%"}></Col>
                            <Col flex="auto" className={Style.card_value}>
                                <Table
                                    className={Style.card_table}
                                    size="large"
                                    rowKey={(record) => record.goodsHistoryId}
                                    columns={[
                                        {
                                            title: "规格名称",
                                            dataIndex: "goodsSkuName",
                                        },
                                        {
                                            title: "餐价",
                                            render: (text, record, recordIndex) => (
                                                <div>
                                                    <div>新餐价: {record.goodsSkuSalePrice}</div>
                                                    <div>旧餐价: {record.goodsSkuOldSalePrice}</div>
                                                </div>
                                            ),
                                        },
                                        {
                                            title: "补贴价",
                                            render: (text, record, recordIndex) => (
                                                <div>
                                                    <div>新补贴价: {record.goodsSkuAllowancePrice}</div>
                                                    <div>旧补贴价: {record.goodsSkuOldAllowancePrice}</div>
                                                </div>
                                            ),
                                        },
                                        {
                                            title: "算价格",
                                            render: (text, record, recordIndex) => (
                                                <div>
                                                    <div>新算价格: {record.goodsSkuSettlementPrice}</div>
                                                    <div>旧算价格: {record.goodsSkuOldSettlementPrice}</div>
                                                </div>
                                            ),
                                        },
                                        {
                                            title: "服务费比例",
                                            render: (text, record, recordIndex) => (
                                                <div>
                                                    <div>新服务费比例: {record.goodsSkuServiceChargeRatio}</div>
                                                    <div>旧服务费比例: {record.goodsSkuOldServiceChargeRatio}</div>
                                                </div>
                                            ),
                                        },
                                        {
                                            title: "修改用户",
                                            dataIndex: "userName",
                                        },
                                        {
                                            title: "动作",
                                            dataIndex: "goodsHistoryTypeValue",
                                        },
                                        {
                                            title: "记录时间",
                                            dataIndex: "goodsHistoryTime",
                                        },
                                    ]}
                                    dataSource={goodsHistoryList}
                                    pagination={false}
                                />
                            </Col>
                        </Row>
                    </Card>
                ) : null}
            </ChuangShiOS.Content>
            <div className={Style.footer}></div>
            {isEdit ? (
                <SkuModal
                    visible={isGoodsSku}
                    goodsCategoryName={goodsCategoryName}
                    goodsSku={goodsSku}
                    onClose={handleCloseGoodsSku}
                    onSubmit={handleSubmitGoodsSku}
                ></SkuModal>
            ) : null}
            {isEdit ? (
                <TaskModal visible={isGoodsTask} goodsTask={goodsTask} onClose={handleCloseGoodsTask} onSubmit={handleSubmitGoodsTask}></TaskModal>
            ) : null}
        </div>
    );
};

GoodsDetail.propTypes = {};

GoodsDetail.defaultProps = {};

export default GoodsDetail;
