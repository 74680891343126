const initialState = {};

function reducer(state = initialState, action) {
    switch (action.type) {
        case "member":
            return Object.assign({}, state, action.data);

        default:
            return state;
    }
}

const data = {
    name: "member",
    reducer: reducer,
};

export default data;