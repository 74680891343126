import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";
import {message, Modal, Space, Button, Form, Row, Col, Input, Table} from "antd";
import {FileDoneOutlined, CloseCircleOutlined} from "@ant-design/icons";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./Modal.module.css";

const RoleModal = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const [rolePageIndex, setRolePageIndex] = useState(1);
    const [rolePageSize, setRolePageSize] = useState(store.main.pageSize);
    const [roleIdList, setRoleIdList] = useState([]);
    const [roleTotal, setRoleTotal] = useState(0);
    const [roleList, setRoleList] = useState([]);
    const [selectList, setSelectList] = useState([]);

    useEffect(() => {
        if (props.visible) {
            handleSearchRole();
        }

        //eslint-disable-next-line
    }, [props.visible]);

    const handleSearchRole = () => {
        handleLoadRole(1, rolePageSize);
    };

    const handleLoadRole = (rolePageIndex, rolePageSize) => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        let data = form.getFieldsValue();
        data.pageIndex = rolePageIndex;
        data.pageSize = rolePageSize;

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/role/business/website/v1/list",
            data: data,
            success: (response) => {
                setRolePageIndex(rolePageIndex);
                setRolePageSize(rolePageSize);
                setRoleIdList([]);
                setRoleTotal(response.data.total);
                setRoleList(response.data.list);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
                setIsError(
                    typeof response.data == "undefined" ||
                        response.data.total == "undefined" ||
                        response.data.total == null ||
                        response.data.list == "undefined" ||
                        response.data.list == null
                );
            },
        });
    };

    const handleChangeRolePagination = (rolePageIndex, rolePageSize) => {
        handleLoadRole(rolePageIndex, rolePageSize);
    };

    const handleSelectRole = (roleIdList) => {
        let selectList = [];
        for (let i = 0; i < roleList.length; i++) {
            let isExit = false;

            for (let j = 0; j < roleIdList.length; j++) {
                if (roleList[i].roleId == roleIdList[j]) {
                    isExit = true;

                    break;
                }
            }

            if (isExit) {
                selectList.push(roleList[i]);
            }
        }

        setRoleIdList(roleIdList);
        setSelectList(selectList);
    };

    const handleClickRole = (role) => {
        let index = -1;

        for (let i = 0; i < roleIdList.length; i++) {
            if (role.roleId == roleIdList[i]) {
                index = i;
                break;
            }
        }

        if (index == -1) {
            if (props.multiple) {
                roleIdList.push(role.roleId);
            } else {
                roleIdList.splice(0, roleIdList.length);
                roleIdList.push(role.roleId);
            }
        } else {
            roleIdList.splice(index, 1);
        }

        let selectList = [];
        for (let i = 0; i < roleList.length; i++) {
            let isExit = false;

            for (let j = 0; j < roleIdList.length; j++) {
                if (roleList[i].roleId == roleIdList[j]) {
                    isExit = true;

                    break;
                }
            }

            if (isExit) {
                selectList.push(roleList[i]);
            }
        }

        setRoleIdList([...roleIdList]);
        setSelectList(selectList);
    };

    const handleCancelRole = () => {
        setRoleIdList([]);
        setSelectList([]);

        props.onClose();
    };

    const handleSubmitRole = () => {
        if (selectList.length > 0) {
            setRoleIdList([]);
            setSelectList([]);

            if (props.multiple) {
                props.onSubmit(selectList);
            } else {
                props.onSubmit(selectList[0]);
            }
        }

        props.onClose();
    };

    return (
        <Modal
            title="权限"
            visible={props.visible}
            centered
            width={ChuangShiOS.Util.getModalWidth()}
            bodyStyle={{
                backgroundColor: "#eff3f8",
                padding: "20px",
            }}
            cancelButtonProps={{
                icon: <CloseCircleOutlined />,
                size: "large",
            }}
            okButtonProps={{
                icon: <FileDoneOutlined />,
                size: "large",
            }}
            onCancel={handleCancelRole}
            onOk={handleSubmitRole}
            footer={
                <div className={Style.footer}>
                    <div className={Style.footer_space}></div>
                    <div className={Style.footer_count}>已选{selectList.length}条</div>
                    <Space>
                        <Button icon={<CloseCircleOutlined />} size="large" onClick={handleCancelRole}>
                            取消
                        </Button>
                        <Button loading={isLoad} icon={<FileDoneOutlined />} type="primary" size="large" onClick={handleSubmitRole}>
                            提交
                        </Button>
                    </Space>
                </div>
            }
        >
            <div style={{height: ChuangShiOS.Util.getModalHeight() + "px"}} className={Style.page}>
                <ChuangShiOS.Content
                    className={Style.content}
                    isLoad={isLoad}
                    isEmpty={false}
                    isError={isError}
                    marginLeft={0}
                    marginRight={0}
                    paddingTop={0}
                    paddingRight={0}
                    paddingBottom={0}
                    paddingLeft={0}
                >
                    <div className={Style.main}>
                        <Form form={form}>
                            <Row>
                                <Col span={6}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "none"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="角色名称"
                                        labelAlign={"left"}
                                        name="roleName"
                                        initialValue={""}
                                        rules={[
                                            {
                                                required: false,
                                                message: "请输入角色名称",
                                            },
                                        ]}
                                    >
                                        <Input className={Style.card_field} placeholder="请输入角色名称" onPressEnter={handleSearchRole} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                        <div className={Style.card_table}>
                            <Table
                                size="small"
                                rowKey={(record) => record.roleId}
                                columns={[
                                    {
                                        title: "角色名称",
                                        dataIndex: "roleName",
                                    },
                                    {
                                        title: "角色类型",
                                        dataIndex: "roleType",
                                    },
                                    {
                                        title: "角色备注",
                                        dataIndex: "roleRemark",
                                    },
                                ]}
                                dataSource={roleList}
                                rowSelection={{
                                    fixed: true,
                                    type: props.multiple ? "checkbox" : "radio",
                                    selectedRowKeys: roleIdList,
                                    onChange: handleSelectRole,
                                }}
                                onRow={(record) => {
                                    return {
                                        onClick: () => {
                                            handleClickRole(record);
                                        },
                                    };
                                }}
                                scroll={{y: ChuangShiOS.Util.getModalHeight() - 200}}
                                pagination={{
                                    size: "default",
                                    showQuickJumper: true,
                                    showSizeChanger: true,
                                    current: rolePageIndex,
                                    pageSize: rolePageSize,
                                    total: roleTotal,
                                    showTotal: (total, range) => "显示" + range[0] + "到" + range[1] + "条,共有" + total + "条",
                                    onChange: handleChangeRolePagination,
                                    onShowSizeChange: handleChangeRolePagination,
                                }}
                            />
                        </div>
                    </div>
                </ChuangShiOS.Content>
            </div>
        </Modal>
    );
};

RoleModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    multiple: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

RoleModal.defaultProps = {};

export default RoleModal;
