import React from "react";
import {Route} from "react-router-dom";
import KeepAlive from "react-activation";
import ChuangShiOS from "chuangshi-os-website-component";

import GoodsCategoryIndex from "./Index";
import GoodsCategoryDetail from "./Detail";

const data = [
    <Route
        exact
        key="GoodsCategoryIndex"
        path="/goods/category/index"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return (
                <KeepAlive name="/goods/category/index">
                    <GoodsCategoryIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="GoodsCategoryAdd"
        path="/goods/category/add"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <GoodsCategoryDetail {...props} />;
        }}
    />,
    <Route
        exact
        key="GoodsCategoryEdit"
        path="/goods/category/edit/:goodsCategoryId"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <GoodsCategoryDetail {...props} />;
        }}
    />,
];

export default data;
