import React from "react";
import {Route} from "react-router-dom";
import KeepAlive from "react-activation";
import ChuangShiOS from "chuangshi-os-website-component";

import TradeIndex from "./Index";
import TradeEnterpriseIndex from "./EnterpriseIndex";
import TradeMerchantIndex from "./MerchantIndex";
import TradeDetail from "./Detail";
import TradeEnterpriseDetail from "./EnterpriseDetail";

const data = [
    <Route
        exact
        key="TradeIndex"
        path="/trade/index"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return (
                <KeepAlive name="/trade/index">
                    <TradeIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="TradeMerchantIndex"
        path="/trade/merchant/index"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return (
                <KeepAlive name="/trade/merchant/index">
                    <TradeMerchantIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="TradeEnterpriseIndex"
        path="/trade/enterprise/index"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return (
                <KeepAlive name="/trade/enterprise/index">
                    <TradeEnterpriseIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="TradeAdd"
        path="/trade/add"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <TradeDetail {...props} />;
        }}
    />,
    <Route
        exact
        key="TradeEdit"
        path="/trade/edit/:tradeId"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <TradeDetail {...props} />;
        }}
    />,
    <Route
        exact
        key="TradeEnterpriseAdd"
        path="/trade/enterprise/add"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <TradeEnterpriseDetail {...props} />;
        }}
    />,
    <Route
        exact
        key="TradeEnterpriseEdit"
        path="/trade/enterprise/edit/:tradeId"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <TradeEnterpriseDetail {...props} />;
        }}
    />,
];

export default data;
