import React, {useState, useRef, useEffect} from "react";
import {useActivate, useUnactivate} from "react-activation";
import {message, Breadcrumb, Space, Form, Row, Col, Input, Button} from "antd";
import {FileDoneOutlined} from "@ant-design/icons";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./Index.module.css";

const UserIndex = (props) => {
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);

    useEffect(() => {
        //eslint-disable-next-line
    }, []);

    useActivate(() => {});

    useUnactivate(() => {});

    const handleSubmit = () => {
        if (request.current) {
            return;
        }

        form.validateFields()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                ChuangShiOS.Util.request(props, {
                    url: Application.host + "/windeal/user/business/website/v1/password",
                    data: data,
                    success: (response) => {
                        ChuangShiOS.Util.setToken(response.data.token);

                        form.resetFields();

                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            })
            .catch((response) => {
                ChuangShiOS.Util.parseResponseError(response);
            });
    };

    return (
        <div className={Style.page}>
            <div className={Style.header}>
                <div className={Style.header_title}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <span>首页</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span>修改密码</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className={Style.header_title_text}>修改密码</div>
                </div>
                <div className={Style.header_button}>
                    <Space>
                        <Button loading={isLoad} icon={<FileDoneOutlined />} type="primary" size="large" onClick={handleSubmit}>
                            提交
                        </Button>
                    </Space>
                </div>
            </div>
            <ChuangShiOS.Content className={Style.content} isLoad={isLoad} isEmpty={false} isError={false} paddingTop={28}>
                <Row>
                    <Col flex="auto">
                        <div className={Style.card_title}>用户信息</div>
                    </Col>
                    <Col></Col>
                </Row>
                <Form form={form}>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="登录密码"
                                name="userPassword"
                                initialValue={""}
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入登录密码",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入登录密码" onPressEnter={handleSubmit} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </ChuangShiOS.Content>
            <div className={Style.footer}></div>
        </div>
    );
};

UserIndex.propTypes = {};

UserIndex.defaultProps = {};

export default UserIndex;
