import React, {useState, useRef, useEffect} from "react";
import PropTypes from "prop-types";
import {message, Modal, Space, Button, Form, Row, Col, Input, InputNumber, Select} from "antd";
import {FileDoneOutlined, CloseCircleOutlined} from "@ant-design/icons";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./SkuModal.module.css";

const SkuModal = (props) => {
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [goodsSkuCategoryList, setGoodsSkuCategoryList] = useState([]);

    useEffect(() => {
        if (props.visible) {
            handleLoadGoodsSkuCategory();

            setTimeout(() => {
                form.setFieldsValue(props.goodsSku);
            }, 100);
        } else {
            form.resetFields();
        }

        //eslint-disable-next-line
    }, [props.visible]);

    const handleLoadGoodsSkuCategory = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/goods/sku/category/business/website/v1/select/list",
            data: {},
            success: (response) => {
                setGoodsSkuCategoryList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleCancelSku = () => {
        props.onClose();
    };

    const handleSubmitSku = () => {
        form.validateFields()
            .then((data) => {
                data.goodsSkuId = props.goodsSku.goodsSkuId;
                data.goodsId = props.goodsSku.goodsId;
                data.systemVersion = props.goodsSku.systemVersion;

                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                let url = "";
                if (props.goodsSku.goodsSkuId) {
                    url = "/windeal/goods/sku/business/website/v1/update";
                } else {
                    url = "/windeal/goods/sku/business/website/v1/save";
                }

                ChuangShiOS.Util.request(props, {
                    url: Application.host + url,
                    data: data,
                    success: (response) => {
                        message.success({content: response.message, duration: 1});

                        props.onSubmit();
                    },
                    error: (response) => {
                        message.error({content: response.message, duration: 1});
                    },
                    complete: (response) => {
                        request.current = false;

                        setIsLoad(false);
                    },
                });
            })
            .catch((response) => {
                ChuangShiOS.Util.parseResponseError(response);
            });
    };

    return (
        <Modal
            title="商品规格"
            visible={props.visible}
            centered
            width={ChuangShiOS.Util.getModalWidth()}
            bodyStyle={{
                backgroundColor: "#eff3f8",
                padding: "20px",
            }}
            cancelButtonProps={{
                icon: <CloseCircleOutlined />,
                size: "large",
            }}
            okButtonProps={{
                icon: <FileDoneOutlined />,
                size: "large",
            }}
            onCancel={handleCancelSku}
            onOk={handleSubmitSku}
            footer={
                <div className={Style.footer}>
                    <div className={Style.footer_space}></div>
                    <Space>
                        <Button icon={<CloseCircleOutlined />} size="large" onClick={handleCancelSku}>
                            取消
                        </Button>
                        <Button icon={<FileDoneOutlined />} type="primary" size="large" onClick={handleSubmitSku}>
                            提交
                        </Button>
                    </Space>
                </div>
            }
        >
            <div style={{height: ChuangShiOS.Util.getModalHeight() + "px"}} className={Style.page}>
                <ChuangShiOS.Content
                    className={Style.content}
                    isLoad={isLoad}
                    isEmpty={false}
                    isError={false}
                    marginLeft={0}
                    marginRight={0}
                    paddingTop={0}
                    paddingRight={0}
                    paddingBottom={0}
                    paddingLeft={0}
                >
                    <div className={Style.main}>
                        <Form form={form}>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "24%"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="规格分类"
                                        name="goodsSkuCategoryId"
                                        initialValue={null}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请选择规格分类",
                                            },
                                        ]}
                                    >
                                        <Select
                                            className={Style.card_field}
                                            placeholder="请选择规格分类"
                                            allowClear
                                            onChange={(event) => {
                                                let goodsSkuCategoryName = "";
                                                for (let i = 0; i < goodsSkuCategoryList.length; i++) {
                                                    if (event == goodsSkuCategoryList[i].goodsSkuCategoryId) {
                                                        goodsSkuCategoryName = goodsSkuCategoryList[i].goodsSkuCategoryName;

                                                        break;
                                                    }
                                                }

                                                form.setFieldsValue({
                                                    goodsSkuName: goodsSkuCategoryName + props.goodsCategoryName,
                                                });
                                            }}
                                        >
                                            {goodsSkuCategoryList.map((goodsSkuCategory, goodsSkuCategoryIndex) => {
                                                return (
                                                    <Select.Option key={goodsSkuCategoryIndex} value={goodsSkuCategory.goodsSkuCategoryId}>
                                                        {goodsSkuCategory.goodsSkuCategoryName}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "24%"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="规格名称"
                                        name="goodsSkuName"
                                        initialValue={""}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入规格名称",
                                            },
                                        ]}
                                    >
                                        <Input className={Style.card_field} placeholder="请输入规格名称" onPressEnter={handleSubmitSku} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "24%"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="市场价"
                                        name="goodsSkuMarketPrice"
                                        initialValue={0}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入市场价",
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            className={Style.card_field}
                                            min={0}
                                            max={999999}
                                            placeholder="请输入市场价"
                                            onPressEnter={handleSubmitSku}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "24%"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="餐价"
                                        name="goodsSkuSalePrice"
                                        initialValue={0}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入餐价",
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            className={Style.card_field}
                                            min={0}
                                            max={999999}
                                            placeholder="请输入餐价"
                                            onPressEnter={handleSubmitSku}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "24%"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="补贴价"
                                        name="goodsSkuAllowancePrice"
                                        initialValue={0}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入补贴价",
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            className={Style.card_field}
                                            min={0}
                                            max={999999}
                                            placeholder="请输入补贴价"
                                            onPressEnter={handleSubmitSku}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "24%"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="结算价"
                                        name="goodsSkuSettlementPrice"
                                        initialValue={0}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入结算价格",
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            className={Style.card_field}
                                            min={0}
                                            max={999999}
                                            placeholder="请输入结算价格"
                                            onPressEnter={handleSubmitSku}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "24%"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="服务费比例"
                                        name="goodsSkuServiceChargeRatio"
                                        initialValue={0}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入服务费比例",
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            className={Style.card_field}
                                            min={0}
                                            max={999999}
                                            placeholder="请输入服务费比例"
                                            onPressEnter={handleSubmitSku}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </ChuangShiOS.Content>
            </div>
        </Modal>
    );
};

SkuModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    goodsCategoryName: PropTypes.string.isRequired,
    goodsSku: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

SkuModal.defaultProps = {};

export default SkuModal;
