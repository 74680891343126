import React from "react";
import ReactDOM from "react-dom";
import {HashRouter, Route, Switch, Redirect} from "react-router-dom";
import {createStore, combineReducers} from "redux";
import {Provider} from "react-redux";
import {routerReducer} from "react-router-redux";
import {AliveScope} from "react-activation";
import {ConfigProvider, Tabs} from "antd";
import zhCN from "antd/lib/locale/zh_CN";
import ChuangShiOS from "chuangshi-os-website-component";
import reportWebVitals from "./reportWebVitals";

import Application from "./common/Application";

import MainIndex from "./view/main/Index";
import LoginIndex from "./view/login/Index";

import "moment/locale/zh-cn";
import "@wangeditor/editor/dist/css/style.css";
import "react-photo-view/dist/index.css";
import "./index.css";

ChuangShiOS.Util.setApplication(Application);

const reducers = {};
let storeContexts = require.context("./view/", true, /^.*\/Store\.js$/);
storeContexts.keys().forEach((fileName) => {
    reducers[storeContexts(fileName).default.name] = storeContexts(fileName).default.reducer;
});

const stores = createStore(
    combineReducers(
        Object.assign(reducers, {
            routing: routerReducer,
        })
    )
);

ReactDOM.render(
    <React.Fragment>
        {true ? null : <Tabs></Tabs>}
        <ConfigProvider locale={zhCN}>
            <Provider store={stores}>
                <HashRouter>
                    <AliveScope>
                        <Switch>
                            <Route path="/login/index" component={LoginIndex}></Route>
                            <Route path="/" component={MainIndex}></Route>
                            <Redirect to={Application.homePath} />
                        </Switch>
                    </AliveScope>
                </HashRouter>
            </Provider>
        </ConfigProvider>
    </React.Fragment>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
