import React from "react";
import {Route} from "react-router-dom";
import KeepAlive from "react-activation";
import ChuangShiOS from "chuangshi-os-website-component";

import PasswordIndex from "./Index";

const data = [
    <Route
        exact
        key="PasswordIndex"
        path="/password/index"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return (
                <KeepAlive name="/password/index">
                    <PasswordIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
];

export default data;
