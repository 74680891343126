import React, {useState, useRef, useEffect} from "react";
import PropTypes from "prop-types";
import {message, Form, Modal, Input, Space, Button} from "antd";
import {FileDoneOutlined, CloseCircleOutlined} from "@ant-design/icons";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./PasswordModal.module.css";

const PasswordModal = (props) => {
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);

    useEffect(() => {
        if (props.visible) {
            form.resetFields();
        }

        //eslint-disable-next-line
    }, [props.visible]);

    const handleClosePassword = () => {
        props.onClose();
    };

    const handleSubmitPassword = () => {
        form.validateFields()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                data.userId = props.userId;

                ChuangShiOS.Util.request(props, {
                    url: Application.host + "/windeal/user/business/website/v1/change",
                    data: data,
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        form.resetFields();

                        props.onSubmit(data);
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });
                    },
                    complete: (response) => {
                        request.current = false;

                        setIsLoad(false);
                    },
                });
            })
            .catch((response) => {
                ChuangShiOS.Util.parseResponseError(response);
            });
    };

    return (
        <Modal
            title="修改密码"
            visible={props.visible}
            centered
            bodyStyle={{
                backgroundColor: "#eff3f8",
                padding: "0px 0px 0px 0px",
            }}
            footer={
                <Space>
                    <Button icon={<CloseCircleOutlined />} size="large" onClick={handleClosePassword}>
                        取消
                    </Button>
                    <Button icon={<FileDoneOutlined />} type="primary" size="large" onClick={handleSubmitPassword}>
                        提交
                    </Button>
                </Space>
            }
        >
            <div style={{padding: "20px", height: 170 + "px"}}>
                <ChuangShiOS.Content className={Style.content} isLoad={isLoad} isEmpty={false} isError={false}>
                    <Form form={form} style={{backgroundColor: "#ffffff", padding: "24px 24px 1px 24px"}}>
                        <Form.Item
                            {...{
                                labelCol: {flex: "24%"},
                                wrapperCol: {flex: "auto"},
                            }}
                            label="用户密码"
                            name="userPassword"
                            initialValue={null}
                            rules={[
                                {
                                    required: true,
                                    message: "请输入用户密码",
                                },
                            ]}
                        >
                            <Input className={Style.card_field} placeholder="请输入用户密码" onPressEnter={handleSubmitPassword} />
                        </Form.Item>
                    </Form>
                </ChuangShiOS.Content>
            </div>
        </Modal>
    );
};

PasswordModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    userId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

PasswordModal.defaultProps = {};

export default PasswordModal;
