import React, {useState, useRef, useEffect} from "react";
import PropTypes from "prop-types";
import {message, Modal, Space, Button, Form, Row, Col, InputNumber, Select, DatePicker} from "antd";
import {FileDoneOutlined, CloseCircleOutlined} from "@ant-design/icons";
import moment from "moment";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./TaskModal.module.css";

const TaskModal = (props) => {
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [goodsTaskType, setGoodsTaskType] = useState("");
    const [goodsSkuList, setGoodsSkuList] = useState([]);

    useEffect(() => {
        if (props.visible) {
            handleLoadGoodsSku();

            let goodsTask = props.goodsTask;
            if (goodsTask.goodsTaskDate) {
                goodsTask.goodsTaskDate = moment(goodsTask.goodsTaskDate, "YYYY-MM-DD");
            }

            setTimeout(() => {
                form.setFieldsValue(goodsTask);
            }, 100);
        } else {
            setGoodsTaskType("");

            form.resetFields();
        }

        //eslint-disable-next-line
    }, [props.visible]);

    const handleLoadGoodsSku = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/goods/sku/business/website/v1/select/list",
            data: {
                goodsId: props.goodsTask.goodsId,
            },
            success: (response) => {
                setGoodsSkuList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleCancelSku = () => {
        props.onClose();
    };

    const handleSubmitSku = () => {
        form.validateFields()
            .then((data) => {
                data.goodsTaskId = props.goodsTask.goodsTaskId;
                data.goodsId = props.goodsTask.goodsId;
                data.goodsTaskDate = data.goodsTaskDate.format("YYYY-MM-DD");
                if (!data.goodsSkuIdList) {
                    data.goodsSkuIdList = [];
                }
                data.systemVersion = props.goodsTask.systemVersion;

                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                let url = "";
                if (props.goodsTask.goodsTaskId) {
                    url = "/windeal/goods/task/business/website/v1/update";
                } else {
                    url = "/windeal/goods/task/business/website/v1/save";
                }

                ChuangShiOS.Util.request(props, {
                    url: Application.host + url,
                    data: data,
                    success: (response) => {
                        message.success({content: response.message, duration: 1});

                        props.onSubmit();
                    },
                    error: (response) => {
                        message.error({content: response.message, duration: 1});
                    },
                    complete: (response) => {
                        request.current = false;

                        setIsLoad(false);
                    },
                });
            })
            .catch((response) => {
                ChuangShiOS.Util.parseResponseError(response);
            });
    };

    return (
        <Modal
            title="定时任务"
            visible={props.visible}
            centered
            width={ChuangShiOS.Util.getModalWidth()}
            bodyStyle={{
                backgroundColor: "#eff3f8",
                padding: "20px",
            }}
            cancelButtonProps={{
                icon: <CloseCircleOutlined />,
                size: "large",
            }}
            okButtonProps={{
                icon: <FileDoneOutlined />,
                size: "large",
            }}
            onCancel={handleCancelSku}
            onOk={handleSubmitSku}
            footer={
                <div className={Style.footer}>
                    <div className={Style.footer_space}></div>
                    <Space>
                        <Button icon={<CloseCircleOutlined />} size="large" onClick={handleCancelSku}>
                            取消
                        </Button>
                        <Button icon={<FileDoneOutlined />} type="primary" size="large" onClick={handleSubmitSku}>
                            提交
                        </Button>
                    </Space>
                </div>
            }
        >
            <div style={{height: ChuangShiOS.Util.getModalHeight() + "px"}} className={Style.page}>
                <ChuangShiOS.Content
                    className={Style.content}
                    isLoad={isLoad}
                    isEmpty={false}
                    isError={false}
                    marginLeft={0}
                    marginRight={0}
                    paddingTop={0}
                    paddingRight={0}
                    paddingBottom={0}
                    paddingLeft={0}
                >
                    <div className={Style.main}>
                        <Form form={form}>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "24%"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="任务类型"
                                        name="goodsTaskType"
                                        initialValue={null}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请选择任务类型",
                                            },
                                        ]}
                                    >
                                        <Select
                                            className={Style.card_field}
                                            placeholder="请选择任务类型"
                                            allowClear
                                            onChange={(value) => {
                                                setGoodsTaskType(value);
                                            }}
                                        >
                                            <Select.Option value="PRICE">修改价格</Select.Option>
                                            <Select.Option value="ON">上架</Select.Option>
                                            <Select.Option value="OFF">下架</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "24%"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="定时日期"
                                        name="goodsTaskDate"
                                        rules={[
                                            {
                                                required: true,
                                                message: "请选择定时日期",
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            className={Style.card_field}
                                            format="YYYY-MM-DD"
                                            showToday={false}
                                            disabledDate={(current) => {
                                                return current && current < moment().endOf("day");
                                            }}
                                            placeholder="请选择定时日期"
                                            onPressEnter={handleSubmitSku}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            {goodsTaskType == "PRICE" ? (
                                <React.Fragment>
                                    <Row>
                                        <Col span={12}>
                                            <Form.Item
                                                {...{
                                                    labelCol: {flex: "24%"},
                                                    wrapperCol: {flex: "auto"},
                                                }}
                                                label="商品规格"
                                                name="goodsSkuIdList"
                                                initialValue={[]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "请选择商品规格",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    className={Style.card_field}
                                                    mode="multiple"
                                                    placeholder="请选择商品规格"
                                                    allowClear
                                                    onChange={(event) => {}}
                                                >
                                                    {goodsSkuList.map((goodsSku, goodsSkuIndex) => {
                                                        return (
                                                            <Select.Option key={goodsSkuIndex} value={goodsSku.goodsSkuId}>
                                                                {goodsSku.goodsSkuName}
                                                            </Select.Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <Form.Item
                                                {...{
                                                    labelCol: {flex: "24%"},
                                                    wrapperCol: {flex: "auto"},
                                                }}
                                                label="市场价"
                                                name="goodsSkuMarketPrice"
                                                initialValue={null}
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "请输入市场价",
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    className={Style.card_field}
                                                    min={0}
                                                    max={999999}
                                                    placeholder="请输入市场价"
                                                    onPressEnter={handleSubmitSku}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <Form.Item
                                                {...{
                                                    labelCol: {flex: "24%"},
                                                    wrapperCol: {flex: "auto"},
                                                }}
                                                label="餐价"
                                                name="goodsSkuSalePrice"
                                                initialValue={null}
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "请输入餐价",
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    className={Style.card_field}
                                                    min={0}
                                                    max={999999}
                                                    placeholder="请输入餐价"
                                                    onPressEnter={handleSubmitSku}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <Form.Item
                                                {...{
                                                    labelCol: {flex: "24%"},
                                                    wrapperCol: {flex: "auto"},
                                                }}
                                                label="补贴价"
                                                name="goodsSkuAllowancePrice"
                                                initialValue={null}
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "请输入补贴价",
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    className={Style.card_field}
                                                    min={0}
                                                    max={999999}
                                                    placeholder="请输入补贴价"
                                                    onPressEnter={handleSubmitSku}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <Form.Item
                                                {...{
                                                    labelCol: {flex: "24%"},
                                                    wrapperCol: {flex: "auto"},
                                                }}
                                                label="结算价"
                                                name="goodsSkuSettlementPrice"
                                                initialValue={null}
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "请输入结算价格",
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    className={Style.card_field}
                                                    min={0}
                                                    max={999999}
                                                    placeholder="请输入结算价格"
                                                    onPressEnter={handleSubmitSku}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <Form.Item
                                                {...{
                                                    labelCol: {flex: "24%"},
                                                    wrapperCol: {flex: "auto"},
                                                }}
                                                label="服务费比例"
                                                name="goodsSkuServiceChargeRatio"
                                                initialValue={null}
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "请输入服务费比例",
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    className={Style.card_field}
                                                    min={0}
                                                    max={999999}
                                                    placeholder="请输入服务费比例"
                                                    onPressEnter={handleSubmitSku}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            ) : null}
                        </Form>
                    </div>
                </ChuangShiOS.Content>
            </div>
        </Modal>
    );
};

TaskModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    goodsTask: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

TaskModal.defaultProps = {};

export default TaskModal;
