import React from "react";
import {Route} from "react-router-dom";
import KeepAlive from "react-activation";
import ChuangShiOS from "chuangshi-os-website-component";

import UserIndex from "./Index";
import UserDetail from "./Detail";

const data = [
    <Route
        exact
        key="UserIndex"
        path="/user/index"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return (
                <KeepAlive name="/user/index">
                    <UserIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="UserAdd"
        path="/user/add"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <UserDetail {...props} />;
        }}
    />,
    <Route
        exact
        key="UserEdit"
        path="/user/edit/:userId"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <UserDetail {...props} />;
        }}
    />,
];

export default data;
