import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {message, Modal, Breadcrumb, Form, Row, Col, Input, InputNumber, TreeSelect, Space, Button, Divider, Table} from "antd";
import {LeftCircleOutlined, FileDoneOutlined, PlusCircleOutlined, UpCircleOutlined, DownCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./Detail.module.css";

const MenuDetail = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [permissionList, setPermissionList] = useState([]);
    const [systemVersion, setSystemVersion] = useState(0);
    const [menuList, setMenuList] = useState([]);

    useEffect(() => {
        if (store.main.userName) {
            ChuangShiOS.Notification.emit("main_menu_active", {url: "/menu/index"});

            switch (props.match.path) {
                case "/menu/add":
                    setIsSave(true);

                    break;
                case "/menu/edit/:menuId":
                    setIsEdit(true);

                    handleLoadMenu();

                    break;
                default:
                    break;
            }

            handleLoadMenuList();
        }

        //eslint-disable-next-line
    }, [store.main.userName]);

    const handleLoadMenu = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/menu/business/website/v1/find",
            data: {
                menuId: props.match.params.menuId,
            },
            success: (response) => {
                if (response.data.menuParentId == "") {
                    delete response.data.menuParentId;
                }

                form.setFieldsValue(response.data);

                setPermissionList(response.data.permissionList);
                setSystemVersion(response.data.systemVersion);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
                setIsError(typeof response.data == "undefined" || typeof response.data.menuId == "undefined" || response.data.menuId == null);
            },
        });
    };

    const handleLoadMenuList = () => {
        ChuangShiOS.Util.request(this, {
            url: Application.host + "/windeal/menu/business/website/v1/select/list",
            data: {
                menuId: props.match.params.menuId,
            },
            success: (response) => {
                setMenuList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleSubmitMenu = () => {
        if (request.current) {
            return;
        }

        form.validateFields()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                data.menuId = props.match.params.menuId;
                data.permissionList = permissionList;
                data.systemVersion = systemVersion;

                let url = "";
                if (isSave) {
                    url = "/windeal/menu/business/website/v1/save";
                }
                if (isEdit) {
                    url = "/windeal/menu/business/website/v1/update";
                }

                ChuangShiOS.Util.request(props, {
                    url: Application.host + url,
                    data: data,
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);

                        ChuangShiOS.Util.navigateBack(props, {});
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            })
            .catch((response) => {
                ChuangShiOS.Util.parseResponseError(response);
            });
    };

    const handleBackMenu = () => {
        ChuangShiOS.Util.navigateBack(props, {});
    };

    const handleAddPermission = () => {
        permissionList.push({
            permissionId: "",
            permissionName: "",
            permissionKey: "",
            permissionSort: permissionList.length,
        });

        setPermissionList([...permissionList]);
    };

    const handleChangePermissionName = (index, event) => {
        permissionList[index].permissionName = event.target.value;

        setPermissionList([...permissionList]);
    };

    const handleChangePermissionKey = (index, event) => {
        permissionList[index].permissionKey = event.target.value;

        setPermissionList([...permissionList]);
    };

    const handleUpPermission = (index) => {
        permissionList[index] = permissionList.splice(index - 1, 1, permissionList[index])[0];

        setPermissionList([...permissionList]);
    };

    const handleDownPermission = (index) => {
        permissionList[index] = permissionList.splice(index + 1, 1, permissionList[index])[0];

        setPermissionList([...permissionList]);
    };

    const handleDeletePermission = (index) => {
        Modal.confirm({
            title: "是否确认操作？",
            content: "本次数据操作后将无法恢复",
            okText: "确认",
            cancelText: "取消",
            onOk: () => {
                permissionList.splice(index, 1);

                setPermissionList([...permissionList]);
            },
        });
    };

    return (
        <div className={Style.page}>
            <div className={Style.header}>
                <div className={Style.header_title}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <span>首页</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span>{store.main.menuName}</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className={Style.header_title_text}>{store.main.sidebarName}</div>
                </div>
                <div className={Style.header_button}>
                    <Space>
                        <Button.Group>
                            <Button icon={<LeftCircleOutlined />} size="large" onClick={handleBackMenu}>
                                返回
                            </Button>
                        </Button.Group>
                        <Button
                            loading={isLoad}
                            disabled={isError}
                            icon={<FileDoneOutlined />}
                            type="primary"
                            size="large"
                            onClick={handleSubmitMenu}
                        >
                            提交
                        </Button>
                    </Space>
                </div>
            </div>
            <ChuangShiOS.Content
                className={Style.content}
                isLoad={isLoad}
                isEmpty={false}
                isError={isError}
                paddingTop={28}
                isScroll={store.main.isFixedContent}
            >
                <Row>
                    <Col flex="auto">
                        <div className={Style.card_title}>基本信息</div>
                    </Col>
                    <Col></Col>
                </Row>
                <Form form={form}>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="父级分类"
                                name="menuParentId"
                                initialValue={null}
                                rules={[
                                    {
                                        required: false,
                                        message: "请选择父级分类",
                                    },
                                ]}
                            >
                                <TreeSelect
                                    className={Style.card_field}
                                    placeholder="请选择父级分类"
                                    allowClear
                                    treeDefaultExpandAll
                                    treeData={menuList}
                                    onChange={(value) => {}}
                                ></TreeSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="菜单名称"
                                name="menuName"
                                initialValue={""}
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入菜单名称",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入菜单名称" onPressEnter={handleSubmitMenu} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="菜单图标"
                                name="menuIcon"
                                initialValue={""}
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入菜单图标",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入菜单图标" onPressEnter={handleSubmitMenu} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="菜单地址"
                                name="menuUrl"
                                initialValue={""}
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入菜单地址",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入菜单地址" onPressEnter={handleSubmitMenu} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="菜单备注"
                                name="menuRemark"
                                initialValue={""}
                                rules={[
                                    {
                                        required: false,
                                        message: "请输入菜单备注",
                                    },
                                ]}
                            >
                                <Input className={Style.card_field} placeholder="请输入菜单备注" onPressEnter={handleSubmitMenu} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                {...{
                                    labelCol: {flex: "24%"},
                                    wrapperCol: {flex: "auto"},
                                }}
                                label="菜单排序"
                                name="menuSort"
                                initialValue={0}
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入菜单排序",
                                    },
                                ]}
                            >
                                <InputNumber
                                    className={Style.card_field}
                                    min={0}
                                    max={999999}
                                    placeholder="请输入菜单排序"
                                    onPressEnter={handleSubmitMenu}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Divider />
                <Row>
                    <Col flex="auto">
                        <div className={Style.card_title}>菜单权限</div>
                    </Col>
                    <Col>
                        <Button
                            icon={<PlusCircleOutlined />}
                            onClick={() => {
                                handleAddPermission();
                            }}
                        >
                            新增
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col flex={"12%"}></Col>
                    <Col flex="auto" className={Style.card_value}>
                        <Table
                            size="large"
                            rowKey={(record) => record.permissionId}
                            columns={[
                                {
                                    title: "权限名称",
                                    render: (text, record, recordIndex) => (
                                        <Input
                                            placeholder="请输入权限名称"
                                            value={record.permissionName}
                                            onChange={(event) => {
                                                handleChangePermissionName(recordIndex, event);
                                            }}
                                            onPressEnter={handleSubmitMenu}
                                        />
                                    ),
                                },
                                {
                                    title: "权限编码",
                                    render: (text, record, recordIndex) => (
                                        <Input
                                            className={Style.card_table_field}
                                            placeholder="请输入权限编码"
                                            value={record.permissionKey}
                                            onChange={(event) => {
                                                handleChangePermissionKey(recordIndex, event);
                                            }}
                                            onPressEnter={handleSubmitMenu}
                                        />
                                    ),
                                },
                                {
                                    title: "操作",
                                    width: 150,
                                    render: (text, record, recordIndex) => (
                                        <Space>
                                            <Button
                                                className={Style.card_table_row_col_margin}
                                                icon={<UpCircleOutlined />}
                                                disabled={recordIndex == 0}
                                                onClick={() => {
                                                    handleUpPermission(recordIndex);
                                                }}
                                            >
                                                上移
                                            </Button>
                                            <Button
                                                className={Style.card_table_row_col_margin}
                                                icon={<DownCircleOutlined />}
                                                disabled={recordIndex == permissionList.length - 1}
                                                onClick={() => {
                                                    handleDownPermission(recordIndex);
                                                }}
                                            >
                                                下移
                                            </Button>
                                            <Button
                                                icon={<CloseCircleOutlined />}
                                                onClick={() => {
                                                    handleDeletePermission(recordIndex);
                                                }}
                                            >
                                                删除
                                            </Button>
                                        </Space>
                                    ),
                                },
                            ]}
                            dataSource={permissionList}
                            pagination={false}
                        />
                        <br />
                    </Col>
                </Row>
            </ChuangShiOS.Content>
            <div className={Style.footer}></div>
        </div>
    );
};

MenuDetail.propTypes = {};

MenuDetail.defaultProps = {};

export default MenuDetail;
