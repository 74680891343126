import React from "react";
import {Route} from "react-router-dom";
import KeepAlive from "react-activation";
import ChuangShiOS from "chuangshi-os-website-component";

import JobIndex from "./Index";
import JobDetail from "./Detail";

const data = [
    <Route
        exact
        key="JobIndex"
        path="/job/index"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return (
                <KeepAlive name="/job/index">
                    <JobIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="JobAdd"
        path="/job/add"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <JobDetail {...props} />;
        }}
    />,
    <Route
        exact
        key="JobEdit"
        path="/job/edit/:jobId"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <JobDetail {...props} />;
        }}
    />,
];

export default data;
