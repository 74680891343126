import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {useActivate, useUnactivate} from "react-activation";
import {message, Modal, Breadcrumb, Space, Button, Form, Row, Table} from "antd";
import * as Icon from "@ant-design/icons";
import {FileAddOutlined, DeleteOutlined, CloseCircleOutlined, FileSearchOutlined, FileTextOutlined} from "@ant-design/icons";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import Style from "./Index.module.css";

const MenuIndex = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const [tableHeight, setTableHeight] = useState(0);
    const [menuIdList, setMenuIdList] = useState([]);
    const [menuExpandedRowKeyList, setMenuExpandedRowKeyList] = useState([]);
    const [menuList, setMenuList] = useState([]);

    useEffect(() => {
        if (store.main.userName) {
            ChuangShiOS.Notification.emit("main_menu_active", {url: "/menu/index"});

            handleSearchMenu();
        }

        //eslint-disable-next-line
    }, [store.main.userName]);

    useActivate(() => {
        ChuangShiOS.Notification.emit("main_set_scroll_top", {});

        handleLoadMenu();
    });

    useUnactivate(() => {
        ChuangShiOS.Notification.emit("main_get_scroll_top", {});
    });

    const handleSearchMenu = () => {
        handleLoadMenu();
    };

    const handleLoadMenu = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        let data = form.getFieldsValue();

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/menu/business/website/v1/list",
            data: data,
            success: (response) => {
                let menuExpandedRowKeyList = handleExpandedMenu(response.data);
                setMenuExpandedRowKeyList(menuExpandedRowKeyList);
                setMenuList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
                setIsError(false);
            },
        });
    };

    const handleExpandedMenu = (menuList) => {
        let menuExpandedRowKeyList = [];
        for (let i = 0; i < menuList.length; i++) {
            menuExpandedRowKeyList.push(menuList[i].menuId);

            if (menuList[i].children) {
                menuExpandedRowKeyList = menuExpandedRowKeyList.concat(handleExpandedMenu(menuList[i].children));
            }
        }
        return menuExpandedRowKeyList;
    };

    const handleAddMenu = () => {
        ChuangShiOS.Util.navigateTo(props, {
            url: "/menu/add",
        });
    };

    const handleEditMenu = (menuId) => {
        ChuangShiOS.Util.navigateTo(props, {
            url: "/menu/edit/" + menuId,
        });
    };

    const handleDelMenu = () => {
        if (menuIdList.length == 0) {
            return;
        }

        if (request.current) {
            return;
        }

        Modal.confirm({
            title: "是否确认操作？",
            content: "本次数据操作后将无法恢复",
            okText: "确认",
            okType: "danger",
            okButtonProps: {
                icon: <DeleteOutlined />,
            },
            cancelText: "取消",
            cancelButtonProps: {
                icon: <CloseCircleOutlined />,
            },
            onOk: () => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                ChuangShiOS.Util.request(props, {
                    url: Application.host + "/windeal/menu/business/website/v1/delete",
                    data: {
                        menuIdList: menuIdList,
                    },
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                        setMenuIdList([]);

                        handleLoadMenu();
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            },
        });
    };

    const handleChangeMenuIdList = (menuIdList) => {
        setMenuIdList(menuIdList);
    };

    return (
        <div className={Style.page}>
            <div className={Style.header}>
                <div className={Style.header_title}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <span>首页</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span>{store.main.menuName}</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className={Style.header_title_text}>{store.main.sidebarName}</div>
                </div>
                <div className={Style.header_button}>
                    <Space>
                        <Button.Group>
                            <Button icon={<FileAddOutlined />} size="large" onClick={handleAddMenu}>
                                新增
                            </Button>
                            <Button loading={isLoad} disabled={menuIdList.length == 0} icon={<DeleteOutlined />} size="large" onClick={handleDelMenu}>
                                删除
                            </Button>
                        </Button.Group>
                        <Button loading={isLoad} icon={<FileSearchOutlined />} type="primary" size="large" onClick={handleSearchMenu}>
                            查询
                        </Button>
                    </Space>
                </div>
            </div>
            <ChuangShiOS.Content className={Style.content} isLoad={isLoad} isEmpty={false} isError={isError} isScroll={store.main.isFixedContent}>
                <Form form={form}>
                    <Row></Row>
                </Form>
                <div
                    ref={(ref) => {
                        if (ref != null && tableHeight == 0 && tableHeight != ref.clientHeight) {
                            setTableHeight(ref.clientHeight);
                        }
                    }}
                    className={store.main.isFixedContent ? Style.card_table : null}
                >
                    <Table
                        size="small"
                        rowKey={(record) => record.menuId}
                        columns={[
                            {
                                title: "菜单名称",
                                width: 180,
                                dataIndex: "menuName",
                            },
                            {
                                title: "菜单图标",
                                width: 100,
                                render: (text, record, recordIndex) =>
                                    record.menuIcon
                                        ? React.createElement(Icon[record.menuIcon], {
                                              style: {
                                                  fontSize: "22px",
                                              },
                                          })
                                        : null,
                            },
                            {
                                title: "菜单地址",
                                dataIndex: "menuUrl",
                            },
                            {
                                title: "菜单备注",
                                dataIndex: "menuRemark",
                            },
                            {
                                title: "菜单排序",
                                width: 100,
                                dataIndex: "menuSort",
                            },
                            {
                                title: "操作",
                                width: 150,
                                render: (text, record, recordIndex) => (
                                    <Space>
                                        <Button icon={<FileTextOutlined />} size="default" onClick={() => handleEditMenu(record.menuId)}>
                                            修改
                                        </Button>
                                    </Space>
                                ),
                            },
                        ]}
                        dataSource={menuList}
                        rowSelection={{
                            fixed: true,
                            selectedRowKeys: menuIdList,
                            onChange: handleChangeMenuIdList,
                        }}
                        expandable={{
                            expandIcon: ({expanded, onExpand, record}) => {
                                return "";
                            },
                            expandedRowKeys: menuExpandedRowKeyList,
                            indentSize: 30,
                        }}
                        scroll={{y: tableHeight - 90}}
                        pagination={false}
                    />
                </div>
            </ChuangShiOS.Content>
            <div className={Style.footer}></div>
        </div>
    );
};

MenuIndex.propTypes = {};

MenuIndex.defaultProps = {};

export default MenuIndex;
