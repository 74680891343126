import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {message, Modal, Breadcrumb, Form, Row, Col, Input, InputNumber, Switch, DatePicker, Space, Button, Select, Card, Divider, Table} from "antd";
import {
    LeftCircleOutlined,
    FileDoneOutlined,
    PlusCircleOutlined,
    CloseCircleOutlined,
    FileTextOutlined,
    DeleteOutlined,
    FileSearchOutlined,
} from "@ant-design/icons";
import moment from "moment";
import ChuangShiOS from "chuangshi-os-website-component";

import Application from "../../common/Application";

import BannerModal from "./BannerModal";
import GoodsSkuListModal from "./GoodsSkuListModal";
import GoodsSkuEditModal from "./GoodsSkuEditModal";
import GoodsSkuBatchModal from "./GoodsSkuBatchModal";

import Style from "./Detail.module.css";

const ActivityDetail = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [systemVersion, setSystemVersion] = useState(0);
    const [enterpriseList, setEnterpriseList] = useState([]);
    const [activityIsValidate, setActivityIsValidate] = useState(false);
    const [activityIsValidateAfter, setActivityIsValidateAfter] = useState(false);
    const [activityMoneyRuleList, setActivityMoneyRuleList] = useState([]);
    const [activityMemberRuleList, setActivityMemberRuleList] = useState([]);
    const [isActivityBanner, setIsActivityBanner] = useState(false);
    const [activityBanner, setActivityBanner] = useState({});
    const [activityBannerList, setActivityBannerList] = useState([]);
    const [goodsCategoryList, setGoodsCategoryList] = useState([]);
    const [isActivityGoodsSkuList, setIsActivityGoodsSkuList] = useState(false);
    const [provinceList, setProvinceList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [isActivityGoodsSkuEdit, setIsActivityGoodsSkuEdit] = useState(false);
    const [isActivityGoodsSkuBatch, setIsActivityGoodsSkuBatch] = useState(false);
    const [activityCategoryIndex, setActivityCategoryIndex] = useState(-1);
    const [activityCategoryId, setActivityCategoryId] = useState("");
    const [activityGoodsSku, setActivityGoodsSku] = useState({});
    const [goodsSkuCategoryList, setGoodsSkuCategoryList] = useState([]);
    const [activityCategoryList, setActivityCategoryList] = useState([]);
    const activityCategoryRefArray = useRef([]);

    useEffect(() => {
        if (store.main.userName) {
            ChuangShiOS.Notification.emit("main_menu_active", {url: "/activity/index"});

            handleLoadEnterprise();

            handleLoadGoodsCategory();

            handleLoadGoodsSkuCategory();

            switch (props.match.path) {
                case "/activity/add":
                    setIsSave(true);

                    break;
                case "/activity/edit/:activityId":
                    setIsEdit(true);

                    handleLoadProvince();

                    handleLoadActivity();

                    break;
                default:
                    break;
            }
        }

        //eslint-disable-next-line
    }, [store.main.userName]);

    const handleLoadEnterprise = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/enterprise/business/website/v1/select/list",
            data: {},
            success: (response) => {
                setEnterpriseList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadProvince = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/province/business/website/v1/select/list",
            data: {},
            success: (response) => {
                setProvinceList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadCity = (provinceId) => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/city/business/website/v1/select/list",
            data: {
                provinceId: provinceId,
            },
            success: (response) => {
                setCityList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadGoodsCategory = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/goods/category/business/website/v1/select/list",
            data: {},
            success: (response) => {
                setGoodsCategoryList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadGoodsSkuCategory = () => {
        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/goods/sku/category/business/website/v1/select/list",
            data: {},
            success: (response) => {
                setGoodsSkuCategoryList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {},
        });
    };

    const handleLoadActivityGoodsSku = (activityCategoryIndex, activityGoodsSkuPageIndex, activityGoodsSkuPageSize) => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        let data = activityCategoryRefArray.current[activityCategoryIndex].getFieldsValue();
        data.activityCategoryId = activityCategoryList[activityCategoryIndex].activityCategoryId;
        data.pageIndex = activityGoodsSkuPageIndex;
        data.pageSize = activityGoodsSkuPageSize;

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/activity/goods/sku/business/website/v1/list",
            data: data,
            success: (response) => {
                activityCategoryList[activityCategoryIndex].activityGoodsSkuPageIndex = activityGoodsSkuPageIndex;
                activityCategoryList[activityCategoryIndex].activityGoodsSkuPageSize = activityGoodsSkuPageSize;
                activityCategoryList[activityCategoryIndex].activityGoodsSkuTotal = response.data.total;
                activityCategoryList[activityCategoryIndex].activityGoodsSkuList = response.data.list;
                setActivityCategoryList([...activityCategoryList]);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
            },
        });
    };

    const handleLoadActivity = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/activity/business/website/v1/find",
            data: {
                activityId: props.match.params.activityId,
            },
            success: (response) => {
                request.current = false;

                setIsLoad(false);

                response.data.activityStart = moment(response.data.activityStart, "YYYY-MM-DD");
                response.data.activityEnd = moment(response.data.activityEnd, "YYYY-MM-DD");

                form.setFieldsValue(response.data);

                setSystemVersion(response.data.systemVersion);

                let activityMoneyRuleList = response.data.activityMoneyRuleList;
                for (let i = 0; i < activityMoneyRuleList.length; i++) {
                    activityMoneyRuleList[i].key = ChuangShiOS.Util.getId();
                }
                setActivityMoneyRuleList(activityMoneyRuleList);

                let activityMemberRuleList = response.data.activityMemberRuleList;
                for (let i = 0; i < activityMemberRuleList.length; i++) {
                    activityMemberRuleList[i].key = ChuangShiOS.Util.getId();
                }
                setActivityMemberRuleList(activityMemberRuleList);

                setActivityBannerList(response.data.activityBannerList);

                handleLoadActivityCategory();
            },
            error: (response) => {
                request.current = false;

                setIsLoad(false);

                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                setIsError(typeof response.data == "undefined" || typeof response.data.activityId == "undefined" || response.data.activityId == null);
            },
        });
    };

    const handleSubmitActivity = () => {
        if (request.current) {
            return;
        }

        form.validateFields()
            .then((data) => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                data.activityId = props.match.params.activityId;
                data.activityStart = data.activityStart.format("YYYY-MM-DD");
                data.activityEnd = data.activityEnd.format("YYYY-MM-DD");
                data.activityMoneyRuleList = activityMoneyRuleList;
                data.activityMemberRuleList = activityMemberRuleList;
                data.activityCategoryList = activityCategoryList;
                data.systemVersion = systemVersion;

                let url = "";
                if (isSave) {
                    url = "/windeal/activity/business/website/v1/save";
                }
                if (isEdit) {
                    url = "/windeal/activity/business/website/v1/update";
                }

                ChuangShiOS.Util.request(props, {
                    url: Application.host + url,
                    data: data,
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);

                        ChuangShiOS.Util.navigateBack(props, {});
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            })
            .catch((response) => {
                ChuangShiOS.Util.parseResponseError(response);
            });
    };

    const handleBackActivity = () => {
        ChuangShiOS.Util.navigateBack(props, {});
    };

    const handleAddActivityMoneyRule = () => {
        activityMoneyRuleList.push({
            key: ChuangShiOS.Util.getId(),
            activityRuleType: null,
            activityRuleAmount: 0,
        });

        setActivityMoneyRuleList([...activityMoneyRuleList]);
    };

    const handleDelActivityMoneyRule = (index) => {
        Modal.confirm({
            title: "是否确认操作？",
            content: "本次数据操作后将无法恢复",
            okText: "确认",
            cancelText: "取消",
            onOk: () => {
                activityMoneyRuleList.splice(index, 1);

                setActivityMoneyRuleList([...activityMoneyRuleList]);
            },
        });
    };

    const handleChangeActivityMoneyRule = (event, index, name) => {
        if (name == "activityRuleType" || name == "activityRuleAmount") {
            activityMoneyRuleList[index][name] = event;
        } else {
            activityMoneyRuleList[index][name] = event.target.value;
        }

        setActivityMoneyRuleList([...activityMoneyRuleList]);
    };

    const handleAddActivityMemberRule = () => {
        activityMemberRuleList.push({
            key: ChuangShiOS.Util.getId(),
            activityRuleType: null,
            activityRuleQuantity: 0,
        });

        setActivityMemberRuleList([...activityMemberRuleList]);
    };

    const handleDelActivityMemberRule = (index) => {
        Modal.confirm({
            title: "是否确认操作？",
            content: "本次数据操作后将无法恢复",
            okText: "确认",
            cancelText: "取消",
            onOk: () => {
                activityMemberRuleList.splice(index, 1);

                setActivityMemberRuleList([...activityMemberRuleList]);
            },
        });
    };

    const handleChangeActivityMemberRule = (event, index, name) => {
        if (name == "activityRuleType" || name == "activityRuleQuantity") {
            activityMemberRuleList[index][name] = event;
        } else {
            activityMemberRuleList[index][name] = event.target.value;
        }

        setActivityMemberRuleList([...activityMemberRuleList]);
    };

    const handleLoadActivityCategory = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/activity/category/business/website/v1/list",
            data: {
                activityId: props.match.params.activityId,
                pageIndex: 1,
                pageSize: 10,
            },
            success: (response) => {
                for (let i = 0; i < response.data.length; i++) {
                    response.data[i].activityGoodsSkuPageIndex = 1;
                    response.data[i].activityGoodsSkuPageSize = 10;
                    response.data[i].activityGoodsSkuIdList = [];
                }
                setActivityCategoryList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
            },
        });
    };

    const handleAddActivityCategoryList = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        message.loading({
            content: "正在加载",
            key: "isLoad",
            duration: 0,
        });

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/activity/category/business/website/v1/save",
            data: {
                activityId: props.match.params.activityId,
            },
            success: (response) => {
                message.success({
                    content: response.message,
                    key: "isLoad",
                    duration: 2,
                });

                request.current = false;

                setIsLoad(false);

                handleLoadActivityCategory();
            },
            error: (response) => {
                message.error({
                    content: response.message,
                    key: "isLoad",
                    duration: 2,
                });

                request.current = false;

                setIsLoad(false);
            },
            complete: (response) => {},
        });
    };

    const handleDelActivityCategoryList = (activityCategoryId) => {
        if (request.current) {
            return;
        }

        Modal.confirm({
            title: "是否确认操作？",
            content: "本次数据操作后将无法恢复",
            okText: "确认",
            cancelText: "取消",
            onOk: () => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                ChuangShiOS.Util.request(props, {
                    url: Application.host + "/windeal/activity/category/business/website/v1/delete",
                    data: {
                        activityCategoryId: activityCategoryId,
                    },
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);

                        handleLoadActivityCategory();
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            },
        });
    };

    const handleChangeActivityCategory = (event, index, name) => {
        if (name == "activityCategoryImage" || name == "activityCategoryQuantity") {
            activityCategoryList[index][name] = event;
        } else {
            activityCategoryList[index][name] = event.target.value;
        }

        setActivityCategoryList([...activityCategoryList]);
    };

    const handleOpenActivityGoodsSkuList = (activityCategoryId) => {
        setIsActivityGoodsSkuList(true);
        setActivityCategoryId(activityCategoryId);
    };

    const handleCloseActivityGoodsSkuList = () => {
        setIsActivityGoodsSkuList(false);
    };

    const handleSubmitActivityGoodsSkuList = () => {
        setIsActivityGoodsSkuList(false);

        let activityCategoryIndex = -1;
        for (let i = 0; i < activityCategoryList.length; i++) {
            if (activityCategoryList[i].activityCategoryId == activityCategoryId) {
                activityCategoryIndex = i;

                break;
            }
        }

        handleLoadActivityGoodsSku(
            activityCategoryIndex,
            activityCategoryList[activityCategoryIndex].activityGoodsSkuPageIndex,
            activityCategoryList[activityCategoryIndex].activityGoodsSkuPageSize
        );
    };

    const handleChangeActivityGoodsSkuIdList = (activityGoodsSkuIdList, activityCategoryIndex) => {
        activityCategoryList[activityCategoryIndex].activityGoodsSkuIdList = activityGoodsSkuIdList;

        setActivityCategoryList([...activityCategoryList]);
    };

    const handleChangeActivityGoodsSkuPagination = (activityGoodsSkuPageIndex, activityGoodsSkuPageSize, activityCategoryIndex) => {
        handleLoadActivityGoodsSku(activityCategoryIndex, activityGoodsSkuPageIndex, activityGoodsSkuPageSize);
    };

    const handleOpenActivityGoodsSkuEdit = (activityGoodsSku, activityCategoryId, activityCategoryIndex) => {
        setIsActivityGoodsSkuEdit(true);
        setActivityGoodsSku(activityGoodsSku);
        setActivityCategoryId(activityCategoryId);
        setActivityCategoryIndex(activityCategoryIndex);
    };

    const handleCloseActivityGoodsSkuEdit = () => {
        setIsActivityGoodsSkuEdit(false);
    };

    const handleSubmitActivityGoodsSkuEdit = () => {
        setIsActivityGoodsSkuEdit(false);

        handleLoadActivityGoodsSku(
            activityCategoryIndex,
            activityCategoryList[activityCategoryIndex].activityGoodsSkuPageIndex,
            activityCategoryList[activityCategoryIndex].activityGoodsSkuPageSize
        );
    };

    const handleDeleteActivityGoodsSku = (activityCategoryIndex) => {
        if (activityCategoryList[activityCategoryIndex].activityGoodsSkuIdList.length == 0) {
            return;
        }

        if (request.current) {
            return;
        }

        Modal.confirm({
            title: "是否确认操作？",
            content: "本次数据操作后将无法恢复",
            okText: "确认",
            okType: "danger",
            okButtonProps: {
                icon: <DeleteOutlined />,
            },
            cancelText: "取消",
            cancelButtonProps: {
                icon: <CloseCircleOutlined />,
            },
            onOk: () => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                ChuangShiOS.Util.request(props, {
                    url: Application.host + "/windeal/activity/goods/sku/business/website/v1/delete",
                    data: {
                        activityGoodsSkuIdList: activityCategoryList[activityCategoryIndex].activityGoodsSkuIdList,
                    },
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);

                        activityCategoryList[activityCategoryIndex].activityGoodsSkuIdList = [];
                        setActivityCategoryList([...activityCategoryList]);

                        handleLoadActivityGoodsSku(
                            activityCategoryIndex,
                            activityCategoryList[activityCategoryIndex].activityGoodsSkuPageIndex,
                            activityCategoryList[activityCategoryIndex].activityGoodsSkuPageSize
                        );
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            },
        });
    };

    const handleOpenActivityGoodsSkuBatch = (activityCategoryIndex) => {
        setIsActivityGoodsSkuBatch(true);
        setActivityCategoryIndex(activityCategoryIndex);
    };

    const handleCloseActivityGoodsSkuBatch = () => {
        setIsActivityGoodsSkuBatch(false);
    };

    const handleSubmitActivityGoodsSkuBatch = () => {
        setIsActivityGoodsSkuBatch(false);

        activityCategoryList[activityCategoryIndex].activityGoodsSkuIdList = [];
        setActivityCategoryList([...activityCategoryList]);

        handleLoadActivityGoodsSku(
            activityCategoryIndex,
            activityCategoryList[activityCategoryIndex].activityGoodsSkuPageIndex,
            activityCategoryList[activityCategoryIndex].activityGoodsSkuPageSize
        );
    };

    const handleOpenActivityBanner = (activityBanner) => {
        activityBanner.activityId = props.match.params.activityId;

        setIsActivityBanner(true);
        setActivityBanner(activityBanner);
    };

    const handleCloseActivityBanner = () => {
        setIsActivityBanner(false);
    };

    const handleSubmitActivityBanner = () => {
        setIsActivityBanner(false);

        handleLoadActivityBanner();
    };

    const handleLoadActivityBanner = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/activity/banner/business/website/v1/list",
            data: {
                activityId: props.match.params.activityId,
            },
            success: (response) => {
                setActivityBannerList(response.data);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;

                setIsLoad(false);
            },
        });
    };

    const handleDelActivityBanner = (activityBannerId) => {
        if (request.current) {
            return;
        }

        Modal.confirm({
            title: "是否确认操作？",
            content: "本次数据操作后将无法恢复",
            okText: "确认",
            cancelText: "取消",
            onOk: () => {
                if (request.current) {
                    return;
                }
                request.current = true;

                setIsLoad(true);

                message.loading({
                    content: "正在加载",
                    key: "isLoad",
                    duration: 0,
                });

                ChuangShiOS.Util.request(props, {
                    url: Application.host + "/windeal/activity/banner/business/website/v1/delete",
                    data: {
                        activityBannerId: activityBannerId,
                    },
                    success: (response) => {
                        message.success({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);

                        handleLoadActivityBanner();
                    },
                    error: (response) => {
                        message.error({
                            content: response.message,
                            key: "isLoad",
                            duration: 2,
                        });

                        request.current = false;

                        setIsLoad(false);
                    },
                    complete: (response) => {},
                });
            },
        });
    };

    return (
        <div className={Style.page}>
            <div className={Style.header}>
                <div className={Style.header_title}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <span>首页</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span>{store.main.menuName}</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className={Style.header_title_text}>{store.main.sidebarName}</div>
                </div>
                <div className={Style.header_button}>
                    <Space>
                        <Button.Group>
                            <Button icon={<LeftCircleOutlined />} size="large" onClick={handleBackActivity}>
                                返回
                            </Button>
                        </Button.Group>
                        <Button
                            loading={isLoad}
                            disabled={isError}
                            icon={<FileDoneOutlined />}
                            type="primary"
                            size="large"
                            onClick={handleSubmitActivity}
                        >
                            提交
                        </Button>
                    </Space>
                </div>
            </div>
            <ChuangShiOS.Content
                className={Style.content}
                isLoad={isLoad}
                isEmpty={false}
                isError={isError}
                paddingTop={0}
                paddingRight={0}
                paddingBottom={0}
                paddingLeft={0}
                isScroll={store.main.isFixedContent}
            >
                <Card>
                    <Row>
                        <Col flex="auto">
                            <div className={Style.card_title}>基本信息</div>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Form form={form}>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "24%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="企业名称"
                                    name="enterpriseId"
                                    initialValue={null}
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入企业名称",
                                        },
                                    ]}
                                >
                                    <Select className={Style.card_field} placeholder="请选择企业名称" allowClear onChange={() => {}}>
                                        {enterpriseList.map((enterprise, enterpriseIndex) => {
                                            return (
                                                <Select.Option key={enterpriseIndex} value={enterprise.enterpriseId}>
                                                    {enterprise.enterpriseName}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "24%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="活动名称"
                                    name="activityName"
                                    initialValue={""}
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入活动名称",
                                        },
                                    ]}
                                >
                                    <Input className={Style.card_field} placeholder="请输入活动名称" onPressEnter={handleSubmitActivity} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "24%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="开始日期"
                                    name="activityStart"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择开始日期",
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        className={Style.card_field}
                                        format="YYYY-MM-DD"
                                        placeholder="请选择开始日期"
                                        onPressEnter={handleSubmitActivity}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "24%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="结束日期"
                                    name="activityEnd"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择结束日期",
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        className={Style.card_field}
                                        format="YYYY-MM-DD"
                                        placeholder="请选择结束日期"
                                        onPressEnter={handleSubmitActivity}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "12%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="活动封面"
                                    name="activityImage"
                                    initialValue={""}
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择活动封面",
                                        },
                                    ]}
                                >
                                    <ChuangShiOS.File fileType={"IMAGE"} limit={1} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "24%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="验证会员"
                                    name="activityIsValidate"
                                    initialValue={false}
                                    valuePropName="checked"
                                    rules={[
                                        {
                                            required: false,
                                            message: "请选择验证会员",
                                        },
                                    ]}
                                >
                                    <Switch
                                        checkedChildren="开启"
                                        unCheckedChildren="关闭"
                                        onChange={(event) => {
                                            setActivityIsValidate(event);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        {activityIsValidate ? (
                            <React.Fragment>
                                <Row>
                                    <Col span={12}>
                                        <Form.Item
                                            {...{
                                                labelCol: {flex: "24%"},
                                                wrapperCol: {flex: "auto"},
                                            }}
                                            label="验证提示"
                                            name="activityValidateMessage"
                                            initialValue={""}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入验证提示",
                                                },
                                            ]}
                                        >
                                            <Input className={Style.card_field} placeholder="请输入验证提示" onPressEnter={handleSubmitActivity} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Form.Item
                                            {...{
                                                labelCol: {flex: "24%"},
                                                wrapperCol: {flex: "auto"},
                                            }}
                                            label="卡前几位"
                                            name="activityValidateBeforeLength"
                                            initialValue={0}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入卡前几位",
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                className={Style.card_field}
                                                min={0}
                                                max={999999}
                                                placeholder="请输入卡前几位"
                                                onPressEnter={handleSubmitActivity}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Form.Item
                                            {...{
                                                labelCol: {flex: "24%"},
                                                wrapperCol: {flex: "auto"},
                                            }}
                                            label="验证卡后"
                                            name="activityIsValidateAfter"
                                            initialValue={false}
                                            valuePropName="checked"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: "请选择验证卡后",
                                                },
                                            ]}
                                        >
                                            <Switch
                                                checkedChildren="开启"
                                                unCheckedChildren="关闭"
                                                onChange={(event) => {
                                                    setActivityIsValidateAfter(event);
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {activityIsValidateAfter ? (
                                    <Row>
                                        <Col span={12}>
                                            <Form.Item
                                                {...{
                                                    labelCol: {flex: "24%"},
                                                    wrapperCol: {flex: "auto"},
                                                }}
                                                label="卡后几位"
                                                name="activityValidateAfterLength"
                                                initialValue={0}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "请输入卡后几位",
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    className={Style.card_field}
                                                    min={0}
                                                    max={999999}
                                                    placeholder="请输入卡后几位"
                                                    onPressEnter={handleSubmitActivity}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                ) : null}
                            </React.Fragment>
                        ) : null}
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "24%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="在线支付"
                                    name="activityIsPayment"
                                    initialValue={false}
                                    valuePropName="checked"
                                    rules={[
                                        {
                                            required: false,
                                            message: "请选择在线支付",
                                        },
                                    ]}
                                >
                                    <Switch checkedChildren="开启" unCheckedChildren="关闭" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "24%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="兑换次数"
                                    name="activityIsQuantity"
                                    initialValue={false}
                                    valuePropName="checked"
                                    rules={[
                                        {
                                            required: false,
                                            message: "请选择兑换次数",
                                        },
                                    ]}
                                >
                                    <Switch checkedChildren="开启" unCheckedChildren="关闭" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "24%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="隐藏底部"
                                    name="activityIsHidden"
                                    initialValue={false}
                                    valuePropName="checked"
                                    rules={[
                                        {
                                            required: false,
                                            message: "请选择隐藏底部",
                                        },
                                    ]}
                                >
                                    <Switch checkedChildren="开启" unCheckedChildren="关闭" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    {...{
                                        labelCol: {flex: "12%"},
                                        wrapperCol: {flex: "auto"},
                                    }}
                                    label="活动协议"
                                    name="activityAgreement"
                                    initialValue={""}
                                    rules={[
                                        {
                                            required: false,
                                            message: "请输入活动协议",
                                        },
                                    ]}
                                >
                                    <ChuangShiOS.Editor id="activityCondition" height={300} placeholder="请输入活动协议" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Divider />
                    <Row>
                        <Col flex="auto">
                            <div className={Style.card_title}>资金规则</div>
                        </Col>
                        <Col>
                            <Button
                                icon={<PlusCircleOutlined />}
                                onClick={() => {
                                    handleAddActivityMoneyRule();
                                }}
                            >
                                新增
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col flex={"12%"}></Col>
                        <Col flex="auto">
                            <Table
                                size="small"
                                rowKey={(record) => record.key}
                                columns={[
                                    {
                                        title: "规则类型",
                                        render: (text, record, recordIndex) => (
                                            <Select
                                                value={record.activityRuleType}
                                                className={Style.table_field}
                                                placeholder="请选择规则类型"
                                                allowClear
                                                onChange={(value) => {
                                                    handleChangeActivityMoneyRule(value, recordIndex, "activityRuleType");
                                                }}
                                            >
                                                <Select.Option value="DAY">每天</Select.Option>
                                                <Select.Option value="WEEK">每周</Select.Option>
                                                <Select.Option value="MONTH">每月</Select.Option>
                                                <Select.Option value="SEASON">每季</Select.Option>
                                                <Select.Option value="YEAR">每年</Select.Option>
                                                <Select.Option value="ALL">全部</Select.Option>
                                            </Select>
                                        ),
                                    },
                                    {
                                        title: "规则金额",
                                        render: (text, record, recordIndex) => (
                                            <InputNumber
                                                value={record.activityRuleAmount}
                                                className={Style.table_field}
                                                min={0}
                                                max={999999}
                                                placeholder="请输入规则金额"
                                                onChange={(value) => {
                                                    handleChangeActivityMoneyRule(value, recordIndex, "activityRuleAmount");
                                                }}
                                            />
                                        ),
                                    },
                                    {
                                        title: "操作",
                                        width: 150,
                                        render: (text, record, recordIndex) => (
                                            <Space>
                                                <Button
                                                    icon={<CloseCircleOutlined />}
                                                    onClick={() => {
                                                        handleDelActivityMoneyRule(recordIndex);
                                                    }}
                                                >
                                                    删除
                                                </Button>
                                            </Space>
                                        ),
                                    },
                                ]}
                                dataSource={activityMoneyRuleList}
                                pagination={false}
                            />
                            <br />
                        </Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col flex="auto">
                            <div className={Style.card_title}>会员规则</div>
                        </Col>
                        <Col>
                            <Button
                                icon={<PlusCircleOutlined />}
                                onClick={() => {
                                    handleAddActivityMemberRule();
                                }}
                            >
                                新增
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col flex={"12%"}></Col>
                        <Col flex="auto">
                            <Table
                                size="small"
                                rowKey={(record) => record.key}
                                columns={[
                                    {
                                        title: "规则类型",
                                        render: (text, record, recordIndex) => (
                                            <Select
                                                value={record.activityRuleType}
                                                className={Style.table_field}
                                                placeholder="请选择规则类型"
                                                allowClear
                                                onChange={(event) => {
                                                    handleChangeActivityMemberRule(event, recordIndex, "activityRuleType");
                                                }}
                                            >
                                                <Select.Option value="DAY">每天</Select.Option>
                                                <Select.Option value="WEEK">每周</Select.Option>
                                                <Select.Option value="MONTH">每月</Select.Option>
                                                <Select.Option value="SEASON">每季</Select.Option>
                                                <Select.Option value="YEAR">每年</Select.Option>
                                                <Select.Option value="ALL">全部</Select.Option>
                                            </Select>
                                        ),
                                    },
                                    {
                                        title: "规则次数",
                                        render: (text, record, recordIndex) => (
                                            <InputNumber
                                                value={record.activityRuleQuantity}
                                                className={Style.table_field}
                                                min={0}
                                                max={999999}
                                                placeholder="请输入规则次数"
                                                onChange={(event) => {
                                                    handleChangeActivityMemberRule(event, recordIndex, "activityRuleQuantity");
                                                }}
                                            />
                                        ),
                                    },
                                    {
                                        title: "操作",
                                        width: 150,
                                        render: (text, record, recordIndex) => (
                                            <Space>
                                                <Button
                                                    icon={<CloseCircleOutlined />}
                                                    onClick={() => {
                                                        handleDelActivityMemberRule(recordIndex);
                                                    }}
                                                >
                                                    删除
                                                </Button>
                                            </Space>
                                        ),
                                    },
                                ]}
                                dataSource={activityMemberRuleList}
                                pagination={false}
                            />
                            <br />
                        </Col>
                    </Row>
                </Card>
                <Card className={Style.card_top}>
                    <Row>
                        <Col flex="auto">
                            <div className={Style.card_title}>活动广告</div>
                        </Col>
                        <Col>
                            <Space>
                                <Button.Group>
                                    <Button
                                        icon={<PlusCircleOutlined />}
                                        onClick={() => {
                                            handleOpenActivityBanner({});
                                        }}
                                    >
                                        新增
                                    </Button>
                                </Button.Group>
                                <Button
                                    icon={<FileSearchOutlined />}
                                    type="primary"
                                    onClick={() => {
                                        handleLoadActivityBanner();
                                    }}
                                >
                                    查询
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                    <Row>
                        <Col flex={"12%"}></Col>
                        <Col flex="auto">
                            <Table
                                size="small"
                                rowKey={(record) => record.activityBannerId}
                                columns={[
                                    {
                                        title: "广告图片",
                                        width: 120,
                                        render: (text, record, recordIndex) => (
                                            <ChuangShiOS.Image
                                                width={110}
                                                src={Application.imagePreviewHost + ChuangShiOS.Util.scaleImage(record.activityBannerImage, 120)}
                                            />
                                        ),
                                    },
                                    {
                                        title: "广告地址",
                                        dataIndex: "activityBannerUrl",
                                    },
                                    {
                                        title: "广告排序",
                                        dataIndex: "activityBannerSort",
                                    },
                                    {
                                        title: "操作",
                                        width: 200,
                                        render: (text, record, recordIndex) => (
                                            <Space>
                                                <Button
                                                    icon={<FileTextOutlined />}
                                                    onClick={() => {
                                                        handleOpenActivityBanner(record);
                                                    }}
                                                >
                                                    修改
                                                </Button>
                                                <Button
                                                    icon={<CloseCircleOutlined />}
                                                    onClick={() => {
                                                        handleDelActivityBanner(record.activityBannerId);
                                                    }}
                                                >
                                                    删除
                                                </Button>
                                            </Space>
                                        ),
                                    },
                                ]}
                                dataSource={activityBannerList}
                                pagination={false}
                            />
                            <br />
                        </Col>
                    </Row>
                </Card>
                {activityCategoryList.map((activityCategory, activityCategoryIndex) => {
                    return (
                        <Card key={activityCategoryIndex} className={Style.card_top}>
                            <Row>
                                <Col flex="auto">
                                    <div className={Style.card_title}>活动分类</div>
                                </Col>
                                <Col>
                                    <Button
                                        icon={<DeleteOutlined />}
                                        onClick={() => {
                                            handleDelActivityCategoryList(activityCategory.activityCategoryId);
                                        }}
                                    >
                                        删除该分类
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "24%"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="分类图片"
                                    >
                                        <ChuangShiOS.File
                                            value={activityCategory.activityCategoryImage}
                                            fileType={"IMAGE"}
                                            limit={1}
                                            onChange={(event) => {
                                                handleChangeActivityCategory(event, activityCategoryIndex, "activityCategoryImage");
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "24%"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="分类名称"
                                    >
                                        <Input
                                            value={activityCategory.activityCategoryName}
                                            className={Style.card_field}
                                            placeholder="请输入分类名称"
                                            onChange={(event) => {
                                                handleChangeActivityCategory(event, activityCategoryIndex, "activityCategoryName");
                                            }}
                                            onPressEnter={handleSubmitActivity}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        {...{
                                            labelCol: {flex: "24%"},
                                            wrapperCol: {flex: "auto"},
                                        }}
                                        label="分类备注"
                                    >
                                        <Input
                                            value={activityCategory.activityCategoryRemark}
                                            className={Style.card_field}
                                            placeholder="请输入分类备注"
                                            onChange={(event) => {
                                                handleChangeActivityCategory(event, activityCategoryIndex, "activityCategoryRemark");
                                            }}
                                            onPressEnter={handleSubmitActivity}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider />
                            <Row>
                                <Col flex="auto">
                                    <div className={Style.card_title}>商品信息</div>
                                </Col>
                                <Col>
                                    <Space>
                                        <Button.Group>
                                            <Button
                                                icon={<PlusCircleOutlined />}
                                                onClick={() => {
                                                    handleOpenActivityGoodsSkuList(activityCategory.activityCategoryId);
                                                }}
                                            >
                                                新增
                                            </Button>
                                            <Button
                                                loading={isLoad}
                                                disabled={activityCategory.activityGoodsSkuIdList.length == 0}
                                                icon={<FileTextOutlined />}
                                                onClick={() => {
                                                    handleOpenActivityGoodsSkuBatch(activityCategoryIndex);
                                                }}
                                            >
                                                批量设置
                                            </Button>
                                            <Button
                                                loading={isLoad}
                                                disabled={activityCategory.activityGoodsSkuIdList.length == 0}
                                                icon={<DeleteOutlined />}
                                                onClick={() => {
                                                    handleDeleteActivityGoodsSku(activityCategoryIndex);
                                                }}
                                            >
                                                删除
                                            </Button>
                                        </Button.Group>
                                        <Button
                                            icon={<FileSearchOutlined />}
                                            type="primary"
                                            onClick={() => {
                                                handleLoadActivityGoodsSku(
                                                    activityCategoryIndex,
                                                    activityCategoryList[activityCategoryIndex].activityGoodsSkuPageIndex,
                                                    activityCategoryList[activityCategoryIndex].activityGoodsSkuPageSize
                                                );
                                            }}
                                        >
                                            查询
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                            <Row>
                                {/* <Col flex={"12%"}></Col> */}
                                <Col flex="auto" className={Style.card_value}>
                                    <Form
                                        ref={(ref) => {
                                            activityCategoryRefArray.current[activityCategoryIndex] = ref;
                                        }}
                                    >
                                        <Row>
                                            <Col span={6}>
                                                <Form.Item
                                                    {...{
                                                        labelCol: {flex: "none"},
                                                        wrapperCol: {flex: "auto"},
                                                    }}
                                                    label="规格分类"
                                                    labelAlign={"left"}
                                                    name="goodsSkuCategoryId"
                                                    initialValue={null}
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: "请输入规格分类",
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        className={Style.card_field_2}
                                                        placeholder="请选择规格分类"
                                                        allowClear
                                                        onChange={(event) => {
                                                            handleLoadActivityGoodsSku(
                                                                activityCategoryIndex,
                                                                activityCategoryList[activityCategoryIndex].activityGoodsSkuPageIndex,
                                                                activityCategoryList[activityCategoryIndex].activityGoodsSkuPageSize
                                                            );
                                                        }}
                                                    >
                                                        {goodsSkuCategoryList.map((goodsSkuCategory, goodsSkuCategoryIndex) => {
                                                            return (
                                                                <Select.Option
                                                                    key={goodsSkuCategoryIndex}
                                                                    value={goodsSkuCategory.goodsSkuCategoryId}
                                                                >
                                                                    {goodsSkuCategory.goodsSkuCategoryName}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item
                                                    {...{
                                                        labelCol: {flex: "none"},
                                                        wrapperCol: {flex: "auto"},
                                                    }}
                                                    label="商品分类"
                                                    labelAlign={"left"}
                                                    name="goodsCategoryId"
                                                    initialValue={null}
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: "请输入商品分类",
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        className={Style.card_field_2}
                                                        placeholder="请选择商品分类"
                                                        allowClear
                                                        onChange={(event) => {
                                                            handleLoadActivityGoodsSku(
                                                                activityCategoryIndex,
                                                                activityCategoryList[activityCategoryIndex].activityGoodsSkuPageIndex,
                                                                activityCategoryList[activityCategoryIndex].activityGoodsSkuPageSize
                                                            );
                                                        }}
                                                    >
                                                        {goodsCategoryList.map((goodsCategory, goodsCategoryIndex) => {
                                                            return (
                                                                <Select.Option key={goodsCategoryIndex} value={goodsCategory.goodsCategoryId}>
                                                                    {goodsCategory.goodsCategoryName}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item
                                                    {...{
                                                        labelCol: {flex: "none"},
                                                        wrapperCol: {flex: "auto"},
                                                    }}
                                                    label="省份名称"
                                                    labelAlign={"left"}
                                                    name="provinceId"
                                                    initialValue={null}
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: "请选择省份名称",
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        className={Style.card_field_2}
                                                        placeholder="请选择省份名称"
                                                        allowClear
                                                        onChange={(event) => {
                                                            activityCategoryRefArray.current[activityCategoryIndex].setFieldsValue({
                                                                cityId: null,
                                                            });

                                                            setCityList([]);

                                                            if (event) {
                                                                handleLoadCity(event);
                                                            }

                                                            handleLoadActivityGoodsSku(
                                                                activityCategoryIndex,
                                                                activityCategoryList[activityCategoryIndex].activityGoodsSkuPageIndex,
                                                                activityCategoryList[activityCategoryIndex].activityGoodsSkuPageSize
                                                            );
                                                        }}
                                                    >
                                                        {provinceList.map((province, provinceIndex) => {
                                                            return (
                                                                <Select.Option key={provinceIndex} value={province.provinceId}>
                                                                    {province.provinceName}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item
                                                    {...{
                                                        labelCol: {flex: "none"},
                                                        wrapperCol: {flex: "auto"},
                                                    }}
                                                    label="城市名称"
                                                    labelAlign={"left"}
                                                    name="cityId"
                                                    initialValue={null}
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: "请选择城市名称",
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        className={Style.card_field_2}
                                                        placeholder="请选择城市名称"
                                                        allowClear
                                                        onChange={(event) => {
                                                            handleLoadActivityGoodsSku(
                                                                activityCategoryIndex,
                                                                activityCategoryList[activityCategoryIndex].activityGoodsSkuPageIndex,
                                                                activityCategoryList[activityCategoryIndex].activityGoodsSkuPageSize
                                                            );
                                                        }}
                                                    >
                                                        {cityList.map((city, cityIndex) => {
                                                            return (
                                                                <Select.Option key={cityIndex} value={city.cityId}>
                                                                    {city.cityName}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item
                                                    {...{
                                                        labelCol: {flex: "none"},
                                                        wrapperCol: {flex: "auto"},
                                                    }}
                                                    label="商户名称"
                                                    labelAlign={"left"}
                                                    name="merchantName"
                                                    initialValue={""}
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: "请输入商户名称",
                                                        },
                                                    ]}
                                                >
                                                    <Input className={Style.card_field_2} placeholder="请输入商户名称" onPressEnter={() => {}} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item
                                                    {...{
                                                        labelCol: {flex: "none"},
                                                        wrapperCol: {flex: "auto"},
                                                    }}
                                                    label="门店名称"
                                                    labelAlign={"left"}
                                                    name="shopName"
                                                    initialValue={""}
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: "请输入门店名称",
                                                        },
                                                    ]}
                                                >
                                                    <Input className={Style.card_field_2} placeholder="请输入门店名称" onPressEnter={() => {}} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <Table
                                        size="large"
                                        rowKey={(record) => record.activityGoodsSkuId}
                                        columns={[
                                            {
                                                title: "商户",
                                                width: 140,
                                                ellipsis: true,
                                                dataIndex: "merchantName",
                                            },
                                            {
                                                title: "门店",
                                                width: 100,
                                                ellipsis: true,
                                                dataIndex: "shopName",
                                            },
                                            {
                                                title: "规格名称",
                                                width: 120,
                                                ellipsis: true,
                                                dataIndex: "goodsSkuName",
                                            },
                                            {
                                                title: "可定日期",
                                                width: 85,
                                                render: (text, record, recordIndex) => (
                                                    <React.Fragment>
                                                        {record.goodsReserveWeekList.sort().map((goodsReserveWeek, goodsReserveWeekIndex) => {
                                                            return (
                                                                <span key={goodsReserveWeekIndex}>
                                                                    {goodsReserveWeek == 0 ? null : goodsReserveWeek}
                                                                    {goodsReserveWeekIndex + 1 < record.goodsReserveWeekList.length &&
                                                                    goodsReserveWeek > 0
                                                                        ? ","
                                                                        : null}
                                                                </span>
                                                            );
                                                        })}
                                                        {record.goodsReserveWeekList.indexOf(0) > -1
                                                            ? (record.goodsReserveWeekList.length > 1 ? "," : "") + "7"
                                                            : null}
                                                    </React.Fragment>
                                                ),
                                            },
                                            {
                                                title: "可定时间",
                                                width: 85,
                                                render: (text, record, recordIndex) => (
                                                    <React.Fragment>
                                                        {record.goodsReserveStartTime}~{record.goodsReserveEndTime}
                                                    </React.Fragment>
                                                ),
                                            },
                                            {
                                                title: "市场价",
                                                width: 60,
                                                dataIndex: "goodsSkuMarketPrice",
                                            },
                                            {
                                                title: "餐价",
                                                width: 60,
                                                dataIndex: "goodsSkuSalePrice",
                                            },
                                            {
                                                title: "服务费比例",
                                                width: 80,
                                                dataIndex: "goodsSkuServiceChargeRatio",
                                            },
                                            {
                                                title: "销售价",
                                                width: 60,
                                                render: (text, record, recordIndex) => (
                                                    <React.Fragment>
                                                        {record.goodsSkuSalePrice + record.goodsSkuSalePrice * record.goodsSkuServiceChargeRatio}
                                                    </React.Fragment>
                                                ),
                                            },
                                            {
                                                title: "补贴价",
                                                width: 60,
                                                dataIndex: "goodsSkuAllowancePrice",
                                            },
                                            {
                                                title: "结算价",
                                                width: 60,
                                                dataIndex: "goodsSkuSettlementPrice",
                                            },
                                            {
                                                title: "规格类型",
                                                width: 110,
                                                dataIndex: "goodsSkuCategoryName",
                                            },
                                            {
                                                title: "城市",
                                                width: 100,
                                                dataIndex: "cityName",
                                            },
                                            {
                                                title: "操作",
                                                fixed: "right",
                                                width: 75,
                                                render: (text, record, recordIndex) => (
                                                    <Space>
                                                        <Button
                                                            icon={<FileTextOutlined />}
                                                            size="default"
                                                            onClick={() => {
                                                                handleOpenActivityGoodsSkuEdit(
                                                                    record,
                                                                    activityCategory.activityCategoryId,
                                                                    activityCategoryIndex
                                                                );
                                                            }}
                                                        >
                                                            修改
                                                        </Button>
                                                    </Space>
                                                ),
                                            },
                                        ]}
                                        dataSource={activityCategory.activityGoodsSkuList}
                                        rowSelection={{
                                            fixed: true,
                                            selectedRowKeys: activityCategory.activityGoodsSkuIdList,
                                            onChange: (activityGoodsSkuIdList) => {
                                                handleChangeActivityGoodsSkuIdList(activityGoodsSkuIdList, activityCategoryIndex);
                                            },
                                        }}
                                        scroll={{x: 1800}}
                                        pagination={{
                                            size: "default",
                                            showQuickJumper: true,
                                            showSizeChanger: true,
                                            current: activityCategory.activityGoodsSkuPageIndex,
                                            pageSize: activityCategory.activityGoodsSkuPageSize,
                                            total: activityCategory.activityGoodsSkuTotal,
                                            showTotal: (total, range) => "显示" + range[0] + "到" + range[1] + "条,共有" + total + "条",
                                            onChange: (activityGoodsSkuPageIndex, activityGoodsSkuPageSize) => {
                                                handleChangeActivityGoodsSkuPagination(
                                                    activityGoodsSkuPageIndex,
                                                    activityGoodsSkuPageSize,
                                                    activityCategoryIndex
                                                );
                                            },
                                            onShowSizeChange: (activityGoodsSkuPageIndex, activityGoodsSkuPageSize) => {
                                                handleChangeActivityGoodsSkuPagination(
                                                    activityGoodsSkuPageIndex,
                                                    activityGoodsSkuPageSize,
                                                    activityCategoryIndex
                                                );
                                            },
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    );
                })}
                {isEdit ? (
                    <Button
                        className={Style.card_top}
                        size="large"
                        icon={<PlusCircleOutlined />}
                        onClick={() => {
                            handleAddActivityCategoryList();
                        }}
                    >
                        新增活动分类
                    </Button>
                ) : null}
            </ChuangShiOS.Content>
            <div className={Style.footer}></div>
            {isEdit ? (
                <BannerModal
                    visible={isActivityBanner}
                    activityBanner={activityBanner}
                    onClose={handleCloseActivityBanner}
                    onSubmit={handleSubmitActivityBanner}
                ></BannerModal>
            ) : null}
            {isEdit ? (
                <GoodsSkuListModal
                    visible={isActivityGoodsSkuList}
                    activityId={props.match.params.activityId}
                    activityCategoryId={activityCategoryId}
                    onClose={handleCloseActivityGoodsSkuList}
                    onSubmit={handleSubmitActivityGoodsSkuList}
                ></GoodsSkuListModal>
            ) : null}
            {isEdit ? (
                <GoodsSkuEditModal
                    visible={isActivityGoodsSkuEdit}
                    activityGoodsSku={activityGoodsSku}
                    onClose={handleCloseActivityGoodsSkuEdit}
                    onSubmit={handleSubmitActivityGoodsSkuEdit}
                ></GoodsSkuEditModal>
            ) : null}
            {isEdit ? (
                <GoodsSkuBatchModal
                    visible={isActivityGoodsSkuBatch}
                    activityGoodsSkuIdList={activityCategoryIndex > -1 ? activityCategoryList[activityCategoryIndex].activityGoodsSkuIdList : []}
                    onClose={handleCloseActivityGoodsSkuBatch}
                    onSubmit={handleSubmitActivityGoodsSkuBatch}
                ></GoodsSkuBatchModal>
            ) : null}
        </div>
    );
};

ActivityDetail.propTypes = {};

ActivityDetail.defaultProps = {};

export default ActivityDetail;
