import React from "react";
import {Route} from "react-router-dom";
import KeepAlive from "react-activation";
import ChuangShiOS from "chuangshi-os-website-component";

import CityIndex from "./Index";
import CityDetail from "./Detail";

const data = [
    <Route
        exact
        key="CityIndex"
        path="/city/index"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return (
                <KeepAlive name="/city/index">
                    <CityIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="CityAdd"
        path="/city/add"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <CityDetail {...props} />;
        }}
    />,
    <Route
        exact
        key="CityEdit"
        path="/city/edit/:cityId"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <CityDetail {...props} />;
        }}
    />,
];

export default data;
