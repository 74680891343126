import React from "react";
import {Route} from "react-router-dom";
import KeepAlive from "react-activation";
import ChuangShiOS from "chuangshi-os-website-component";

import MemberIndex from "./Index";
import MemberDetail from "./Detail";

const data = [
    <Route
        exact
        key="MemberIndex"
        path="/member/index"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return (
                <KeepAlive name="/member/index">
                    <MemberIndex {...props} />
                </KeepAlive>
            );
        }}
    />,
    <Route
        exact
        key="MemberAdd"
        path="/member/add"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <MemberDetail {...props} />;
        }}
    />,
    <Route
        exact
        key="MemberEdit"
        path="/member/edit/:memberId"
        render={(props) => {
            ChuangShiOS.Util.validateToken(props);

            return <MemberDetail {...props} />;
        }}
    />,
];

export default data;
