import React, {useState, useRef, useEffect} from "react";
import {message} from "antd";
import {PlusOutlined, CloseCircleFilled} from "@ant-design/icons";
import ChuangShiOS from "chuangshi-os-website-component";

import EnterpriseModal from "../enterprise/Modal";

import Application from "../../common/Application";

import Style from "./Input.module.css";

const EnterpriseInput = (props) => {
    const request = useRef(false);
    const [isModal, setIsModal] = useState(false);
    const [enterpriseId, setEnterpriseId] = useState("");
    const [enterpriseName, setEnterpriseName] = useState("");

    useEffect(() => {
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (props.value) {
            handleLoadEnterprise(props.value);
        }

        //eslint-disable-next-line
    }, [props.value]);

    const handleLoadEnterprise = (enterpriseId) => {
        if (request.current) {
            return;
        }
        request.current = true;

        ChuangShiOS.Util.request(props, {
            url: Application.host + "/windeal/enterprise/business/website/v1/find",
            data: {
                enterpriseId: enterpriseId,
            },
            success: (response) => {
                setEnterpriseId(response.data.enterpriseId);
                setEnterpriseName(response.data.enterpriseName);
            },
            error: (response) => {
                message.error({content: response.message, duration: 1});
            },
            complete: (response) => {
                request.current = false;
            },
        });
    };

    const handleOpenModal = () => {
        setIsModal(true);
    };

    const handleCloseModal = () => {
        setIsModal(false);
    };

    const handleSubmitModal = (enterprise) => {
        setIsModal(false);
        setEnterpriseId(enterprise.enterpriseId);
        setEnterpriseName(enterprise.enterpriseName);

        props.onChange(enterprise.enterpriseId);
    };

    const handleClearEnterprise = () => {
        setEnterpriseId("");
        setEnterpriseName("");

        props.onChange("");
    };

    return (
        <React.Fragment>
            <div className={Style.input + " ant-input-affix-wrenterpriseer " + props.className}>
                <div className={Style.input_text} onClick={handleOpenModal}>
                    {enterpriseId == "" && enterpriseName == "" ? <span className={Style.input_placeholder}>{props.placeholder}</span> : null}
                    {enterpriseName ? enterpriseName : enterpriseId}
                </div>
                {enterpriseId == "" ? (
                    <div className={Style.input_clear + " ant-input-clear-icon"} onClick={handleClearEnterprise}>
                        <PlusOutlined />
                    </div>
                ) : (
                    <div className={Style.input_clear + " ant-input-clear-icon"} onClick={handleClearEnterprise}>
                        <CloseCircleFilled />
                    </div>
                )}
            </div>
            <EnterpriseModal visible={isModal} multiple={false} onClose={handleCloseModal} onSubmit={handleSubmitModal}></EnterpriseModal>
        </React.Fragment>
    );
};

EnterpriseInput.propTypes = {};

EnterpriseInput.defaultProps = {};

export default EnterpriseInput;
